import { getDirtyFields, mapFieldsToFormValues } from '@aquga/app/formHelpers';
import { isBusinessSide, mapParticipantAccountFormValuesToFieldInputs } from '@aquga/app/participantHelpers';
import validator from '@aquga/app/validators';
import CountryCodeField from '@aquga/components/common/CountryCodeField';
import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { Field } from '@aquga/services/randevuApi/generatedTypes';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant, updateMyFields, updateMyFieldsFailed } from '@aquga/store/slices/participantSlice';
import { Button, Divider, Grid, Stack } from '@mui/material';
import { FormApi } from 'final-form';
import { Debug, Select, TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { useIntl, FormattedMessage } from 'react-intl';

interface CompanyInfoFormProps {
	participantFields: Field[];
	loading: boolean;
}

export interface CompanyInfoFormValues {
	kind: 'companyInfo';
	company_name: string;
	industries: string;
	vat_id: string;
	city: string;
	country: string;
	zip_code: string;
	street_name: string;
	house_number: string;
	address_line_2: string | null;
}
const CompanyInfoForm = ({ loading, participantFields }: CompanyInfoFormProps) => {
	const intl = useIntl();
	const dispatch = useAppDispatch();
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const userType = isBusinessSide(currentParticipant)
		? RANDEVU_IDS.USER_TYPE_CUSTOMER
		: RANDEVU_IDS.USER_TYPE_CONSULTING_HOUSE;

	const industryOptions = [
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.automotive',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.AUTOMOTIVE,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.mechanical_electrical_engineering',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.MECHANICAL_ELECTRICAL_ENGINEERING,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.chemistry_pharmaceuticals',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.CHEMISTRY_PHARMACEUTICALS,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.consumer',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.CONSUMER,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.trade_retail',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.TRADE_RETAIL,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.logistics',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.LOGISTICS,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.service',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.SERVICE,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.banking_insurance',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.BANKING_INSURANCE,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.energy',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.ENERGY,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.aerospace_and_defense',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.AEROSPACE_AND_DEFENSE,
		},
	];

	const handleValidate = (values: any) => {
		const fieldValidators = {
			vat_id: [
				(value: string) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
			],
			company_name: [
				(value: string) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
				(value: string) =>
					validator.minLength({
						value,
						min: 3,
						errorMsg: intl.formatMessage({ id: 'validation.general.minLengthThreeCharacters' }),
					}),
			],
			industries: [
				(value: string) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
			],
			city: [
				(value: string) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
			],
			zip_code: [
				(value: string) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
			],
			country_code: [
				(value: string) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
			],
			street_name: [
				(value: string) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
			],
			street_number: [
				(value: string) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
			],
		};

		return validator.doValidation(fieldValidators, values);
	};

	const handleSubmit = async (values: any, form: FormApi) => {
		const dirtyValues = getDirtyFields({ ...values }, form);
		const fieldValues = mapParticipantAccountFormValuesToFieldInputs(dirtyValues);

		const resolvedAction = await dispatch(updateMyFields({ fields: fieldValues, fieldsGroup: 'companyInfo' }));

		if (resolvedAction.type === updateMyFieldsFailed.type) {
			return resolvedAction.payload;
		}
	};

	return (
		<Form
			onSubmit={handleSubmit}
			validate={handleValidate}
			initialValues={mapFieldsToFormValues(participantFields)}
			render={({ handleSubmit, pristine, form, submitting }) => (
				<form onSubmit={handleSubmit} noValidate={true}>
					<Grid
						container
						direction="row"
						alignItems="center"
						justifyContent="center"
						spacing={3}
						textAlign="center"
						paddingBottom={5}
					>
						<Grid item textAlign="center" width="inherit">
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								disabled={submitting}
								name="company_name"
								label={intl.formatMessage({
									id: 'participantOnboardingPage.forms.onboarding.companyName.label',
								})}
								placeholder={intl.formatMessage({
									id: 'participantOnboardingPage.forms.onboarding.companyName.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item textAlign="left" width="inherit">
							<Select
								label={intl.formatMessage({
									id: 'participantOnboardingPage.forms.onboarding.industryField',
								})}
								name="industries"
								required
								disabled={submitting}
								data={industryOptions}
							/>
						</Grid>
						<Grid item textAlign="center" width="inherit">
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								name="vat_id"
								disabled={submitting}
								label={intl.formatMessage({
									id: 'participantOnboardingPage.forms.onboarding.vatId.label',
								})}
								placeholder={intl.formatMessage({
									id: 'participantOnboardingPage.forms.onboarding.vatId.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item textAlign="center" width="inherit">
							<Divider variant="middle" />
						</Grid>
						<Grid item textAlign="center" width="inherit">
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								disabled={submitting}
								name="city"
								label={intl.formatMessage({
									id: 'participantOnboardingPage.forms.companyLocation.city.label',
								})}
								placeholder={intl.formatMessage({
									id: 'participantOnboardingPage.forms.companyLocation.city.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item textAlign="left" width="inherit">
							<CountryCodeField
								name="country_code"
								label={intl.formatMessage({
									id: 'participantOnboardingPage.forms.companyLocation.country',
								})}
							/>
						</Grid>
						<Grid item textAlign="center" width="inherit">
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								disabled={submitting}
								name="zip_code"
								label={intl.formatMessage({
									id: 'participantOnboardingPage.forms.companyLocation.zipCode.label',
								})}
								placeholder={intl.formatMessage({
									id: 'participantOnboardingPage.forms.companyLocation.zipCode.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item textAlign="center" width="inherit">
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								disabled={submitting}
								name="street_name"
								label={intl.formatMessage({
									id: 'participantOnboardingPage.forms.companyLocation.streetName.label',
								})}
								placeholder={intl.formatMessage({
									id: 'participantOnboardingPage.forms.companyLocation.streetName.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item textAlign="center" width="inherit">
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								disabled={submitting}
								name="street_number"
								label={intl.formatMessage({
									id: 'participantOnboardingPage.forms.companyLocation.houseNumber.label',
								})}
								placeholder={intl.formatMessage({
									id: 'participantOnboardingPage.forms.companyLocation.houseNumber.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item textAlign="center" width="inherit">
							<TextField
								InputLabelProps={{ shrink: true }}
								name="address_line_2"
								disabled={submitting}
								fieldProps={{ allowNull: true, parse: (value) => (value === '' ? null : value) }}
								label={intl.formatMessage({
									id: 'participantOnboardingPage.forms.companyLocation.addressLine2.label',
								})}
								placeholder={intl.formatMessage({
									id: 'participantOnboardingPage.forms.companyLocation.addressLine2.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>

						<Grid item xs={12}>
							<Stack direction="row" justifyContent="space-between">
								<Button
									variant="outlined"
									color="primary"
									onClick={() => {
										form.restart();
									}}
								>
									<FormattedMessage id="common.cancel" />
								</Button>
								<RdvLoadingButton
									disabled={pristine}
									variant="contained"
									color="primary"
									type="submit"
									loading={loading}
									loadingPosition="center"
								>
									<FormattedMessage id="common.save" />
								</RdvLoadingButton>
							</Stack>
						</Grid>
					</Grid>
				</form>
			)}
		/>
	);
};

export default CompanyInfoForm;

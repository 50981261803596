import { getDirtyFields } from '@aquga/app/formHelpers';
import { mapParticipantAccountFormValuesToFieldInputs } from '@aquga/app/participantHelpers';
import validator from '@aquga/app/validators';
import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import { useAppDispatch } from '@aquga/store/configureStore';
import { updateMyFields, updateMyFieldsFailed } from '@aquga/store/slices/participantSlice';
import { Button, Grid, Stack } from '@mui/material';
import { FormApi } from 'final-form';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { useIntl, FormattedMessage } from 'react-intl';

interface PointOfContactFormProps {
	initialFormValues: PointOfContactFormValues;
	loading: boolean;
}

export interface PointOfContactFormValues {
	contactPerson?: string;
	email?: string;
	phoneNumber?: string;
}
const PointOfContactForm = ({ loading, initialFormValues }: PointOfContactFormProps) => {
	const intl = useIntl();
	const dispatch = useAppDispatch();

	const handleSubmit = async (values: PointOfContactFormValues, form: FormApi) => {
		const dirtyValues = getDirtyFields({ ...values }, form);
		const fieldValues = mapParticipantAccountFormValuesToFieldInputs(dirtyValues);

		const resolvedAction = await dispatch(updateMyFields({ fields: fieldValues, fieldsGroup: 'pointOfContact' }));

		if (resolvedAction.type === updateMyFieldsFailed.type) {
			return resolvedAction.payload;
		}
	};

	const handleValidate = (values: PointOfContactFormValues) => {
		const fieldValidators = {
			contact_email: [
				(value: string) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
				(value: string) =>
					validator.isEmail({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.invalidEmail' }),
					}),
			],
			contact_person: [
				(value: string) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
				(value: string) =>
					validator.minLength({
						value,
						min: 2,
						errorMsg: intl.formatMessage({ id: 'validation.general.minLengthThreeCharacters' }),
					}),
			],
			phone_number: [
				(value: string) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
				(value: string) =>
					validator.minLength({
						value,
						min: 5,
						errorMsg: intl.formatMessage({ id: 'validation.general.minLengthFiveCharacters' }),
					}),
			],
		};

		return validator.doValidation(fieldValidators, values);
	};

	return (
		<Form
			onSubmit={handleSubmit}
			validate={handleValidate}
			initialValues={initialFormValues}
			render={({ handleSubmit, pristine, form, submitting }) => (
				<form onSubmit={handleSubmit} noValidate={true}>
					<Grid
						container
						direction="row"
						alignItems="center"
						justifyContent="center"
						spacing={3}
						textAlign="center"
					>
						<Grid item textAlign="center" xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								disabled={submitting}
								name="contact_person"
								label={intl.formatMessage({
									id: 'registerPage.addContactDetails.contactPerson.label',
								})}
								placeholder={intl.formatMessage({
									id: 'registerPage.addContactDetails.contactPerson.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item textAlign="center" xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								disabled={submitting}
								name="contact_email"
								type="email"
								label={intl.formatMessage({ id: 'registerPage.addContactDetails.email.label' })}
								placeholder={intl.formatMessage({
									id: 'registerPage.addContactDetails.email.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item textAlign="center" xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								disabled={submitting}
								name="phone_number"
								label={intl.formatMessage({
									id: 'registerPage.addContactDetails.phoneNumber.label',
								})}
								placeholder={intl.formatMessage({
									id: 'registerPage.addContactDetails.phoneNumber.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Stack direction="row" justifyContent="space-between">
								<Button
									variant="outlined"
									color="primary"
									onClick={() => {
										form.restart(initialFormValues);
									}}
								>
									<FormattedMessage id="common.cancel" />{' '}
								</Button>
								<RdvLoadingButton
									disabled={pristine}
									variant="contained"
									color="primary"
									type="submit"
									loading={loading}
									loadingPosition="center"
								>
									<FormattedMessage id="common.save" />{' '}
								</RdvLoadingButton>
							</Stack>
						</Grid>
					</Grid>
				</form>
			)}
		/>
	);
};

export default PointOfContactForm;

import { isBusinessSide } from '@aquga/app/participantHelpers';
import AddCompanyLocationView from '@aquga/components/register/AddCompanyLocationView';
import AddContactDetailsView from '@aquga/components/register/AddContactDetailsView';
import WhatIsYourCompanyView from '@aquga/components/register/WhatIsYourCompanyView';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant, selectLoading } from '@aquga/store/slices/participantSlice';

interface OnboardingViewProps {
	onboardingState: string;
}

const OnboardingView = ({ onboardingState }: OnboardingViewProps) => {
	const currentParticipant = useAppSelector(selectCurrentParticipant);

	const loading = useAppSelector(selectLoading);
	const userType = isBusinessSide(currentParticipant)
		? RANDEVU_IDS.USER_TYPE_CUSTOMER
		: RANDEVU_IDS.USER_TYPE_CONSULTING_HOUSE;
	if (onboardingState === userType.ONBOARDING.STATE_TYPES.COMPANY_INFORMATION) {
		return <WhatIsYourCompanyView userType={userType} loading={loading} />;
	}

	if (onboardingState === userType.ONBOARDING.STATE_TYPES.LOCATION_INFORMATION) {
		return <AddCompanyLocationView loading={loading} />;
	}

	if (onboardingState === userType.ONBOARDING.STATE_TYPES.CONTACT_INFORMATION) {
		return <AddContactDetailsView loading={loading} />;
	}

	return <></>;
};

export default OnboardingView;

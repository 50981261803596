import { Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

interface ContactDetailsViewProps {
	contactPerson: string;
	contactEmail: string;
	contactNumber: string;
	loading: boolean;
}

const ContactDetailsView = ({ contactPerson, contactEmail, contactNumber, loading }: ContactDetailsViewProps) => {
	const intl = useIntl();
	return (
		<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" mt={1} spacing={1}>
			{loading ? (
				<Skeleton width={100} />
			) : (
				<Typography variant="overline">
					{intl.formatMessage({ id: 'questions.details.contactDetailsView.title' })}
				</Typography>
			)}
			{loading ? (
				<Skeleton width={80} />
			) : (
				<Typography variant="body1">
					{intl.formatMessage({ id: 'questions.details.contactDetailsView.name' })}
					{contactPerson}
				</Typography>
			)}
			{loading ? (
				<Skeleton width={100} />
			) : (
				<Typography variant="body1">
					{intl.formatMessage({ id: 'questions.details.contactDetailsView.phone' })}
					{contactNumber}
				</Typography>
			)}
			{loading ? (
				<Skeleton width={100} />
			) : (
				<Typography variant="body1">
					{intl.formatMessage({ id: 'questions.details.contactDetailsView.email' })}
					{contactEmail}
				</Typography>
			)}
		</Stack>
	);
};

export default ContactDetailsView;

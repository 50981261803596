import Footer from '@aquga/components/layout/footer/Footer';
import PublicHeader from '@aquga/components/layout/header/PublicHeader';
import { styled } from '@mui/system';
import { Outlet } from 'react-router-dom';

import ProtectedHeader from './header/ProtectedHeader';

const StyledMain = styled('main')(() => ({
	padding: '0 0px 0 2rem',
	display: 'flex',
	flex: '1',
	height: 'inherit',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	overflowX: 'hidden',
	overflowY: 'hidden',
}));

const PrivateLayout = () => {
	return (
		<>
			<ProtectedHeader />
			<StyledMain>
				<Outlet />
			</StyledMain>
		</>
	);
};

export default PrivateLayout;

// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { ROUTES } from '@aquga/config/routes';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface NavigationItemsProps {
	onDrawerToggle: (params: any) => any;
}

const NavigationItems = ({ onDrawerToggle }: NavigationItemsProps) => {
	return (
		<Box sx={{ textAlign: 'left' }}>
			<Grid container sx={{ justifyContent: 'space-between', alignItems: 'center', padding: '20px' }}>
				<Grid item>
					<Typography
						color="textSecondary"
						fontWeight="bold"
						sx={{ my: 2, paddingLeft: '3%', paddingTop: '3%', textTransform: 'uppercase' }}
					>
						<FormattedMessage id="navigation.menu" />
					</Typography>
				</Grid>
				<Grid item>
					<IconButton sx={{ alignItems: 'flex-end' }} onClick={onDrawerToggle}>
						<CloseIcon />
					</IconButton>
				</Grid>
			</Grid>
			<List>
				<ListItem>
					<Button component={Link} variant="contained" color="quaternary" to={ROUTES.SIGN_UP_BUSINESS_PAGE}>
						<FormattedMessage id="common.btnAskQuestions" />
					</Button>
				</ListItem>
				<ListItem>
					<Button
						component={Link}
						variant="contained"
						color="tertiary"
						to={ROUTES.SIGN_UP_CONSULTING_HOUSE_PAGE}
					>
						<FormattedMessage id="common.btnAnswerQuestions" />
					</Button>
				</ListItem>
				<ListItem>
					<Button
						to={ROUTES.SIGN_IN_PAGE}
						component={Link}
						disableRipple
						variant="text"
						color="secondary"
						sx={{ backgroundColor: 'transparent' }}
					>
						<FormattedMessage id="common.btnSignIn" />
					</Button>
				</ListItem>
			</List>
		</Box>
	);
};

export default NavigationItems;

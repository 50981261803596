import { SECURITY_FORMS_MAX_WIDTH } from '@aquga/app/constants';
import { mapRegisterFormValuesToSignUpRequest } from '@aquga/app/participantHelpers';
import { validator } from '@aquga/app/validators';
import RegisterForm, { RegisterFormValues } from '@aquga/components/register/RegisterForm';
import RegisterFormBottomBar from '@aquga/components/register/RegisterFormBottomBar';
import RegistrationSubmittedView from '@aquga/components/register/RegistrationSubmittedView';
import SecurityPageLayout from '@aquga/components/security/SecurityPageLayout';
import SecurityPageTopBar from '@aquga/components/security/SecurityPageTopBar';
import { ROUTES } from '@aquga/config/routes';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { selectLoading, signupParticipant, signUpParticipantFailed } from '@aquga/store/slices/authSlice';
import { AQUGA_BLUE_30, themeBusinessSide } from '@aquga/styles/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const RegistrationBusinessPage = () => {
	const intl = useIntl();
	const metaTitle = `${intl.formatMessage({ id: 'registerPage.businessSide.metaTitle' })} - AQUGA`;
	const [isEmailSent, setEmailSent] = useState(false);
	const dispatch = useAppDispatch();
	const loading = useAppSelector(selectLoading);

	const handleValidateRegisterBusinessForm = (values: RegisterFormValues) => {
		const fieldValidators = {
			email: [
				(value: any) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
				(value: any) =>
					validator.isEmail({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.invalidEmail' }),
					}),
			],
			firstName: [
				(value: any) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
			],
			lastName: [
				(value: any) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
			],
			acceptedTerms: [
				(value: any) =>
					validator.isTrue({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
			],
		};

		return validator.doValidation(fieldValidators, values);
	};

	const handleSubmitRegisterBusinessForm = async (values: RegisterFormValues) => {
		values.isCustomer = true;
		const resolvedAction = await dispatch(signupParticipant(mapRegisterFormValuesToSignUpRequest({ ...values })));

		if (resolvedAction.type === signUpParticipantFailed.type) {
			const submissionErrors = { ...resolvedAction.payload };

			// TODO: write nice helper function which does this one
			Object.entries(submissionErrors).map((keyValue) => {
				submissionErrors[keyValue[0]] = intl.formatMessage({ id: keyValue[1] as string });
			});

			return submissionErrors;
		}
		setEmailSent(true);
	};

	return (
		<>
			<Helmet>
				<title>{metaTitle}</title>
			</Helmet>
			<SecurityPageLayout backgroundColor={AQUGA_BLUE_30}>
				<ThemeProvider theme={themeBusinessSide}>
					<CssBaseline />
					<Grid
						container
						justifyContent="center"
						maxWidth={SECURITY_FORMS_MAX_WIDTH}
						marginLeft="auto"
						marginRight="auto"
					>
						<Grid item xs={12} paddingLeft={2} marginBottom={3}>
							<SecurityPageTopBar step={isEmailSent ? 2 : 1} numberOfSteps={6} />
						</Grid>
						{isEmailSent ? (
							<RegistrationSubmittedView />
						) : (
							<>
								<Grid item xs={12} marginBottom={8}>
									<RegisterForm
										loading={loading}
										participantRole="business"
										onSubmit={handleSubmitRegisterBusinessForm}
										onValidate={handleValidateRegisterBusinessForm}
									/>
								</Grid>
								<Grid item xs={12} marginBottom={4}>
									<RegisterFormBottomBar
										href={ROUTES.SIGN_UP_CONSULTING_HOUSE_PAGE}
										label={intl.formatMessage({
											id: 'registerPage.businessSide.signUpAsOtherSide',
										})}
									/>
								</Grid>
							</>
						)}
					</Grid>
				</ThemeProvider>
			</SecurityPageLayout>
		</>
	);
};
export default RegistrationBusinessPage;

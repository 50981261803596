import { findFieldByTechName } from '@aquga/app/fieldHelpers';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { Skeleton, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface RequestedChangesViewProps {
	matchFields: any;
	loading: boolean;
}

export const RequestedChangesView = ({ matchFields, loading }: RequestedChangesViewProps) => {
	const requestedChanges = findFieldByTechName(
		matchFields,
		RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.AGREED_DESCRIPTION
	)?.value;
	return (
		<Stack direction="column" justifyContent="flex-start">
			{requestedChanges && (
				<>
					<Typography variant="overline">
						<FormattedMessage id="questions.details.requested_changes_view.title" />
					</Typography>
					{loading ? (
						<Skeleton width={120} />
					) : (
						<Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
							{requestedChanges[requestedChanges.length - 1]}
						</Typography>
					)}
				</>
			)}
		</Stack>
	);
};

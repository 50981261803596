import unifyFieldValue from '@aquga/services/randevuApi/fieldHelper';
import { Participant, User } from '@aquga/services/randevuApi/generatedTypes';

import * as participantMutations from './participantMutations';
import * as participantQueries from './participantQueries';
import { UpdateMyFieldsRequest } from './types';

class ParticipantsService {
	private randevu: any;

	constructor(randevu: any) {
		this.randevu = randevu;
	}

	async getCurrentUser(): Promise<{ user: User; errors: any }> {
		const payload = participantQueries.getCurrentUser();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { user: data.me, errors };
	}

	async getCurrentParticipant(): Promise<{ participant: Participant | null; errors: any }> {
		const payload = participantQueries.myParticipants();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const participant = data?.myParticipants[0];

		const fields = unifyFieldValue(participant?.fields);

		if (participant) {
			participant.fields = fields;
		}

		return { participant: participant, errors };
	}

	async updateParticipant({ id, fields }: UpdateMyFieldsRequest): Promise<{ updated: boolean; errors: any }> {
		const payload = participantMutations.updateParticipant({ id, fields });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { updated: data?.updateUser as boolean, errors };
	}
}

export default ParticipantsService;

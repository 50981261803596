import Footer from '@aquga/components/layout/footer/Footer';
import PublicHeader from '@aquga/components/layout/header/PublicHeader';
import { styled } from '@mui/system';
import { Outlet } from 'react-router-dom';

const StyledMain = styled('main')(() => ({
	padding: '0 0 0 0',
	display: 'flex',
	flex: '1',
	height: 'inherit',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	overflowX: 'hidden',
	overflowY: 'hidden',
}));

const PublicLayout = () => {
	return (
		<>
			<PublicHeader />
			<StyledMain>
				<Outlet />
			</StyledMain>
			<Footer />
		</>
	);
};

export default PublicLayout;

import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { AQUGA_BLUE_20, AQUGA_WARNING_10, AQUGA_SUCCESS_20, AQUGA_PURPLE_50, AQUGA_BLUE_40 } from '@aquga/styles/theme';
import { Skeleton, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useIntl } from 'react-intl';

interface QuestionStatusProps {
	loading: boolean;
	status: string;
}

export const determineQuestionStatusColor = (status: string) => {
	if (status?.toUpperCase() == RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.OPEN.TECH_NAME?.toUpperCase())
		return { backgroundColor: AQUGA_SUCCESS_20, color: AQUGA_PURPLE_50, fontWeight: 600 };

	if (status?.toUpperCase() == RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.IN_PROGRESS.TECH_NAME?.toUpperCase())
		return { backgroundColor: AQUGA_WARNING_10, color: AQUGA_PURPLE_50, fontWeight: 600 };

	if (status?.toUpperCase() == RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.DONE.TECH_NAME?.toUpperCase())
		return { backgroundColor: AQUGA_BLUE_20, color: AQUGA_PURPLE_50, fontWeight: 600 };

	return { backgroundColor: 'orange', color: 'black', fontWeight: 600 };
};

const QuestionStatus = ({ loading, status }: QuestionStatusProps) => {
	const intl = useIntl();

	if (loading) return <Skeleton width={60} height={40} />;

	if (status == RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONSULTANT_APPLIED.TECH_NAME)
		return <Typography variant="body1">-</Typography>;

	const label = intl.formatMessage({ id: `resources.mainTransaction.stateTypes.${status}` });

	const color = determineQuestionStatusColor(status);

	return <Chip label={label} sx={color} />;
};

export default QuestionStatus;

import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentLanguage, setLanguage } from '@aquga/store/slices/uiSlice';
import LanguageIcon from '@mui/icons-material/Language';
import { Divider, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';

function LanguagePickerDrawer() {
	const currentLanguage = useAppSelector(selectCurrentLanguage);
	const dispatch = useAppDispatch();
	const isGermanSelected = currentLanguage?.includes('de');

	return (
		<Stack direction="row" justifyContent="flex-start" alignItems="center" padding={2} spacing={1}>
			<LanguageIcon />
			<Button
				color={isGermanSelected ? 'inherit' : 'primary'}
				variant="text"
				onClick={() => {
					dispatch(setLanguage('en-US'));
				}}
			>
				<Typography variant="caption">
					<FormattedMessage id="langs.english" />
				</Typography>
			</Button>
			<Divider orientation="vertical" variant="middle" />
			<Button
				color={isGermanSelected ? 'primary' : 'inherit'}
				variant="text"
				onClick={() => {
					dispatch(setLanguage('de-DE'));
				}}
			>
				<Typography variant="caption">
					<FormattedMessage id="langs.german" />
				</Typography>
			</Button>
		</Stack>
	);
}

export default LanguagePickerDrawer;

import { isBusinessSide } from '@aquga/app/participantHelpers';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import {
	AQUGA_PURPLE_40,
	AQUGA_NEUTRAL_0,
	AQUGA_BLUE_40,
	AQUGA_SUCCESS_50,
	AQUGA_YELLOW_30,
	AQUGA_NEUTRAL_80,
	AQUGA_PURPLE_50,
	AQUGA_DANGER_10,
	AQUGA_DANGER_30,
} from '@aquga/styles/theme';
import { Skeleton } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useIntl } from 'react-intl';

interface RespondentMatchStatusProps {
	status: string;
	loading: boolean;
}

export const determineMatchStatusColor = (status: string) => {
	if (
		status?.toUpperCase() == RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.DECIDE_LATER.TECH_NAME.toUpperCase()
	)
		return { backgroundColor: AQUGA_BLUE_40, color: AQUGA_NEUTRAL_0, fontWeight: 600 };

	if (status?.toUpperCase() == RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.DECLINED.TECH_NAME.toUpperCase())
		return { backgroundColor: AQUGA_PURPLE_40, color: AQUGA_NEUTRAL_0, fontWeight: 600 };
	if (
		status?.toUpperCase() ==
		RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONSULTANT_APPLIED.TECH_NAME.toUpperCase()
	)
		return { backgroundColor: AQUGA_YELLOW_30, color: AQUGA_NEUTRAL_0, fontWeight: 600 };
	if (
		status?.toUpperCase() ==
		RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.DISMISSED_BY_CUSTOMER.TECH_NAME.toUpperCase()
	)
		return { backgroundColor: AQUGA_NEUTRAL_80, color: AQUGA_PURPLE_50, fontWeight: 600 };
	if (
		status?.toUpperCase() ==
		RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.DECLINED_BY_CUSTOMER.TECH_NAME.toUpperCase()
	)
		return { backgroundColor: AQUGA_PURPLE_40, color: AQUGA_NEUTRAL_0, fontWeight: 600 };
	if (
		status?.toUpperCase() ==
		RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.AUTO_REJECTED.TECH_NAME.toUpperCase()
	)
		return { backgroundColor: AQUGA_DANGER_10, color: AQUGA_NEUTRAL_0, fontWeight: 600 };
	if (
		status?.toUpperCase() ==
		RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONTRACT_WITHDRAWN.TECH_NAME.toUpperCase()
	)
		return { backgroundColor: AQUGA_DANGER_30, color: AQUGA_NEUTRAL_0, fontWeight: 600 };

	return { backgroundColor: AQUGA_SUCCESS_50, color: AQUGA_NEUTRAL_0, fontWeight: 600 };
};

// FIXME: @Nikola rename prop "status" to stateTypeName
const RespondentMatchStatus = ({ status, loading }: RespondentMatchStatusProps) => {
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const businessSide = isBusinessSide(currentParticipant);
	const intl = useIntl();
	if (loading) return <Skeleton width={60} height={40} />;
	if (
		status?.toUpperCase() ==
			RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONSULTANT_APPLIED.TECH_NAME.toUpperCase() &&
		businessSide
	)
		return <p>-</p>;

	const localizedName =
		status.toUpperCase() === RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.DECIDE_LATER.TECH_NAME.toUpperCase()
			? businessSide
				? status
				: RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONSULTANT_APPLIED.TECH_NAME
			: status.toUpperCase() ===
			  RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.OPEN_OFFER.TECH_NAME.toUpperCase()
				? status
				: status.toUpperCase() ===
			  RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONSULTANT_APPLIED.TECH_NAME.toUpperCase()
					? status
					: status.toUpperCase() ===
			  RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.DISMISSED_BY_CUSTOMER.TECH_NAME.toUpperCase()
						? status
						: status?.toUpperCase() ==
			  RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.DECLINED_BY_CUSTOMER.TECH_NAME.toUpperCase()
							? status
							: status.toUpperCase() ===
			  RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.DECLINED.TECH_NAME.toUpperCase()
								? status
								: status.toUpperCase() ===
			  RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.AUTO_REJECTED.TECH_NAME.toUpperCase()
									? status
									: status.toUpperCase() ===
			  RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONTRACT_WITHDRAWN.TECH_NAME.toUpperCase()
										? status
										: RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.MATCH_TIME.TECH_NAME;
	const label = intl.formatMessage({ id: `resources.subTransaction.stateTypes.${localizedName}` });

	const color = determineMatchStatusColor(localizedName);

	return <Chip label={label} sx={color} />;
};

export default RespondentMatchStatus;

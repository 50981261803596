import randevu, { isSessionExpired } from '@aquga/services/randevuApi';
import { FieldInput, Supply } from '@aquga/services/randevuApi/generatedTypes';
import { AppDispatch, RootState } from '@aquga/store/configureStore';
import { mapCreateAnsweringExpertiseFieldsToMySupplyFields } from '@aquga/store/helpers';
import { requestReauthentication } from '@aquga/store/slices/authSlice';
import THUNKS from '@aquga/store/thunkKeys';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SupplySliceState {
	loading: boolean;
	items: Supply[];
	item: Supply;
	errors: any | undefined;
}

// Initial state for the slice
const initialState: SupplySliceState = {
	loading: false,
	items: [],
	item: {} as Supply,
	errors: undefined,
};

const supplySlice = createSlice({
	name: 'supply',
	initialState,
	reducers: {
		createSupplyRequested: (state: SupplySliceState) => {
			state.loading = true;
			state.errors = null;
		},
		createSupplyFailed: (state: SupplySliceState, action: PayloadAction<any>) => {
			state.loading = false;
			state.errors = action.payload;
		},
		supplyCreated: (state: SupplySliceState, action: PayloadAction<Supply>) => {
			state.loading = false;
			state.item = action.payload;
		},
		loadMySuppliesRequested: (state: SupplySliceState) => {
			state.loading = true;
			state.errors = null;
		},
		loadMySuppliesFailed: (state: SupplySliceState, action: PayloadAction<any>) => {
			state.loading = false;
			state.errors = action.payload;
		},
		mySuppliesLoaded: (state: SupplySliceState, action: PayloadAction<any>) => {
			state.loading = false;
			state.errors = null;
			state.items = action.payload;
		},
		loadMySupplyRequested: (state: SupplySliceState) => {
			state.loading = true;
			state.errors = null;
		},
		loadMySupplyFailed: (state: SupplySliceState, action: PayloadAction<any>) => {
			state.loading = false;
			state.errors = action.payload;
		},
		mySupplyLoaded: (state: SupplySliceState, action: PayloadAction<any>) => {
			state.loading = false;
			state.errors = null;
			state.item = action.payload;
		},
		updateSupplyRequested: (state: SupplySliceState) => {
			state.loading = true;
			state.errors = null;
		},
		updateSupplyFailed: (state: SupplySliceState, action: PayloadAction<any>) => {
			state.loading = false;
			state.errors = action.payload;
		},
		supplyUpdated: (state: SupplySliceState, action: PayloadAction<Supply>) => {
			state.loading = false;
			state.item = action.payload;
		},
	},
});

export const {
	loadMySuppliesRequested,
	loadMySuppliesFailed,
	mySuppliesLoaded,
	createSupplyRequested,
	createSupplyFailed,
	supplyCreated,
	loadMySupplyRequested,
	loadMySupplyFailed,
	mySupplyLoaded,
	updateSupplyRequested,
	updateSupplyFailed,
	supplyUpdated,
} = supplySlice.actions;

/////////////////////
// 	 	THUNKS	   //
/////////////////////

interface CreateSupplyRequest {
	fields: FieldInput[];
	field_additional_docs?: { tech_name: string; value: File[] };
}

export const createSupply =
	({ fields, field_additional_docs }: CreateSupplyRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(createSupplyRequested());
			const randevuService = new randevu({ token: getState().auth.token });

			const input_field_additional_docs_w_uploaded_files = {
				tech_name: field_additional_docs?.tech_name ?? '',
				value: [] as any[],
			};

			const { created_supply: created_supply_id, errors } = await randevuService.supplies.createSupply();

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.CREATE_SUPPLY.key,
						payload: { fields, field_additional_docs },
					})
				);
				return dispatch(createSupplyFailed(undefined));
			}

			if (field_additional_docs && field_additional_docs?.value?.length > 0) {
				input_field_additional_docs_w_uploaded_files.value = await randevuService.files.uploadFiles({
					files: field_additional_docs?.value,
				});

				if (input_field_additional_docs_w_uploaded_files.value.length > 0) {
					fields.push(input_field_additional_docs_w_uploaded_files);
				}
			}

			const { supply_updated, errors: updateErrors } = await randevuService.supplies.updateSupply({
				id: created_supply_id,
				fields: fields,
			});

			const { my_supply: updatedSupply, errors: updatedQueryErrors } = await randevuService.supplies.mySupply({
				id: created_supply_id,
			});

			if (updateErrors) {
				return dispatch(createSupplyFailed(updateErrors));
			}

			return dispatch(supplyCreated(updatedSupply));
		};

interface UpdateSupplyRequest {
	id: string;
	fields: FieldInput[];
	field_additional_docs?: { tech_name: string; value: File[] };
}

export const updateSupply =
	({ id, fields, field_additional_docs }: UpdateSupplyRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(updateSupplyRequested());
			const randevuService = new randevu({ token: getState().auth.token });
			if (field_additional_docs?.value !== null) {
				const filesToBeUploaded = field_additional_docs?.value.filter((file) => file instanceof File);
				const uploadedFiles = field_additional_docs?.value.filter((file) => !(file instanceof File)) || [];

				const input_field_additional_docs_w_uploaded_files = {
					tech_name: field_additional_docs?.tech_name ?? '',
					value: [] as any[] | null,
				};

				if (field_additional_docs && field_additional_docs?.value?.length > 0) {
					input_field_additional_docs_w_uploaded_files.value = await randevuService.files.uploadFiles({
						files: filesToBeUploaded,
					});
					uploadedFiles && input_field_additional_docs_w_uploaded_files.value.push(...uploadedFiles);

					if (input_field_additional_docs_w_uploaded_files?.value?.length > 0) {
						fields.push(input_field_additional_docs_w_uploaded_files);
					}
				}
				if (
					(field_additional_docs && field_additional_docs?.value?.length === 0) ||
				field_additional_docs?.value === null
				) {
					input_field_additional_docs_w_uploaded_files.value = null;
					fields.push(input_field_additional_docs_w_uploaded_files);
				}
			} else {
				const input_field_additional_docs_w_uploaded_files = {
					tech_name: field_additional_docs?.tech_name ?? '',
					value: null,
				};
				fields.push(input_field_additional_docs_w_uploaded_files);
			}

			const { supply_updated, errors } = await randevuService.supplies.updateSupply({
				id: id,
				fields: fields,
			});
			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.UPDATE_SUPPLY.key,
						payload: { fields, field_additional_docs },
					})
				);
				return dispatch(updateSupplyFailed(undefined));
			}

			const { my_supply: updatedSupply, errors: updatedQueryErrors } = await randevuService.supplies.mySupply({
				id,
			});

			if (errors) {
				return dispatch(updateSupplyFailed(errors));
			}

			return dispatch(supplyUpdated(updatedSupply));
		};
export const loadMySupplies = () => async (dispatch: AppDispatch, getState: () => RootState) => {
	dispatch(loadMySuppliesRequested());

	const randevuService = new randevu({ token: getState().auth.token });
	const { my_supplies, errors } = await randevuService.supplies.mySupplies();

	if (isSessionExpired(errors)) {
		dispatch(
			requestReauthentication({
				callbackThunkKey: THUNKS.LOAD_MY_SUPPLIES.key,
			})
		);
		return loadMySuppliesFailed(errors);
	}
	if (errors) {
		return dispatch(loadMySuppliesFailed(JSON.stringify(errors)));
	}

	return dispatch(mySuppliesLoaded(my_supplies));
};

interface LoadMySupplyRequest {
	id: string;
}

export const loadMySupply =
	({ id }: LoadMySupplyRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(loadMySupplyRequested());

			const randevuService = new randevu({ token: getState().auth.token });
			const { my_supply, errors } = await randevuService.supplies.mySupply({ id });

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.LOAD_MY_SUPPLY.key,
					})
				);
				return loadMySupplyFailed(errors);
			}
			if (errors) {
				return dispatch(loadMySupplyFailed(JSON.stringify(errors)));
			}

			return dispatch(mySupplyLoaded(my_supply));
		};

/////////////////////
//   SELECTORS     //
/////////////////////
export const selectLoading = (state: RootState) => state.supply.loading;
export const selectMySupplies = (state: RootState) => state.supply.items;
export const selectMySupply = (state: RootState): Supply => state.supply.item;

export default supplySlice.reducer;

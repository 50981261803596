export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	DateTime: any;
	HttpURL: any;
	JSON: any;
	JSONObject: any;
	Upload: any;
};

export type ApiKey = {
	__typename?: 'APIKey';
	created_at: Scalars['DateTime'];
	token: Scalars['String'];
	type: ApiKeyKind;
};

export enum ApiKeyKind {
	Public = 'PUBLIC',
	Secret = 'SECRET',
}

export type ApiUser = {
	__typename?: 'APIUser';
	created_at: Scalars['DateTime'];
	id: Scalars['ID'];
	username: Scalars['String'];
};

export type AboutSectionConfig = {
	__typename?: 'AboutSectionConfig';
	contact_button_enabled?: Maybe<Scalars['Boolean']>;
	enabled?: Maybe<Scalars['Boolean']>;
	subtitle?: Maybe<Scalars['String']>;
	text?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

export type AboutSectionConfigInput = {
	contact_button_enabled?: InputMaybe<Scalars['Boolean']>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	subtitle?: InputMaybe<Scalars['String']>;
	text?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

/** Every user (both BO user and a participant) have an account. */
export type Account = {
	__typename?: 'Account';
	address_line_1?: Maybe<Scalars['String']>;
	address_line_2?: Maybe<Scalars['String']>;
	contact_phone?: Maybe<Scalars['String']>;
	country?: Maybe<Scalars['String']>;
	data?: Maybe<Scalars['JSONObject']>;
	id: Scalars['ID'];
	id_owner: Scalars['ID'];
	legal_business_name?: Maybe<Scalars['String']>;
	merchant_accounts?: Maybe<Array<MerchantAccount>>;
	place?: Maybe<Scalars['String']>;
	postal_code?: Maybe<Scalars['String']>;
	support_email?: Maybe<Scalars['String']>;
	support_phone?: Maybe<Scalars['String']>;
	type: AccountKind;
};

export type AccountInput = {
	address_line_1?: InputMaybe<Scalars['String']>;
	address_line_2?: InputMaybe<Scalars['String']>;
	contact_phone?: InputMaybe<Scalars['String']>;
	country?: InputMaybe<Scalars['String']>;
	data?: InputMaybe<Scalars['JSONObject']>;
	domain_name?: InputMaybe<Scalars['String']>;
	legal_business_name?: InputMaybe<Scalars['String']>;
	place?: InputMaybe<Scalars['String']>;
	postal_code?: InputMaybe<Scalars['String']>;
	support_email?: InputMaybe<Scalars['String']>;
	support_phone?: InputMaybe<Scalars['String']>;
};

export enum AccountKind {
	Business = 'BUSINESS',
	Personal = 'PERSONAL',
}

export enum ActionKind {
	AddConsumerToVar = 'ADD_CONSUMER_TO_VAR',
	AddMatchToVar = 'ADD_MATCH_TO_VAR',
	AddProviderToVar = 'ADD_PROVIDER_TO_VAR',
	AddSupplyToVar = 'ADD_SUPPLY_TO_VAR',
	ApproveObject = 'APPROVE_OBJECT',
	CopyFieldValue = 'COPY_FIELD_VALUE',
	ForceTransition = 'FORCE_TRANSITION',
	LockMatchPriceOverride = 'LOCK_MATCH_PRICE_OVERRIDE',
	LockObject = 'LOCK_OBJECT',
	OnboardObject = 'ONBOARD_OBJECT',
	RejectObject = 'REJECT_OBJECT',
	RequestTimeEvent = 'REQUEST_TIME_EVENT',
	SetTimestampField = 'SET_TIMESTAMP_FIELD',
	SetVar = 'SET_VAR',
	UnlockMatchPriceOverride = 'UNLOCK_MATCH_PRICE_OVERRIDE',
	UnlockObject = 'UNLOCK_OBJECT',
	UpdateFieldsAccesses = 'UPDATE_FIELDS_ACCESSES',
	UpdateFieldAccess = 'UPDATE_FIELD_ACCESS',
}

export type ActionType = {
	__typename?: 'ActionType';
	params: Scalars['JSONObject'];
	type: ActionKind;
};

export type ActionTypeInput = {
	params?: InputMaybe<Scalars['JSONObject']>;
	type: ActionKind;
};

export type AddOn = {
	__typename?: 'AddOn';
	description: Scalars['String'];
	guard_expression: Scalars['String'];
	name: Scalars['String'];
	tech_name: Scalars['String'];
	value_expression: Scalars['String'];
};

export type AddOnInput = {
	description: Scalars['String'];
	guard_expression: Scalars['String'];
	name: Scalars['String'];
	tech_name: Scalars['String'];
	value_expression: Scalars['String'];
};

export type AddonItem = {
	__typename?: 'AddonItem';
	description: Scalars['String'];
	value: Scalars['Int'];
};

export enum AvailabilityManagementKind {
	Default = 'DEFAULT',
	External = 'EXTERNAL',
	None = 'NONE',
}

export type AvailabilityParameterMapping = {
	__typename?: 'AvailabilityParameterMapping';
	name: Scalars['String'];
	source_parameter: Scalars['String'];
};

export type AvailabilityRecord = {
	__typename?: 'AvailabilityRecord';
	end_date?: Maybe<Scalars['DateTime']>;
	end_datetime?: Maybe<Scalars['DateTime']>;
	quantity?: Maybe<Scalars['Int']>;
	start_date?: Maybe<Scalars['DateTime']>;
	start_datetime?: Maybe<Scalars['DateTime']>;
};

export type AvailabilityRecordInput = {
	end_date?: InputMaybe<Scalars['DateTime']>;
	end_datetime?: InputMaybe<Scalars['DateTime']>;
	quantity?: InputMaybe<Scalars['Int']>;
	start_date?: InputMaybe<Scalars['DateTime']>;
	start_datetime?: InputMaybe<Scalars['DateTime']>;
};

export enum AvailabilityTrackingKind {
	SimpleDayCalendar = 'SIMPLE_DAY_CALENDAR',
	SimpleStock = 'SIMPLE_STOCK',
	StockCalendar = 'STOCK_CALENDAR',
}

export type AvailabilityTrackingScheme = {
	__typename?: 'AvailabilityTrackingScheme';
	guard_expression: Scalars['String'];
	name: Scalars['String'];
	parameter_mappings: Array<AvailabilityParameterMapping>;
};

export type AvailableIntegrationProvider = {
	__typename?: 'AvailableIntegrationProvider';
	is_default: Scalars['Boolean'];
	name: Scalars['String'];
	provider: Scalars['String'];
	type: IntegrationProviderKind;
};

export type BackofficeUser = {
	__typename?: 'BackofficeUser';
	created_at: Scalars['DateTime'];
	email: Scalars['String'];
	email_verified_at?: Maybe<Scalars['DateTime']>;
	first_name?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	invited_by?: Maybe<BackofficeUser>;
	last_logged_in_at?: Maybe<Scalars['DateTime']>;
	last_name?: Maybe<Scalars['String']>;
	platform_display_name: Scalars['String'];
	platform_name: Scalars['String'];
	platform_onboarding?: Maybe<PlatformOwnerOnboardingProgress>;
	profile_image?: Maybe<Scalars['String']>;
	role: PlatformTeamRole;
};

export type BackofficeUserFilter = {
	email?: InputMaybe<Scalars['String']>;
	first_name?: InputMaybe<Scalars['String']>;
	last_name?: InputMaybe<Scalars['String']>;
	role?: InputMaybe<Array<PlatformTeamRole>>;
};

export enum CacheControlScope {
	Private = 'PRIVATE',
	Public = 'PUBLIC',
}

export type Chargeable = {
	__typename?: 'Chargeable';
	chargeable_items: Array<ChargeableItem>;
	total_after_tax: Scalars['Int'];
	total_before_tax: Scalars['Int'];
	total_fees: Scalars['Int'];
	total_taxes: Scalars['Int'];
	total_to_charge: Scalars['Int'];
};

export type ChargeableItem = {
	__typename?: 'ChargeableItem';
	data: Scalars['JSONObject'];
	description: Scalars['String'];
	type: PriceItemKind;
	value: Scalars['Int'];
};

export type ChooseNewPasswordPageConfig = {
	__typename?: 'ChooseNewPasswordPageConfig';
	form?: Maybe<ChooseNewPasswordPageFormConfig>;
	title?: Maybe<Scalars['String']>;
};

export type ChooseNewPasswordPageConfigInput = {
	form?: InputMaybe<ChooseNewPasswordPageFormConfigInput>;
	title?: InputMaybe<Scalars['String']>;
};

export type ChooseNewPasswordPageFormConfig = {
	__typename?: 'ChooseNewPasswordPageFormConfig';
	button_submit?: Maybe<FrontofficeFormFieldConfig>;
	field_password?: Maybe<FrontofficeFormFieldConfig>;
};

export type ChooseNewPasswordPageFormConfigInput = {
	button_submit?: InputMaybe<FrontofficeFormFieldConfigInput>;
	field_password?: InputMaybe<FrontofficeFormFieldConfigInput>;
};

export type ConditionInput = {
	payload: Scalars['JSONObject'];
	type: FieldConditionKind;
};

export type ConnectedParticipantPageUiConfig = {
	__typename?: 'ConnectedParticipantPageUiConfig';
	title?: Maybe<Scalars['String']>;
};

export type ConnectedParticipantPageUiConfigInput = {
	title?: InputMaybe<Scalars['String']>;
};

export type Connection = {
	__typename?: 'Connection';
	archived_by_end1_at?: Maybe<Scalars['DateTime']>;
	archived_by_end2_at?: Maybe<Scalars['DateTime']>;
	end1: Participant;
	end1_unlocked_to_end2?: Maybe<Scalars['Boolean']>;
	end2: Participant;
	end2_unlocked_to_end1?: Maybe<Scalars['Boolean']>;
	id: Scalars['ID'];
	participant: Participant;
	status: ConnectionStatusKind;
	type: ConnectionType;
};

export enum ConnectionStatusKind {
	Declined = 'DECLINED',
	Established = 'ESTABLISHED',
	Establishing = 'ESTABLISHING',
	Rejected = 'REJECTED',
}

export type ConnectionType = {
	__typename?: 'ConnectionType';
	auto_backoffice_approval: Scalars['Boolean'];
	auto_end1_acceptance: Scalars['Boolean'];
	auto_end2_acceptance: Scalars['Boolean'];
	end1_discovery_enabled: Scalars['Boolean'];
	end1_name: Scalars['String'];
	end1_participant_type: ParticipantType;
	end2_discovery_enabled: Scalars['Boolean'];
	end2_name: Scalars['String'];
	end2_participant_type: ParticipantType;
	id: Scalars['ID'];
	initial_match_visibility: ConnectionVisibilityInfo;
	is_active: Scalars['Boolean'];
	name: Scalars['String'];
	participant_type: ParticipantType;
	tech_name: Scalars['String'];
	type_visibility: ConnectionTypeVisibilityInfo;
};

export type ConnectionTypeVisibilityInfo = {
	__typename?: 'ConnectionTypeVisibilityInfo';
	connection_fields: Array<Scalars['ID']>;
	end1_fields: Array<Scalars['ID']>;
	end1_fields_out_for_end2: Array<Scalars['ID']>;
	end2_fields: Array<Scalars['ID']>;
	end2_fields_out_for_end1: Array<Scalars['ID']>;
};

export type ConnectionTypeVisibilityInfoInput = {
	end1_fields?: InputMaybe<Array<Scalars['ID']>>;
	end1_fields_out_for_end2?: InputMaybe<Array<Scalars['ID']>>;
	end2_fields?: InputMaybe<Array<Scalars['ID']>>;
	end2_fields_out_for_end1?: InputMaybe<Array<Scalars['ID']>>;
};

export type ConnectionVisibilityInfo = {
	__typename?: 'ConnectionVisibilityInfo';
	connection_by_end1: Scalars['Boolean'];
	connection_by_end2: Scalars['Boolean'];
	end1_fields: Array<Scalars['ID']>;
	end2_fields: Array<Scalars['ID']>;
};

export type ConnectionVisibilityInfoInput = {
	connection_by_end1?: InputMaybe<Scalars['Boolean']>;
	connection_by_end2?: InputMaybe<Scalars['Boolean']>;
	connection_fields?: InputMaybe<Array<Scalars['ID']>>;
	end1_fields?: InputMaybe<Array<Scalars['ID']>>;
	end2_fields?: InputMaybe<Array<Scalars['ID']>>;
};

export type ConnectionsPageUiConfig = {
	__typename?: 'ConnectionsPageUiConfig';
	title: Array<Maybe<PageTitleUiConfig>>;
};

export type ConnectionsPageUiConfigInput = {
	title: Array<InputMaybe<PageTitleUiConfigInput>>;
};

export type ContactUsFormConfig = {
	__typename?: 'ContactUsFormConfig';
	button_submit?: Maybe<FrontofficeFormFieldConfig>;
	field_email?: Maybe<FrontofficeFormFieldConfig>;
	field_first_name?: Maybe<FrontofficeFormFieldConfig>;
	field_last_name?: Maybe<FrontofficeFormFieldConfig>;
	field_message?: Maybe<FrontofficeFormFieldConfig>;
	legal_text?: Maybe<ContactUsFormLegalTextConfig>;
};

export type ContactUsFormConfigInput = {
	button_submit?: InputMaybe<FrontofficeFormFieldConfigInput>;
	field_email?: InputMaybe<FrontofficeFormFieldConfigInput>;
	field_first_name?: InputMaybe<FrontofficeFormFieldConfigInput>;
	field_last_name?: InputMaybe<FrontofficeFormFieldConfigInput>;
	field_message?: InputMaybe<FrontofficeFormFieldConfigInput>;
	legal_text?: InputMaybe<ContactUsFormLegalTextConfigInput>;
};

export type ContactUsFormLegalLinkConfig = {
	__typename?: 'ContactUsFormLegalLinkConfig';
	label?: Maybe<Scalars['String']>;
	url?: Maybe<Scalars['String']>;
};

export type ContactUsFormLegalLinkConfigInput = {
	label?: InputMaybe<Scalars['String']>;
	url?: InputMaybe<Scalars['String']>;
};

export type ContactUsFormLegalTextConfig = {
	__typename?: 'ContactUsFormLegalTextConfig';
	links?: Maybe<Array<Maybe<ContactUsFormLegalLinkConfig>>>;
	text?: Maybe<Scalars['String']>;
};

export type ContactUsFormLegalTextConfigInput = {
	links?: InputMaybe<Array<InputMaybe<ContactUsFormLegalLinkConfigInput>>>;
	text?: InputMaybe<Scalars['String']>;
};

export type ContactUsPageAbputSectionConfig = {
	__typename?: 'ContactUsPageAbputSectionConfig';
	text?: Maybe<Scalars['String']>;
};

export type ContactUsPageAbputSectionConfigInput = {
	text?: InputMaybe<Scalars['String']>;
};

export type ContactUsPageConfig = {
	__typename?: 'ContactUsPageConfig';
	additional_info?: Maybe<ContactUsPageAbputSectionConfig>;
	description?: Maybe<Scalars['String']>;
	eyebrow?: Maybe<Scalars['String']>;
	form?: Maybe<ContactUsFormConfig>;
	form_submitted_view?: Maybe<ContactUsPageFormSubmittedViewConfig>;
	title?: Maybe<Scalars['String']>;
};

export type ContactUsPageConfigInput = {
	additional_info?: InputMaybe<ContactUsPageAbputSectionConfigInput>;
	description?: InputMaybe<Scalars['String']>;
	eyebrow?: InputMaybe<Scalars['String']>;
	form?: InputMaybe<ContactUsFormConfigInput>;
	form_submitted_view?: InputMaybe<ContactUsPageFormSubmittedViewConfigInput>;
	title?: InputMaybe<Scalars['String']>;
};

export type ContactUsPageFormSubmittedViewConfig = {
	__typename?: 'ContactUsPageFormSubmittedViewConfig';
	description?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

export type ContactUsPageFormSubmittedViewConfigInput = {
	description?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export enum CurrencyKind {
	Eur = 'EUR',
}

export enum CurrencyUnitKind {
	Cent = 'CENT',
}

export type CustomerFrontendUrl = {
	__typename?: 'CustomerFrontendUrl';
	is_favourite?: Maybe<Scalars['Boolean']>;
	name?: Maybe<Scalars['String']>;
	url?: Maybe<Scalars['String']>;
};

export type CustomerFrontendUrlInput = {
	is_favourite?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	url?: InputMaybe<Scalars['String']>;
};

export type DashboardPageUiConfig = {
	__typename?: 'DashboardPageUiConfig';
	enabled?: Maybe<Scalars['Boolean']>;
	tiles: Array<DashboardTileUiConfig>;
	title: Scalars['String'];
};

export type DashboardPageUiConfigInput = {
	enabled?: InputMaybe<Scalars['Boolean']>;
	tiles: Array<DashboardTileUiConfigInput>;
	title: Scalars['String'];
};

export type DashboardTileUiConfig = {
	__typename?: 'DashboardTileUiConfig';
	ids_participant_type: Array<Maybe<Scalars['ID']>>;
	target_route: Scalars['String'];
	title: Scalars['String'];
};

export type DashboardTileUiConfigInput = {
	ids_participant_type: Array<InputMaybe<Scalars['ID']>>;
	target_route: Scalars['String'];
	title: Scalars['String'];
};

export type Discount = {
	__typename?: 'Discount';
	description: Scalars['String'];
	guard_expression: Scalars['String'];
	name: Scalars['String'];
	tech_name: Scalars['String'];
	value_expression: Scalars['String'];
};

export type DiscountInput = {
	description: Scalars['String'];
	guard_expression: Scalars['String'];
	name: Scalars['String'];
	tech_name: Scalars['String'];
	value_expression: Scalars['String'];
};

export type DiscountItem = {
	__typename?: 'DiscountItem';
	description: Scalars['String'];
	value: Scalars['Int'];
};

export type EMailNotificationRecipient = {
	__typename?: 'EMailNotificationRecipient';
	data?: Maybe<Scalars['JSONObject']>;
	email_recipient_type: EMailRecipientKind;
	parent_type?: Maybe<NotificationFieldParentKind>;
	role_type?: Maybe<NotificationRecipientRoleKind>;
};

export type EMailNotificationRecipientInput = {
	data?: InputMaybe<Scalars['JSONObject']>;
	email_recipient_type: EMailRecipientKind;
	parent_type?: InputMaybe<NotificationFieldParentKind>;
	role_type?: InputMaybe<NotificationRecipientRoleKind>;
};

export enum EMailRecipientKind {
	Bcc = 'BCC',
	Cc = 'CC',
	To = 'TO',
}

export enum EndpointKind {
	Graphql = 'GRAPHQL',
	Rest = 'REST',
}

export type EnvironmentVariable = {
	__typename?: 'EnvironmentVariable';
	id: Scalars['ID'];
	name: Scalars['String'];
	value?: Maybe<Scalars['String']>;
};

export type Event = {
	__typename?: 'Event';
	code: RandevuEventKind;
	id: Scalars['ID'];
	payload: Scalars['JSONObject'];
	triggered_at: Scalars['DateTime'];
};

export enum EventObjectKind {
	Match = 'MATCH',
	MatchingTool = 'MATCHING_TOOL',
	Payment = 'PAYMENT',
	Supply = 'SUPPLY',
	Transaction = 'TRANSACTION',
	User = 'USER',
}

export enum EventObjectTypeKind {
	MatchingToolType = 'MATCHING_TOOL_TYPE',
	MatchType = 'MATCH_TYPE',
	ParticipantType = 'PARTICIPANT_TYPE',
	PaymentType = 'PAYMENT_TYPE',
	SupplyType = 'SUPPLY_TYPE',
	TransactionType = 'TRANSACTION_TYPE',
	UserType = 'USER_TYPE',
}

export type EventSelectorRecord = {
	__typename?: 'EventSelectorRecord';
	event_type: RandevuEventKind;
	ids_event_object_types: Array<Scalars['ID']>;
	ids_field_types?: Maybe<Array<Scalars['ID']>>;
	ids_state_types?: Maybe<Array<Scalars['ID']>>;
	ids_transition_types?: Maybe<Array<Scalars['ID']>>;
};

export type EventSelectorRecordInput = {
	event_type: RandevuEventKind;
	ids_event_object_types: Array<Scalars['ID']>;
	ids_field_types?: InputMaybe<Array<Scalars['ID']>>;
	ids_state_types?: InputMaybe<Array<Scalars['ID']>>;
	ids_transition_types?: InputMaybe<Array<Scalars['ID']>>;
};

export enum ExecutionSchedulingKind {
	OneTime = 'ONE_TIME',
	OnNewSupply = 'ON_NEW_SUPPLY',
}

export type FavouriteFieldType = {
	__typename?: 'FavouriteFieldType';
	input_options: Scalars['JSONObject'];
	input_type: InputKind;
	is_nullable: Scalars['Boolean'];
	is_required: Scalars['Boolean'];
	name: Scalars['String'];
};

export type Field = ObjectField | ObjectSetField | SimpleField;

export enum FieldAccessClientKind {
	Backoffice = 'BACKOFFICE',
	Consumer = 'CONSUMER',
	Creator = 'CREATOR',
	NoCreator = 'NO_CREATOR',
	Owner = 'OWNER',
	Provider = 'PROVIDER',
}

export type FieldAccessInfo = {
	__typename?: 'FieldAccessInfo';
	to_consumer?: Maybe<FieldAccessKind>;
	to_creator?: Maybe<FieldAccessKind>;
	to_guest?: Maybe<FieldAccessKind>;
	to_no_creator?: Maybe<FieldAccessKind>;
	to_owner?: Maybe<FieldAccessKind>;
	to_provider?: Maybe<FieldAccessKind>;
};

export type FieldAccessInfoInput = {
	to_consumer?: InputMaybe<FieldAccessKind>;
	to_creator?: InputMaybe<FieldAccessKind>;
	to_guest?: InputMaybe<FieldAccessKind>;
	to_no_creator?: InputMaybe<FieldAccessKind>;
	to_owner?: InputMaybe<FieldAccessKind>;
	to_provider?: InputMaybe<FieldAccessKind>;
};

export enum FieldAccessKind {
	None = 'NONE',
	Read = 'READ',
	Write = 'WRITE',
}

export enum FieldCategoryKind {
	BasePrice = 'BASE_PRICE',
	Description = 'DESCRIPTION',
	EndDate = 'END_DATE',
	EndDatetime = 'END_DATETIME',
	FirstName = 'FIRST_NAME',
	LastName = 'LAST_NAME',
	MainDatetime = 'MAIN_DATETIME',
	MainImage = 'MAIN_IMAGE',
	Name = 'NAME',
	ProfileImage = 'PROFILE_IMAGE',
	Quantity = 'QUANTITY',
	StartDate = 'START_DATE',
	StartDatetime = 'START_DATETIME',
}

export type FieldCondition = {
	__typename?: 'FieldCondition';
	payload: Scalars['JSONObject'];
	type: FieldConditionKind;
};

export enum FieldConditionKind {
	ValueEqualTo = 'VALUE_EQUAL_TO',
	ValueIsIn = 'VALUE_IS_IN',
	ValueIsNotIn = 'VALUE_IS_NOT_IN',
	ValueNotEqualTo = 'VALUE_NOT_EQUAL_TO',
	ValueProvided = 'VALUE_PROVIDED',
}

export enum FieldContainerKind {
	Match = 'MATCH',
	MatchingTool = 'MATCHING_TOOL',
	Object = 'OBJECT',
	Participant = 'PARTICIPANT',
	Supply = 'SUPPLY',
}

export type FieldFilter = {
	__typename?: 'FieldFilter';
	id_target_field_type?: Maybe<Scalars['ID']>;
	tech_name?: Maybe<Scalars['String']>;
	value?: Maybe<Scalars['JSON']>;
};

export type FieldFilterInput = {
	id_target_field_type?: InputMaybe<Scalars['ID']>;
	tech_name?: InputMaybe<Scalars['String']>;
	value?: InputMaybe<Scalars['JSON']>;
};

export type FieldInput = {
	id?: InputMaybe<Scalars['ID']>;
	tech_name?: InputMaybe<Scalars['String']>;
	value?: InputMaybe<Scalars['JSON']>;
};

export type FieldParameterInput = {
	tech_name: Scalars['String'];
	value?: InputMaybe<Scalars['JSON']>;
};

export type FieldParameterType = {
	__typename?: 'FieldParameterType';
	initial_value?: Maybe<Scalars['JSONObject']>;
	input_options: Scalars['JSONObject'];
	input_type: InputKind;
	is_availability_param: Scalars['Boolean'];
	is_required: Scalars['Boolean'];
	name: Scalars['String'];
	tech_name: Scalars['String'];
	ui_config?: Maybe<FieldTypeUiConfig>;
};

export type FieldParameterTypeInput = {
	initial_value?: InputMaybe<Scalars['JSONObject']>;
	input_options?: InputMaybe<Scalars['JSONObject']>;
	input_type: InputKind;
	is_availability_param?: InputMaybe<Scalars['Boolean']>;
	is_required?: InputMaybe<Scalars['Boolean']>;
	name: Scalars['String'];
	tech_name: Scalars['String'];
	ui_config?: InputMaybe<FieldTypeUiConfigInput>;
};

export type FieldType = {
	__typename?: 'FieldType';
	category?: Maybe<FieldCategoryKind>;
	conditions?: Maybe<Array<FieldCondition>>;
	id: Scalars['ID'];
	initial_accesses: FieldAccessInfo;
	initial_value?: Maybe<Scalars['JSONObject']>;
	input_options: Scalars['JSONObject'];
	input_type: InputKind;
	is_availability_param: Scalars['Boolean'];
	is_deletable_type: Scalars['Boolean'];
	is_nullable: Scalars['Boolean'];
	is_required: Scalars['Boolean'];
	is_variant_identifier: Scalars['Boolean'];
	is_variant_specific: Scalars['Boolean'];
	matching_weight?: Maybe<Scalars['Int']>;
	name: Scalars['String'];
	object_fields?: Maybe<Array<Maybe<FieldType>>>;
	tech_name: Scalars['String'];
	ui_config: FieldTypeUiConfig;
	visible_to_guests: Scalars['Boolean'];
	visible_to_participants: Scalars['Boolean'];
};

export enum FieldTypeContainerKind {
	MatchingToolType = 'MATCHING_TOOL_TYPE',
	MatchType = 'MATCH_TYPE',
	ObjectType = 'OBJECT_TYPE',
	ParticipantType = 'PARTICIPANT_TYPE',
	SupplyType = 'SUPPLY_TYPE',
}

export type FieldTypeSelectUiConfigOption = {
	__typename?: 'FieldTypeSelectUIConfigOption';
	label: Scalars['String'];
	value: Scalars['String'];
};

export type FieldTypeSelectUiConfigOptionInput = {
	label: Scalars['String'];
	value: Scalars['String'];
};

export type FieldTypeUiConfig = {
	__typename?: 'FieldTypeUIConfig';
	helper_text?: Maybe<Scalars['String']>;
	input_control?: Maybe<InputControlKind>;
	label?: Maybe<Scalars['String']>;
	options?: Maybe<Array<FieldTypeSelectUiConfigOption>>;
};

export type FieldTypeUiConfigInput = {
	helper_text?: InputMaybe<Scalars['String']>;
	input_control: InputControlKind;
	label?: InputMaybe<Scalars['String']>;
	options?: InputMaybe<Array<FieldTypeSelectUiConfigOptionInput>>;
};

export type File = {
	__typename?: 'File';
	id: Scalars['ID'];
	name: Scalars['String'];
	physical_name: Scalars['String'];
	type: FileKind;
	url: Scalars['String'];
};

export enum FileKind {
	Document = 'DOCUMENT',
	Image = 'IMAGE',
}

export type FileUpload = {
	__typename?: 'FileUpload';
	id: Scalars['ID'];
	upload_url: Scalars['String'];
};

export type Flow = {
	__typename?: 'Flow';
	current_state: Scalars['String'];
	id_flow_type: Scalars['ID'];
	initiated_at: Scalars['DateTime'];
	terminated_at?: Maybe<Scalars['DateTime']>;
};

export type FlowCondition = {
	__typename?: 'FlowCondition';
	payload: Scalars['JSONObject'];
	type: FlowConditionKind;
};

export type FlowConditionInput = {
	payload: Scalars['JSONObject'];
	type: FlowConditionKind;
};

export enum FlowConditionKind {
	AwaitingPayment = 'AWAITING_PAYMENT',
	EventParInVar = 'EVENT_PAR_IN_VAR',
	FieldValueContains = 'FIELD_VALUE_CONTAINS',
	FieldValueEqualTo = 'FIELD_VALUE_EQUAL_TO',
	FieldValueNotContains = 'FIELD_VALUE_NOT_CONTAINS',
	FieldValueNotEqualTo = 'FIELD_VALUE_NOT_EQUAL_TO',
	FieldValueNotProvided = 'FIELD_VALUE_NOT_PROVIDED',
	FieldValueProvided = 'FIELD_VALUE_PROVIDED',
	InvokerRoleIn = 'INVOKER_ROLE_IN',
	MatchCreatedByTool = 'MATCH_CREATED_BY_TOOL',
	ObjectApproved = 'OBJECT_APPROVED',
	ObjectConsistent = 'OBJECT_CONSISTENT',
	ObjectNotConsistent = 'OBJECT_NOT_CONSISTENT',
	ObjectRejected = 'OBJECT_REJECTED',
	RequestedTimeEventAlias = 'REQUESTED_TIME_EVENT_ALIAS',
	SetContainsFieldValue = 'SET_CONTAINS_FIELD_VALUE',
	SetNotContainsFieldValue = 'SET_NOT_CONTAINS_FIELD_VALUE',
	VarValueEqualTo = 'VAR_VALUE_EQUAL_TO',
}

export type FlowMapping = {
	__typename?: 'FlowMapping';
	id_flow_type: Scalars['ID'];
	state_mappings: Array<FlowStateMapping>;
};

export type FlowMappingInput = {
	id_flow_type: Scalars['ID'];
	state_mappings: Array<FlowStateMappingInput>;
};

export enum FlowPurposeKind {
	ParticipantOnboarding = 'PARTICIPANT_ONBOARDING',
	SupplyOnboarding = 'SUPPLY_ONBOARDING',
	Transaction = 'TRANSACTION',
}

export type FlowStateMapping = {
	__typename?: 'FlowStateMapping';
	actions: Array<ActionType>;
	id_new_state: Scalars['ID'];
	id_old_state: Scalars['ID'];
};

export type FlowStateMappingInput = {
	actions?: InputMaybe<Array<ActionTypeInput>>;
	id_new_state: Scalars['ID'];
	id_old_state: Scalars['ID'];
};

export type FlowType = {
	__typename?: 'FlowType';
	id: Scalars['ID'];
	id_root_type: Scalars['ID'];
	initiating_conditions: Array<FlowCondition>;
	initiating_event: RandevuEventKind;
	is_active: Scalars['Boolean'];
	name: Scalars['String'];
	purpose?: Maybe<FlowPurposeKind>;
	retired_at?: Maybe<Scalars['DateTime']>;
	root_type: EventObjectTypeKind;
	states: Array<StateType>;
	transitions: Array<TransitionType>;
	var_types: Array<FlowVariableType>;
};

export type FlowVariableType = {
	__typename?: 'FlowVariableType';
	name: Scalars['String'];
	type?: Maybe<EventObjectTypeKind>;
};

export type FlowVariableTypeInput = {
	name: Scalars['String'];
	type?: InputMaybe<EventObjectTypeKind>;
};

export enum FoParticipantTypeInputControlsKind {
	Dropdown = 'DROPDOWN',
	Radio = 'RADIO',
}

export type FrontofficeConfig = {
	__typename?: 'FrontofficeConfig';
	choose_new_password_page?: Maybe<ChooseNewPasswordPageConfig>;
	connected_participant_page?: Maybe<ConnectedParticipantPageUiConfig>;
	connections_page?: Maybe<ConnectionsPageUiConfig>;
	contact_us_page?: Maybe<ContactUsPageConfig>;
	custom_domain_name?: Maybe<Scalars['String']>;
	customer_frontend_urls?: Maybe<Array<CustomerFrontendUrl>>;
	dashboard_page?: Maybe<DashboardPageUiConfig>;
	forgot_password_page?: Maybe<SecurityPageConfig>;
	global_settings?: Maybe<GlobalConfig>;
	landing_page?: Maybe<LandingPageConfig>;
	onboarding_page?: Maybe<IOnboardingPageUiConfig>;
	participant_account_page?: Maybe<ParticipantAccountPageConfig>;
	rdv_fo_enabled?: Maybe<Scalars['Boolean']>;
	reset_password_page?: Maybe<ResetPasswordPageConfig>;
	sign_in_page?: Maybe<SignInPageConfig>;
	sign_up_page?: Maybe<SignUpPageUiConfig>;
	verify_participant_account_page?: Maybe<VerifyParticipantAccountPageConfig>;
};

export type FrontofficeConfigInput = {
	choose_new_password_page?: InputMaybe<ChooseNewPasswordPageConfigInput>;
	connected_participant_page?: InputMaybe<ConnectedParticipantPageUiConfigInput>;
	connections_page?: InputMaybe<ConnectionsPageUiConfigInput>;
	contact_us_page?: InputMaybe<ContactUsPageConfigInput>;
	custom_domain_name?: InputMaybe<Scalars['String']>;
	customer_frontend_urls?: InputMaybe<Array<CustomerFrontendUrlInput>>;
	dashboard_page?: InputMaybe<DashboardPageUiConfigInput>;
	forgot_password_page?: InputMaybe<SecurityPageConfigInput>;
	global_settings?: InputMaybe<GlobalConfigInput>;
	landing_page?: InputMaybe<LandingPageConfigInput>;
	onboarding_page?: InputMaybe<IOnboardingPageUiConfigInput>;
	participant_account_page?: InputMaybe<ParticipantAccountPageConfigInput>;
	rdv_fo_enabled?: InputMaybe<Scalars['Boolean']>;
	reset_password_page?: InputMaybe<ResetPasswordPageConfigInput>;
	sign_in_page?: InputMaybe<SignInPageConfigInput>;
	sign_up_page?: InputMaybe<SignUpPageUiConfigInput>;
	verify_participant_account_page?: InputMaybe<VerifyParticipantAccountPageConfigInput>;
};

export type FrontofficeConfiguration = {
	__typename?: 'FrontofficeConfiguration';
	custom_domain_name?: Maybe<Scalars['String']>;
	customer_frontend_urls?: Maybe<Array<CustomerFrontendUrl>>;
	integration_providers: Array<IntegrationProviderLight>;
	marketplace_display_name: Scalars['String'];
	marketplace_name: Scalars['String'];
	participant_types: Array<ParticipantType>;
	public_key: Scalars['String'];
	rdv_fo_enabled?: Maybe<Scalars['Boolean']>;
	ui_config: FrontofficeUiConfig;
};

export type FrontofficeFormFieldConfig = {
	__typename?: 'FrontofficeFormFieldConfig';
	label?: Maybe<Scalars['String']>;
	placeholder?: Maybe<Scalars['String']>;
};

export type FrontofficeFormFieldConfigInput = {
	label?: InputMaybe<Scalars['String']>;
	placeholder?: InputMaybe<Scalars['String']>;
};

export type FrontofficeParticipantTypeFieldConfig = {
	__typename?: 'FrontofficeParticipantTypeFieldConfig';
	input_control?: Maybe<FoParticipantTypeInputControlsKind>;
	label?: Maybe<Scalars['String']>;
};

export type FrontofficeParticipantTypeFieldConfigInput = {
	input_control?: InputMaybe<FoParticipantTypeInputControlsKind>;
	label?: InputMaybe<Scalars['String']>;
};

export type FrontofficeRedirectToTextConfig = {
	__typename?: 'FrontofficeRedirectToTextConfig';
	link_label?: Maybe<Scalars['String']>;
	text?: Maybe<Scalars['String']>;
};

export type FrontofficeRedirectToTextConfigInput = {
	link_label?: InputMaybe<Scalars['String']>;
	text?: InputMaybe<Scalars['String']>;
};

export type FrontofficeUiConfig = {
	__typename?: 'FrontofficeUIConfig';
	choose_new_password_page?: Maybe<ChooseNewPasswordPageConfig>;
	connected_participant_page?: Maybe<ConnectedParticipantPageUiConfig>;
	connections_page?: Maybe<ConnectionsPageUiConfig>;
	contact_us_page?: Maybe<ContactUsPageConfig>;
	custom_domain_name?: Maybe<Scalars['String']>;
	customer_frontend_urls?: Maybe<Array<CustomerFrontendUrl>>;
	dashboard_page?: Maybe<DashboardPageUiConfig>;
	forgot_password_page?: Maybe<SecurityPageConfig>;
	global_settings?: Maybe<GlobalConfig>;
	landing_page?: Maybe<LandingPageConfig>;
	onboarding_page?: Maybe<IOnboardingPageUiConfig>;
	participant_account_page?: Maybe<ParticipantAccountPageConfig>;
	rdv_fo_enabled?: Maybe<Scalars['Boolean']>;
	reset_password_page?: Maybe<ResetPasswordPageConfig>;
	sign_in_page?: Maybe<SignInPageConfig>;
	sign_up_page?: Maybe<SignUpPageUiConfig>;
	verify_participant_account_page?: Maybe<VerifyParticipantAccountPageConfig>;
};

export type GlobalConfig = {
	__typename?: 'GlobalConfig';
	external_links?: Maybe<GlobalConfigExternalLinks>;
	favicon_url?: Maybe<Scalars['String']>;
	logo_in_header?: Maybe<Scalars['Boolean']>;
	logo_url?: Maybe<Scalars['String']>;
	marketplace_name_in_header?: Maybe<Scalars['Boolean']>;
	primary_color?: Maybe<Scalars['String']>;
	primary_slogan?: Maybe<Scalars['String']>;
	secondary_color?: Maybe<Scalars['String']>;
	secondary_slogan?: Maybe<Scalars['String']>;
	sign_up_claim?: Maybe<Scalars['String']>;
};

export type GlobalConfigExternalLinks = {
	__typename?: 'GlobalConfigExternalLinks';
	email?: Maybe<Scalars['String']>;
	facebook?: Maybe<Scalars['String']>;
	imprint?: Maybe<Scalars['String']>;
	instagram?: Maybe<Scalars['String']>;
	linkedin?: Maybe<Scalars['String']>;
	privacy?: Maybe<Scalars['String']>;
	support?: Maybe<Scalars['String']>;
	terms?: Maybe<Scalars['String']>;
	twitter?: Maybe<Scalars['String']>;
};

export type GlobalConfigExternalLinksInput = {
	email?: InputMaybe<Scalars['String']>;
	facebook?: InputMaybe<Scalars['String']>;
	imprint?: InputMaybe<Scalars['String']>;
	instagram?: InputMaybe<Scalars['String']>;
	linkedin?: InputMaybe<Scalars['String']>;
	privacy?: InputMaybe<Scalars['String']>;
	support?: InputMaybe<Scalars['String']>;
	terms?: InputMaybe<Scalars['String']>;
	twitter?: InputMaybe<Scalars['String']>;
};

export type GlobalConfigInput = {
	external_links?: InputMaybe<GlobalConfigExternalLinksInput>;
	id_file_favicon?: InputMaybe<Scalars['ID']>;
	id_file_logo?: InputMaybe<Scalars['ID']>;
	logo_in_header?: InputMaybe<Scalars['Boolean']>;
	marketplace_name_in_header?: InputMaybe<Scalars['Boolean']>;
	primary_color?: InputMaybe<Scalars['String']>;
	primary_slogan?: InputMaybe<Scalars['String']>;
	secondary_color?: InputMaybe<Scalars['String']>;
	secondary_slogan?: InputMaybe<Scalars['String']>;
	sign_up_claim?: InputMaybe<Scalars['String']>;
};

export type HeroSectionConfig = {
	__typename?: 'HeroSectionConfig';
	image_url?: Maybe<Scalars['String']>;
};

export type HeroSectionConfigInput = {
	id_file_image?: InputMaybe<Scalars['String']>;
};

export type HowItWorksSectionConfig = {
	__typename?: 'HowItWorksSectionConfig';
	enabled?: Maybe<Scalars['Boolean']>;
	image_1_url?: Maybe<Scalars['String']>;
	image_2_url?: Maybe<Scalars['String']>;
	image_3_url?: Maybe<Scalars['String']>;
	step_1_description?: Maybe<Scalars['String']>;
	step_1_name?: Maybe<Scalars['String']>;
	step_1_title?: Maybe<Scalars['String']>;
	step_2_description?: Maybe<Scalars['String']>;
	step_2_name?: Maybe<Scalars['String']>;
	step_2_title?: Maybe<Scalars['String']>;
	step_3_description?: Maybe<Scalars['String']>;
	step_3_name?: Maybe<Scalars['String']>;
	step_3_title?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

export type HowItWorksSectionConfigInput = {
	enabled?: InputMaybe<Scalars['Boolean']>;
	id_file_image_1?: InputMaybe<Scalars['String']>;
	id_file_image_2?: InputMaybe<Scalars['String']>;
	id_file_image_3?: InputMaybe<Scalars['String']>;
	step_1_description?: InputMaybe<Scalars['String']>;
	step_1_name?: InputMaybe<Scalars['String']>;
	step_1_title?: InputMaybe<Scalars['String']>;
	step_2_description?: InputMaybe<Scalars['String']>;
	step_2_name?: InputMaybe<Scalars['String']>;
	step_2_title?: InputMaybe<Scalars['String']>;
	step_3_description?: InputMaybe<Scalars['String']>;
	step_3_name?: InputMaybe<Scalars['String']>;
	step_3_title?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type IOnboardingPageUiConfig = {
	__typename?: 'IOnboardingPageUiConfig';
	title?: Maybe<Scalars['String']>;
};

export type IOnboardingPageUiConfigInput = {
	title?: InputMaybe<Scalars['String']>;
};

export enum ImagePositionKind {
	Left = 'LEFT',
	Right = 'RIGHT',
}

export enum InputControlKind {
	Asset = 'ASSET',
	AssetGallery = 'ASSET_GALLERY',
	AssetList = 'ASSET_LIST',
	Checkbox = 'CHECKBOX',
	DatetimePicker = 'DATETIME_PICKER',
	DatePicker = 'DATE_PICKER',
	Dropdown = 'DROPDOWN',
	Location = 'LOCATION',
	NumberEditor = 'NUMBER_EDITOR',
	Radio = 'RADIO',
	Switch = 'SWITCH',
	Text = 'TEXT',
	TextArea = 'TEXT_AREA',
}

export enum InputKind {
	AutoincId = 'AUTOINC_ID',
	Boolean = 'BOOLEAN',
	Date = 'DATE',
	Datetime = 'DATETIME',
	DateSet = 'DATE_SET',
	Decimal = 'DECIMAL',
	Document = 'DOCUMENT',
	DocumentSet = 'DOCUMENT_SET',
	Image = 'IMAGE',
	ImageSet = 'IMAGE_SET',
	Integer = 'INTEGER',
	Location = 'LOCATION',
	MonetaryValue = 'MONETARY_VALUE',
	MultiSelect = 'MULTI_SELECT',
	Object = 'OBJECT',
	ObjectSet = 'OBJECT_SET',
	Participant = 'PARTICIPANT',
	ParticipantSet = 'PARTICIPANT_SET',
	RichText = 'RICH_TEXT',
	Select = 'SELECT',
	SmartText = 'SMART_TEXT',
	SmartTextSet = 'SMART_TEXT_SET',
	Text = 'TEXT',
	TextSet = 'TEXT_SET',
}

export type IntegrationProvider = {
	__typename?: 'IntegrationProvider';
	data: Scalars['JSON'];
	id: Scalars['ID'];
	is_active: Scalars['Boolean'];
	name: Scalars['String'];
	type: IntegrationProviderKind;
};

export enum IntegrationProviderKind {
	Email = 'EMAIL',
	Payment = 'PAYMENT',
}

export type IntegrationProviderLight = {
	__typename?: 'IntegrationProviderLight';
	id: Scalars['ID'];
	is_active: Scalars['Boolean'];
	name: Scalars['String'];
	type: IntegrationProviderKind;
};

export enum IntegrationProviders {
	Sendinblue = 'SENDINBLUE',
	Stripe = 'STRIPE',
}

export type LandingPageConfig = {
	__typename?: 'LandingPageConfig';
	about: AboutSectionConfig;
	enabled?: Maybe<Scalars['Boolean']>;
	hero: HeroSectionConfig;
	how_it_works?: Maybe<HowItWorksSectionConfig>;
	unique_selling_points: UniqueSellingPointsSectionConfig;
};

export type LandingPageConfigInput = {
	about?: InputMaybe<AboutSectionConfigInput>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	hero?: InputMaybe<HeroSectionConfigInput>;
	how_it_works?: InputMaybe<HowItWorksSectionConfigInput>;
	unique_selling_points?: InputMaybe<UniqueSellingPointsSectionConfigInput>;
};

export enum MarketplaceEnvironmentKind {
	Production = 'PRODUCTION',
	Sandbox = 'SANDBOX',
}

export type Match = {
	__typename?: 'Match';
	backoffice_approved_at?: Maybe<Scalars['DateTime']>;
	backoffice_rejected_at?: Maybe<Scalars['DateTime']>;
	canceled_at?: Maybe<Scalars['DateTime']>;
	closed_at?: Maybe<Scalars['DateTime']>;
	consumer: User;
	consumer_accepted_at?: Maybe<Scalars['DateTime']>;
	consumer_declined_at?: Maybe<Scalars['DateTime']>;
	consumer_unlocked_to_provider?: Maybe<Scalars['Boolean']>;
	created_at: Scalars['DateTime'];
	created_by?: Maybe<User>;
	end_date?: Maybe<Scalars['DateTime']>;
	end_datetime?: Maybe<Scalars['DateTime']>;
	fields: Array<Maybe<Field>>;
	id: Scalars['ID'];
	matching_score?: Maybe<Scalars['Int']>;
	matching_tool?: Maybe<MatchingTool>;
	my_role?: Maybe<MatchTypeRoleKind>;
	paid_at?: Maybe<Scalars['DateTime']>;
	price?: Maybe<SupplyPrice>;
	price_override_unlocked?: Maybe<Scalars['Boolean']>;
	provider: User;
	provider_accepted_at?: Maybe<Scalars['DateTime']>;
	provider_declined_at?: Maybe<Scalars['DateTime']>;
	provider_unlocked_to_consumer?: Maybe<Scalars['Boolean']>;
	/** @deprecated Not used any more. Use price.qty instead */
	quantity?: Maybe<Scalars['Int']>;
	start_date?: Maybe<Scalars['DateTime']>;
	start_datetime?: Maybe<Scalars['DateTime']>;
	status: MatchStatusKind;
	supply?: Maybe<Supply>;
	supply_unlocked_to_consumer?: Maybe<Scalars['Boolean']>;
	type: MatchType;
	unlocked_consumer_fields: Array<Scalars['ID']>;
	unlocked_provider_fields: Array<Scalars['ID']>;
	unlocked_supply_fields: Array<Scalars['ID']>;
	unlocked_to_consumer: Scalars['Boolean'];
	unlocked_to_provider: Scalars['Boolean'];
	user_actions: Array<UserAction>;
};

export type MatchConfiguration = {
	__typename?: 'MatchConfiguration';
	add_ons: Array<AddOn>;
	availability_management_type: AvailabilityManagementKind;
	availability_tracking_schemes: Array<AvailabilityTrackingScheme>;
	availability_tracking_type?: Maybe<AvailabilityTrackingKind>;
	discounts: Array<Discount>;
	id: Scalars['ID'];
	match_parameter_types: Array<FieldParameterType>;
	match_restrictions: Array<MatchRestriction>;
	matching_unit_type: MatchingUnitKind;
	name: Scalars['String'];
	platform_fees: Array<PlatformFee>;
	pricing_enabled: Scalars['Boolean'];
	pricing_rules: Array<PricingRule>;
	taxes: Array<Tax>;
	tech_name: Scalars['String'];
};

export type MatchFilter = {
	id_consumer?: InputMaybe<Array<Scalars['ID']>>;
	id_match_type?: InputMaybe<Array<Scalars['ID']>>;
	id_matching_tool?: InputMaybe<Scalars['ID']>;
	id_provider?: InputMaybe<Array<Scalars['ID']>>;
	id_supply?: InputMaybe<Array<Scalars['ID']>>;
	my_role?: InputMaybe<MatchTypeRoleKind>;
	status?: InputMaybe<Array<MatchStatusKind>>;
};

export type MatchLight = {
	__typename?: 'MatchLight';
	backoffice_approved_at?: Maybe<Scalars['DateTime']>;
	backoffice_rejected_at?: Maybe<Scalars['DateTime']>;
	closed_at?: Maybe<Scalars['DateTime']>;
	consumer_accepted_at?: Maybe<Scalars['DateTime']>;
	consumer_declined_at?: Maybe<Scalars['DateTime']>;
	created_at: Scalars['DateTime'];
	id: Scalars['ID'];
	id_consumer: Scalars['ID'];
	id_match_type: Scalars['ID'];
	id_provider: Scalars['ID'];
	matching_score?: Maybe<Scalars['Int']>;
	matching_tool?: Maybe<MatchingTool>;
	my_role?: Maybe<MatchTypeRoleKind>;
	provider_accepted_at?: Maybe<Scalars['DateTime']>;
	provider_declined_at?: Maybe<Scalars['DateTime']>;
	status: MatchStatusKind;
	supply?: Maybe<SupplyLight>;
};

export type MatchRestriction = {
	__typename?: 'MatchRestriction';
	expression: Scalars['String'];
	name: Scalars['String'];
	tech_name: Scalars['String'];
};

export type MatchRestrictionInput = {
	expression: Scalars['String'];
	name: Scalars['String'];
	tech_name: Scalars['String'];
};

export enum MatchStatusKind {
	Canceled = 'CANCELED',
	Closed = 'CLOSED',
	Declined = 'DECLINED',
	Paid = 'PAID',
	Pending = 'PENDING',
	Rejected = 'REJECTED',
}

export type MatchType = {
	__typename?: 'MatchType';
	allow_consumer_guests: Scalars['Boolean'];
	allow_provider_guests: Scalars['Boolean'];
	auto_backoffice_approval: Scalars['Boolean'];
	auto_consumer_acceptance: Scalars['Boolean'];
	auto_provider_acceptance: Scalars['Boolean'];
	availability_management_type: AvailabilityManagementKind;
	consumer_discovery_enabled: Scalars['Boolean'];
	consumer_type: ParticipantType;
	fields: Array<FieldType>;
	id: Scalars['ID'];
	initial_match_visibility: MatchVisibilityInfo;
	matching_tool_types: Array<MatchingToolType>;
	matching_unit_type: MatchingUnitKind;
	my_role?: Maybe<MatchTypeRoleKind>;
	name: Scalars['String'];
	provider_discovery_enabled: Scalars['Boolean'];
	provider_type: ParticipantType;
	retired_at?: Maybe<Scalars['DateTime']>;
	supply_discovery_enabled: Scalars['Boolean'];
	supply_type?: Maybe<SupplyType>;
	type_visibility: MatchTypeVisibilityInfo;
};

export type MatchTypeLight = {
	__typename?: 'MatchTypeLight';
	consumer_discovery_enabled: Scalars['Boolean'];
	id: Scalars['ID'];
	id_consumer_type: Scalars['ID'];
	id_provider_type: Scalars['ID'];
	id_supply_type?: Maybe<Scalars['ID']>;
	matching_tool_types: Array<MatchingToolType>;
	my_role?: Maybe<MatchTypeRoleKind>;
	name: Scalars['String'];
	provider_discovery_enabled: Scalars['Boolean'];
	supply_discovery_enabled: Scalars['Boolean'];
};

export enum MatchTypeRoleKind {
	Backoffice = 'BACKOFFICE',
	Consumer = 'CONSUMER',
	ConsumerOrProvider = 'CONSUMER_OR_PROVIDER',
	Guest = 'GUEST',
	Provider = 'PROVIDER',
}

export type MatchTypeVisibilityInfo = {
	__typename?: 'MatchTypeVisibilityInfo';
	consumer_fields?: Maybe<Array<Scalars['ID']>>;
	consumer_fields_out_for_guest?: Maybe<Array<Scalars['ID']>>;
	consumer_fields_out_for_provider?: Maybe<Array<Scalars['ID']>>;
	match_fields?: Maybe<Array<Scalars['ID']>>;
	provider_fields?: Maybe<Array<Scalars['ID']>>;
	provider_fields_out_for_consumer?: Maybe<Array<Scalars['ID']>>;
	provider_fields_out_for_guest?: Maybe<Array<Scalars['ID']>>;
	supply_fields?: Maybe<Array<Scalars['ID']>>;
	supply_fields_out_for_consumer?: Maybe<Array<Scalars['ID']>>;
	supply_fields_out_for_guest?: Maybe<Array<Scalars['ID']>>;
};

export type MatchTypeVisibilityInfoInput = {
	consumer_fields?: InputMaybe<Array<Scalars['ID']>>;
	consumer_fields_out_for_guest?: InputMaybe<Array<Scalars['ID']>>;
	consumer_fields_out_for_provider?: InputMaybe<Array<Scalars['ID']>>;
	match_fields?: InputMaybe<Array<Scalars['ID']>>;
	provider_fields?: InputMaybe<Array<Scalars['ID']>>;
	provider_fields_out_for_consumer?: InputMaybe<Array<Scalars['ID']>>;
	provider_fields_out_for_guest?: InputMaybe<Array<Scalars['ID']>>;
	supply_fields?: InputMaybe<Array<Scalars['ID']>>;
	supply_fields_out_for_consumer?: InputMaybe<Array<Scalars['ID']>>;
	supply_fields_out_for_guest?: InputMaybe<Array<Scalars['ID']>>;
};

export type MatchVisibilityInfo = {
	__typename?: 'MatchVisibilityInfo';
	consumer_by_provider?: Maybe<Scalars['Boolean']>;
	consumer_fields?: Maybe<Array<Scalars['ID']>>;
	match_by_consumer?: Maybe<Scalars['Boolean']>;
	match_by_provider?: Maybe<Scalars['Boolean']>;
	provider_by_consumer?: Maybe<Scalars['Boolean']>;
	provider_fields?: Maybe<Array<Scalars['ID']>>;
	supply_by_consumer?: Maybe<Scalars['Boolean']>;
	supply_fields?: Maybe<Array<Scalars['ID']>>;
};

export type MatchVisibilityInfoInput = {
	consumer_by_provider?: InputMaybe<Scalars['Boolean']>;
	consumer_fields?: InputMaybe<Array<Scalars['ID']>>;
	match_by_consumer?: InputMaybe<Scalars['Boolean']>;
	match_by_provider?: InputMaybe<Scalars['Boolean']>;
	provider_by_consumer?: InputMaybe<Scalars['Boolean']>;
	provider_fields?: InputMaybe<Array<Scalars['ID']>>;
	supply_by_consumer?: InputMaybe<Scalars['Boolean']>;
	supply_fields?: InputMaybe<Array<Scalars['ID']>>;
};

export type MatchingTool = {
	__typename?: 'MatchingTool';
	/** @deprecated This att is not used any more. Use fields instead */
	availability?: Maybe<AvailabilityRecord>;
	consumer_filter?: Maybe<Array<FieldFilter>>;
	created_at: Scalars['DateTime'];
	created_by: User;
	creator: Participant;
	fields: Array<Maybe<Field>>;
	id: Scalars['ID'];
	ids_supplies: Array<Scalars['ID']>;
	is_active: Scalars['Boolean'];
	last_execution_at?: Maybe<Scalars['DateTime']>;
	matches: Array<Match>;
	name: Scalars['String'];
	provider_filter?: Maybe<Array<FieldFilter>>;
	supply_filter?: Maybe<Array<FieldFilter>>;
	type: MatchingToolType;
	user_actions: Array<UserAction>;
};

export enum MatchingToolKind {
	Auction = 'AUCTION',
	DirectMatcher = 'DIRECT_MATCHER',
	MatchingOrder = 'MATCHING_ORDER',
}

export type MatchingToolType = {
	__typename?: 'MatchingToolType';
	config?: Maybe<Scalars['JSONObject']>;
	execution_type: TaskExecutionKind;
	fields: Array<FieldType>;
	id: Scalars['ID'];
	initially_unlocked_to_consumer: Scalars['Boolean'];
	initially_unlocked_to_provider: Scalars['Boolean'];
	match_type: MatchType;
	name: Scalars['String'];
	retired_at?: Maybe<Scalars['DateTime']>;
	type: MatchingToolKind;
};

export enum MatchingToolTypeRoleKind {
	Creator = 'CREATOR',
	NoCreator = 'NO_CREATOR',
}

export enum MatchingUnitKind {
	PerDay = 'PER_DAY',
	PerEvent = 'PER_EVENT',
	PerHour = 'PER_HOUR',
	PerUnit = 'PER_UNIT',
}

export type MerchantAccount = {
	__typename?: 'MerchantAccount';
	data?: Maybe<Scalars['JSONObject']>;
	id: Scalars['ID'];
	id_psp_account: Scalars['String'];
	payment_provider_kind: PaymentProviderKind;
};

export type Migration = {
	__typename?: 'Migration';
	canceled_at?: Maybe<Scalars['DateTime']>;
	canceled_by?: Maybe<Scalars['ID']>;
	executed_by?: Maybe<Scalars['ID']>;
	execution_ended_at?: Maybe<Scalars['DateTime']>;
	execution_started_at?: Maybe<Scalars['DateTime']>;
	flow_mappings: Array<FlowMapping>;
	id: Scalars['ID'];
	interrupted_at?: Maybe<Scalars['DateTime']>;
	prepared_at: Scalars['DateTime'];
	prepared_by: Scalars['ID'];
	rolledback_at?: Maybe<Scalars['DateTime']>;
	status: MigrationStatusKind;
	status_message?: Maybe<Scalars['String']>;
};

export type MigrationOption = {
	__typename?: 'MigrationOption';
	option: MigrationOptionKind;
	title: Scalars['String'];
};

export type MigrationOptionChoice = {
	__typename?: 'MigrationOptionChoice';
	option: MigrationOptionKind;
	payload?: Maybe<Scalars['JSONObject']>;
};

export type MigrationOptionChoiceInput = {
	option: MigrationOptionKind;
	payload?: InputMaybe<Scalars['JSONObject']>;
};

export enum MigrationOptionKind {
	CreateFieldsAndLeaveUnset = 'CREATE_FIELDS_AND_LEAVE_UNSET',
	CreateFieldsAndRequestProvidingFieldValue = 'CREATE_FIELDS_AND_REQUEST_PROVIDING_FIELD_VALUE',
	DeleteAffectedObjects = 'DELETE_AFFECTED_OBJECTS',
	RequestProvidingFieldValue = 'REQUEST_PROVIDING_FIELD_VALUE',
}

export enum MigrationStatusKind {
	Canceled = 'CANCELED',
	Executed = 'EXECUTED',
	Interrupted = 'INTERRUPTED',
	InExecution = 'IN_EXECUTION',
	NotReady = 'NOT_READY',
	Ready = 'READY',
}

export type ModifiedFlowType = {
	__typename?: 'ModifiedFlowType';
	id_flow_type: Scalars['ID'];
	new_states: Array<StateType>;
	old_states: Array<ModifiedStateRecord>;
};

/**
 *  type FlowVariable {
 *   name: String!
 *   type: EventObjectKind
 *   values: [String!]
 * }
 */
export type ModifiedStateRecord = {
	__typename?: 'ModifiedStateRecord';
	id_state: Scalars['ID'];
	is_deleted: Scalars['Boolean'];
	name_state: Scalars['String'];
};

export type MonetaryValue = {
	__typename?: 'MonetaryValue';
	amount: Scalars['Int'];
	currency: CurrencyKind;
	unit: CurrencyUnitKind;
};

export type MonetaryValueInput = {
	amount: Scalars['Int'];
	currency: CurrencyKind;
	unit: CurrencyUnitKind;
};

export type Mutation = {
	__typename?: 'Mutation';
	acceptTransactedMatch: Scalars['Boolean'];
	activateFlowType: FlowType;
	activateIntegrationProvider: Scalars['Boolean'];
	activateNotificationType: Scalars['Boolean'];
	activateSupply: Supply;
	activateTransactionType: TransactionType;
	addFieldTypeTo: FieldType;
	addIntegrationProvider: IntegrationProvider;
	addNotificationType: NotificationType;
	addStateTypeToFlowType: StateType;
	addSupplyToShoppingCart: Scalars['Boolean'];
	addTransitionTypeToFlowType: TransitionType;
	addWebhookNotificationType: NotificationType;
	approveMatch: Scalars['Boolean'];
	approveParticipant: Scalars['Boolean'];
	approveSupply: Supply;
	authorizeTransactionPayment: Payment;
	buildInitialPlatform: PlatformOwnerOnboardingProgress;
	cancelMigration: Migration;
	cancelOwnerOnboardingProgress: PlatformOwnerOnboardingProgress;
	cancelTransaction: TransactionLight;
	captureTransactionPaymentAsParticipant: Payment;
	captureTransactionPaymentAsPlatform: Payment;
	createAPIUser: ApiUser;
	createConnectionType: ConnectionType;
	createEnvironmentVariable: EnvironmentVariable;
	createFavouriteFieldType: FavouriteFieldType;
	createFlowType: FlowType;
	createInvitedParticipant: User;
	createObjectType: ObjectType;
	createParticipantType: ParticipantType;
	createPaymentType: PaymentType;
	createPlatformTeamMember: BackofficeUser;
	createSharedObject: Object;
	createShoppingCart: ShoppingCart;
	createSupply: Supply;
	createSupplyOnBehalfOfProvider: Supply;
	createSupplyType: SupplyType;
	createSupplyVariant: Supply;
	createSupplyVariantOnBehalfOfProvider: Supply;
	createTransactionType: TransactionType;
	deactivateFlowType: FlowType;
	deactivateIntegrationProvider: Scalars['Boolean'];
	deactivateNotificationType: Scalars['Boolean'];
	deactivateSupply: Supply;
	deactivateTransactionType: TransactionType;
	declineTransactedMatch: Scalars['Boolean'];
	deleteEnvironmentVariable: Scalars['Boolean'];
	deleteSharedObject: Scalars['Boolean'];
	executeMigration: Migration;
	flagTransferAsSucceeded: Transfer;
	forceConnection: Connection;
	forceTransactedMatch: Scalars['ID'];
	forceTransactionTermination: Scalars['Boolean'];
	getGuestToken: Session;
	initiateTransaction: TransactionLight;
	inviteConsumerToForwardAuction: Scalars['Boolean'];
	inviteProviderToReverseAuction: Scalars['Boolean'];
	joinForwardAuction: Scalars['Boolean'];
	loginAPIUser: Session;
	loginBackoffice: Session;
	loginParticipant: Session;
	logoutMe: Scalars['Boolean'];
	moveStateType: StateType;
	overrideTransactedMatchPrice: Scalars['Boolean'];
	overrideTransactedMatchPriceOnBehalfOfProvider: Scalars['Boolean'];
	prepareAndInitiateForwardAuctionTransaction: TransactionLight;
	prepareAndInitiateReverseAuctionTransaction: TransactionLight;
	prepareAndInitiateSingleDirectTransaction: TransactionLight;
	prepareConfigFileUpload: FileUpload;
	prepareFileUpload: FileUpload;
	prepareMigration: Migration;
	prepareTransaction: TransactionLight;
	prepareTransactionPayment: Payment;
	purgeSandboxData: Scalars['Boolean'];
	registerMarketplaceOwner: Scalars['Boolean'];
	registerParticipant: Scalars['Boolean'];
	rejectMatch: Scalars['Boolean'];
	rejectParticipant: Scalars['Boolean'];
	rejectSupply: Supply;
	removeFieldTypeFrom: Scalars['Boolean'];
	removeIntegrationProvider: Scalars['Boolean'];
	removeNotificationType: Scalars['Boolean'];
	removeShoppingCartItem: Scalars['Boolean'];
	removeStateType: Scalars['Boolean'];
	removeTransitionType: Scalars['Boolean'];
	reorderFieldTypesIn: Scalars['Boolean'];
	requestBackofficePasswordReset: Scalars['Boolean'];
	requestMerchantAccountConnection: PaymentProviderConnection;
	requestParticipantPasswordReset: Scalars['Boolean'];
	requestPaymentCheckoutConnection: PaymentCheckoutConnection;
	requestPayout: Scalars['Boolean'];
	requestPayoutOnBehalfOf: Scalars['Boolean'];
	requestStripeDashboardSignInLink: Scalars['String'];
	resendUserVerificationEMail: Scalars['Boolean'];
	resetBackofficePassword: Scalars['Boolean'];
	resetParticipantPassword: Scalars['Boolean'];
	sendTransfer: Transfer;
	setDefaultTransactionType: Scalars['Boolean'];
	triggerManualParticipantOnboardingTransition: Scalars['Boolean'];
	triggerManualSupplyOnboardingTransition: Scalars['Boolean'];
	triggerManualTransactionTransition: Scalars['Boolean'];
	unlockMatch: Scalars['Boolean'];
	updateConnectionType: ConnectionType;
	updateEnvironmentVariable: EnvironmentVariable;
	updateFieldAccess: Scalars['Boolean'];
	updateFieldType: FieldType;
	updateFlowType: FlowType;
	updateFrontofficeConfiguration: FrontofficeConfig;
	updateIntegrationProvider: Scalars['Boolean'];
	updateMatch: Scalars['Boolean'];
	updateMatchConfigurationForSupplyType: MatchConfiguration;
	updateMatchType: MatchType;
	updateMatchingTool: Scalars['Boolean'];
	updateMatchingToolType: MatchingToolType;
	updateMigration: Migration;
	updateMyEmail: Scalars['Boolean'];
	updateMySimpleStockSupplyAvailability: SimpleStockAvailabilityData;
	updateNotificationType: NotificationType;
	updateObjectType: ObjectType;
	updateOwnerOnboardingProgress: PlatformOwnerOnboardingProgress;
	updateParticipant: Scalars['Boolean'];
	updateParticipantFieldAccess: Scalars['Boolean'];
	updateParticipantType: ParticipantType;
	updatePaymentServiceProvider: PaymentServiceProvider;
	updatePaymentType: PaymentType;
	updatePlatformAccount: Account;
	updateSharedObject: Scalars['Boolean'];
	updateShoppingCart: ShoppingCart;
	updateShoppingCartItem: Scalars['Boolean'];
	updateSimpleStockSupplyAvailabilityOnBehalfOfProvider: SimpleStockAvailabilityData;
	updateStateType: StateType;
	updateSupply: Scalars['Boolean'];
	updateSupplyFieldAccess: Scalars['Boolean'];
	updateSupplyType: SupplyType;
	updateTransactedMatch: Scalars['Boolean'];
	updateTransactionFieldAccess: Scalars['Boolean'];
	updateTransactionOrder: Scalars['Boolean'];
	updateTransactionType: TransactionType;
	updateTransitionType: TransitionType;
	updateUser: Scalars['Boolean'];
	updateUserEmailOnBehalfOf: Scalars['Boolean'];
	verifyBackofficeUser: Scalars['Boolean'];
	verifyParticipant: Scalars['Boolean'];
};

export type MutationAcceptTransactedMatchArgs = {
	id_transaction: Scalars['ID'];
};

export type MutationActivateFlowTypeArgs = {
	id: Scalars['ID'];
};

export type MutationActivateIntegrationProviderArgs = {
	id: Scalars['ID'];
};

export type MutationActivateNotificationTypeArgs = {
	id: Scalars['ID'];
};

export type MutationActivateSupplyArgs = {
	id: Scalars['ID'];
};

export type MutationActivateTransactionTypeArgs = {
	id: Scalars['ID'];
};

export type MutationAddFieldTypeToArgs = {
	category?: InputMaybe<FieldCategoryKind>;
	conditions?: InputMaybe<Array<ConditionInput>>;
	id_parent: Scalars['ID'];
	initial_accesses?: InputMaybe<FieldAccessInfoInput>;
	initial_value?: InputMaybe<Scalars['JSON']>;
	input_options?: InputMaybe<Scalars['JSONObject']>;
	input_type: InputKind;
	is_nullable?: InputMaybe<Scalars['Boolean']>;
	is_required?: InputMaybe<Scalars['Boolean']>;
	is_variant_identifier?: InputMaybe<Scalars['Boolean']>;
	is_variant_specific?: InputMaybe<Scalars['Boolean']>;
	matching_weight?: InputMaybe<Scalars['Int']>;
	name: Scalars['String'];
	parent_type: FieldTypeContainerKind;
	tech_name?: InputMaybe<Scalars['String']>;
	ui_config?: InputMaybe<FieldTypeUiConfigInput>;
	visible_to_guests?: InputMaybe<Scalars['Boolean']>;
	visible_to_participants?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAddIntegrationProviderArgs = {
	data: Scalars['JSONObject'];
	name?: InputMaybe<Scalars['String']>;
	provider?: InputMaybe<IntegrationProviders>;
	type?: InputMaybe<IntegrationProviderKind>;
};

export type MutationAddNotificationTypeArgs = {
	channel: NotificationChannelKind;
	data?: InputMaybe<Scalars['JSONObject']>;
	description?: InputMaybe<Scalars['String']>;
	id_integration_provider?: InputMaybe<Scalars['ID']>;
	name: Scalars['String'];
	parameters?: Array<NotificationParameterInput>;
	recipients: Array<EMailNotificationRecipientInput>;
	where_event: Array<EventSelectorRecordInput>;
};

export type MutationAddStateTypeToFlowTypeArgs = {
	id_flow_type: Scalars['ID'];
	name: Scalars['String'];
	tech_name?: InputMaybe<Scalars['String']>;
	ui_config: Scalars['JSONObject'];
};

export type MutationAddSupplyToShoppingCartArgs = {
	fields?: InputMaybe<Array<FieldInput>>;
	id_cart: Scalars['ID'];
	id_supply: Scalars['ID'];
};

export type MutationAddTransitionTypeToFlowTypeArgs = {
	id_flow_type: Scalars['ID'];
	id_from: Scalars['ID'];
	id_to: Scalars['ID'];
	name: Scalars['String'];
	tech_name?: InputMaybe<Scalars['String']>;
	triggering_conditions?: InputMaybe<Array<FlowConditionInput>>;
	triggering_event: RandevuEventKind;
};

export type MutationAddWebhookNotificationTypeArgs = {
	description?: InputMaybe<Scalars['String']>;
	name: Scalars['String'];
	target_url: Scalars['HttpURL'];
	where_event: Array<EventSelectorRecordInput>;
};

export type MutationApproveMatchArgs = {
	id: Scalars['ID'];
};

export type MutationApproveParticipantArgs = {
	id: Scalars['ID'];
};

export type MutationApproveSupplyArgs = {
	id: Scalars['ID'];
};

export type MutationAuthorizeTransactionPaymentArgs = {
	id: Scalars['ID'];
};

export type MutationBuildInitialPlatformArgs = {
	id_user: Scalars['ID'];
};

export type MutationCancelMigrationArgs = {
	id: Scalars['ID'];
};

export type MutationCancelOwnerOnboardingProgressArgs = {
	id_user: Scalars['ID'];
};

export type MutationCancelTransactionArgs = {
	id_transaction: Scalars['ID'];
};

export type MutationCaptureTransactionPaymentAsParticipantArgs = {
	id: Scalars['ID'];
};

export type MutationCaptureTransactionPaymentAsPlatformArgs = {
	id_payment: Scalars['ID'];
};

export type MutationCreateApiUserArgs = {
	password: Scalars['String'];
	username: Scalars['String'];
};

export type MutationCreateConnectionTypeArgs = {
	end1_name?: InputMaybe<Scalars['String']>;
	end2_name?: InputMaybe<Scalars['String']>;
	id_end1_participant_type: Scalars['ID'];
	id_end2_participant_type: Scalars['ID'];
	name: Scalars['String'];
	tech_name?: InputMaybe<Scalars['String']>;
};

export type MutationCreateEnvironmentVariableArgs = {
	name: Scalars['String'];
	value?: InputMaybe<Scalars['String']>;
};

export type MutationCreateFavouriteFieldTypeArgs = {
	input_options?: InputMaybe<Scalars['JSONObject']>;
	input_type: InputKind;
	is_nullable?: InputMaybe<Scalars['Boolean']>;
	is_required?: InputMaybe<Scalars['Boolean']>;
	name: Scalars['String'];
};

export type MutationCreateFlowTypeArgs = {
	id_root_type: Scalars['String'];
	initiating_conditions?: InputMaybe<Array<FlowConditionInput>>;
	initiating_event: RandevuEventKind;
	is_active?: InputMaybe<Scalars['Boolean']>;
	name: Scalars['String'];
	purpose?: InputMaybe<FlowPurposeKind>;
	root_type: EventObjectTypeKind;
};

export type MutationCreateInvitedParticipantArgs = {
	email: Scalars['String'];
	first_name: Scalars['String'];
	last_name: Scalars['String'];
};

export type MutationCreateObjectTypeArgs = {
	name: Scalars['String'];
	tech_name?: InputMaybe<Scalars['String']>;
};

export type MutationCreateParticipantTypeArgs = {
	account_type?: InputMaybe<AccountKind>;
	auto_backoffice_approval?: InputMaybe<Scalars['Boolean']>;
	inviting_enabled?: InputMaybe<Scalars['Boolean']>;
	is_master_type?: InputMaybe<Scalars['Boolean']>;
	name: Scalars['String'];
	tech_name?: InputMaybe<Scalars['String']>;
};

export type MutationCreatePaymentTypeArgs = {
	config: Scalars['JSONObject'];
	id_integration_provider?: InputMaybe<Scalars['ID']>;
	name: Scalars['String'];
	tech_name?: InputMaybe<Scalars['String']>;
};

export type MutationCreatePlatformTeamMemberArgs = {
	email: Scalars['String'];
	first_name: Scalars['String'];
	last_name: Scalars['String'];
	role: PlatformTeamRole;
};

export type MutationCreateSharedObjectArgs = {
	is_public: Scalars['Boolean'];
	tech_name: Scalars['String'];
};

export type MutationCreateShoppingCartArgs = {
	fields?: InputMaybe<Array<FieldInput>>;
	transaction_tech_name?: InputMaybe<Scalars['String']>;
};

export type MutationCreateSupplyArgs = {
	fields?: InputMaybe<Array<FieldInput>>;
	match_conf_tech_name?: InputMaybe<Scalars['String']>;
	tech_name: Scalars['String'];
};

export type MutationCreateSupplyOnBehalfOfProviderArgs = {
	fields?: InputMaybe<Array<FieldInput>>;
	id_provider: Scalars['ID'];
	match_conf_tech_name?: InputMaybe<Scalars['String']>;
	tech_name: Scalars['String'];
};

export type MutationCreateSupplyTypeArgs = {
	auto_activation?: InputMaybe<Scalars['Boolean']>;
	auto_backoffice_approval?: InputMaybe<Scalars['Boolean']>;
	id_provider_type: Scalars['String'];
	matching_unit_type?: InputMaybe<MatchingUnitKind>;
	name: Scalars['String'];
	tech_name?: InputMaybe<Scalars['String']>;
};

export type MutationCreateSupplyVariantArgs = {
	fields?: InputMaybe<Array<FieldInput>>;
	tech_name: Scalars['String'];
	variant_group: Scalars['String'];
};

export type MutationCreateSupplyVariantOnBehalfOfProviderArgs = {
	fields?: InputMaybe<Array<FieldInput>>;
	id_provider: Scalars['ID'];
	tech_name: Scalars['String'];
	variant_group: Scalars['String'];
};

export type MutationCreateTransactionTypeArgs = {
	id_consumer_type: Scalars['ID'];
	id_match_configuration?: InputMaybe<Scalars['ID']>;
	id_supply_type: Scalars['ID'];
	name: Scalars['String'];
	tech_name?: InputMaybe<Scalars['String']>;
	type: TransactionKind;
};

export type MutationDeactivateFlowTypeArgs = {
	id: Scalars['ID'];
};

export type MutationDeactivateIntegrationProviderArgs = {
	id: Scalars['ID'];
};

export type MutationDeactivateNotificationTypeArgs = {
	id: Scalars['ID'];
};

export type MutationDeactivateSupplyArgs = {
	id: Scalars['ID'];
};

export type MutationDeactivateTransactionTypeArgs = {
	id: Scalars['ID'];
};

export type MutationDeclineTransactedMatchArgs = {
	id_transaction: Scalars['ID'];
};

export type MutationDeleteEnvironmentVariableArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteSharedObjectArgs = {
	id: Scalars['ID'];
};

export type MutationExecuteMigrationArgs = {
	id: Scalars['ID'];
};

export type MutationFlagTransferAsSucceededArgs = {
	id: Scalars['ID'];
};

export type MutationForceConnectionArgs = {
	connection_tech_name: Scalars['String'];
	id_end1_participant: Scalars['ID'];
	id_end2_participant: Scalars['ID'];
};

export type MutationForceTransactedMatchArgs = {
	id_consumer: Scalars['ID'];
	id_provider: Scalars['ID'];
	id_supply?: InputMaybe<Scalars['ID']>;
	id_transaction: Scalars['ID'];
	match_params: Array<FieldInput>;
};

export type MutationForceTransactionTerminationArgs = {
	id_transaction: Scalars['ID'];
};

export type MutationInitiateTransactionArgs = {
	id_transaction: Scalars['ID'];
};

export type MutationInviteConsumerToForwardAuctionArgs = {
	id_consumer: Scalars['ID'];
	id_transaction: Scalars['ID'];
	match_params?: InputMaybe<Array<FieldInput>>;
};

export type MutationInviteProviderToReverseAuctionArgs = {
	id_provider: Scalars['ID'];
	id_supply?: InputMaybe<Scalars['ID']>;
	id_transaction: Scalars['ID'];
	match_params?: InputMaybe<Array<FieldInput>>;
};

export type MutationJoinForwardAuctionArgs = {
	id_supply: Scalars['ID'];
	id_transaction: Scalars['ID'];
	match_params?: InputMaybe<Array<FieldInput>>;
};

export type MutationLoginApiUserArgs = {
	password: Scalars['String'];
	username: Scalars['String'];
};

export type MutationLoginBackofficeArgs = {
	email: Scalars['String'];
	marketplace_name: Scalars['String'];
	password: Scalars['String'];
};

export type MutationLoginParticipantArgs = {
	email: Scalars['String'];
	password: Scalars['String'];
};

export type MutationMoveStateTypeArgs = {
	id: Scalars['ID'];
	id_flow_type: Scalars['ID'];
	x: Scalars['Int'];
	y: Scalars['Int'];
};

export type MutationOverrideTransactedMatchPriceArgs = {
	id_transaction: Scalars['ID'];
	price: Scalars['JSONObject'];
};

export type MutationOverrideTransactedMatchPriceOnBehalfOfProviderArgs = {
	id_transaction: Scalars['ID'];
	price: Scalars['JSONObject'];
};

export type MutationPrepareAndInitiateForwardAuctionTransactionArgs = {
	consumer_filter?: InputMaybe<Array<FieldFilterInput>>;
	fields?: InputMaybe<Array<FieldInput>>;
	ids_supplies: Array<Scalars['ID']>;
	transaction_tech_name: Scalars['String'];
};

export type MutationPrepareAndInitiateReverseAuctionTransactionArgs = {
	consumer_filter?: InputMaybe<Array<FieldFilterInput>>;
	fields?: InputMaybe<Array<FieldInput>>;
	provider_filter?: InputMaybe<Array<FieldFilterInput>>;
	supply_filter?: InputMaybe<Array<FieldFilterInput>>;
	transaction_tech_name?: InputMaybe<Scalars['String']>;
};

export type MutationPrepareAndInitiateSingleDirectTransactionArgs = {
	fields?: InputMaybe<Array<FieldInput>>;
	id_supply: Scalars['ID'];
	transaction_tech_name?: InputMaybe<Scalars['String']>;
};

export type MutationPrepareConfigFileUploadArgs = {
	description?: InputMaybe<Scalars['String']>;
	extension: Scalars['String'];
	file_name: Scalars['String'];
	name?: InputMaybe<Scalars['String']>;
	type: FileKind;
};

export type MutationPrepareFileUploadArgs = {
	description?: InputMaybe<Scalars['String']>;
	extension: Scalars['String'];
	file_name: Scalars['String'];
	name?: InputMaybe<Scalars['String']>;
	type: FileKind;
};

export type MutationPrepareMigrationArgs = {
	flow_mappings: Array<FlowMappingInput>;
};

export type MutationPrepareTransactionArgs = {
	transaction_tech_name?: InputMaybe<Scalars['String']>;
};

export type MutationPrepareTransactionPaymentArgs = {
	id_transaction: Scalars['ID'];
	tech_name: Scalars['String'];
};

export type MutationPurgeSandboxDataArgs = {
	keep_participants?: InputMaybe<Scalars['Boolean']>;
	keep_supplies?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRegisterMarketplaceOwnerArgs = {
	email: Scalars['String'];
	first_name: Scalars['String'];
	last_name: Scalars['String'];
	marketplace_name: Scalars['String'];
};

export type MutationRegisterParticipantArgs = {
	email: Scalars['String'];
	first_name: Scalars['String'];
	last_name: Scalars['String'];
	participant_tech_name: Scalars['String'];
};

export type MutationRejectMatchArgs = {
	id: Scalars['ID'];
};

export type MutationRejectParticipantArgs = {
	id: Scalars['ID'];
};

export type MutationRejectSupplyArgs = {
	id: Scalars['ID'];
};

export type MutationRemoveFieldTypeFromArgs = {
	id_field_type: Scalars['ID'];
	id_parent: Scalars['ID'];
	parent_type: FieldTypeContainerKind;
};

export type MutationRemoveIntegrationProviderArgs = {
	id: Scalars['ID'];
};

export type MutationRemoveNotificationTypeArgs = {
	id: Scalars['ID'];
};

export type MutationRemoveShoppingCartItemArgs = {
	id_cart: Scalars['String'];
	id_item: Scalars['String'];
};

export type MutationRemoveStateTypeArgs = {
	id: Scalars['ID'];
	id_flow_type: Scalars['ID'];
};

export type MutationRemoveTransitionTypeArgs = {
	id: Scalars['ID'];
	id_flow_type: Scalars['ID'];
};

export type MutationReorderFieldTypesInArgs = {
	id_parent: Scalars['ID'];
	parent_type: FieldTypeContainerKind;
	sorted_field_type_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationRequestBackofficePasswordResetArgs = {
	email: Scalars['String'];
	marketplace_name: Scalars['String'];
};

export type MutationRequestMerchantAccountConnectionArgs = {
	id_integration_provider: Scalars['String'];
	refresh_url?: InputMaybe<Scalars['String']>;
	return_url?: InputMaybe<Scalars['String']>;
};

export type MutationRequestParticipantPasswordResetArgs = {
	email: Scalars['String'];
};

export type MutationRequestPaymentCheckoutConnectionArgs = {
	cancel_url?: InputMaybe<Scalars['String']>;
	id_payment: Scalars['ID'];
	success_url?: InputMaybe<Scalars['String']>;
};

export type MutationRequestPayoutArgs = {
	id_merchant_account: Scalars['ID'];
};

export type MutationRequestPayoutOnBehalfOfArgs = {
	id_merchant_account: Scalars['ID'];
};

export type MutationRequestStripeDashboardSignInLinkArgs = {
	id_merchant_account: Scalars['ID'];
};

export type MutationResendUserVerificationEMailArgs = {
	id_user: Scalars['ID'];
};

export type MutationResetBackofficePasswordArgs = {
	marketplace_name: Scalars['String'];
	password: Scalars['String'];
	token: Scalars['String'];
};

export type MutationResetParticipantPasswordArgs = {
	password: Scalars['String'];
	token: Scalars['String'];
};

export type MutationSendTransferArgs = {
	id: Scalars['ID'];
};

export type MutationSetDefaultTransactionTypeArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type MutationTriggerManualParticipantOnboardingTransitionArgs = {
	id_participant: Scalars['ID'];
	transition_tech_name?: InputMaybe<Scalars['String']>;
};

export type MutationTriggerManualSupplyOnboardingTransitionArgs = {
	id_supply: Scalars['ID'];
	transition_tech_name: Scalars['String'];
};

export type MutationTriggerManualTransactionTransitionArgs = {
	id_transaction: Scalars['ID'];
	transition_tech_name: Scalars['String'];
};

export type MutationUnlockMatchArgs = {
	id: Scalars['ID'];
	to: MatchTypeRoleKind;
};

export type MutationUpdateConnectionTypeArgs = {
	end1_name?: InputMaybe<Scalars['String']>;
	end2_name?: InputMaybe<Scalars['String']>;
	id: Scalars['ID'];
	name?: InputMaybe<Scalars['String']>;
	tech_name?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateEnvironmentVariableArgs = {
	id: Scalars['ID'];
	value?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateFieldAccessArgs = {
	client_type: FieldAccessClientKind;
	field_tech_name: Scalars['String'];
	id_match_context?: InputMaybe<Scalars['ID']>;
	id_parent: Scalars['ID'];
	new_access: FieldAccessKind;
	parent_type: FieldContainerKind;
};

export type MutationUpdateFieldTypeArgs = {
	conditions?: InputMaybe<Array<ConditionInput>>;
	id_field_type: Scalars['ID'];
	id_parent: Scalars['ID'];
	initial_accesses?: InputMaybe<FieldAccessInfoInput>;
	initial_value?: InputMaybe<Scalars['JSON']>;
	input_options?: InputMaybe<Scalars['JSONObject']>;
	is_nullable?: InputMaybe<Scalars['Boolean']>;
	is_required?: InputMaybe<Scalars['Boolean']>;
	is_variant_identifier?: InputMaybe<Scalars['Boolean']>;
	is_variant_specific?: InputMaybe<Scalars['Boolean']>;
	matching_weight?: InputMaybe<Scalars['Int']>;
	name?: InputMaybe<Scalars['String']>;
	parent_type: FieldTypeContainerKind;
	tech_name?: InputMaybe<Scalars['String']>;
	ui_config?: InputMaybe<FieldTypeUiConfigInput>;
	visible_to_guests?: InputMaybe<Scalars['Boolean']>;
	visible_to_participants?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateFlowTypeArgs = {
	id: Scalars['ID'];
	initiating_conditions?: InputMaybe<Array<FlowConditionInput>>;
	name?: InputMaybe<Scalars['String']>;
	var_types?: InputMaybe<Array<FlowVariableTypeInput>>;
};

export type MutationUpdateFrontofficeConfigurationArgs = {
	config_update: FrontofficeConfigInput;
};

export type MutationUpdateIntegrationProviderArgs = {
	data: Scalars['JSONObject'];
	id: Scalars['ID'];
};

export type MutationUpdateMatchArgs = {
	fields?: InputMaybe<Array<FieldInput>>;
	id: Scalars['ID'];
};

export type MutationUpdateMatchConfigurationForSupplyTypeArgs = {
	add_ons?: InputMaybe<Array<AddOnInput>>;
	discounts?: InputMaybe<Array<DiscountInput>>;
	id: Scalars['ID'];
	id_supply_type: Scalars['ID'];
	match_parameter_types?: InputMaybe<Array<FieldParameterTypeInput>>;
	match_restrictions?: InputMaybe<Array<MatchRestrictionInput>>;
	name?: InputMaybe<Scalars['String']>;
	platform_fees?: InputMaybe<Array<PlatformFeeInput>>;
	pricing_enabled?: InputMaybe<Scalars['Boolean']>;
	pricing_rules?: InputMaybe<Array<PricingRuleInput>>;
	taxes?: InputMaybe<Array<TaxInput>>;
	tech_name?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateMatchTypeArgs = {
	allow_consumer_guests?: InputMaybe<Scalars['Boolean']>;
	allow_provider_guests?: InputMaybe<Scalars['Boolean']>;
	auto_backoffice_approval?: InputMaybe<Scalars['Boolean']>;
	auto_consumer_acceptance?: InputMaybe<Scalars['Boolean']>;
	auto_provider_acceptance?: InputMaybe<Scalars['Boolean']>;
	availability_management_type?: InputMaybe<AvailabilityManagementKind>;
	consumer_discovery_enabled?: InputMaybe<Scalars['Boolean']>;
	id: Scalars['ID'];
	initial_match_visibility?: InputMaybe<MatchVisibilityInfoInput>;
	matching_unit_type?: InputMaybe<MatchingUnitKind>;
	name?: InputMaybe<Scalars['String']>;
	provider_discovery_enabled?: InputMaybe<Scalars['Boolean']>;
	supply_discovery_enabled?: InputMaybe<Scalars['Boolean']>;
	type_visibility?: InputMaybe<MatchTypeVisibilityInfoInput>;
	ui_config?: InputMaybe<Scalars['JSONObject']>;
};

export type MutationUpdateMatchingToolArgs = {
	fields?: InputMaybe<Array<FieldInput>>;
	id: Scalars['ID'];
};

export type MutationUpdateMatchingToolTypeArgs = {
	config?: InputMaybe<Scalars['JSONObject']>;
	execution_type?: InputMaybe<TaskExecutionKind>;
	id: Scalars['ID'];
	initially_unlocked_to_consumer?: InputMaybe<Scalars['Boolean']>;
	initially_unlocked_to_provider?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	type?: InputMaybe<MatchingToolKind>;
};

export type MutationUpdateMigrationArgs = {
	flow_mappings?: InputMaybe<Array<FlowMappingInput>>;
	id: Scalars['ID'];
};

export type MutationUpdateMyEmailArgs = {
	current_password: Scalars['String'];
	new_email: Scalars['String'];
};

export type MutationUpdateMySimpleStockSupplyAvailabilityArgs = {
	id_supply: Scalars['ID'];
	remaining_qty: Scalars['Int'];
};

export type MutationUpdateNotificationTypeArgs = {
	data?: InputMaybe<Scalars['JSONObject']>;
	description?: InputMaybe<Scalars['String']>;
	id: Scalars['ID'];
	id_integration_provider?: InputMaybe<Scalars['ID']>;
	name?: InputMaybe<Scalars['String']>;
	parameters?: InputMaybe<Array<NotificationParameterInput>>;
	recipients?: InputMaybe<Array<EMailNotificationRecipientInput>>;
	target_url?: InputMaybe<Scalars['HttpURL']>;
	where_event?: InputMaybe<Array<EventSelectorRecordInput>>;
};

export type MutationUpdateObjectTypeArgs = {
	id: Scalars['ID'];
	name?: InputMaybe<Scalars['String']>;
	shared_instances_enabled?: InputMaybe<Scalars['Boolean']>;
	tech_name?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateOwnerOnboardingProgressArgs = {
	answer: Scalars['JSON'];
	id_user: Scalars['ID'];
	step: Scalars['Int'];
};

export type MutationUpdateParticipantArgs = {
	fields?: InputMaybe<Array<FieldInput>>;
	id: Scalars['ID'];
};

export type MutationUpdateParticipantFieldAccessArgs = {
	field_tech_name: Scalars['String'];
	id_participant: Scalars['ID'];
	new_access: FieldAccessKind;
};

export type MutationUpdateParticipantTypeArgs = {
	account_type?: InputMaybe<AccountKind>;
	auto_backoffice_approval?: InputMaybe<Scalars['Boolean']>;
	id: Scalars['ID'];
	inviting_enabled?: InputMaybe<Scalars['Boolean']>;
	is_master_type?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	tech_name?: InputMaybe<Scalars['String']>;
	ui_config?: InputMaybe<Scalars['JSONObject']>;
};

export type MutationUpdatePaymentServiceProviderArgs = {
	data: Scalars['JSONObject'];
	type: PaymentProviderKind;
};

export type MutationUpdatePaymentTypeArgs = {
	config: Scalars['JSONObject'];
	id: Scalars['ID'];
	id_integration_provider?: InputMaybe<Scalars['ID']>;
	name?: InputMaybe<Scalars['String']>;
	tech_name?: InputMaybe<Scalars['String']>;
	ui_config?: InputMaybe<Scalars['JSONObject']>;
};

export type MutationUpdatePlatformAccountArgs = {
	account_update: AccountInput;
};

export type MutationUpdateSharedObjectArgs = {
	fields?: InputMaybe<Array<FieldInput>>;
	id: Scalars['ID'];
};

export type MutationUpdateShoppingCartArgs = {
	fields?: InputMaybe<Array<FieldInput>>;
	id_cart: Scalars['String'];
};

export type MutationUpdateShoppingCartItemArgs = {
	fields: Array<FieldInput>;
	id_cart: Scalars['String'];
	id_item: Scalars['String'];
};

export type MutationUpdateSimpleStockSupplyAvailabilityOnBehalfOfProviderArgs = {
	id_supply: Scalars['ID'];
	remaining_qty: Scalars['Int'];
};

export type MutationUpdateStateTypeArgs = {
	actions?: InputMaybe<Array<ActionTypeInput>>;
	id: Scalars['ID'];
	id_flow_type: Scalars['ID'];
	name?: InputMaybe<Scalars['String']>;
	tech_name?: InputMaybe<Scalars['String']>;
	ui_config?: InputMaybe<Scalars['JSONObject']>;
};

export type MutationUpdateSupplyArgs = {
	fields?: InputMaybe<Array<FieldInput>>;
	id: Scalars['ID'];
};

export type MutationUpdateSupplyFieldAccessArgs = {
	field_tech_name: Scalars['String'];
	id_supply: Scalars['ID'];
	new_access: FieldAccessKind;
};

export type MutationUpdateSupplyTypeArgs = {
	allow_variant_duplicates?: InputMaybe<Scalars['Boolean']>;
	auto_activation?: InputMaybe<Scalars['Boolean']>;
	auto_backoffice_approval?: InputMaybe<Scalars['Boolean']>;
	auto_variants_onboarding?: InputMaybe<Scalars['Boolean']>;
	id: Scalars['ID'];
	name?: InputMaybe<Scalars['String']>;
	tech_name?: InputMaybe<Scalars['String']>;
	ui_config?: InputMaybe<Scalars['JSONObject']>;
};

export type MutationUpdateTransactedMatchArgs = {
	fields?: InputMaybe<Array<FieldInput>>;
	id_transaction: Scalars['ID'];
};

export type MutationUpdateTransactionFieldAccessArgs = {
	client_type: FieldAccessClientKind;
	field_tech_name: Scalars['String'];
	id_transaction: Scalars['ID'];
	new_access: FieldAccessKind;
	parent_type: FieldContainerKind;
};

export type MutationUpdateTransactionOrderArgs = {
	consumer_filter?: InputMaybe<Array<FieldFilterInput>>;
	fields?: InputMaybe<Array<FieldInput>>;
	id_supply?: InputMaybe<Scalars['ID']>;
	id_transaction: Scalars['ID'];
	name?: InputMaybe<Scalars['String']>;
	provider_filter?: InputMaybe<Array<FieldFilterInput>>;
	supply_filter?: InputMaybe<Array<FieldFilterInput>>;
};

export type MutationUpdateTransactionTypeArgs = {
	cascading_termination?: InputMaybe<Scalars['Boolean']>;
	forced_termination_enabled?: InputMaybe<Scalars['Boolean']>;
	id: Scalars['ID'];
	name?: InputMaybe<Scalars['String']>;
	order_configuration?: InputMaybe<OrderConfigurationInput>;
	tech_name?: InputMaybe<Scalars['String']>;
	ui_config?: InputMaybe<Scalars['JSONObject']>;
};

export type MutationUpdateTransitionTypeArgs = {
	actions?: InputMaybe<Array<ActionTypeInput>>;
	id: Scalars['ID'];
	id_flow_type: Scalars['ID'];
	id_from?: InputMaybe<Scalars['ID']>;
	id_to?: InputMaybe<Scalars['ID']>;
	name?: InputMaybe<Scalars['String']>;
	tech_name?: InputMaybe<Scalars['String']>;
	triggering_conditions?: InputMaybe<Array<FlowConditionInput>>;
	triggering_event?: InputMaybe<RandevuEventKind>;
	ui_config?: InputMaybe<Scalars['JSONObject']>;
};

export type MutationUpdateUserArgs = {
	first_name?: InputMaybe<Scalars['String']>;
	id: Scalars['ID'];
	last_name?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateUserEmailOnBehalfOfArgs = {
	id_user: Scalars['ID'];
	new_email: Scalars['String'];
};

export type MutationVerifyBackofficeUserArgs = {
	marketplace_name: Scalars['String'];
	password: Scalars['String'];
	token: Scalars['String'];
};

export type MutationVerifyParticipantArgs = {
	password: Scalars['String'];
	token: Scalars['String'];
};

export type NextStep = {
	__typename?: 'NextStep';
	action_message?: Maybe<Scalars['String']>;
	config: Scalars['JSONObject'];
	type: NextStepKind;
};

export enum NextStepKind {
	ManualTrigger = 'MANUAL_TRIGGER',
	ObjectLifecycle = 'OBJECT_LIFECYCLE',
	ObjectUpdate = 'OBJECT_UPDATE',
}

export type NoOnboardingProgress = {
	__typename?: 'NoOnboardingProgress';
	dummy?: Maybe<Scalars['Int']>;
};

export type Notification = {
	__typename?: 'Notification';
	event: Event;
	id: Scalars['ID'];
	is_success?: Maybe<Scalars['Boolean']>;
	replied_at?: Maybe<Scalars['DateTime']>;
	request: Scalars['JSONObject'];
	response?: Maybe<Scalars['JSONObject']>;
	sent_at: Scalars['DateTime'];
	type: NotificationType;
};

export enum NotificationChannelKind {
	Email = 'EMAIL',
	Frontend = 'FRONTEND',
	PushNotification = 'PUSH_NOTIFICATION',
	Webhook = 'WEBHOOK',
}

export enum NotificationFieldParentKind {
	Consumer = 'CONSUMER',
	Creator = 'CREATOR',
	Match = 'MATCH',
	MatchingTool = 'MATCHING_TOOL',
	NoCreator = 'NO_CREATOR',
	Owner = 'OWNER',
	Provider = 'PROVIDER',
	Supply = 'SUPPLY',
	Transaction = 'TRANSACTION',
}

export type NotificationFilterInput = {
	channel: NotificationChannelKind;
	event_code?: InputMaybe<RandevuEventKind>;
	id_notification_type?: InputMaybe<Scalars['ID']>;
	is_success?: InputMaybe<Scalars['Boolean']>;
};

export type NotificationLight = {
	__typename?: 'NotificationLight';
	event_code: RandevuEventKind;
	id: Scalars['ID'];
	is_success?: Maybe<Scalars['Boolean']>;
	replied_at?: Maybe<Scalars['DateTime']>;
	sent_at: Scalars['DateTime'];
};

export type NotificationParameter = {
	__typename?: 'NotificationParameter';
	data?: Maybe<Scalars['JSONObject']>;
	name: Scalars['String'];
	parent_type?: Maybe<NotificationFieldParentKind>;
	source_type: NotificationParameterSourceKind;
};

export type NotificationParameterInput = {
	data?: InputMaybe<Scalars['JSONObject']>;
	name: Scalars['String'];
	parent_type?: InputMaybe<NotificationFieldParentKind>;
	source_type: NotificationParameterSourceKind;
};

export enum NotificationParameterSourceKind {
	BoUserResetPassRequest = 'BO_USER_RESET_PASS_REQUEST',
	Const = 'CONST',
	Email = 'EMAIL',
	EncodedChangePassToken = 'ENCODED_CHANGE_PASS_TOKEN',
	EncodedVerificationToken = 'ENCODED_VERIFICATION_TOKEN',
	EnvVar = 'ENV_VAR',
	Event = 'EVENT',
	Field = 'FIELD',
	FirstName = 'FIRST_NAME',
	LastName = 'LAST_NAME',
	MatchId = 'MATCH_ID',
	MobileNr = 'MOBILE_NR',
	NewEmail = 'NEW_EMAIL',
	OldEmail = 'OLD_EMAIL',
	ParticipantId = 'PARTICIPANT_ID',
	ParticipantResetPassRequest = 'PARTICIPANT_RESET_PASS_REQUEST',
	StateName = 'STATE_NAME',
	SupplyId = 'SUPPLY_ID',
	TransactionId = 'TRANSACTION_ID',
	UpdatedByBackoffice = 'UPDATED_BY_BACKOFFICE',
	UserId = 'USER_ID',
	VerifyBoUserEmail = 'VERIFY_BO_USER_EMAIL',
	VerifyParticipantEmail = 'VERIFY_PARTICIPANT_EMAIL',
}

export enum NotificationRecipientRoleKind {
	Bo = 'BO',
	Const = 'CONST',
	Consumer = 'CONSUMER',
	Creator = 'CREATOR',
	Field = 'FIELD',
	NewEmail = 'NEW_EMAIL',
	NoCreator = 'NO_CREATOR',
	OldEmail = 'OLD_EMAIL',
	Owner = 'OWNER',
	Provider = 'PROVIDER',
}

export type NotificationType = {
	__typename?: 'NotificationType';
	channel: NotificationChannelKind;
	data?: Maybe<Scalars['JSONObject']>;
	description?: Maybe<Scalars['String']>;
	event_filter: Array<EventSelectorRecord>;
	id: Scalars['ID'];
	integration_provider?: Maybe<IntegrationProvider>;
	is_active: Scalars['Boolean'];
	name: Scalars['String'];
	parameters: Array<NotificationParameter>;
	recipients: Array<EMailNotificationRecipient>;
	target_url?: Maybe<Scalars['HttpURL']>;
};

export type NotificationTypeFilterInput = {
	channel: NotificationChannelKind;
	event_code?: InputMaybe<Scalars['String']>;
	is_active?: InputMaybe<Scalars['Boolean']>;
	is_system?: InputMaybe<Scalars['Boolean']>;
};

export type Object = {
	__typename?: 'Object';
	fields?: Maybe<Array<Field>>;
	id: Scalars['ID'];
	id_object_type: Scalars['ID'];
};

export type ObjectField = {
	__typename?: 'ObjectField';
	current_accesses: FieldAccessInfo;
	field_type: FieldType;
	id: Scalars['ID'];
	my_access?: Maybe<FieldAccessKind>;
	object_type: ObjectType;
	value?: Maybe<ObjectFieldValue>;
};

export type ObjectFieldValue = {
	__typename?: 'ObjectFieldValue';
	fields: Array<Field>;
	id_object: Scalars['ID'];
	id_object_type: Scalars['ID'];
};

export type ObjectSetField = {
	__typename?: 'ObjectSetField';
	current_accesses: FieldAccessInfo;
	field_type: FieldType;
	id: Scalars['ID'];
	my_access?: Maybe<FieldAccessKind>;
	object_type: ObjectType;
	value?: Maybe<Array<ObjectFieldValue>>;
};

export type ObjectType = {
	__typename?: 'ObjectType';
	fields: Array<FieldType>;
	id: Scalars['ID'];
	name: Scalars['String'];
	shared_instances_enabled: Scalars['Boolean'];
	tech_name: Scalars['String'];
};

export type Order = {
	__typename?: 'Order';
	gross_value: Scalars['Int'];
	id: Scalars['ID'];
	net_value: Scalars['Int'];
	order_items: Array<OrderItem>;
	provider: Participant;
	total_before_tax: Scalars['Int'];
	total_provider_fees: Scalars['Int'];
	total_taxes: Scalars['Int'];
};

export type OrderConfiguration = {
	__typename?: 'OrderConfiguration';
	platform_fees: Array<PlatformFee>;
};

export type OrderConfigurationInput = {
	platform_fees: Array<PlatformFeeInput>;
};

export type OrderItem = {
	__typename?: 'OrderItem';
	data: Scalars['JSONObject'];
	description: Scalars['String'];
	type: PriceItemKind;
	value: Scalars['Int'];
};

export type OwnerScopeObject = {
	id_arg: Scalars['String'];
	owner_arg: Scalars['String'];
	type: Scalars['String'];
};

export type PageTitleUiConfig = {
	__typename?: 'PageTitleUiConfig';
	ids_participant_type: Array<Maybe<Scalars['String']>>;
	label: Scalars['String'];
};

export type PageTitleUiConfigInput = {
	ids_participant_type: Array<InputMaybe<Scalars['String']>>;
	label: Scalars['String'];
};

export type Participant = {
	__typename?: 'Participant';
	account: Account;
	approved_at?: Maybe<Scalars['DateTime']>;
	created_at?: Maybe<Scalars['DateTime']>;
	fields: Array<Field>;
	id: Scalars['ID'];
	id_onboarding_flow?: Maybe<Scalars['ID']>;
	onboarded_at?: Maybe<Scalars['DateTime']>;
	onboarding_progress?: Maybe<ParticipantOnboardingProgress>;
	provided_supplies?: Maybe<Array<Supply>>;
	rejected_at?: Maybe<Scalars['DateTime']>;
	status: ParticipantStatusKind;
	type: ParticipantType;
	user_actions: Array<UserAction>;
	users: Array<User>;
};

export type ParticipantAccountPageConfig = {
	__typename?: 'ParticipantAccountPageConfig';
	description?: Maybe<Scalars['String']>;
	payment_section?: Maybe<ParticipantAccountPaymentSectionConfig>;
	profile_section?: Maybe<ParticipantAccountProfileSectionConfig>;
	title?: Maybe<Scalars['String']>;
};

export type ParticipantAccountPageConfigInput = {
	description?: InputMaybe<Scalars['String']>;
	payment_section?: InputMaybe<ParticipantAccountPaymentSectionConfigInput>;
	profile_section?: InputMaybe<ParticipantAccountProfileSectionConfigInput>;
	title?: InputMaybe<Scalars['String']>;
};

export type ParticipantAccountPaymentSectionConfig = {
	__typename?: 'ParticipantAccountPaymentSectionConfig';
	tab_name?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

export type ParticipantAccountPaymentSectionConfigInput = {
	tab_name?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ParticipantAccountProfileSectionConfig = {
	__typename?: 'ParticipantAccountProfileSectionConfig';
	button_save?: Maybe<FrontofficeFormFieldConfig>;
	tab_name?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

export type ParticipantAccountProfileSectionConfigInput = {
	button_save?: InputMaybe<FrontofficeFormFieldConfigInput>;
	tab_name?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ParticipantFilter = {
	ids_participant_types?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	pending_backoffice_actions?: InputMaybe<Scalars['Boolean']>;
	status?: InputMaybe<Array<ParticipantStatusKind>>;
};

export type ParticipantOnboardingProgress = {
	__typename?: 'ParticipantOnboardingProgress';
	message?: Maybe<Scalars['String']>;
	next_steps: Array<NextStep>;
	status?: Maybe<Scalars['String']>;
	status_tech_name?: Maybe<Scalars['String']>;
};

export type ParticipantPublicProfile = {
	__typename?: 'ParticipantPublicProfile';
	fields: Array<Field>;
	id_participant: Scalars['ID'];
	id_participant_type: Scalars['ID'];
};

export enum ParticipantStatusKind {
	Onboarded = 'ONBOARDED',
	Onboarding = 'ONBOARDING',
	PendingOnboarding = 'PENDING_ONBOARDING',
	Rejected = 'REJECTED',
}

export type ParticipantType = {
	__typename?: 'ParticipantType';
	account_type?: Maybe<AccountKind>;
	auto_backoffice_approval: Scalars['Boolean'];
	fields: Array<FieldType>;
	flow_type: FlowType;
	id: Scalars['ID'];
	id_onboarding_flow_type?: Maybe<Scalars['ID']>;
	inviting_enabled: Scalars['Boolean'];
	is_master_type: Scalars['Boolean'];
	name: Scalars['String'];
	tech_name: Scalars['String'];
	ui_config: Scalars['JSONObject'];
};

export type Payment = {
	__typename?: 'Payment';
	amount: MonetaryValue;
	authorized_at?: Maybe<Scalars['DateTime']>;
	captured_at?: Maybe<Scalars['DateTime']>;
	checkout_requested_at?: Maybe<Scalars['DateTime']>;
	completed_at?: Maybe<Scalars['DateTime']>;
	created_at: Scalars['DateTime'];
	error?: Maybe<PaymentError>;
	failed_at?: Maybe<Scalars['DateTime']>;
	id: Scalars['ID'];
	id_psp_transaction?: Maybe<Scalars['String']>;
	id_transaction: Scalars['ID'];
	payer: Participant;
	platform_revenue?: Maybe<PlatformRevenue>;
	prepared_at: Scalars['DateTime'];
	prepared_by: User;
	psp_fees?: Maybe<Scalars['JSONObject']>;
	psp_name?: Maybe<Scalars['String']>;
	status: PaymentStatusKind;
	transfers: Array<Transfer>;
	transfers_completed_at?: Maybe<Scalars['DateTime']>;
	updated_at?: Maybe<Scalars['DateTime']>;
	user: User;
};

export type PaymentCheckoutConnection = {
	__typename?: 'PaymentCheckoutConnection';
	id_payment: Scalars['ID'];
	url: Scalars['String'];
};

export type PaymentError = {
	__typename?: 'PaymentError';
	error_code?: Maybe<Scalars['String']>;
	error_message?: Maybe<Scalars['String']>;
};

export type PaymentFilter = {
	status?: InputMaybe<PaymentStatusKind>;
};

export type PaymentLight = {
	__typename?: 'PaymentLight';
	amount: MonetaryValue;
	authorized_at?: Maybe<Scalars['DateTime']>;
	captured_at?: Maybe<Scalars['DateTime']>;
	checkout_requested_at?: Maybe<Scalars['DateTime']>;
	completed_at?: Maybe<Scalars['DateTime']>;
	created_at: Scalars['DateTime'];
	failed_at?: Maybe<Scalars['DateTime']>;
	id: Scalars['ID'];
	id_transaction: Scalars['ID'];
	payer: Participant;
	prepared_at: Scalars['DateTime'];
	prepared_by: User;
	status: PaymentStatusKind;
	updated_at?: Maybe<Scalars['DateTime']>;
	user: User;
};

export type PaymentProviderConnection = {
	__typename?: 'PaymentProviderConnection';
	url: Scalars['String'];
};

export enum PaymentProviderKind {
	Stripe = 'STRIPE',
}

export type PaymentServiceProvider = {
	__typename?: 'PaymentServiceProvider';
	data: Scalars['JSONObject'];
	type: PaymentProviderKind;
};

export enum PaymentStatusKind {
	Authorized = 'AUTHORIZED',
	Captured = 'CAPTURED',
	CheckoutRequested = 'CHECKOUT_REQUESTED',
	Completed = 'COMPLETED',
	Failed = 'FAILED',
	Prepared = 'PREPARED',
}

export type PaymentType = {
	__typename?: 'PaymentType';
	config: Scalars['JSONObject'];
	id: Scalars['ID'];
	integration_provider?: Maybe<IntegrationProvider>;
	name: Scalars['String'];
	tech_name: Scalars['String'];
	ui_config: Scalars['JSONObject'];
};

export type PlatformFee = {
	__typename?: 'PlatformFee';
	description: Scalars['String'];
	guard_expression: Scalars['String'];
	is_consumer_fee?: Maybe<Scalars['Boolean']>;
	is_provider_fee?: Maybe<Scalars['Boolean']>;
	name: Scalars['String'];
	tech_name: Scalars['String'];
	value_expression: Scalars['String'];
};

export type PlatformFeeInput = {
	description: Scalars['String'];
	guard_expression: Scalars['String'];
	is_consumer_fee?: InputMaybe<Scalars['Boolean']>;
	is_provider_fee?: InputMaybe<Scalars['Boolean']>;
	name: Scalars['String'];
	tech_name: Scalars['String'];
	value_expression: Scalars['String'];
};

export type PlatformFeeItem = {
	__typename?: 'PlatformFeeItem';
	description: Scalars['String'];
	is_consumer_fee?: Maybe<Scalars['Boolean']>;
	is_provider_fee?: Maybe<Scalars['Boolean']>;
	value: Scalars['Int'];
};

export type PlatformOwnerOnboardingProgress = {
	__typename?: 'PlatformOwnerOnboardingProgress';
	answers?: Maybe<Array<PlatformOwnerOnboardingStep>>;
	status?: Maybe<Scalars['String']>;
};

export enum PlatformOwnerOnboardingStatusKind {
	Canceled = 'CANCELED',
	Declined = 'DECLINED',
	InitialPlatformCreated = 'INITIAL_PLATFORM_CREATED',
	NotStarted = 'NOT_STARTED',
	Ongoing = 'ONGOING',
	Rejected = 'REJECTED',
	SurveyDone = 'SURVEY_DONE',
}

export type PlatformOwnerOnboardingStep = {
	__typename?: 'PlatformOwnerOnboardingStep';
	answer: Scalars['JSON'];
	step: Scalars['Int'];
};

export type PlatformRevenue = {
	__typename?: 'PlatformRevenue';
	consumer?: Maybe<Array<PlatformRevenueItem>>;
	providers?: Maybe<Array<PlatformRevenueItem>>;
};

export type PlatformRevenueItem = {
	__typename?: 'PlatformRevenueItem';
	description: Scalars['String'];
	id_order?: Maybe<Scalars['ID']>;
	id_participant: Scalars['ID'];
	id_supply?: Maybe<Scalars['ID']>;
	value: Scalars['Int'];
};

export enum PlatformTeamRole {
	MarketplaceAdmin = 'MARKETPLACE_ADMIN',
	MarketplaceDeveloper = 'MARKETPLACE_DEVELOPER',
	MarketplaceOperator = 'MARKETPLACE_OPERATOR',
	MarketplaceOwner = 'MARKETPLACE_OWNER',
}

export enum PriceItemKind {
	AddOn = 'ADD_ON',
	Discount = 'DISCOUNT',
	PlatformFee = 'PLATFORM_FEE',
	PriceWithQty = 'PRICE_WITH_QTY',
	Tax = 'TAX',
}

export type PricingRule = {
	__typename?: 'PricingRule';
	description: Scalars['String'];
	guard_expression?: Maybe<Scalars['String']>;
	name: Scalars['String'];
	tech_name: Scalars['String'];
	value_expression: Scalars['String'];
};

export type PricingRuleInput = {
	description: Scalars['String'];
	guard_expression?: InputMaybe<Scalars['String']>;
	name: Scalars['String'];
	tech_name: Scalars['String'];
	value_expression: Scalars['String'];
};

export type Provider = {
	__typename?: 'Provider';
	fields: Array<Field>;
	id_participant: Scalars['ID'];
	supplies: Array<Supply>;
};

export type PublicKeyRecord = {
	__typename?: 'PublicKeyRecord';
	environment: MarketplaceEnvironmentKind;
	id_marketplace: Scalars['ID'];
	marketplace_name: Scalars['String'];
};

export type Query = {
	__typename?: 'Query';
	calculateSupplyPrice: SupplyPrice;
	discoverConsumerDetails: Supply;
	discoverConsumers?: Maybe<Array<User>>;
	discoverProviderDetails: Provider;
	discoverProviders?: Maybe<Array<User>>;
	discoverSupply?: Maybe<Array<Supply>>;
	discoverSupplyDetails: Supply;
	getAccount: Account;
	getApiKeys: Array<ApiKey>;
	getApiUsers: Array<ApiUser>;
	getConnectionType: ConnectionType;
	getConnectionTypes: Array<ConnectionType>;
	getConnections: Array<Connection>;
	getEnvironmentVariables: Array<EnvironmentVariable>;
	getFavouriteFieldTypes: Array<FavouriteFieldType>;
	getFlowType: FlowType;
	getFlowTypes?: Maybe<Array<FlowType>>;
	getFrontofficeConfiguration: FrontofficeConfiguration;
	getIntegrationProviderCatalog: Array<AvailableIntegrationProvider>;
	getIntegrationProviders: Array<IntegrationProvider>;
	getMatch?: Maybe<Match>;
	getMatchType?: Maybe<MatchType>;
	getMatchTypes?: Maybe<Array<MatchType>>;
	getMatches?: Maybe<Array<Match>>;
	getMatchingToolType: MatchingToolType;
	getMigration: Migration;
	getMigrations: Array<Migration>;
	getModifiedFlowTypes: Array<ModifiedFlowType>;
	getNotification: Notification;
	getNotificationType: NotificationType;
	getNotificationTypeSecret: Scalars['String'];
	getNotificationTypes: Array<NotificationType>;
	getNotifications: Array<NotificationLight>;
	getObjectType: ObjectType;
	getObjectTypes: Array<ObjectType>;
	getParticipant: Participant;
	getParticipantPublicProfile: ParticipantPublicProfile;
	getParticipantType?: Maybe<ParticipantType>;
	getParticipantTypes: Array<ParticipantType>;
	getParticipants: Array<Participant>;
	getPayment: Payment;
	getPaymentServiceProvider: PaymentServiceProvider;
	getPaymentServiceProviders: Array<Maybe<IntegrationProvider>>;
	getPaymentTypes: Array<PaymentType>;
	getPayments?: Maybe<Array<Payment>>;
	getPlatformEnvironmentsDifferences: Array<Scalars['String']>;
	getPlatformTeam: Array<BackofficeUser>;
	getRandevuEvents: Array<RandevuEvent>;
	getSharedObject: Object;
	getSharedObjects?: Maybe<Array<Object>>;
	getShoppingCart: ShoppingCartForBo;
	getShoppingCarts: Array<ShoppingCartLight>;
	getSupplies?: Maybe<Array<Supply>>;
	getSupply: Supply;
	getSupplyType?: Maybe<SupplyType>;
	getSupplyTypes?: Maybe<Array<SupplyType>>;
	getTransaction: Transaction;
	getTransactionType: TransactionType;
	getTransactionTypes?: Maybe<Array<TransactionType>>;
	getTransactions: Array<TransactionLight>;
	getTransfers: Array<Maybe<Transfer>>;
	getUser: User;
	isSupplyAvailable: Scalars['Boolean'];
	me: User;
	myActiveShoppingCart?: Maybe<ShoppingCart>;
	myConnectionType: ConnectionType;
	myConnectionTypes: Array<ConnectionType>;
	myConnections: Array<Connection>;
	myParticipant: Participant;
	myParticipants: Array<Participant>;
	myPayment: Payment;
	myPaymentTypes: Array<PaymentType>;
	myPayments: Array<Maybe<PaymentLight>>;
	mySharedObject: Object;
	mySharedObjectTypes?: Maybe<Array<ObjectType>>;
	mySharedObjects?: Maybe<Array<Object>>;
	myShoppingCart: ShoppingCart;
	myShoppingCartOrder: ShoppingCartOrder;
	myShoppingCartOrders: Array<ShoppingCartOrderLight>;
	myShoppingCarts?: Maybe<Array<ShoppingCartLight>>;
	mySupplies?: Maybe<Array<Supply>>;
	mySupply?: Maybe<Supply>;
	mySupplyTypes?: Maybe<Array<SupplyType>>;
	myTransaction: Transaction;
	myTransactionTypes?: Maybe<Array<TransactionType>>;
	myTransactions?: Maybe<Array<TransactionLight>>;
	myTransfers: Array<Maybe<TransferLight>>;
	myself: BackofficeUser;
};

export type QueryCalculateSupplyPriceArgs = {
	id_supply: Scalars['ID'];
	match_parameters: Array<FieldParameterInput>;
};

export type QueryDiscoverConsumerDetailsArgs = {
	id_consumer: Scalars['ID'];
	transaction_tech_name?: InputMaybe<Scalars['String']>;
};

export type QueryDiscoverConsumersArgs = {
	transaction_tech_name?: InputMaybe<Scalars['String']>;
	where: Array<FieldFilterInput>;
};

export type QueryDiscoverProviderDetailsArgs = {
	id_participant: Scalars['ID'];
	transaction_tech_name?: InputMaybe<Scalars['String']>;
};

export type QueryDiscoverProvidersArgs = {
	transaction_tech_name?: InputMaybe<Scalars['String']>;
	where: Array<FieldFilterInput>;
};

export type QueryDiscoverSupplyArgs = {
	availability_params?: InputMaybe<Array<FieldParameterInput>>;
	available_supply_only?: InputMaybe<Scalars['Boolean']>;
	transaction_tech_name?: InputMaybe<Scalars['String']>;
	where: Array<FieldFilterInput>;
};

export type QueryDiscoverSupplyDetailsArgs = {
	id_supply: Scalars['ID'];
	transaction_tech_name?: InputMaybe<Scalars['String']>;
};

export type QueryGetConnectionTypeArgs = {
	id: Scalars['ID'];
};

export type QueryGetFlowTypeArgs = {
	id: Scalars['ID'];
};

export type QueryGetFrontofficeConfigurationArgs = {
	domain_name?: InputMaybe<Scalars['String']>;
	environment: MarketplaceEnvironmentKind;
	marketplace_name?: InputMaybe<Scalars['String']>;
};

export type QueryGetMatchArgs = {
	id: Scalars['ID'];
};

export type QueryGetMatchTypeArgs = {
	id: Scalars['ID'];
};

export type QueryGetMatchesArgs = {
	filter?: InputMaybe<MatchFilter>;
};

export type QueryGetMatchingToolTypeArgs = {
	id: Scalars['ID'];
};

export type QueryGetMigrationArgs = {
	id: Scalars['ID'];
};

export type QueryGetNotificationArgs = {
	id: Scalars['ID'];
};

export type QueryGetNotificationTypeArgs = {
	id: Scalars['ID'];
};

export type QueryGetNotificationTypeSecretArgs = {
	id: Scalars['ID'];
};

export type QueryGetNotificationTypesArgs = {
	where?: InputMaybe<NotificationTypeFilterInput>;
};

export type QueryGetNotificationsArgs = {
	where?: InputMaybe<NotificationFilterInput>;
};

export type QueryGetObjectTypeArgs = {
	id: Scalars['ID'];
};

export type QueryGetParticipantArgs = {
	id: Scalars['ID'];
};

export type QueryGetParticipantPublicProfileArgs = {
	id_participant: Scalars['ID'];
	participant_tech_name: Scalars['String'];
};

export type QueryGetParticipantTypeArgs = {
	id: Scalars['ID'];
};

export type QueryGetParticipantsArgs = {
	where?: InputMaybe<ParticipantFilter>;
};

export type QueryGetPaymentArgs = {
	id: Scalars['ID'];
};

export type QueryGetPaymentServiceProviderArgs = {
	type: PaymentProviderKind;
};

export type QueryGetPaymentsArgs = {
	where?: InputMaybe<PaymentFilter>;
};

export type QueryGetPlatformTeamArgs = {
	where?: InputMaybe<BackofficeUserFilter>;
};

export type QueryGetRandevuEventsArgs = {
	where?: InputMaybe<RandevuEventsFilter>;
};

export type QueryGetSharedObjectArgs = {
	id: Scalars['ID'];
};

export type QueryGetSharedObjectsArgs = {
	where?: InputMaybe<SharedObjectFilter>;
};

export type QueryGetShoppingCartArgs = {
	id_cart: Scalars['ID'];
};

export type QueryGetShoppingCartsArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ShoppingCartFilter>;
};

export type QueryGetSuppliesArgs = {
	where?: InputMaybe<SupplyFilter>;
};

export type QueryGetSupplyArgs = {
	id: Scalars['ID'];
};

export type QueryGetSupplyTypeArgs = {
	id: Scalars['ID'];
};

export type QueryGetTransactionArgs = {
	id: Scalars['ID'];
};

export type QueryGetTransactionTypeArgs = {
	id: Scalars['ID'];
};

export type QueryGetTransactionsArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<TransactionFilter>;
};

export type QueryGetTransfersArgs = {
	id_payment: Scalars['ID'];
};

export type QueryGetUserArgs = {
	id: Scalars['ID'];
};

export type QueryIsSupplyAvailableArgs = {
	availability_params: Array<FieldParameterInput>;
	id_supply: Scalars['ID'];
};

export type QueryMyConnectionTypeArgs = {
	tech_name: Scalars['String'];
};

export type QueryMyParticipantArgs = {
	id: Scalars['ID'];
};

export type QueryMyPaymentArgs = {
	id: Scalars['ID'];
};

export type QueryMySharedObjectArgs = {
	id: Scalars['ID'];
};

export type QueryMySharedObjectsArgs = {
	where?: InputMaybe<SharedObjectFilter>;
};

export type QueryMyShoppingCartArgs = {
	id_cart: Scalars['ID'];
};

export type QueryMyShoppingCartOrderArgs = {
	id_cart_order: Scalars['ID'];
};

export type QueryMyShoppingCartOrdersArgs = {
	where: ShoppingCartOrderFilter;
};

export type QueryMyShoppingCartsArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	where?: InputMaybe<ShoppingCartFilter>;
};

export type QueryMySupplyArgs = {
	id: Scalars['ID'];
};

export type QueryMyTransactionArgs = {
	id: Scalars['ID'];
};

export type RandevuEvent = {
	__typename?: 'RandevuEvent';
	code: RandevuEventKind;
	id: Scalars['ID'];
	payload: Scalars['JSONObject'];
	triggered_at: Scalars['DateTime'];
};

export enum RandevuEventKind {
	FlowInitiated = 'FLOW_INITIATED',
	FlowTerminated = 'FLOW_TERMINATED',
	ManualTransitionTriggered = 'MANUAL_TRANSITION_TRIGGERED',
	MatchingToolCreated = 'MATCHING_TOOL_CREATED',
	MatchingToolToDeactivate = 'MATCHING_TOOL_TO_DEACTIVATE',
	MatchingToolToExecute = 'MATCHING_TOOL_TO_EXECUTE',
	MatchingToolUpdated = 'MATCHING_TOOL_UPDATED',
	MatchAccepted = 'MATCH_ACCEPTED',
	MatchApproved = 'MATCH_APPROVED',
	MatchCanceled = 'MATCH_CANCELED',
	MatchClosed = 'MATCH_CLOSED',
	MatchCreated = 'MATCH_CREATED',
	MatchDeclined = 'MATCH_DECLINED',
	MatchPaid = 'MATCH_PAID',
	MatchPriceOverridden = 'MATCH_PRICE_OVERRIDDEN',
	MatchRejected = 'MATCH_REJECTED',
	MatchUpdated = 'MATCH_UPDATED',
	ParticipantApproved = 'PARTICIPANT_APPROVED',
	ParticipantCreated = 'PARTICIPANT_CREATED',
	ParticipantOnboarded = 'PARTICIPANT_ONBOARDED',
	ParticipantOnboardingStateReached = 'PARTICIPANT_ONBOARDING_STATE_REACHED',
	ParticipantOnboardingTransitionExecuted = 'PARTICIPANT_ONBOARDING_TRANSITION_EXECUTED',
	ParticipantRejected = 'PARTICIPANT_REJECTED',
	ParticipantUpdated = 'PARTICIPANT_UPDATED',
	PaymentAuthorized = 'PAYMENT_AUTHORIZED',
	PaymentCaptured = 'PAYMENT_CAPTURED',
	PaymentCompleted = 'PAYMENT_COMPLETED',
	PaymentFailed = 'PAYMENT_FAILED',
	PaymentPrepared = 'PAYMENT_PREPARED',
	RequestedForcedTransition = 'REQUESTED_FORCED_TRANSITION',
	RequestedTimeEvent = 'REQUESTED_TIME_EVENT',
	StateReached = 'STATE_REACHED',
	SupplyApproved = 'SUPPLY_APPROVED',
	SupplyCreated = 'SUPPLY_CREATED',
	SupplyOnboarded = 'SUPPLY_ONBOARDED',
	SupplyOnboardingStateReached = 'SUPPLY_ONBOARDING_STATE_REACHED',
	SupplyOnboardingTransitionExecuted = 'SUPPLY_ONBOARDING_TRANSITION_EXECUTED',
	SupplyRejected = 'SUPPLY_REJECTED',
	SupplyUpdated = 'SUPPLY_UPDATED',
	TransactionForcedlyTerminated = 'TRANSACTION_FORCEDLY_TERMINATED',
	TransactionInitiated = 'TRANSACTION_INITIATED',
	TransactionPrepared = 'TRANSACTION_PREPARED',
	TransactionStateReached = 'TRANSACTION_STATE_REACHED',
	TransactionTerminated = 'TRANSACTION_TERMINATED',
	TransactionTransitionExecuted = 'TRANSACTION_TRANSITION_EXECUTED',
	TransferCompleted = 'TRANSFER_COMPLETED',
	TransferCreated = 'TRANSFER_CREATED',
	TransferFailed = 'TRANSFER_FAILED',
	TransitionExecuted = 'TRANSITION_EXECUTED',
	UserCreated = 'USER_CREATED',
	UserEmailUpdated = 'USER_EMAIL_UPDATED',
	UserEmailVerificationRequested = 'USER_EMAIL_VERIFICATION_REQUESTED',
	UserEmailVerified = 'USER_EMAIL_VERIFIED',
	UserPasswordResetRequested = 'USER_PASSWORD_RESET_REQUESTED',
	WhStripeAccountUpdated = 'WH_STRIPE_ACCOUNT_UPDATED',
	WhStripeChargeSucceeded = 'WH_STRIPE_CHARGE_SUCCEEDED',
	WhStripePaymentFailed = 'WH_STRIPE_PAYMENT_FAILED',
	WhStripePaymentSucceeded = 'WH_STRIPE_PAYMENT_SUCCEEDED',
}

export type RandevuEventsFilter = {
	code?: InputMaybe<Array<RandevuEventKind>>;
};

export type ResetPasswordPageConfig = {
	__typename?: 'ResetPasswordPageConfig';
	description?: Maybe<Scalars['String']>;
	form?: Maybe<ResetPasswordPageFormConfig>;
	form_submitted_view?: Maybe<ResetPasswordPageFormSubmittedView>;
	image_position?: Maybe<ImagePositionKind>;
	image_url?: Maybe<Scalars['String']>;
	style?: Maybe<SecurityPageStyleKind>;
	title?: Maybe<Scalars['String']>;
};

export type ResetPasswordPageConfigInput = {
	description?: InputMaybe<Scalars['String']>;
	form?: InputMaybe<ResetPasswordPageFormConfigInput>;
	form_submitted_view?: InputMaybe<ResetPasswordPageFormSubmittedViewInput>;
	id_file_image?: InputMaybe<Scalars['String']>;
	image_position?: InputMaybe<ImagePositionKind>;
	style?: InputMaybe<SecurityPageStyleKind>;
	title?: InputMaybe<Scalars['String']>;
};

export type ResetPasswordPageFormConfig = {
	__typename?: 'ResetPasswordPageFormConfig';
	button_submit?: Maybe<FrontofficeFormFieldConfig>;
	field_email?: Maybe<FrontofficeFormFieldConfig>;
	redirect_to_sign_in?: Maybe<FrontofficeRedirectToTextConfig>;
};

export type ResetPasswordPageFormConfigInput = {
	button_submit?: InputMaybe<FrontofficeFormFieldConfigInput>;
	field_email?: InputMaybe<FrontofficeFormFieldConfigInput>;
	redirect_to_sign_in?: InputMaybe<FrontofficeRedirectToTextConfigInput>;
};

export type ResetPasswordPageFormSubmittedView = {
	__typename?: 'ResetPasswordPageFormSubmittedView';
	description?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

export type ResetPasswordPageFormSubmittedViewInput = {
	description?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type SecurityPageConfig = {
	__typename?: 'SecurityPageConfig';
	image_position?: Maybe<ImagePositionKind>;
	image_url?: Maybe<Scalars['String']>;
	style: SecurityPageStyleKind;
};

export type SecurityPageConfigInput = {
	id_file_image?: InputMaybe<Scalars['String']>;
	image_position?: InputMaybe<ImagePositionKind>;
	style?: InputMaybe<SecurityPageStyleKind>;
};

export enum SecurityPageStyleKind {
	Cover = 'COVER',
	Simple = 'SIMPLE',
}

export type Session = {
	__typename?: 'Session';
	pk_production?: Maybe<Scalars['String']>;
	pk_sandbox?: Maybe<Scalars['String']>;
	token: Scalars['String'];
};

export type SharedObjectFilter = {
	tech_name?: InputMaybe<Scalars['String']>;
};

export type ShoppingCart = {
	__typename?: 'ShoppingCart';
	cancelled_at?: Maybe<Scalars['DateTime']>;
	chargeable?: Maybe<Chargeable>;
	created_at: Scalars['DateTime'];
	fields: Array<Field>;
	forcedly_terminated: Scalars['Boolean'];
	id: Scalars['ID'];
	initiated_at?: Maybe<Scalars['DateTime']>;
	items: Array<ShoppingCartItemForConsumer>;
	prepared_at?: Maybe<Scalars['DateTime']>;
	state?: Maybe<TransactionState>;
	terminated_at?: Maybe<Scalars['DateTime']>;
	type: TransactionType;
};

export type ShoppingCartFilter = {
	id_transaction_type?: InputMaybe<Scalars['String']>;
};

export type ShoppingCartForBo = {
	__typename?: 'ShoppingCartForBO';
	cancelled_at?: Maybe<Scalars['DateTime']>;
	chargeable?: Maybe<Chargeable>;
	created_at: Scalars['DateTime'];
	creator: Participant;
	fields: Array<Field>;
	forcedly_terminated: Scalars['Boolean'];
	id: Scalars['ID'];
	initiated_at?: Maybe<Scalars['DateTime']>;
	items: Array<ShoppingCartItemForBo>;
	orders: Array<Order>;
	prepared_at?: Maybe<Scalars['DateTime']>;
	state?: Maybe<TransactionState>;
	terminated_at?: Maybe<Scalars['DateTime']>;
	type: TransactionType;
};

export type ShoppingCartItemForBo = {
	__typename?: 'ShoppingCartItemForBO';
	backoffice_approved_at?: Maybe<Scalars['DateTime']>;
	backoffice_rejected_at?: Maybe<Scalars['DateTime']>;
	canceled_at?: Maybe<Scalars['DateTime']>;
	closed_at?: Maybe<Scalars['DateTime']>;
	consumer_accepted_at?: Maybe<Scalars['DateTime']>;
	consumer_declined_at?: Maybe<Scalars['DateTime']>;
	created_at: Scalars['DateTime'];
	fields: Array<Field>;
	id: Scalars['ID'];
	paid_at?: Maybe<Scalars['DateTime']>;
	price?: Maybe<SupplyPrice>;
	provider: Participant;
	provider_accepted_at?: Maybe<Scalars['DateTime']>;
	provider_declined_at?: Maybe<Scalars['DateTime']>;
	state?: Maybe<TransactionState>;
	status: MatchStatusKind;
	supply: Supply;
};

export type ShoppingCartItemForConsumer = {
	__typename?: 'ShoppingCartItemForConsumer';
	backoffice_approved_at?: Maybe<Scalars['DateTime']>;
	backoffice_rejected_at?: Maybe<Scalars['DateTime']>;
	canceled_at?: Maybe<Scalars['DateTime']>;
	closed_at?: Maybe<Scalars['DateTime']>;
	consumer_accepted_at?: Maybe<Scalars['DateTime']>;
	consumer_declined_at?: Maybe<Scalars['DateTime']>;
	created_at: Scalars['DateTime'];
	fields: Array<Field>;
	id: Scalars['ID'];
	id_provider: Scalars['ID'];
	id_supply: Scalars['ID'];
	paid_at?: Maybe<Scalars['DateTime']>;
	price?: Maybe<SupplyPrice>;
	provider_accepted_at?: Maybe<Scalars['DateTime']>;
	provider_declined_at?: Maybe<Scalars['DateTime']>;
	provider_fields: Array<Field>;
	state?: Maybe<TransactionState>;
	status: MatchStatusKind;
	supply_fields: Array<Field>;
};

export type ShoppingCartItemForProvider = {
	__typename?: 'ShoppingCartItemForProvider';
	backoffice_approved_at?: Maybe<Scalars['DateTime']>;
	backoffice_rejected_at?: Maybe<Scalars['DateTime']>;
	canceled_at?: Maybe<Scalars['DateTime']>;
	closed_at?: Maybe<Scalars['DateTime']>;
	consumer_accepted_at?: Maybe<Scalars['DateTime']>;
	consumer_declined_at?: Maybe<Scalars['DateTime']>;
	consumer_fields: Array<Field>;
	created_at: Scalars['DateTime'];
	fields: Array<Field>;
	id: Scalars['ID'];
	paid_at?: Maybe<Scalars['DateTime']>;
	price?: Maybe<SupplyPrice>;
	provider_accepted_at?: Maybe<Scalars['DateTime']>;
	provider_declined_at?: Maybe<Scalars['DateTime']>;
	state?: Maybe<TransactionState>;
	status: MatchStatusKind;
	supply_fields: Array<Field>;
};

export type ShoppingCartLight = {
	__typename?: 'ShoppingCartLight';
	cancelled_at?: Maybe<Scalars['DateTime']>;
	created_at: Scalars['DateTime'];
	fields: Array<Field>;
	forcedly_terminated: Scalars['Boolean'];
	id: Scalars['ID'];
	id_creator: Scalars['ID'];
	id_transaction_type: Scalars['ID'];
	initiated_at?: Maybe<Scalars['DateTime']>;
	prepared_at?: Maybe<Scalars['DateTime']>;
	state?: Maybe<TransactionStateLight>;
	terminated_at?: Maybe<Scalars['DateTime']>;
};

export type ShoppingCartOrder = {
	__typename?: 'ShoppingCartOrder';
	cancelled_at?: Maybe<Scalars['DateTime']>;
	created_at: Scalars['DateTime'];
	forcedly_terminated: Scalars['Boolean'];
	id: Scalars['ID'];
	initiated_at?: Maybe<Scalars['DateTime']>;
	items: Array<ShoppingCartItemForProvider>;
	order?: Maybe<Order>;
	prepared_at?: Maybe<Scalars['DateTime']>;
	state?: Maybe<TransactionState>;
	terminated_at?: Maybe<Scalars['DateTime']>;
	type: TransactionType;
};

export type ShoppingCartOrderFilter = {
	id_transaction_type?: InputMaybe<Scalars['String']>;
};

export type ShoppingCartOrderLight = {
	__typename?: 'ShoppingCartOrderLight';
	cancelled_at?: Maybe<Scalars['DateTime']>;
	created_at: Scalars['DateTime'];
	forcedly_terminated: Scalars['Boolean'];
	id: Scalars['ID'];
	id_transaction_type: Scalars['ID'];
	initiated_at?: Maybe<Scalars['DateTime']>;
	order?: Maybe<Order>;
	prepared_at?: Maybe<Scalars['DateTime']>;
	state?: Maybe<TransactionStateLight>;
	terminated_at?: Maybe<Scalars['DateTime']>;
};

export type SignInPageConfig = {
	__typename?: 'SignInPageConfig';
	description?: Maybe<Scalars['String']>;
	eyebrow?: Maybe<Scalars['String']>;
	form?: Maybe<SignInPageFormConfig>;
	image_position?: Maybe<ImagePositionKind>;
	image_url?: Maybe<Scalars['String']>;
	style?: Maybe<SecurityPageStyleKind>;
	title?: Maybe<Scalars['String']>;
};

export type SignInPageConfigInput = {
	description?: InputMaybe<Scalars['String']>;
	eyebrow?: InputMaybe<Scalars['String']>;
	form?: InputMaybe<SignInPageFormConfigInput>;
	id_file_image?: InputMaybe<Scalars['String']>;
	image_position?: InputMaybe<ImagePositionKind>;
	style?: InputMaybe<SecurityPageStyleKind>;
	title?: InputMaybe<Scalars['String']>;
};

export type SignInPageFormConfig = {
	__typename?: 'SignInPageFormConfig';
	button_submit?: Maybe<FrontofficeFormFieldConfig>;
	field_email?: Maybe<FrontofficeFormFieldConfig>;
	field_password?: Maybe<FrontofficeFormFieldConfig>;
	redirect_to_sign_up?: Maybe<FrontofficeRedirectToTextConfig>;
};

export type SignInPageFormConfigInput = {
	button_submit?: InputMaybe<FrontofficeFormFieldConfigInput>;
	field_email?: InputMaybe<FrontofficeFormFieldConfigInput>;
	field_password?: InputMaybe<FrontofficeFormFieldConfigInput>;
	redirect_to_sign_up?: InputMaybe<FrontofficeRedirectToTextConfigInput>;
};

export type SignUpFormConfig = {
	__typename?: 'SignUpFormConfig';
	button_submit?: Maybe<FrontofficeFormFieldConfig>;
	field_email?: Maybe<FrontofficeFormFieldConfig>;
	field_first_name?: Maybe<FrontofficeFormFieldConfig>;
	field_last_name?: Maybe<FrontofficeFormFieldConfig>;
	field_participant_type?: Maybe<FrontofficeParticipantTypeFieldConfig>;
	redirect_to_sign_in?: Maybe<FrontofficeRedirectToTextConfig>;
};

export type SignUpFormConfigInput = {
	button_submit?: InputMaybe<FrontofficeFormFieldConfigInput>;
	field_email?: InputMaybe<FrontofficeFormFieldConfigInput>;
	field_first_name?: InputMaybe<FrontofficeFormFieldConfigInput>;
	field_last_name?: InputMaybe<FrontofficeFormFieldConfigInput>;
	field_participant_type?: InputMaybe<FrontofficeParticipantTypeFieldConfigInput>;
	redirect_to_sign_in?: InputMaybe<FrontofficeRedirectToTextConfigInput>;
};

export type SignUpFormSubmittedView = {
	__typename?: 'SignUpFormSubmittedView';
	description?: Maybe<Scalars['String']>;
	eyebrow?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

export type SignUpFormSubmittedViewInput = {
	description?: InputMaybe<Scalars['String']>;
	eyebrow?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type SignUpPageUiConfig = {
	__typename?: 'SignUpPageUIConfig';
	description?: Maybe<Scalars['String']>;
	eyebrow?: Maybe<Scalars['String']>;
	form?: Maybe<SignUpFormConfig>;
	form_submitted_view?: Maybe<SignUpFormSubmittedView>;
	image_position?: Maybe<ImagePositionKind>;
	image_url?: Maybe<Scalars['String']>;
	style?: Maybe<SecurityPageStyleKind>;
	title?: Maybe<Scalars['String']>;
};

export type SignUpPageUiConfigInput = {
	description?: InputMaybe<Scalars['String']>;
	eyebrow?: InputMaybe<Scalars['String']>;
	form?: InputMaybe<SignUpFormConfigInput>;
	form_submitted_view?: InputMaybe<SignUpFormSubmittedViewInput>;
	id_file_image?: InputMaybe<Scalars['String']>;
	image_position?: InputMaybe<ImagePositionKind>;
	style?: InputMaybe<SecurityPageStyleKind>;
	title?: InputMaybe<Scalars['String']>;
};

export type SimpleField = {
	__typename?: 'SimpleField';
	current_accesses: FieldAccessInfo;
	field_type: FieldType;
	id: Scalars['ID'];
	my_access?: Maybe<FieldAccessKind>;
	value?: Maybe<Scalars['JSON']>;
};

export type SimpleStockAvailabilityData = {
	__typename?: 'SimpleStockAvailabilityData';
	remaining_qty: Scalars['Int'];
};

export type StateType = {
	__typename?: 'StateType';
	actions: Array<ActionType>;
	id: Scalars['ID'];
	is_initial: Scalars['Boolean'];
	name: Scalars['String'];
	tech_name: Scalars['String'];
	ui_config: Scalars['JSONObject'];
};

export type Supply = {
	__typename?: 'Supply';
	approved_at?: Maybe<Scalars['DateTime']>;
	availability?: Maybe<Scalars['JSONObject']>;
	created_at: Scalars['DateTime'];
	created_by?: Maybe<User>;
	fields: Array<Field>;
	id: Scalars['ID'];
	is_active: Scalars['Boolean'];
	onboarded_at?: Maybe<Scalars['DateTime']>;
	onboarding_progress?: Maybe<SupplyOnboardingProgress>;
	provider: Participant;
	rejected_at?: Maybe<Scalars['DateTime']>;
	status: SupplyStatusKind;
	type: SupplyType;
	user_actions: Array<UserAction>;
	variant_group?: Maybe<Scalars['String']>;
	variants?: Maybe<Array<SupplyVariant>>;
};

export type SupplyFilter = {
	ids_provider?: InputMaybe<Array<Scalars['ID']>>;
	ids_supply_type?: InputMaybe<Array<Scalars['ID']>>;
	is_active?: InputMaybe<Scalars['Boolean']>;
	pending_backoffice_actions?: InputMaybe<Scalars['Boolean']>;
	statuses?: InputMaybe<Array<SupplyStatusKind>>;
};

export type SupplyLight = {
	__typename?: 'SupplyLight';
	id: Scalars['ID'];
	id_provider: Scalars['ID'];
	id_supply_type: Scalars['ID'];
	status: SupplyStatusKind;
};

export type SupplyOnboardingProgress = {
	__typename?: 'SupplyOnboardingProgress';
	message?: Maybe<Scalars['String']>;
	next_steps: Array<NextStep>;
	status?: Maybe<Scalars['String']>;
	status_tech_name?: Maybe<Scalars['String']>;
};

export type SupplyPrice = {
	__typename?: 'SupplyPrice';
	addon_items: Array<AddonItem>;
	discount_items: Array<DiscountItem>;
	platform_fee_items: Array<PlatformFeeItem>;
	qty: Scalars['Int'];
	tax_items: Array<TaxItem>;
	total_before_tax: Scalars['Int'];
	total_consumer_fees: Scalars['Int'];
	total_fees: Scalars['Int'];
	total_provider_fees: Scalars['Int'];
	total_taxes: Scalars['Int'];
	unit_price: Scalars['Int'];
	unit_price_description?: Maybe<Scalars['String']>;
};

export enum SupplyStatusKind {
	Onboarded = 'ONBOARDED',
	Onboarding = 'ONBOARDING',
	Rejected = 'REJECTED',
}

export type SupplyType = {
	__typename?: 'SupplyType';
	allow_variant_duplicates: Scalars['Boolean'];
	auto_activation: Scalars['Boolean'];
	auto_backoffice_approval?: Maybe<Scalars['Boolean']>;
	auto_variants_onboarding: Scalars['Boolean'];
	fields: Array<FieldType>;
	flow_type: FlowType;
	id: Scalars['ID'];
	id_onboarding_flow_type?: Maybe<Scalars['ID']>;
	match_configurations: Array<MatchConfiguration>;
	name: Scalars['String'];
	provider_type: ParticipantType;
	tech_name: Scalars['String'];
	ui_config: Scalars['JSONObject'];
};

export type SupplyVariant = {
	__typename?: 'SupplyVariant';
	approved_at?: Maybe<Scalars['DateTime']>;
	availability?: Maybe<Scalars['JSONObject']>;
	created_at: Scalars['DateTime'];
	created_by?: Maybe<User>;
	fields: Array<Field>;
	id: Scalars['ID'];
	is_active: Scalars['Boolean'];
	onboarded_at?: Maybe<Scalars['DateTime']>;
	onboarding_progress?: Maybe<SupplyOnboardingProgress>;
	rejected_at?: Maybe<Scalars['DateTime']>;
	status: SupplyStatusKind;
	user_actions: Array<UserAction>;
	variant_group?: Maybe<Scalars['String']>;
};

export type TargetScopeObject = {
	id_argument: Scalars['String'];
	type: Scalars['String'];
};

export enum TaskExecutionKind {
	Default = 'DEFAULT',
	External = 'EXTERNAL',
}

export type Tax = {
	__typename?: 'Tax';
	description: Scalars['String'];
	guard_expression: Scalars['String'];
	name: Scalars['String'];
	tech_name: Scalars['String'];
	value_expression: Scalars['String'];
};

export type TaxInput = {
	description: Scalars['String'];
	guard_expression: Scalars['String'];
	name: Scalars['String'];
	tech_name: Scalars['String'];
	value_expression: Scalars['String'];
};

export type TaxItem = {
	__typename?: 'TaxItem';
	description: Scalars['String'];
	value: Scalars['Int'];
};

export type Transaction = {
	__typename?: 'Transaction';
	cancelled_at?: Maybe<Scalars['DateTime']>;
	chargeable?: Maybe<Chargeable>;
	created_at: Scalars['DateTime'];
	forcedly_terminated: Scalars['Boolean'];
	id: Scalars['ID'];
	id_parent_transaction?: Maybe<Scalars['ID']>;
	initiated_at?: Maybe<Scalars['DateTime']>;
	matches?: Maybe<Array<TransactionMatch>>;
	my_role: MatchTypeRoleKind;
	order: TransactionOrder;
	orders: Array<Order>;
	prepared_at?: Maybe<Scalars['DateTime']>;
	/** @deprecated This prop will be removed. Use order and match instead */
	root_object: TransactionRootObject;
	state?: Maybe<TransactionState>;
	sub_transactions: Array<TransactionLight>;
	terminated_at?: Maybe<Scalars['DateTime']>;
	type: TransactionType;
	unlocked_for: Array<User>;
};

export type TransactionFilter = {
	id_transaction_type?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<TransactionStatusFilter>;
};

export enum TransactionKind {
	ForwardAuction = 'FORWARD_AUCTION',
	MultipleDirect = 'MULTIPLE_DIRECT',
	ReverseAuction = 'REVERSE_AUCTION',
	SingleDirect = 'SINGLE_DIRECT',
	SubTransaction = 'SUB_TRANSACTION',
}

export type TransactionLight = {
	__typename?: 'TransactionLight';
	cancelled_at?: Maybe<Scalars['DateTime']>;
	consumer_fields?: Maybe<Array<Field>>;
	created_at: Scalars['DateTime'];
	creator: Participant;
	forcedly_terminated?: Maybe<Scalars['Boolean']>;
	id: Scalars['ID'];
	id_parent_transaction?: Maybe<Scalars['ID']>;
	initiated_at?: Maybe<Scalars['DateTime']>;
	match_fields?: Maybe<Array<Field>>;
	my_role: MatchTypeRoleKind;
	order_fields?: Maybe<Array<Field>>;
	prepared_at?: Maybe<Scalars['DateTime']>;
	provider_fields?: Maybe<Array<Field>>;
	state?: Maybe<TransactionStateLight>;
	sub_transactions_count: Scalars['Int'];
	supply_fields?: Maybe<Array<Field>>;
	terminated_at?: Maybe<Scalars['DateTime']>;
	type: TransactionType;
};

export type TransactionMatch = {
	__typename?: 'TransactionMatch';
	/** @deprecated This att is not used any more. Use fields instead */
	availability?: Maybe<AvailabilityRecord>;
	backoffice_approved_at?: Maybe<Scalars['DateTime']>;
	backoffice_rejected_at?: Maybe<Scalars['DateTime']>;
	closed_at?: Maybe<Scalars['DateTime']>;
	consumer: Participant;
	consumer_accepted_at?: Maybe<Scalars['DateTime']>;
	consumer_declined_at?: Maybe<Scalars['DateTime']>;
	consumer_fields: Array<Field>;
	consumer_unlocked_to_provider?: Maybe<Scalars['Boolean']>;
	created_at: Scalars['DateTime'];
	created_by?: Maybe<User>;
	fields: Array<Field>;
	id: Scalars['ID'];
	matching_score?: Maybe<Scalars['Int']>;
	price?: Maybe<SupplyPrice>;
	price_override_unlocked?: Maybe<Scalars['Boolean']>;
	provider: Participant;
	provider_accepted_at?: Maybe<Scalars['DateTime']>;
	provider_declined_at?: Maybe<Scalars['DateTime']>;
	provider_fields: Array<Field>;
	provider_unlocked_to_consumer?: Maybe<Scalars['Boolean']>;
	status: MatchStatusKind;
	supply: Supply;
	supply_fields: Array<Field>;
	supply_unlocked_to_consumer?: Maybe<Scalars['Boolean']>;
	type: MatchType;
	unlocked_consumer_fields: Array<Scalars['ID']>;
	unlocked_provider_fields: Array<Scalars['ID']>;
	unlocked_supply_fields: Array<Scalars['ID']>;
	unlocked_to_consumer: Scalars['Boolean'];
	unlocked_to_provider: Scalars['Boolean'];
};

export type TransactionOrder = {
	__typename?: 'TransactionOrder';
	auctioned_supply: Array<Supply>;
	/** @deprecated This att is not used any more. Use fields instead */
	availability?: Maybe<AvailabilityRecord>;
	consumer_filter?: Maybe<Array<FieldFilter>>;
	creator: Participant;
	fields: Array<Maybe<Field>>;
	id: Scalars['ID'];
	is_active: Scalars['Boolean'];
	last_execution_at?: Maybe<Scalars['DateTime']>;
	provider_filter?: Maybe<Array<FieldFilter>>;
	supply_filter?: Maybe<Array<FieldFilter>>;
	type: MatchingToolType;
};

export type TransactionRootObject = Match | MatchingTool;

export type TransactionRootObjectType = MatchType | MatchingToolType;

export type TransactionState = {
	__typename?: 'TransactionState';
	message?: Maybe<Scalars['String']>;
	next_steps?: Maybe<Array<NextStep>>;
	status?: Maybe<Scalars['String']>;
	status_tech_name?: Maybe<Scalars['String']>;
};

export type TransactionStateLight = {
	__typename?: 'TransactionStateLight';
	status?: Maybe<Scalars['String']>;
	status_tech_name?: Maybe<Scalars['String']>;
};

export enum TransactionStatusFilter {
	Draft = 'DRAFT',
	Ongoing = 'ONGOING',
	PendingBackofficeActions = 'PENDING_BACKOFFICE_ACTIONS',
	Terminated = 'TERMINATED',
}

export enum TransactionStatusKind {
	Checkout = 'CHECKOUT',
	Declined = 'DECLINED',
	Failed = 'FAILED',
	Ongoing = 'ONGOING',
	Paid = 'PAID',
	Rejected = 'REJECTED',
}

export type TransactionType = {
	__typename?: 'TransactionType';
	cascading_termination?: Maybe<Scalars['Boolean']>;
	flow_type?: Maybe<FlowType>;
	forced_termination_enabled: Scalars['Boolean'];
	id: Scalars['ID'];
	id_parent_transaction_type?: Maybe<Scalars['ID']>;
	is_active: Scalars['Boolean'];
	is_default: Scalars['Boolean'];
	match_configuration?: Maybe<MatchConfiguration>;
	my_role: MatchTypeRoleKind;
	name: Scalars['String'];
	order_configuration: OrderConfiguration;
	retired_at?: Maybe<Scalars['DateTime']>;
	root_type: TransactionRootObjectType;
	sub_flow_type?: Maybe<FlowType>;
	tech_name: Scalars['String'];
	type: TransactionKind;
	ui_config: Scalars['JSONObject'];
};

export type Transfer = {
	__typename?: 'Transfer';
	amount: MonetaryValue;
	created_at: Scalars['DateTime'];
	destination?: Maybe<Scalars['JSON']>;
	error?: Maybe<Scalars['JSON']>;
	failed_at?: Maybe<Scalars['DateTime']>;
	flagged_as_succeeded: Scalars['Boolean'];
	flagged_as_succeeded_by?: Maybe<User>;
	id: Scalars['ID'];
	id_payment: Scalars['ID'];
	id_recipient_participant: Scalars['ID'];
	id_transfer_origin?: Maybe<Scalars['ID']>;
	order: Order;
	status: TransferStatusKind;
	succeeded_at?: Maybe<Scalars['DateTime']>;
};

export type TransferLight = {
	__typename?: 'TransferLight';
	amount: MonetaryValue;
	created_at: Scalars['DateTime'];
	destination?: Maybe<Scalars['JSON']>;
	failed_at?: Maybe<Scalars['DateTime']>;
	flagged_as_succeeded: Scalars['Boolean'];
	id: Scalars['ID'];
	id_transfer_origin?: Maybe<Scalars['ID']>;
	order: Order;
	status: TransferStatusKind;
	succeeded_at?: Maybe<Scalars['DateTime']>;
};

export enum TransferStatusKind {
	Created = 'CREATED',
	Failed = 'FAILED',
	Succeeded = 'SUCCEEDED',
}

export type TransitionType = {
	__typename?: 'TransitionType';
	actions?: Maybe<Array<ActionType>>;
	from?: Maybe<StateType>;
	id: Scalars['ID'];
	name: Scalars['String'];
	tech_name: Scalars['String'];
	to?: Maybe<StateType>;
	triggering_conditions?: Maybe<Array<FlowCondition>>;
	triggering_event: RandevuEventKind;
	ui_config: Scalars['JSONObject'];
};

export type TypedFieldInput = {
	id_target_field_type?: InputMaybe<Scalars['ID']>;
	value?: InputMaybe<Scalars['JSON']>;
};

export type UniqueSellingPointsSectionConfig = {
	__typename?: 'UniqueSellingPointsSectionConfig';
	enabled?: Maybe<Scalars['Boolean']>;
	image_1_url?: Maybe<Scalars['String']>;
	image_2_url?: Maybe<Scalars['String']>;
	image_3_url?: Maybe<Scalars['String']>;
	text_1_description?: Maybe<Scalars['String']>;
	text_1_subtitle?: Maybe<Scalars['String']>;
	text_1_title?: Maybe<Scalars['String']>;
	text_2_description?: Maybe<Scalars['String']>;
	text_2_subtitle?: Maybe<Scalars['String']>;
	text_2_title?: Maybe<Scalars['String']>;
	text_3_description?: Maybe<Scalars['String']>;
	text_3_subtitle?: Maybe<Scalars['String']>;
	text_3_title?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

export type UniqueSellingPointsSectionConfigInput = {
	enabled?: InputMaybe<Scalars['Boolean']>;
	id_file_image_1?: InputMaybe<Scalars['String']>;
	id_file_image_2?: InputMaybe<Scalars['String']>;
	id_file_image_3?: InputMaybe<Scalars['String']>;
	text_1_description?: InputMaybe<Scalars['String']>;
	text_1_subtitle?: InputMaybe<Scalars['String']>;
	text_1_title?: InputMaybe<Scalars['String']>;
	text_2_description?: InputMaybe<Scalars['String']>;
	text_2_subtitle?: InputMaybe<Scalars['String']>;
	text_2_title?: InputMaybe<Scalars['String']>;
	text_3_description?: InputMaybe<Scalars['String']>;
	text_3_subtitle?: InputMaybe<Scalars['String']>;
	text_3_title?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type User = {
	__typename?: 'User';
	created_at: Scalars['DateTime'];
	email: Scalars['String'];
	email_verified_at?: Maybe<Scalars['DateTime']>;
	first_name?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	invited_by?: Maybe<Scalars['String']>;
	/** Visible only to Backoffice users. */
	last_logged_in_at?: Maybe<Scalars['DateTime']>;
	last_name?: Maybe<Scalars['String']>;
};

export type UserAction = {
	__typename?: 'UserAction';
	payload?: Maybe<Scalars['JSONObject']>;
	reason: Scalars['String'];
	type: UserActionKind;
};

export enum UserActionKind {
	FieldValueRequired = 'FIELD_VALUE_REQUIRED',
}

export type UserFilter = {
	email?: InputMaybe<Scalars['String']>;
	first_name?: InputMaybe<Scalars['String']>;
	last_name?: InputMaybe<Scalars['String']>;
};

export enum UserRoleKind {
	ApiUser = 'API_USER',
	MarketplaceAdmin = 'MARKETPLACE_ADMIN',
	MarketplaceOperator = 'MARKETPLACE_OPERATOR',
	MarketplaceOwner = 'MARKETPLACE_OWNER',
	MarketplaceUser = 'MARKETPLACE_USER',
}

export type VerifyParticipantAccountPageConfig = {
	__typename?: 'VerifyParticipantAccountPageConfig';
	description?: Maybe<Scalars['String']>;
	form?: Maybe<VerifyParticipantAccountPageFormConfig>;
	image_position?: Maybe<ImagePositionKind>;
	image_url?: Maybe<Scalars['String']>;
	style?: Maybe<SecurityPageStyleKind>;
	title?: Maybe<Scalars['String']>;
};

export type VerifyParticipantAccountPageConfigInput = {
	description?: InputMaybe<Scalars['String']>;
	form?: InputMaybe<VerifyParticipantAccountPageFormConfigInput>;
	id_file_image?: InputMaybe<Scalars['String']>;
	image_position?: InputMaybe<ImagePositionKind>;
	style?: InputMaybe<SecurityPageStyleKind>;
	title?: InputMaybe<Scalars['String']>;
};

export type VerifyParticipantAccountPageFormConfig = {
	__typename?: 'VerifyParticipantAccountPageFormConfig';
	button_submit?: Maybe<FrontofficeFormFieldConfig>;
	field_password?: Maybe<FrontofficeFormFieldConfig>;
};

export type VerifyParticipantAccountPageFormConfigInput = {
	button_submit?: InputMaybe<FrontofficeFormFieldConfigInput>;
	field_password?: InputMaybe<FrontofficeFormFieldConfigInput>;
};

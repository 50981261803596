import OnboardingStepper from '@aquga/components/register/OnboardingStepper';
import { ROUTES } from '@aquga/config/routes';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface SecurityPageTopBarProps {
	noStepper?: boolean | undefined;
	noButton?: boolean | undefined;
	step?: number;
	numberOfSteps?: number;
}
const SecurityPageTopBar = ({ noButton, noStepper, step, numberOfSteps }: SecurityPageTopBarProps) => {
	return (
		<Stack
			direction="row"
			justifyContent={
				noButton && !noStepper ? 'flex-end' : !noButton && noStepper ? 'flex-start' : 'space-between'
			}
			alignItems="center"
			spacing={1}
		>
			{noButton != true && (
				<Button
					component={Link}
					to={ROUTES.SIGN_IN_PAGE}
					startIcon={<KeyboardBackspaceIcon />}
					color="secondary"
				>
					<FormattedMessage id="common.back" />
				</Button>
			)}
			{noStepper != true && <OnboardingStepper currentStep={step ?? 1} numberOfSteps={numberOfSteps ?? 6} />}
		</Stack>
	);
};

export default SecurityPageTopBar;

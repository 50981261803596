import { ROUTES } from '@aquga/config/routes';
import { Button, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const CallToSignUp = () => {
	const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
	return (
		<Grid
			container
			direction="row"
			alignItems="stretch"
			justifyContent="center"
			textAlign="center"
			spacing={3}
			paddingLeft={7}
			paddingRight={7}
		>
			<Grid item xs={12} textAlign="center">
				<Typography variant="body1">
					<FormattedMessage id="loginPage.bottomBar.title" />
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
				<Button
					component={Link}
					variant="contained"
					color="quaternary"
					to={ROUTES.SIGN_UP_BUSINESS_PAGE}
					fullWidth={largeScreen ? false : true}
				>
					<FormattedMessage id="loginPage.bottomBar.btnSignUpAsBusiness" />
				</Button>
			</Grid>
			<Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
				<Button
					component={Link}
					variant="contained"
					color="tertiary"
					to={ROUTES.SIGN_UP_CONSULTING_HOUSE_PAGE}
					fullWidth={largeScreen ? false : true}
				>
					<FormattedMessage id="loginPage.bottomBar.btnSignUpAsConsultant" />
				</Button>
			</Grid>
		</Grid>
	);
};

export default CallToSignUp;

import { Field, FieldInput, MutationUpdateParticipantArgs } from '@aquga/services/randevuApi/generatedTypes';
import { print } from 'graphql';
import gql from 'graphql-tag';

const TRIGGER_MANUAL_PARTICIPANT_ONBOARDING_TRANSITION = gql`
	mutation INITIATE_MANUAL_TRANSACTION_TRANSITION($id_participant: ID!, $id_transition_type: ID!) {
		triggerManualParticipantOnboardingTransition(
			id_participant: $id_participant
			id_transition_type: $id_transition_type
		)
	}
`;

interface ManualParticipantOnboardingTransition {
	id_participant: string;
	id_transition_type: string;
}
const triggerManualParticipantOnboardingTransition = ({
	id_participant,
	id_transition_type,
}: ManualParticipantOnboardingTransition) => {
	return JSON.stringify({
		query: print(TRIGGER_MANUAL_PARTICIPANT_ONBOARDING_TRANSITION),
		variables: { id_participant, id_transition_type },
	});
};

const UPDATE_PARTICIPANT = gql`
	mutation UPDATE_USER($id: ID!, $fields: [FieldInput!]) {
		updateParticipant(id: $id, fields: $fields)
	}
`;

const updateParticipant = ({ id, fields }: MutationUpdateParticipantArgs) => {
	type Variables = {
		id: string;
		password?: string;
		fields?: Field[];
	};
	const variables = { id, fields } as Variables;

	if (fields === null || fields === undefined || fields?.length < 1) {
		delete variables.fields;
	}

	return JSON.stringify({
		query: print(UPDATE_PARTICIPANT),
		variables,
	});
};

export { triggerManualParticipantOnboardingTransition, updateParticipant };

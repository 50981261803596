import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';

import { ProviderConsultantAppliedOptionsView } from './options/ProviderConsultantAppliedOptions';
import { ProviderOpenOfferOptionsView } from './options/ProviderOpenOfferOptionsView';

interface ProviderMatchOptionsProps {
	transactionId: string | undefined;
	status: string;
	loading: boolean;
}

export const ProviderMatchOptions = ({ transactionId, status, loading }: ProviderMatchOptionsProps) => {
	const theme = useTheme();
	const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<Stack
			sx={{ borderRadius: 3, width: { xs: '100%' }, p: 2 }}
			component={Paper}
			elevation={0}
			spacing={2}
			direction={isLargeScreen ? 'row' : 'column-reverse'}
			justifyContent="space-around"
		>
			{status?.toUpperCase() ===
				RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.OPEN_OFFER.TECH_NAME.toUpperCase() && (
				<ProviderOpenOfferOptionsView transactionId={transactionId} loading={loading} />
			)}
			{status?.toUpperCase() ===
				RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONSULTANT_APPLIED.TECH_NAME.toUpperCase() && (
				<ProviderConsultantAppliedOptionsView loading={loading} transactionId={transactionId} />
			)}
		</Stack>
	);
};

import ERROR_CODES from './errors';

export const hasSubmissionErrors = (errors: any) => errors?.length > 0;

export const isInvalidCredentials = (errors: any) => {
	return (
		Array.isArray(errors) &&
		errors.filter(
			(error) =>
				error.extensions?.code === ERROR_CODES.GLOBAL.AUTHENTICATION_ERROR &&
				error.extensions?.internal_code === ERROR_CODES.INTERNAL.INVALID_CREDENTIALS
		).length === 1
	);
};

export const isSessionExpired = (errors: any) => {
	if (!Array.isArray(errors)) return false;

	return (
		errors?.filter(
			(error) =>
				error.extensions?.code === ERROR_CODES.GLOBAL.AUTHENTICATION_ERROR &&
				error.extensions?.internal_code === ERROR_CODES.INTERNAL.INVALID_TOKEN_IN_REQUEST
		).length === 1
	);
};

export const isNotFound = (errors: any) => {
	if (!Array.isArray(errors)) return false;

	return (
		errors?.filter(
			(error) =>
				error.extensions?.code === ERROR_CODES.GLOBAL.REQUEST_INPUT_ERROR &&
				error.extensions?.internal_code === ERROR_CODES.INTERNAL.OBJECT_NOT_FOUND
		).length === 1
	);
};

const FETCH_ERRORS = ['ERR_CONNECTION_REFUSED', 'ERR_CONNECTION_SSL_REFUSED', 'UNEXPECTED_ERROR'];

export const isFetchError = (errors: any) => {
	if (!errors) return false;

	const errorCodes = errors?.map((error: any) => error.extensions.code);

	return errorCodes.some((item: any) => FETCH_ERRORS.includes(item));
};

export const isDuplicate = (errors: any) => {
	return (
		Array.isArray(errors) &&
		errors.filter(
			(error) =>
				error.extensions?.code === ERROR_CODES.GLOBAL.REQUEST_INPUT_ERROR &&
				error.extensions?.internal_code === ERROR_CODES.INTERNAL.NAME_ALREADY_IN_USE
		).length === 1
	);
};

import { findFieldByTechName } from '@aquga/app/fieldHelpers';
import { CreateAnsweringExpertiseFormValues } from '@aquga/components/services/create/CreateAnsweringExpertiseForm';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { Field, Supply } from '@aquga/services/randevuApi/generatedTypes';

export const mapCreateAnsweringExpertiseFormValuesAdditionDocumentsFormFieldRandevuField = (
	formValues: CreateAnsweringExpertiseFormValues
): undefined | { tech_name: any; value: any } => {
	const fieldTypes = Object.entries(RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS).map(
		(fieldTypeMap) => fieldTypeMap[1]
	);

	const foundField = Object.entries(formValues)
		.map(([fieldName, value]) => {
			const idFieldType = fieldTypes.find((field) => field.TECH_NAME === fieldName)?.TECH_NAME;

			return {
				tech_name: idFieldType,
				value: value,
			};
		})
		.filter(
			(fieldValue) =>
				fieldValue.tech_name === RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.ADDITIONAL_DOCS.TECH_NAME
		);

	if (foundField) return foundField[0];

	return undefined;
};

export const mapCreateAnsweringExpertiseFormValuesToSupplyFields = (
	formValues: CreateAnsweringExpertiseFormValues
): any => {
	const fieldTypes = Object.entries(RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS).map(
		(fieldTypeMap) => fieldTypeMap[1]
	);

	return Object.entries(formValues)
		.map(([fieldName, fieldValue]) => {
			const idFieldType = fieldTypes.find((field) => field.TECH_NAME === fieldName)?.TECH_NAME;
			return {
				tech_name: idFieldType,
				value: fieldValue,
			};
		})
		.filter(
			(fieldTypeValue) =>
				fieldTypeValue.tech_name !== undefined &&
				fieldTypeValue.tech_name !== RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.ADDITIONAL_DOCS.TECH_NAME
		);
};

export const mapSupplyToAnsweringExpertise = (fields: Field[]): any => {
	return {
		business_area: findFieldByTechName(
			fields,
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.TECH_NAME
		)?.value,
		business_area_other: findFieldByTechName(
			fields,
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA_OTHER.TECH_NAME
		)?.value,
		technology_kind: findFieldByTechName(
			fields,
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KINDS.TECH_NAME
		)?.value,
		technology_kind_other: findFieldByTechName(
			fields,
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KIND_OTHER.TECH_NAME
		)?.value,
		title: findFieldByTechName(fields, RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TITLE.TECH_NAME)?.value,
		description: findFieldByTechName(
			fields,
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.DESCRIPTION.TECH_NAME
		)?.value,
		notes: findFieldByTechName(fields, RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.ADDITIONAL_NOTES.TECH_NAME)
			?.value,
		documents: findFieldByTechName(
			fields,
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.ADDITIONAL_DOCS.TECH_NAME
		)?.value,
	};
};

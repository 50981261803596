import { combineDateAndTime } from '@aquga/app/helpers';
import { isBusinessSide } from '@aquga/app/participantHelpers';
import validator from '@aquga/app/validators';
import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import {
	updateMatchFailed,
	updateScheduledMeetingTimeAndTriggerTransition,
} from '@aquga/store/slices/transactionSlice';
import { selectCurrentLanguage } from '@aquga/store/slices/uiSlice';
import { Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import { DatePicker, TimePicker } from 'mui-rff';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

interface ScheduleMeetingFormValues {
	message: string;
	date: string;
	from: string;
	to: string;
}

interface ScheduleMeetingDialogProps {
	transactionId: string;
	open: boolean;
	onClose: any;
}

const ScheduleMeetingDialog = ({ transactionId, open, onClose }: ScheduleMeetingDialogProps) => {
	const intl = useIntl();
	const currentLanguage = useAppSelector(selectCurrentLanguage);
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const isBusiness = isBusinessSide(currentParticipant);
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

	const handleSubmit = async (values: ScheduleMeetingFormValues) => {
		const appointmentStartDatetime = combineDateAndTime(
			values.date as unknown as Date,
			values.from as unknown as Date
		);
		const appointmentEndDatetime = combineDateAndTime(values.date as unknown as Date, values.to as unknown as Date);
		const fields = [
			{
				tech_name: RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.APPOINTMENT_START_DATETIME,
				value: appointmentStartDatetime,
			},
			{
				tech_name: RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.APPOINTMENT_END_DATETIME,
				value: appointmentEndDatetime,
			},
		];

		const resolvedAction = await dispatch(
			updateScheduledMeetingTimeAndTriggerTransition({
				transactionId,
				fields,
				isBusiness,
				transition_tech_name: isBusiness
					? RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.MEETING_INVITE.TRANSITIONS.CUSTOMER_PROPOSES_NEW_DATE
					: RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.PROPOSED_TIME.TRANSITIONS.CONSULTANT_PROPOSES_NEW_DATE,
			})
		);

		if (resolvedAction.type === updateMatchFailed.type) {
			const submissionErrors = { ...resolvedAction.payload };

			// TODO: write nice helper function which does this one
			Object.entries(submissionErrors).map((keyValue) => {
				submissionErrors[keyValue[0]] = intl.formatMessage({ id: keyValue[1] as string });
			});

			return submissionErrors;
		}
	};

	const fieldValidators = {
		date: [
			(value: string) =>
				validator.required({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
				}),
		],
		from: [
			(value: string) =>
				validator.required({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
				}),
			(value: string) =>
				validator.validDate({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.hourMinuteFormat' })}`,
				}),
		],
		to: [
			(value: string) =>
				validator.required({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
				}),
			(value: string) =>
				validator.validDate({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.hourMinuteFormat' })}`,
				}),
		],
	};

	const handleValidate = async (values: ScheduleMeetingFormValues) => {
		return validator.doValidation(fieldValidators, values);
	};

	const calenderLocale = currentLanguage?.includes('de') ? deLocale : enLocale;

	return (
		<Dialog
			aria-labelledby="schedule-meeting-dialog"
			open={open}
			fullWidth
			maxWidth="sm"
			BackdropProps={{
				sx: {
					backdropFilter: 'blur(5px)',
					backgroundColor: 'rgba(0,0,30,0.5)',
				},
			}}
		>
			<DialogTitle id="schedule-meeting-dialog-title">
				<Stack direction="column" spacing={2} justifyContent="flex-start">
					<Typography variant="h6" fontWeight={700}>
						<FormattedMessage id="questions.dialogs.scheduleMeetingDialog.title" />
					</Typography>
					<Typography variant="body1">
						<FormattedMessage id="questions.dialogs.scheduleMeetingDialog.body" />
					</Typography>
				</Stack>
			</DialogTitle>
			<Form
				onSubmit={handleSubmit}
				validate={handleValidate}
				render={({ handleSubmit, submitting }) => (
					<form onSubmit={handleSubmit} noValidate>
						<DialogContent>
							<Stack
								direction="column"
								alignItems="stretch"
								justifyContent="center"
								spacing={5}
								textAlign="left"
							>
								<Stack direction={isLargeScreen ? 'row' : 'column'} spacing={5}>
									<Container disableGutters>
										<LocalizationProvider
											dateAdapter={AdapterDateFns}
											adapterLocale={calenderLocale}
										>
											<DatePicker
												label={intl.formatMessage({
													id: 'questions.dialogs.scheduleMeetingDialog.form.date.label',
												})}
												name="date"
												locale={calenderLocale}
												disablePast
												disabled={submitting}
												disableMaskedInput
												inputFormat="dd MMMM yyyy"
											/>
										</LocalizationProvider>
									</Container>
									<Stack direction="row" spacing={2}>
										<LocalizationProvider
											dateAdapter={AdapterDateFns}
											adapterLocale={calenderLocale}
										>
											<TimePicker
												label={intl.formatMessage({
													id: 'questions.dialogs.scheduleMeetingDialog.form.time.from',
												})}
												ampm={false}
												name="from"
												disabled={submitting}
												locale={calenderLocale}
												disableMaskedInput
											/>
										</LocalizationProvider>
										<LocalizationProvider
											dateAdapter={AdapterDateFns}
											adapterLocale={calenderLocale}
										>
											<TimePicker
												label={intl.formatMessage({
													id: 'questions.dialogs.scheduleMeetingDialog.form.time.to',
												})}
												name="to"
												ampm={false}
												disabled={submitting}
												locale={calenderLocale}
												disableMaskedInput
											/>
										</LocalizationProvider>
									</Stack>
								</Stack>
								<Stack direction="row" justifyContent="flex-end" spacing={1} pt={3}>
									<Button variant="outlined" onClick={onClose} size="large" disabled={submitting}>
										<FormattedMessage id="questions.dialogs.scheduleMeetingDialog.btnCancel" />
									</Button>
									<RdvLoadingButton
										variant="contained"
										color="primary"
										type="submit"
										loading={submitting}
										loadingPosition="center"
									>
										<FormattedMessage id="questions.dialogs.scheduleMeetingDialog.btnSubmit" />
									</RdvLoadingButton>
								</Stack>
							</Stack>
						</DialogContent>
					</form>
				)}
			/>
		</Dialog>
	);
};

export default ScheduleMeetingDialog;

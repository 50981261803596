import Navbar from '@aquga/components/layout/nav/Navbar';

const PublicHeader = () => {
	return (
		<header>
			<Navbar />
		</header>
	);
};

export default PublicHeader;

import PageHeader from '@aquga/components/common/PageHeader';
import WhiteBackground from '@aquga/components/common/WhiteBackground';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';

const DashboardPage = () => {
	return (
		<>
			<Helmet>
				<title>Dashboard - AQUGA</title>
			</Helmet>
			<PageHeader title="Dashboard" />
			<br></br>
			<WhiteBackground>
				<Typography variant="h6">
					<FormattedMessage id="common.comingSoon" />
				</Typography>
			</WhiteBackground>
		</>
	);
};
export default DashboardPage;

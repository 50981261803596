import validator from '@aquga/app/validators';
import CompanyInfoForm, { CompanyInfoFormValues } from '@aquga/components/participantAccount/CompanyInfoForm';
import { Field, FieldAccessKind } from '@aquga/services/randevuApi/generatedTypes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useIntl, FormattedMessage } from 'react-intl';

interface CompanyInfoSectionProps {
	participantFields: Field[];
	loading: boolean;
}

const CompanyInfoSection = ({ loading, participantFields }: CompanyInfoSectionProps) => {
	const intl = useIntl();

	return (
		<Accordion
			sx={{
				borderRadius: '12px !important',
				boxShadow: 'none',
				'&:before': {
					display: 'none',
				},
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="subtitle2">
					<FormattedMessage id="participantAccount.companyInfo.title" />
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<CompanyInfoForm loading={loading} participantFields={participantFields} />
			</AccordionDetails>
		</Accordion>
	);
};

export default CompanyInfoSection;

import ContractMatchStatus from '@aquga/components/questions/status/ContractMatchStatus';
import { RdvFileFieldValue } from '@aquga/components/services/types';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppDispatch } from '@aquga/store/configureStore';
import { offerContract, updateMatchFailed } from '@aquga/store/slices/transactionSlice';
import { AQUGA_NATURAL_30 } from '@aquga/styles/theme';
import { Box, CircularProgress, Container, Paper, Stack, Typography } from '@mui/material';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import DropZoneField from './DropZoneField';
import File from './File';
import RdvLoadingButton from './RdvLoadingButton';

interface UploadContractFormValues {
	contract: File;
}

interface ContractOfferingViewProps {
	status: string;
	transactionId: string;
	generatedContract: RdvFileFieldValue;
	loading: boolean;
}

export const ContractOfferingView = ({
	status,
	transactionId,
	generatedContract,
	loading,
}: ContractOfferingViewProps) => {
	const dispatch = useAppDispatch();
	const intl = useIntl();
	const onSubmit = async (values: UploadContractFormValues) => {
		const contract = { tech_name: RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.CONTRACT, value: values.contract };
		const resolvedAction = await dispatch(
			offerContract({ transactionId: transactionId, contract_field: contract })
		);

		if (resolvedAction.type === updateMatchFailed.type) {
			const submissionErrors = { ...resolvedAction.payload };

			// TODO: write nice helper function which does this one
			Object.entries(submissionErrors).map((keyValue) => {
				submissionErrors[keyValue[0]] = intl.formatMessage({ id: keyValue[1] as string });
			});

			return submissionErrors;
		}
	};

	return (
		<Form
			onSubmit={onSubmit}
			mutators={{
				...arrayMutators,
			}}
		>
			{({
				handleSubmit,
				form: {
					mutators: { push },
				},
				submitting,
			}) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
						<ContractMatchStatus status={status} loading={loading} />
						{generatedContract ? (
							<Container disableGutters>
								<Stack direction="column" spacing={1} mb={2}>
									{generatedContract && (
										<File
											key={generatedContract?.id_file}
											url={generatedContract?.url}
											fileName={generatedContract?.file_name}
										/>
									)}
								</Stack>
							</Container>
						) : (
							<Stack
								direction="row"
								spacing={2}
								justifyContent="flex-start"
								alignItems="center"
								component={Paper}
								p="10px 5px 10px "
								width="100%"
								sx={{
									backgroundColor: AQUGA_NATURAL_30,
									borderRadius: 3,
								}}
							>
								<CircularProgress sx={{ color: 'primary', ml: 2 }} size={20} />
								<Typography component="span">
									<FormattedMessage id="questions.details.contract.generating" />
								</Typography>
							</Stack>
						)}
					</Stack>
					<br />
					<Box width="100%" justifyContent="flex-end" display="flex">
						<RdvLoadingButton
							variant="contained"
							color="primary"
							type="submit"
							loading={loading}
							loadingPosition="center"
							disabled={loading || submitting || !generatedContract}
						>
							<FormattedMessage id="questions.match.contract.contractOfferingView.btnSubmit" />
						</RdvLoadingButton>
					</Box>
				</form>
			)}
		</Form>
	);
};

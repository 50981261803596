import RdvLoadingTextButton from '@aquga/components/common/RdvLoadingTextButton';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import {
	selectProviderWithdrawFromTicketLoading,
	triggerManualTransactionTransition,
	withdrawFromTicketProvider,
} from '@aquga/store/slices/transactionSlice';
import { Button, Skeleton } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface ProviderConsultantAppliedOptionsViewProps {
	transactionId: string | undefined;
	loading: boolean;
}

export const ProviderConsultantAppliedOptionsView = ({
	transactionId,
	loading,
}: ProviderConsultantAppliedOptionsViewProps) => {
	const dispatch = useAppDispatch();
	const withdrawLoading = useAppSelector(selectProviderWithdrawFromTicketLoading);
	return (
		<>
			{loading ? (
				<Skeleton width={120} />
			) : (
				<RdvLoadingTextButton
					variant="text"
					color="secondary"
					loading={withdrawLoading}
					disabled={withdrawLoading}
					onClick={() =>
						transactionId &&
						dispatch(
							withdrawFromTicketProvider({
								id_transaction: transactionId,
							})
						)
					}
				>
					<FormattedMessage id="common.withdraw" />
				</RdvLoadingTextButton>
			)}
		</>
	);
};

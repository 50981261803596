import { Form } from 'react-final-form';

import OnboardingView from './OnboardingView';

export interface OnboardingFormValues {
	contact_email?: string;
	first_name?: string;
	last_name?: string;
	phone_number?: string;
	zip_code?: string;
	vat_id?: string;
	company_name?: string;
	industry?: string;
	city?: string;
	country_code?: string;
	street_name?: string;
	address_line_2?: string;
	street_number?: number;
}

interface ParticipantOnboardingFormProps {
	onSubmit: (values: OnboardingFormValues, form: any) => any;
	onValidate: (values: OnboardingFormValues) => OnboardingFormValues | Record<string, never>;
	onboardingState: string;
}

const ParticipantOnboardingForm = ({ onSubmit, onValidate, onboardingState }: ParticipantOnboardingFormProps) => {
	return (
		<Form
			onSubmit={onSubmit}
			validate={onValidate}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate={true}>
					<OnboardingView onboardingState={onboardingState} />
				</form>
			)}
		/>
	);
};

export default ParticipantOnboardingForm;

import { Field, Maybe, FieldFilter } from '@aquga/services/randevuApi/generatedTypes';

export const findFieldPurpose = (fields: Field[], purpose: string): Field | undefined => {
	return fields?.find((field: Field) => field.field_type.category === purpose);
};

export const findFieldByFieldTypeId = (fields: Maybe<Field>[], id_field_type?: string): Maybe<Field> | undefined => {
	return fields?.find((field: Maybe<Field>) => field?.field_type.id === id_field_type);
};

export const findFilterFieldByFieldTypeId = (
	fieldFilters: Maybe<FieldFilter[]> | undefined,
	id_field_type?: string
): Maybe<FieldFilter> | undefined => {
	return fieldFilters?.find((field: FieldFilter) => field?.id_target_field_type === id_field_type);
};

export const findFieldByTechName = (fields: Field[], tech_name: string): Maybe<Field> | undefined => {
	return fields?.find((field: Field) => field?.field_type?.tech_name === tech_name);
};

export const findSupplyFilterByTechName = (fields: FieldFilter[], tech_name: string): FieldFilter | undefined => {
	return fields?.find((field: FieldFilter) => field.tech_name === tech_name);
};

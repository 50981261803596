import { ROUTES } from '@aquga/config/routes';
import { PARTICIPANT_STATUS_KIND } from '@aquga/services/randevuApi';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

const ParticipantRejectedPage = () => {
	const intl = useIntl();
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const navigate = useNavigate();

	useEffect(() => {
		if (PARTICIPANT_STATUS_KIND.REJECTED.value !== currentParticipant?.status) {
			navigate(ROUTES.SIGN_IN_PAGE);
		}
	}, [currentParticipant, navigate]);

	return (
		<>
			<Helmet>
				<title>{intl.formatMessage({ id: 'participantRejectedPage.meta' })}</title>
			</Helmet>

			<Box maxWidth="sm">
				<Typography component="h1" variant="h4" fontWeight="bold">
					{intl.formatMessage({ id: 'participantRejectedPage.title' })}
				</Typography>
				{intl.formatMessage({ id: 'participantRejectedPage.body' })}
				<Typography variant="body1"></Typography>
			</Box>
		</>
	);
};
export default ParticipantRejectedPage;

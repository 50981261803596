import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import PointOfContactForm, { PointOfContactFormValues } from './PointOfContactForm';

interface PointOfContactSectionProps {
	initialFormValues: PointOfContactFormValues;
	loading: boolean;
}

const PointOfContactSection = ({ loading, initialFormValues }: PointOfContactSectionProps) => {
	const intl = useIntl();
	return (
		<Accordion
			sx={{
				borderRadius: '12px !important',
				boxShadow: 'none',
				'&.MuiAccordion-root:before': {
					backgroundColor: 'transparent',
				},
				'&:before': {
					display: 'none',
				},
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="subtitle2">
					<FormattedMessage id="pointOfContact.title" />
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<PointOfContactForm loading={loading} initialFormValues={initialFormValues} />
			</AccordionDetails>
		</Accordion>
	);
};

export default PointOfContactSection;

export const LAYOUT_PADDING = 20;
export const DRAWER_WIDTH = 300;
export const PAGE_WIDTH = 1200;
export const SECURITY_FORMS_MAX_WIDTH = 800;
export const MAIN_LAYOUT_TOP_SPACING = 8;
export const MAIN_LAYOUT_BOTTOM_SPACING = 4;
export const MOBILE_HEADER_HEIGHT = 4;
export const BASE_ONBOARDING_STEP = 4;
export const MOBILE_APPBAR_HEIGHT = 80;
export const MATCH_DETAILS_DRAWER_WIDTH = 520;

export const EXTERNAL_URLS = {
	AQUGA_SOURCED_TALENT_MATCHING_AGREEMENT: 'https://aquga.com',
	AQUGA_LINKEDIN: 'https://www.linkedin.com/',
	AQUGA_CONTACT_US: 'https://www.aquga.com/de/contact-us/',
	AQUGA_FAQ: 'https://www.aquga.com/de/faq/',
	AQUGA_COOKIE_POLICY: 'https://www.aquga.com/de/privacy-policy/',
	AQUGA_PRIVACY_POLICY: 'https://www.aquga.com/de/privacy-policy/',
	AQUGA_TERMS_OF_SERVICE: 'https://www.aquga.com/terms-of-service/',

	RANDEVU_TECH: 'https://www.randevu.tech',
};

export const ASSETS = {
	AQUGA_DASHBOARD_LOGO: '/assets/aquga-dashboard-logo.svg',
	ANSWERING_EXPERTISE_LOGO: '/assets/answering-expertise-logo.svg',
	FAV_ICON: '/favicon.ico',
	ACCOUNT_REVIEW_GAVEL: '/assets/gavel.svg',
	AQUGA_LOGO_MAIN: '/assets/logo-main.svg',
	USP_IMAGE: '/assets/hiw-1.jpg',
	AQUGA_SIDEBAR_LOGO_Q: '/assets/Logo-Q.svg',
	HOW_IT_WORKS_01: '/assets/hiw-1.jpg',
	HOW_IT_WORKS_02: '/assets/macka.jpg',
	HOW_IT_WORKS_03: '/assets/tigar.jpeg',
	AQUGA_HERO_LOGO: '/assets/logo-symbol-pink.svg',
	NO_QUESTIONS_ASKED_YET_CONSUMER: '/assets/consumer-empty-state-tickets.svg',
	NO_QUESTIONS_ASKED_YET_PROVIDER: '/assets/provider-empty-state-tickets.svg',
	NO_SERVICES_ADDED_YET: '/assets/provider-empty_state_no_services.svg',
};

import ScheduleMeetingDialog from '@aquga/components/matchDialogs/ScheduleMeetingDialog';
import SendMessageDialog from '@aquga/components/matchDialogs/SendMessageDialog';
import { Button, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface MatchTimeViewProps {
	transactionId: string;
}

export const MatchTimeView = ({ transactionId }: MatchTimeViewProps) => {
	const theme = useTheme();
	const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
	const [isOpenMessageDialog, setOpenMessageDialog] = useState(false);
	const [isOpenScheduleDialog, setOpenScheduleDialog] = useState(false);

	return (
		<Stack direction="column" spacing={2}>
			<SendMessageDialog open={isOpenMessageDialog} onClose={() => setOpenMessageDialog(false)} />
			<ScheduleMeetingDialog
				open={isOpenScheduleDialog}
				onClose={() => setOpenScheduleDialog(false)}
				transactionId={transactionId}
			/>
			<Divider variant="middle" />
			<Typography variant="subtitle2">
				<FormattedMessage id="questions.match.contract.matchTimeView.title" />
			</Typography>
			<Typography variant="body1">
				<FormattedMessage id="questions.match.contract.matchTimeView.body" />
			</Typography>
			<Stack direction={isLargeScreen ? 'row' : 'column'} justifyContent="space-between" spacing={2}>
				{/* <Button variant="outlined" color="primary" onClick={() => setOpenMessageDialog(true)} disabled={true}>
					<FormattedMessage id="questions.match.contract.matchTimeView.btnSendMessage" />
				</Button> */}
				<Button variant="contained" color="primary" onClick={() => setOpenScheduleDialog(true)}>
					<FormattedMessage id="questions.match.contract.matchTimeView.btnScheduleMeeting" />
				</Button>
			</Stack>
		</Stack>
	);
};

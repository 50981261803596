import { findFieldByTechName } from '@aquga/app/fieldHelpers';
import ScheduleMeetingDialog from '@aquga/components/matchDialogs/ScheduleMeetingDialog';
import MatchStatus from '@aquga/components/questions/status/MatchStatus';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { Field } from '@aquga/services/randevuApi/generatedTypes';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentLanguage } from '@aquga/store/slices/uiSlice';
import { Button, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface ProposedTimeViewProps {
	match_fields: Field[];
	loading: boolean;
	transactionId: string;
	button: boolean;
}

export const ProposedTimeView = ({ match_fields, transactionId, button, loading }: ProposedTimeViewProps) => {
	const intl = useIntl();
	const locale = useAppSelector(selectCurrentLanguage);

	const [isOpenScheduleDialog, setOpenScheduleDialog] = useState(false);

	const meetingStartDatetime = findFieldByTechName(
		match_fields,
		RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.APPOINTMENT_START_DATETIME
	)?.value;
	const meetingEndDatetime = findFieldByTechName(
		match_fields,
		RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.APPOINTMENT_END_DATETIME
	)?.value;

	const meetingDate = intl.formatDate(meetingStartDatetime, {
		day: 'numeric',
		year: 'numeric',
		month: 'long',
	});

	const meetingStartTime = new Intl.DateTimeFormat(locale, { timeStyle: 'short' }).format(
		new Date(meetingStartDatetime)
	);

	const meetingEndTime = new Intl.DateTimeFormat(locale, { timeStyle: 'short' }).format(new Date(meetingEndDatetime));
	return (
		<>
			<ScheduleMeetingDialog
				open={isOpenScheduleDialog}
				onClose={() => setOpenScheduleDialog(false)}
				transactionId={transactionId}
			/>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
				<Divider sx={{ color: 'black' }} />
				<Typography variant="overline">
					<FormattedMessage id="questions.match.contract.proposedTimeView.title" />
				</Typography>
				<MatchStatus status="meeting_time_proposed" loading={loading} />
				{loading ? <Skeleton width={80} /> : <Typography variant="body1">{meetingDate}</Typography>}
				{loading ? (
					<Skeleton width={100} />
				) : (
					<Typography variant="body1">
						{meetingStartTime} - {meetingEndTime}
					</Typography>
				)}
				{button && (
					<Button variant="text" color="secondary" onClick={() => setOpenScheduleDialog(true)}>
						<FormattedMessage id="questions.match.contract.proposedTimeView.btnSendNewProposal" />
					</Button>
				)}
			</Stack>
		</>
	);
};

import { ASSETS } from '@aquga/app/constants';
import { Grid } from '@mui/material';

interface SecurityPageLayoutProps {
	backgroundColor: string;
	children?: React.ReactNode;
}

const SecurityPageLayout = ({ backgroundColor, children }: SecurityPageLayoutProps) => {
	return (
		<Grid container>
			<Grid item xs={12} sm={12} md={8}>
				{children}
			</Grid>
			<Grid
				minHeight="100vH"
				item
				xs={0}
				md={4}
				sx={{
					backgroundColor: { backgroundColor },
					backgroundImage: `url(${ASSETS.AQUGA_SIDEBAR_LOGO_Q})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
					backgroundPosition: 'bottom',
				}}
			/>
			<br />
		</Grid>
	);
};

export default SecurityPageLayout;

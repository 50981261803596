import { OnboardingFormValues } from '@aquga/components/onboarding/ParticipantOnboardingForm';
import { CompanyInfoFormValues } from '@aquga/components/participantAccount/CompanyInfoForm';
import { PersonalInfoFormValues } from '@aquga/components/participantAccount/PersonalInfoForm';
import { PointOfContactFormValues } from '@aquga/components/participantAccount/PointOfContactForm';
import { RegisterFormValues } from '@aquga/components/register/RegisterForm';
import { RANDEVU_IDS, UserTypeDefinition } from '@aquga/config/randevuIds';
import {
	Field,
	FieldAccessKind,
	Maybe,
	MutationRegisterParticipantArgs,
	FieldInput,
} from '@aquga/services/randevuApi/generatedTypes';

export const mapRegisterFormValuesToSignUpRequest = (data: RegisterFormValues): MutationRegisterParticipantArgs => {
	if (!data.firstName) throw new Error('Expected first name in UI model');
	if (!data.lastName) throw new Error('Expected last name in UI model');
	if (!data.email) throw new Error('Expected email in UI model');
	return {
		first_name: data.firstName,
		last_name: data.lastName,
		participant_tech_name: data.isCustomer
			? RANDEVU_IDS.USER_TYPE_CUSTOMER.TECH_NAME
			: RANDEVU_IDS.USER_TYPE_CONSULTING_HOUSE.TECH_NAME,
		email: data.email,
	};
};

export const isBusinessSide = (currentParticipant: any): boolean => {
	return currentParticipant?.type?.tech_name === RANDEVU_IDS.USER_TYPE_CUSTOMER.TECH_NAME;
};

export const isConsultingSide = (currentParticipant: any): boolean => {
	return currentParticipant?.type?.tech_name === RANDEVU_IDS.USER_TYPE_CONSULTING_HOUSE.TECH_NAME;
};

export const mapOnboardingFormValuesToUpdateMyFieldsRequest = (
	formValues: OnboardingFormValues | CompanyInfoFormValues | PersonalInfoFormValues | PointOfContactFormValues,
	userType: UserTypeDefinition
): FieldInput[] => {
	const fieldTypes = Object.entries(userType.FIELDS).map((fieldTypeMap) => fieldTypeMap[1]);

	return Object.entries(formValues).map(([fieldName, fieldValue]) => {
		const idFieldType = fieldTypes.find((field) => field.TECH_NAME === fieldName)?.TECH_NAME;
		return {
			tech_name: idFieldType,
			value: fieldValue,
		};
	});
};

export const mapParticipantAccountFormValuesToFieldInputs = (
	formValues: OnboardingFormValues | CompanyInfoFormValues | PersonalInfoFormValues | PointOfContactFormValues
) => {
	return Object.entries(formValues).map(([field_tech_name, value]) => {
		return {
			tech_name: field_tech_name,
			value: value,
		} as FieldInput;
	});
};

export const mapParticipantFieldsToFormValues = (fields: Field[]) => {
	if (fields === undefined) return {};

	const formValues: any = fields?.reduce((accumulator, currentField) => {
		return { ...accumulator, [currentField.field_type.tech_name]: currentField.value };
	}, {});

	return formValues;
};

export const initPersonalInfoFormValues = (fields: Field[], email: string | undefined) => {
	if (!fields) return {};
	const initialFormData = mapParticipantFieldsToFormValues(fields);
	initialFormData.email = email;
	return initialFormData;
};

export const initCompanyInfoFormValues = (fields: Field[] | undefined) => {
	if (!fields) return {};
	const initialFormData = mapParticipantFieldsToFormValues(fields);
	return initialFormData;
};

export const initPointOfContactFormValues = (fields: Field[] | undefined) => {
	if (!fields) return {};
	const initialFormData = mapParticipantFieldsToFormValues(fields);
	return initialFormData;
};

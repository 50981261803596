import validator from '@aquga/app/validators';
import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppDispatch } from '@aquga/store/configureStore';
import { declineContract, updateMatchFailed } from '@aquga/store/slices/transactionSlice';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

interface DeclineContractFormValues {
	message: string;
}

interface DeclineContractDialogProps {
	open: boolean;
	transactionId: string;
	onClose: any;
}

const DeclineContractDialog = ({ transactionId, open, onClose }: DeclineContractDialogProps) => {
	const intl = useIntl();
	const dispatch = useAppDispatch();
	const handleSubmit = async (values: DeclineContractFormValues) => {
		const fields = [
			{ tech_name: RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.CONTRACT_DECLINED_REASON, value: values.message },
		];
		const resolvedAction = await dispatch(declineContract({ fields, transactionId }));

		if ((resolvedAction.type = updateMatchFailed.type)) {
			const submissionErrors = { ...resolvedAction.payload };

			// TODO: write nice helper function which does this one
			Object.entries(submissionErrors).map((keyValue) => {
				submissionErrors[keyValue[0]] = intl.formatMessage({ id: keyValue[1] as string });
			});

			return submissionErrors;
		}
	};

	const fieldValidators = {
		message: [
			(value: string) =>
				validator.required({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
				}),
		],
	};

	const handleValidate = async (values: DeclineContractFormValues) => {
		return validator.doValidation(fieldValidators, values);
	};

	return (
		<Dialog
			aria-labelledby="decline-contract-dialog"
			open={open}
			fullWidth
			maxWidth="sm"
			BackdropProps={{
				sx: {
					backdropFilter: 'blur(5px)',
					backgroundColor: 'rgba(0,0,30,0.5)',
				},
			}}
		>
			<DialogTitle id="decline-contract-dialog-title">
				<Stack direction="column" spacing={2} justifyContent="flex-start">
					<Typography variant="h6" fontWeight={700}>
						<FormattedMessage id="questions.dialogs.declineContractDialog.title" />
					</Typography>
					<Typography variant="body2">
						<FormattedMessage id="questions.dialogs.declineContractDialog.body" />
					</Typography>
					<Typography variant="body1">
						<FormattedMessage id="questions.dialogs.declineContractDialog.explainer" />
					</Typography>
				</Stack>
			</DialogTitle>
			<Form
				onSubmit={handleSubmit}
				validate={handleValidate}
				render={({ handleSubmit, submitting }) => (
					<form onSubmit={handleSubmit} noValidate>
						<DialogContent>
							<Stack
								direction="column"
								alignItems="stretch"
								justifyContent="center"
								spacing={2}
								textAlign="left"
							>
								<TextField
									name="message"
									label={intl.formatMessage({
										id: 'questions.dialogs.declineContractDialog.fields.message.label',
									})}
									placeholder={intl.formatMessage({
										id: 'questions.dialogs.declineContractDialog.fields.message.placeholder',
									})}
									required
									multiline
									rows={5}
									sx={{ height: 'auto ' }}
									margin="none"
								/>
								<Stack direction="row" justifyContent="flex-end" spacing={2}>
									<Button variant="outlined" onClick={() => onClose()} size="large">
										<FormattedMessage id="questions.dialogs.declineContractDialog.btnCancel" />
									</Button>
									<RdvLoadingButton
										variant="contained"
										color="primary"
										type="submit"
										loading={submitting}
										loadingPosition="center"
									>
										<FormattedMessage id="questions.dialogs.declineContractDialog.btnSubmit" />
									</RdvLoadingButton>
								</Stack>
							</Stack>
						</DialogContent>
					</form>
				)}
			/>
		</Dialog>
	);
};

export default DeclineContractDialog;

import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

interface QuestionPriorityProps {
	priority: string;
	dueDate?: string;
}

const QuestionPriority = ({ priority, dueDate }: QuestionPriorityProps) => {
	const intl = useIntl();
	let priorityLabel = intl.formatMessage({ id: 'resources.question.priority.values.asap' });

	if (priority === RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.PRIORITY.VALUES.DUE_DATE) {
		priorityLabel = intl.formatMessage(
			{ id: 'questions.details.dueDate' },
			{
				dueDate: intl.formatDate(dueDate, {
					day: 'numeric',
					year: 'numeric',
					month: 'long',
				}),
			}
		);
	}

	return <Typography variant="body1">{priorityLabel}</Typography>;
};

export default QuestionPriority;

import { ENV_VARS } from '@aquga/config/environmentVariables';

import Features from './features';
import {
	ITransactionService,
	IParticipantsService,
	IAuthService,
	ISuppliesService,
	IFilesService,
} from './features/index';

interface RandevuArguments {
	token: string | undefined;
	apiKey?: string | undefined;
}

class randevu {
	_baseURL: string;
	_headers: any;

	auth: IAuthService;
	participants: IParticipantsService;
	transactions: ITransactionService;
	supplies: ISuppliesService;
	files: IFilesService;

	constructor(args?: RandevuArguments) {
		const apiEndpoint = ENV_VARS.RANDEVU_API;
		const apiKey = ENV_VARS.RANDEVU_PUBLIC_API_KEY;

		this._baseURL = apiEndpoint;
		this._headers = {
			'Content-Type': 'application/json',
			'x-randevu-key': apiKey,
		};

		if (args?.token !== undefined) this.withAuth(args.token);
		if (args?.apiKey !== undefined) this.withAuth(args.apiKey);

		this.auth = new Features.AuthService(this);
		this.participants = new Features.ParticipantsService(this);
		this.transactions = new Features.TransactionService(this);
		this.supplies = new Features.SuppliesService(this);
		this.files = new Features.FilesService(this);
	}

	_handleFetchErrors(fetchErrors: any) {
		const response = {} as any;

		if ('Failed to fetch' === fetchErrors.message) {
			console.error('Network problems detected. User is probably without internet..');

			response.errors = [
				{
					message: 'Poor network connection detected. Please check your internet connection and try again.',
					extensions: { code: 'ERR_CONNECTION_REFUSED' },
				},
			];

			return response;
		} else if (fetchErrors?.message?.includes('NetworkError when attempting to fetch resource')) {
			console.log('SSL Certificate is not accepted by user\'s browser.');
			response.errors = [
				{
					message:
						'Shoot! It seems that secure connection to your browser cannot be established. Please try again later.',
					extensions: { code: 'ERR_CONNECTION_SSL_REFUSED' },
				},
			];
			return response;
		}

		// Unexpected and unhandled fetch errors
		response.errors = [
			{
				message: 'Poor network connection detected. Please try again later.',
				extensions: { code: 'UNEXPECTED_ERROR' },
			},
		];

		return response;
	}

	setHeader(key: string, value: string) {
		this._headers[key] = value;
		return this;
	}

	withAuth(token: string) {
		this._headers['Authorization'] = token;
		return this;
	}

	withApiKey(apiKey: string) {
		this._headers['x-randevu-key'] = apiKey;
		return this;
	}

	call({ payload }: any) {
		return fetch(this._baseURL, {
			method: 'POST',
			headers: { ...this._headers },
			body: payload,
		})
			.then((res) => res.json())
			.catch(this._handleFetchErrors);
	}

	assertPublicApiKey() {
		const apiKey = this._headers['x-randevu-key'];

		if (!apiKey) throw new Error('Public API Key is missing.');

		if (
			(!apiKey.startsWith('pk-sand_') && apiKey.startsWith('pk-prod_')) ||
			(apiKey.startsWith('pk-sand_') && !apiKey.startsWith('pk-prod_'))
		)
			throw new Error('Public api key is supposed to start with pk-sand_ or pk-prod_');

		return this;
	}
}

export default randevu;

import { ASSETS, MOBILE_APPBAR_HEIGHT } from '@aquga/app/constants';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { Stack } from '@mui/system';

interface MobileHeaderProps {
	onMenuClick?: (params: any) => any;
}

const MobileHeader = ({ onMenuClick }: MobileHeaderProps) => {
	return (
		<AppBar
			position="fixed"
			elevation={0}
			sx={{
				display: { sm: 'none' },
				backgroundColor: 'white',
				height: { xs: MOBILE_APPBAR_HEIGHT, sm: MOBILE_APPBAR_HEIGHT, md: MOBILE_APPBAR_HEIGHT },
			}}
		>
			<Toolbar>
				<IconButton
					aria-label="open navigation"
					edge="start"
					onClick={onMenuClick}
					sx={{ mr: 2, display: { sm: 'none' }, color: 'black' }}
				>
					<MenuIcon />
				</IconButton>
				<Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
					<img
						alt="AQUGA Logo"
						style={{ width: 200, height: MOBILE_APPBAR_HEIGHT }}
						src={ASSETS.AQUGA_LOGO_MAIN}
					/>
				</Stack>
			</Toolbar>
		</AppBar>
	);
};

export default MobileHeader;

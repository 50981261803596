import unifyFieldValue from '@aquga/services/randevuApi/fieldHelper';
import {
	MutationAcceptTransactedMatchArgs,
	MutationDeclineTransactedMatchArgs,
	MutationPrepareAndInitiateReverseAuctionTransactionArgs,
	MutationTriggerManualTransactionTransitionArgs,
	MutationUpdateTransactedMatchArgs,
	QueryMyTransactionArgs,
	Transaction,
	TransactionLight,
	TransactionMatch,
} from '@aquga/services/randevuApi/generatedTypes';

import * as transactionMutations from './transactionMutations';
import * as transactionQueries from './transactionQueries';

class TransactionService {
	private randevu: any;

	constructor(randevu: any) {
		this.randevu = randevu;
	}

	async prepareAndInitiateReverseAuctionTransaction({
		transaction_tech_name,
		supply_filter,
		provider_filter,
		consumer_filter,
		fields,
	}: MutationPrepareAndInitiateReverseAuctionTransactionArgs): Promise<{ id_transaction: string; errors: any }> {
		const payload = transactionMutations.initiateReverseAuctionTransaction({
			transaction_tech_name,
			supply_filter,
			provider_filter,
			consumer_filter,
			fields,
		});

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { id_transaction: data?.prepareAndInitiateReverseAuctionTransaction?.id, errors };
	}

	async myTransactions(): Promise<{ transactions: TransactionLight[]; errors: any }> {
		const payload = transactionQueries.myTransactions();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		let transactions = data?.myTransactions;

		transactions = transactions?.map((transaction: any) => {
			const order_fields = unifyFieldValue(transaction?.order_fields);
			const match_fields = unifyFieldValue(transaction?.match_fields);

			transaction.order_fields = order_fields;
			transaction.match_fields = match_fields;

			return transaction as TransactionLight;
		});

		return { transactions, errors };
	}

	async myTransaction({ id }: QueryMyTransactionArgs): Promise<{ transaction: Transaction; errors: any }> {
		const payload = transactionQueries.myTransaction({ id });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		const transaction = data?.myTransaction as Transaction;

		if (transaction) {
			const order_fields = unifyFieldValue(transaction?.order?.fields);
			if (order_fields) {
				transaction.order.fields = order_fields;
			}

			if (transaction?.order?.creator?.fields) {
				transaction.order.creator.fields = unifyFieldValue(transaction.order.creator.fields);
			}

			transaction.matches?.map((match: TransactionMatch) => {
				match.provider_fields = unifyFieldValue(match?.provider_fields);
				match.consumer_fields = unifyFieldValue(match?.consumer_fields);
				match.supply_fields = unifyFieldValue(match?.supply_fields);
				match.fields = unifyFieldValue(match?.fields);

				if (match.provider?.fields) {
					match.provider.fields = unifyFieldValue(match.provider.fields);
				}
				if (match.consumer?.fields) {
					match.provider.fields = unifyFieldValue(match.consumer.fields);
				}
				if (match.supply?.fields) {
					match.provider.fields = unifyFieldValue(match.supply.fields);
				}
			});

			transaction.sub_transactions?.map((transactionLight: TransactionLight) => {
				if (transactionLight.order_fields) {
					transactionLight.order_fields = unifyFieldValue(transactionLight.order_fields);
				}
				if (transactionLight.match_fields) {
					transactionLight.match_fields = unifyFieldValue(transactionLight.match_fields);
				}
				if (transactionLight.provider_fields) {
					transactionLight.provider_fields = unifyFieldValue(transactionLight.provider_fields);
				}
				if (transactionLight.consumer_fields) {
					transactionLight.consumer_fields = unifyFieldValue(transactionLight.consumer_fields);
				}
				if (transactionLight.supply_fields) {
					transactionLight.supply_fields = unifyFieldValue(transactionLight.supply_fields);
				}
			});
		}

		return { transaction: transaction, errors };
	}
	async triggerManualTransactionTransition({
		id_transaction,
		transition_tech_name,
	}: MutationTriggerManualTransactionTransitionArgs): Promise<{ transition_triggered: boolean; errors: any }> {
		const payload = transactionMutations.triggerManualTransactionTransition({
			id_transaction,
			transition_tech_name: transition_tech_name,
		});

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { transition_triggered: data?.triggerManualTransactionTransition, errors };
	}

	async acceptTransactedMatch({
		id_transaction,
	}: MutationAcceptTransactedMatchArgs): Promise<{ accepted: boolean | null; errors: any }> {
		const payload = transactionMutations.acceptTransactedMatch({ id_transaction });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { accepted: data?.acceptTransactedMatch, errors };
	}

	async declineTransactedMatch({
		id_transaction,
	}: MutationDeclineTransactedMatchArgs): Promise<{ declined: boolean | null; errors: any }> {
		const payload = transactionMutations.declineTransactedMatch({ id_transaction });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { declined: data?.declineTransactedMatch, errors };
	}

	async updateTransactedMatch({
		id_transaction,
		fields,
	}: MutationUpdateTransactedMatchArgs): Promise<{ match_updated: boolean | null; errors: any }> {
		const payload = transactionMutations.updateTransactedMatch({ id_transaction, fields });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { match_updated: data?.updateTransactedMatch, errors };
	}
}

export default TransactionService;

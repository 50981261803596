import QuestionPriority from '@aquga/components/questions/QuestionPriority';
import QuestionStatus from '@aquga/components/questions/QuestionStatus';
import RespondentMatchStatus from '@aquga/components/questions/status/RespondentMatchStatus';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { Skeleton } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { FormattedDate, useIntl } from 'react-intl';

interface LabelProps {
	text: string;
}
const Label = ({ text }: LabelProps) => (
	<Typography color="textSecondary" sx={{ textTransform: 'uppercase' }} variant="caption">
		{text}
	</Typography>
);

interface QuestionRequirementsProps {
	loading: boolean;
	status: string;
	topics: string[];
	services: string[];
	topicsOther: string;
	expertiseOther: string;
	price: number;
	priority: string;
	dueDate?: string;
	internalRefTicket?: string | undefined;
	createdAt: string;
	isBusiness: boolean;
}
const QuestionRequirements = ({
	loading,
	status,
	topics = [],
	services = [],
	price,
	priority,
	dueDate,
	internalRefTicket,
	createdAt,
	topicsOther,
	expertiseOther,
	isBusiness,
}: QuestionRequirementsProps) => {
	const intl = useIntl();

	const localizedTopics = topics?.map((topic) =>
		intl.formatMessage({ id: `resources.question.service.values.${topic}` })
	);

	let localizedOtherTopic = '';
	if (
		topicsOther &&
		topics.includes(RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA_OTHER.TECH_NAME)
	) {
		localizedOtherTopic = `${intl.formatMessage({
			id: 'resources.question.service.values.other',
		})} : ${topicsOther}`;
	}

	const localizedServices = services?.map((service) =>
		intl.formatMessage({ id: `resources.question.topics.values.${service}` })
	);

	let localizedOtherService = '';
	if (
		expertiseOther &&
		topics.includes(RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KIND_OTHER.TECH_NAME)
	) {
		localizedOtherService = `${intl.formatMessage({
			id: 'resources.question.service.values.other',
		})} : ${expertiseOther}`;
	}
	return (
		<Stack justifyContent="center" alignItems="flex-start" direction="column" spacing={3}>
			<Label text={intl.formatMessage({ id: 'resources.question.status.label' })} />
			{isBusiness ? (
				<QuestionStatus loading={loading} status={status} />
			) : (
				<RespondentMatchStatus loading={loading} status={status} />
			)}
			<Label text={intl.formatMessage({ id: 'resources.question.service.label' })} />
			<Typography variant="body1">
				{loading && <Skeleton width={60} />}
				{!loading && localizedServices?.join(' | ')}
			</Typography>
			{localizedOtherService !== '' && (
				<Typography variant="body1">
					{loading && <Skeleton width={60} />}
					{!loading && localizedOtherService}
				</Typography>
			)}
			<Label text={intl.formatMessage({ id: 'resources.question.topics.label' })} />
			<Typography variant="body1">
				{loading && <Skeleton width={60} />}
				{!loading && localizedTopics?.join(' | ')}
			</Typography>
			{localizedOtherTopic !== '' && (
				<Typography variant="body1">
					{loading && <Skeleton width={60} />}
					{!loading && localizedOtherTopic}
				</Typography>
			)}
			<Label text={intl.formatMessage({ id: 'resources.question.priority.label' })} />
			{loading && <Skeleton width={60} />}
			{!loading && <QuestionPriority priority={priority} dueDate={dueDate} />}
			<Label text={intl.formatMessage({ id: 'resources.question.price.label1' })} />

			<Typography variant="body1">
				{loading && <Skeleton width={60} />}
				{!loading && intl.formatNumber(price, { style: 'currency', currency: 'EUR' })}
			</Typography>

			{isBusiness ? (
				<>
					<Divider flexItem sx={{ mb: 6, mt: 6 }} />
					<Label text={intl.formatMessage({ id: 'resources.question.internalRefTicket.label' })} />
					<Typography variant="body1">
						{loading && <Skeleton width={60} />}
						{!loading && internalRefTicket ? internalRefTicket : '-'}
					</Typography>{' '}
				</>
			) : (
				<></>
			)}
			<Divider flexItem sx={{ mb: 6, mt: 6 }} />
			<Label text={intl.formatMessage({ id: 'resources.question.createdOn.label' })} />
			{loading && <Skeleton width={60} />}
			{!loading && <FormattedDate value={createdAt} day="numeric" year="numeric" month="long" />}
		</Stack>
	);
};

export default QuestionRequirements;

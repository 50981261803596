import { SECURITY_FORMS_MAX_WIDTH } from '@aquga/app/constants';
import { validator } from '@aquga/app/validators';
import ForgotPasswordBottomBar from '@aquga/components/forgotPassword/ForgotPasswordBottomBar';
import ForgotPasswordForm, { ForgetPasswordFormValues } from '@aquga/components/forgotPassword/ForgotPasswordForm';
import ForgotPasswordRequestedView from '@aquga/components/forgotPassword/ForgotPasswordRequestedView';
import SecurityPageLayout from '@aquga/components/security/SecurityPageLayout';
import SecurityPageTopBar from '@aquga/components/security/SecurityPageTopBar';
import { useAppDispatch } from '@aquga/store/configureStore';
import { requestPasswordReset, requestPasswordResetFailed } from '@aquga/store/slices/authSlice';
import { AQUGA_LOGIN_GRAY } from '@aquga/styles/theme';
import { Divider, Grid } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const ForgotPasswordPage = () => {
	const intl = useIntl();
	const metaTitle = `${intl.formatMessage({ id: 'forgotPasswordPage.metaTitle' })} - AQUGA`;
	const [passwordRequested, setPasswordRequested] = useState(false);
	const dispatch = useAppDispatch();
	const [email, setEmail] = useState('');

	const handleSubmitForgotPasswordForm = async (values: ForgetPasswordFormValues) => {
		const resolvedAction = await dispatch(requestPasswordReset(values.email));

		if (resolvedAction.type !== requestPasswordResetFailed.type) {
			setEmail(values.email);
			setPasswordRequested(true);
		}
	};

	const handleValidateForgotPasswordForm = (values: ForgetPasswordFormValues) => {
		const fieldValidators = {
			email: [
				(value: any) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
				(value: any) =>
					validator.isEmail({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.invalidEmail' }),
					}),
			],
		};

		return validator.doValidation(fieldValidators, values);
	};

	return (
		<>
			<Helmet>
				<title>{metaTitle}</title>
			</Helmet>
			<SecurityPageLayout backgroundColor={AQUGA_LOGIN_GRAY}>
				<Grid
					container
					justifyContent="center"
					maxWidth={SECURITY_FORMS_MAX_WIDTH}
					marginLeft="auto"
					marginRight="auto"
				>
					<Grid item xs={12} paddingLeft={1} marginBottom={5}>
						<SecurityPageTopBar noStepper />
					</Grid>
					{passwordRequested ? (
						<ForgotPasswordRequestedView email={email} />
					) : (
						<>
							<Grid item xs={12} marginBottom={8}>
								<ForgotPasswordForm
									onSubmit={handleSubmitForgotPasswordForm}
									onValidate={handleValidateForgotPasswordForm}
								/>
							</Grid>
							<Grid item xs={10} marginBottom={4}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<ForgotPasswordBottomBar />
							</Grid>
						</>
					)}
				</Grid>
			</SecurityPageLayout>
		</>
	);
};
export default ForgotPasswordPage;

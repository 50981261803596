export type TicketFilter = 'all' | 'active' | 'past' | undefined;

export enum TicketFilterEnum {
	ALL = 'all',
	ACTIVE = 'active',
	PAST = 'past',
}

export interface Question {
	id: string;
	title: string;
	createdAt: string;
	terminatedAt: string;
	toBeAnsweredUntil: string;
	matches: number;
	value: { amount: number; currency: string; unit: number };
	status: string;
}

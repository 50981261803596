import { MutationCreateSupplyArgs, MutationUpdateSupplyArgs } from '@aquga/services/randevuApi/generatedTypes';
import { print } from 'graphql';
import gql from 'graphql-tag';

const CREATE_SUPPLY = gql`
	mutation CREATE_SUPPLY($tech_name: String!) {
		createSupply(tech_name: $tech_name) {
			id
		}
	}
`;

const createSupply = ({ tech_name }: MutationCreateSupplyArgs) => {
	return JSON.stringify({
		query: print(CREATE_SUPPLY),
		variables: { tech_name },
	});
};

const UPDATE_SUPPLY = gql`
	mutation UPDATE_SUPPLY($id: ID!, $fields: [FieldInput!]) {
		updateSupply(id: $id, fields: $fields)
	}
`;

const updateSupply = ({ id, fields }: MutationUpdateSupplyArgs) => {
	return JSON.stringify({
		query: print(UPDATE_SUPPLY),
		variables: { id, fields },
	});
};

export { createSupply, updateSupply };

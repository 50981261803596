import ConsumerUpdateRequirementsDialog from '@aquga/components/common/ConsumerUpdateRequirementsDialog';
import { useAppDispatch } from '@aquga/store/configureStore';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface ScheduledViewProps {
	messages: string[];
	loading: boolean;
	oppositeSideParticipantName: string;
	transactionId: string;
	price: number;
}

export const ScheduledView = ({
	messages,
	oppositeSideParticipantName,
	transactionId,
	price,
	loading,
}: ScheduledViewProps) => {
	const [isUpdateRequirementsDialogOpen, setIsUpdateRequirementsDialogOpen] = useState(false);
	return (
		<Stack direction="column" width="100%" spacing={1} justifyContent="flex-end">
			<ConsumerUpdateRequirementsDialog
				oppositeSideParticipantName={oppositeSideParticipantName}
				messages={messages}
				loading={loading}
				transactionId={transactionId}
				open={isUpdateRequirementsDialogOpen}
				price={price}
				onClose={() => setIsUpdateRequirementsDialogOpen(false)}
			/>
			<Divider />
			<Typography variant="overline" textAlign="left">
				<FormattedMessage id="questions.match.contract.scheduledView.title" />
			</Typography>
			<Button variant="text" color="secondary" onClick={() => setIsUpdateRequirementsDialogOpen(true)}>
				<FormattedMessage id="questions.match.contract.scheduledView.btnUpdateRequirements" />
			</Button>
		</Stack>
	);
};

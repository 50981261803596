import { MATCH_DETAILS_DRAWER_WIDTH } from '@aquga/app/constants';
import { AQUGA_BACKGROUND_COLOR } from '@aquga/styles/theme';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Stack } from '@mui/system';

interface SideDrawerProps {
	title: string;
	open: boolean;
	children: React.ReactNode;
	onClose: (params: any) => any;
}

const SideDrawer = ({ title, open, children, onClose }: SideDrawerProps) => {
	return (
		<>
			<Drawer
				anchor="right"
				open={open}
				onClose={onClose}
				PaperProps={{
					sx: {
						backgroundColor: AQUGA_BACKGROUND_COLOR,
						width: { xs: '100%', sm: MATCH_DETAILS_DRAWER_WIDTH },
					},
				}}
			>
				<Box
					sx={{
						pl: 3,
						pr: 2,
						pt: 3,
					}}
				>
					<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} mb={3}>
						<Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
							{title}
						</Typography>

						<IconButton onClick={onClose}>
							<CloseIcon sx={{ color: 'black' }} />
						</IconButton>
					</Stack>

					{children}
				</Box>
			</Drawer>
		</>
	);
};

export default SideDrawer;

import {
	mapCreateAnsweringExpertiseFormValuesAdditionDocumentsFormFieldRandevuField,
	mapCreateAnsweringExpertiseFormValuesToSupplyFields,
} from '@aquga/app/answeringExpertiseHelpers';
import PageHeader from '@aquga/components/common/PageHeader';
import WhiteBackground from '@aquga/components/common/WhiteBackground';
import CreateAnsweringExpertiseForm, {
	CreateAnsweringExpertiseFormValues,
} from '@aquga/components/services/create/CreateAnsweringExpertiseForm';
import { ROUTES } from '@aquga/config/routes';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { createSupply, createSupplyFailed, selectLoading } from '@aquga/store/slices/supplySlice';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

const CreateAnsweringExpertisePage = () => {
	const intl = useIntl();
	const dispatch = useAppDispatch();
	const loading = useAppSelector(selectLoading);
	const navigate = useNavigate();

	const handleSubmitAnsweringExpertiseForm = async (values: CreateAnsweringExpertiseFormValues) => {
		const supplyFields = mapCreateAnsweringExpertiseFormValuesToSupplyFields({
			...values,
		});

		const supplyFieldsAdditionalDoc = mapCreateAnsweringExpertiseFormValuesAdditionDocumentsFormFieldRandevuField({
			...values,
		});

		const resolvedAction = await dispatch(
			createSupply({
				fields: supplyFields,
				field_additional_docs: supplyFieldsAdditionalDoc,
			})
		);

		if (resolvedAction.type === createSupplyFailed.type) {
			return resolvedAction.payload;
		}

		navigate(ROUTES.LIST_SERVICES);
	};

	const handleCancelSubmitAnsweringExpertiseForm = () => {
		navigate(-1);
	};

	return (
		<>
			<Helmet>
				<title>{intl.formatMessage({ id: 'createAnsweringExpertisePage.metaTitle' })}</title>
			</Helmet>

			<PageHeader title={intl.formatMessage({ id: 'createAnsweringExpertisePage.metaTitle' })} />
			<br />

			<Breadcrumbs>
				<Button
					component={Link}
					disableRipple
					disableFocusRipple
					disableElevation
					color="secondary"
					to={ROUTES.LIST_SERVICES}
					startIcon={<KeyboardBackspaceIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
				>
					{intl.formatMessage({ id: 'common.back' })}
				</Button>
				<Button
					disableRipple
					disableElevation
					disableFocusRipple
					color="inherit"
					to={ROUTES.LIST_SERVICES}
					component={Link}
				>
					<FormattedMessage id="createAnsweringExpertisePage.title" />
				</Button>
				<Typography color="text.primary" variant="caption" fontSize="small" fontWeight={600}>
					<FormattedMessage id="createAnsweringExpertisePage.addAnsweringExpertise" />
				</Typography>
			</Breadcrumbs>
			<br />
			<WhiteBackground>
				<Typography component="h2" variant="h5" gutterBottom fontWeight="bold">
					<FormattedMessage id="createAnsweringExpertisePage.addAnsweringExpertise" />
				</Typography>
				<Typography component="h3">
					<FormattedMessage id="createAnsweringExpertisePage.moreInfo" />
				</Typography>
				<br />
				<br />
				<CreateAnsweringExpertiseForm
					loading={loading}
					onSubmit={handleSubmitAnsweringExpertiseForm}
					onCancel={handleCancelSubmitAnsweringExpertiseForm}
					maxWidth="sm"
				/>
			</WhiteBackground>
		</>
	);
};
export default CreateAnsweringExpertisePage;

import * as flat from 'flat';

import messages_de from './messages_de';
import messages_en from './messages_en';

export interface ISupportedLangs {
	'en-US': string;
	'de-DE': string;
}

export type SupportedLocaleKeys = keyof ISupportedLangs;

const keepArrays = {
	safe: true,
};

export const messages = {
	'en-US': flat.flatten(messages_en, keepArrays),
	'de-DE': flat.flatten(messages_de, keepArrays),
};

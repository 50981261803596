import { SupportedLocaleKeys } from './messages';

export const DEFAULT_LOCALE = 'en-US';

interface Navigator {
	userLanguage?: string;
	browserLanguage?: string;

	languages: readonly string[];
	language: string;
}

const navigator: Navigator = window?.navigator;

const determineLocale = (): SupportedLocaleKeys => {
	const SUPPORTED_LOCALES = ['en-US', 'de-DE'];
	const DEFAULT_LOCALE = 'de-DE';

	// Take the first lang from user's browser, typically the preferred language
	// of preferred lang is not supported by user's browser, take browser's language
	// or take user's language if browser's lang is not available
	// fallback take en as lang
	const currentUserLocale =
		(navigator?.languages?.[0] as SupportedLocaleKeys) ||
		(navigator.language as SupportedLocaleKeys) ||
		(navigator.userLanguage as SupportedLocaleKeys) ||
		('en-US' as SupportedLocaleKeys);

	if (SUPPORTED_LOCALES.includes(currentUserLocale)) return currentUserLocale;

	return DEFAULT_LOCALE;
};

export const locale = determineLocale();

import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const ForgotPasswordBottomBar = () => {
	return (
		<Typography variant="body1" textAlign="center">
			<FormattedMessage id="forgotPasswordPage.bottomBarNormalText" />
			<Typography
				component="a"
				href="mailto:name@email.com"
				variant="body1"
				color="secondary"
				sx={{ textDecoration: 'none' }}
			>
				support@aquga.com{' '}
			</Typography>
		</Typography>
	);
};

export default ForgotPasswordBottomBar;

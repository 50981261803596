import ListServicesTop from '@aquga/components/services/list/ListServicesTop';
import ServicesList from '@aquga/components/services/list/ServicesList';
import AnsweringExpertiseTable from '@aquga/components/services/list/ServicesTable';
import { Service } from '@aquga/components/services/types';
import { Theme, useMediaQuery } from '@mui/material';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Box from '@mui/system/Box';

interface ListServicesViewProps {
	items: Service[];
}
const ListServicesView = ({ items }: ListServicesViewProps) => {
	const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

	return (
		<Box component={Paper} sx={{ pl: 4, pr: 4, pt: 4, pb: 10, width: '100%' }}>
			<ListServicesTop />
			<Divider sx={{ mt: 3, mb: 3 }} />
			<br />
			{largeScreen ? <AnsweringExpertiseTable items={items} /> : <ServicesList items={items} />}
		</Box>
	);
};

export default ListServicesView;

import {
	MutationRequestParticipantPasswordResetArgs,
	MutationResetParticipantPasswordArgs,
	MutationLoginParticipantArgs,
	MutationRegisterParticipantArgs,
	MutationVerifyParticipantArgs,
} from '@aquga/services/randevuApi/generatedTypes';

import * as authMutations from './authMutations';

class AuthService {
	private randevu: any;

	constructor(randevu: any) {
		this.randevu = randevu;
	}

	async signInParticipant({ email, password }: MutationLoginParticipantArgs): Promise<any> {
		const payload = authMutations.signInParticipant({ email, password });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { token: data?.loginParticipant?.token, errors };
	}
	async signOut() {
		const payload = authMutations.signOut();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { signed_out: data?.logoutMe, errors };
	}
	async signUp({ first_name, last_name, email, participant_tech_name }: MutationRegisterParticipantArgs) {
		const payload = authMutations.registerParticipant({
			first_name,
			last_name,
			email,
			participant_tech_name,
		});
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { signed_up: data?.registerParticipant, errors };
	}

	async requestParticipantPasswordReset({ email }: MutationRequestParticipantPasswordResetArgs) {
		const payload = authMutations.requestParticipantPasswordReset({
			email,
		});
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { requested: data?.requestParticipantPasswordReset, errors };
	}
	/**

	async getCurrentUser() {
		const payload = authQueries.getCurrentUser();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { user: data?.me, errors };
	}
	**/
	async verifyParticipantAccount({ token, password }: MutationVerifyParticipantArgs) {
		const payload = authMutations.verifyParticipantAccount({ token, password });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { verified: data?.verifyParticipant, errors };
	}

	async resetParticipantPassword({ token, password }: MutationResetParticipantPasswordArgs) {
		const payload = authMutations.resetParticipantPassword({ token, password });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { password_set: data?.resetParticipantPassword, errors };
	}
}

export default AuthService;

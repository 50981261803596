import { ASSETS } from '@aquga/app/constants';
import { ROUTES } from '@aquga/config/routes';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Box, Stack } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const NoServicesYetView = () => {
	return (
		<Box component={Paper} sx={{ pl: 2, pr: 2, pt: 10, pb: 10, width: '100%' }}>
			<Stack justifyContent="center" alignItems="center" spacing={3}>
				<Stack justifyContent="center" alignItems="center" spacing={0}>
					<img
						alt="Add your first service"
						style={{ width: 200, height: 200 }}
						src={ASSETS.NO_SERVICES_ADDED_YET}
					/>

					<Typography
						color="textSecondary"
						fontWeight="bold"
						sx={{
							marginTop: '-30px',
							zIndex: 100,
						}}
					>
						<FormattedMessage id="services.list.noServicesAddedyet" />
					</Typography>
				</Stack>
				<Button component={Link} to={ROUTES.CREATE_SERVICE} color="primary" variant="contained">
					<FormattedMessage id="common.btnAddAnsweringExpertise" />
				</Button>
			</Stack>
		</Box>
	);
};

export default NoServicesYetView;

import { findFieldByTechName } from '@aquga/app/fieldHelpers';
import { isBusinessSide } from '@aquga/app/participantHelpers';
import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import ScheduleMeetingDialog from '@aquga/components/matchDialogs/ScheduleMeetingDialog';
import MatchStatus from '@aquga/components/questions/status/MatchStatus';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import {
	triggerConsumerManualTransactionTransition,
	triggerManualTransactionTransition,
} from '@aquga/store/slices/transactionSlice';
import { selectCurrentLanguage } from '@aquga/store/slices/uiSlice';
import { Button, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface MeetingProposedViewProps {
	matchFields: any;
	transactionId: string;
	buttons?: boolean;
	loading: boolean;
}

export const MeetingProposedView = ({
	matchFields,
	transactionId,
	buttons = true,
	loading,
}: MeetingProposedViewProps) => {
	const intl = useIntl();
	const locale = useAppSelector(selectCurrentLanguage);
	const dispatch = useAppDispatch();
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const isBusiness = isBusinessSide(currentParticipant);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isOpenScheduleDialog, setOpenScheduleDialog] = useState(false);

	const meetingStartDatetime = findFieldByTechName(
		matchFields,
		RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.APPOINTMENT_START_DATETIME
	)?.value;
	const meetingEndDatetime = findFieldByTechName(
		matchFields,
		RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.APPOINTMENT_END_DATETIME
	)?.value;

	const meetingDate = intl.formatDate(meetingStartDatetime, {
		day: 'numeric',
		year: 'numeric',
		month: 'long',
	});

	const meetingStartTime = new Intl.DateTimeFormat(locale, { timeStyle: 'short' }).format(
		new Date(meetingStartDatetime)
	);

	const handleScheduleMeeting = () => {
		if (isBusiness) {
			dispatch(
				triggerConsumerManualTransactionTransition({
					id_transaction: transactionId,
					transition_tech_name:
						RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.MEETING_INVITE.TRANSITIONS
							.CUSTOMER_CONFIRMS_APPOINTMENT_DATE,
				})
			);
		} else {
			dispatch(
				triggerManualTransactionTransition({
					id_transaction: transactionId,
					transition_tech_name:
						RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.PROPOSED_TIME.TRANSITIONS
							.CONSULTANT_CONFIRMS_APPOINTMENT_DATE,
				})
			);
		}
	};

	const meetingEndTime = new Intl.DateTimeFormat(locale, { timeStyle: 'short' }).format(new Date(meetingEndDatetime));
	return (
		<>
			<ScheduleMeetingDialog
				open={isOpenScheduleDialog}
				onClose={() => setOpenScheduleDialog(false)}
				transactionId={transactionId}
			/>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2} width="100%">
				<Divider sx={{ color: 'black', width: '100%' }} />
				<Typography variant="overline">
					<FormattedMessage id="questions.details.meeting_proposed_view.title" />
				</Typography>
				<MatchStatus status="meeting_time_proposed" loading={loading} />
				{loading ? <Skeleton width={80} /> : <Typography variant="body1">{meetingDate}</Typography>}
				{loading ? (
					<Skeleton width={80} />
				) : (
					<Typography variant="body1">
						{meetingStartTime} - {meetingEndTime}
					</Typography>
				)}
				<Stack direction="row" justifyContent="space-between" width="100%">
					{buttons && (
						<>
							<Button
								variant="text"
								color="secondary"
								onClick={() => setOpenScheduleDialog(true)}
								disabled={isSubmitting}
							>
								<FormattedMessage id="questions.details.meeting_proposed_view.btnProposeNewTime" />
							</Button>

							<RdvLoadingButton
								variant="contained"
								color="primary"
								type="submit"
								loading={loading}
								loadingPosition="center"
								onClick={() => {
									setIsSubmitting(true);
									handleScheduleMeeting();
								}}
							>
								<FormattedMessage id="questions.details.meeting_proposed_view.btnSubmit" />
							</RdvLoadingButton>
						</>
					)}
				</Stack>
			</Stack>
		</>
	);
};

import { SECURITY_FORMS_MAX_WIDTH } from '@aquga/app/constants';
import CountryCodeField from '@aquga/components/common/CountryCodeField';
import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import { Button, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { TextField } from 'mui-rff';
import { FormattedMessage, useIntl } from 'react-intl';

export interface AddCompanyLocationViewValues {
	city: string;
	country_code: string;
	zip_code: string;
	street_name: string;
	street_number: string;
	address_line_2: string;
}

interface AddCompanyLocationViewProps {
	loading: boolean;
}

const AddCompanyLocationView = ({ loading }: AddCompanyLocationViewProps) => {
	const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
	const intl = useIntl();

	return (
		<Grid
			maxWidth={SECURITY_FORMS_MAX_WIDTH}
			container
			direction="column"
			alignItems="center"
			justifyContent="center"
			spacing={3}
			paddingRight={largeScreen ? 12 : 12}
			paddingLeft={largeScreen ? 12 : 5}
			paddingBottom={5}
		>
			<Grid item xs={12} width="inherit">
				<Typography variant="h4" component="h1" textAlign="center">
					<FormattedMessage id="participantOnboardingPage.forms.companyLocation.title.normalTitle" />
					<Typography variant="h4" component="span" color="primary">
						<FormattedMessage id="participantOnboardingPage.forms.companyLocation.title.coloredTitle" />
					</Typography>
				</Typography>
			</Grid>
			<Grid item textAlign="center" width="inherit">
				<TextField
					InputLabelProps={{ shrink: true }}
					required
					name="city"
					label={intl.formatMessage({ id: 'participantOnboardingPage.forms.companyLocation.city.label' })}
					placeholder={intl.formatMessage({
						id: 'participantOnboardingPage.forms.companyLocation.city.placeholder',
					})}
					margin="normal"
					fullWidth
				/>
			</Grid>
			<Grid item textAlign="left" width="inherit">
				<CountryCodeField
					name="country_code"
					label={intl.formatMessage({
						id: 'participantOnboardingPage.forms.companyLocation.country',
					})}
				/>
			</Grid>
			<Grid item textAlign="center" width="inherit">
				<TextField
					InputLabelProps={{ shrink: true }}
					required
					name="zip_code"
					label={intl.formatMessage({ id: 'participantOnboardingPage.forms.companyLocation.zipCode.label' })}
					placeholder={intl.formatMessage({
						id: 'participantOnboardingPage.forms.companyLocation.zipCode.placeholder',
					})}
					margin="normal"
					fullWidth
				/>
			</Grid>
			<Grid item textAlign="center" width="inherit">
				<TextField
					InputLabelProps={{ shrink: true }}
					required
					name="street_name"
					label={intl.formatMessage({
						id: 'participantOnboardingPage.forms.companyLocation.streetName.label',
					})}
					placeholder={intl.formatMessage({
						id: 'participantOnboardingPage.forms.companyLocation.streetName.placeholder',
					})}
					margin="normal"
					fullWidth
				/>
			</Grid>
			<Grid item textAlign="center" width="inherit">
				<TextField
					InputLabelProps={{ shrink: true }}
					required
					name="street_number"
					label={intl.formatMessage({
						id: 'participantOnboardingPage.forms.companyLocation.houseNumber.label',
					})}
					placeholder={intl.formatMessage({
						id: 'participantOnboardingPage.forms.companyLocation.houseNumber.placeholder',
					})}
					margin="normal"
					fullWidth
				/>
			</Grid>
			<Grid item textAlign="center" width="inherit">
				<TextField
					InputLabelProps={{ shrink: true }}
					name="address_line_2"
					label={intl.formatMessage({
						id: 'participantOnboardingPage.forms.companyLocation.addressLine2.label',
					})}
					placeholder={intl.formatMessage({
						id: 'participantOnboardingPage.forms.companyLocation.addressLine2.placeholder',
					})}
					margin="normal"
					fullWidth
				/>
			</Grid>
			<Grid item textAlign="center" width="inherit">
				<RdvLoadingButton
					variant="contained"
					color="primary"
					type="submit"
					loading={loading}
					loadingPosition="center"
					fullWidth={largeScreen ? false : true}
				>
					<FormattedMessage id="participantOnboardingPage.forms.companyLocation.buttonText" />
				</RdvLoadingButton>
			</Grid>
		</Grid>
	);
};

export default AddCompanyLocationView;

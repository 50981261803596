export const PARTICIPANT_STATUS_KIND = {
	PENDING_EMAIL_VERIFICATION: {
		value: 'PENDING_EMAIL_VERIFICATION',
		label: 'Pending email verification',
	},
	ONBOARDING: {
		value: 'ONBOARDING',
		label: 'Onboarding',
	},
	ONBOARDED: {
		value: 'ONBOARDED',
		label: 'Onboarded',
	},

	REJECTED: {
		value: 'REJECTED',
		label: 'Rejected',
	},
};

import File from '@aquga/components/common/File';
import RequestChangeDialog from '@aquga/components/matchDialogs/RequestChangeDialog';
import AnswerMatchStatus from '@aquga/components/questions/status/AnswerMatchStatus';
import MatchStatus from '@aquga/components/questions/status/MatchStatus';
import { RdvFileFieldValue } from '@aquga/components/services/types';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppDispatch } from '@aquga/store/configureStore';
import { triggerManualMatchTransition } from '@aquga/store/slices/transactionSlice';
import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import RdvLoadingButton from './RdvLoadingButton';

interface AnswerDeliveredViewProps {
	solutionDocuments: RdvFileFieldValue[];
	transactionId: string;
	buttons?: boolean;
	status: string;
	requestedChangesFromMatch?: string;
	loading: boolean;
}

export const AnswerDeliveredView = ({
	solutionDocuments,
	transactionId,
	buttons = false,
	status,
	requestedChangesFromMatch,
	loading,
}: AnswerDeliveredViewProps) => {
	const dispatch = useAppDispatch();
	const [isRequestChangeDialogOpen, setRequestChangeDialogOpen] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	return (
		<>
			<RequestChangeDialog
				transactionId={transactionId}
				open={isRequestChangeDialogOpen}
				onClose={() => setRequestChangeDialogOpen(false)}
			/>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
				<AnswerMatchStatus status={status} loading={loading} />
				{loading ? (
					<Skeleton width={120} />
				) : (
					<Stack direction="column" spacing={2} mt={2} mb={2}>
						{solutionDocuments?.map((doc) => (
							<File key={doc?.id_file} url={doc?.url} fileName={doc?.file_name} />
						))}
					</Stack>
				)}
				{buttons && (
					<Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => setRequestChangeDialogOpen(true)}
							disabled={isSubmitting}
						>
							<FormattedMessage id="questions.details.answerDeliveredView.btnRequestChanges" />
						</Button>
						<RdvLoadingButton
							variant="contained"
							color="primary"
							loading={loading}
							onClick={() => {
								setIsSubmitting(true);
								dispatch(
									triggerManualMatchTransition({
										transition_tech_name:
											RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_DELIVERED.TRANSITIONS
												.CONSUMER_ACCEPTS_SOLUTION,
										id_transaction: transactionId,
									})
								);
							}}
						>
							<FormattedMessage id="questions.details.answerDeliveredView.btnSubmit" />
						</RdvLoadingButton>
					</Stack>
				)}
				{requestedChangesFromMatch && (
					<>
						<Typography variant="overline">
							<FormattedMessage id="questions.details.answerDeliveredView.requestedChanges.title" />
						</Typography>
						{loading ? (
							<Skeleton width={120} />
						) : (
							<Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
								{requestedChangesFromMatch}
							</Typography>
						)}
					</>
				)}
			</Stack>
		</>
	);
};

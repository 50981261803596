import File from '@aquga/components/common/File';
import ContractMatchStatus from '@aquga/components/questions/status/ContractMatchStatus';
import { Skeleton, Stack, Typography } from '@mui/material';

import { RdvFileFieldValue } from '../services/types';

interface ContractDeclinedViewProps {
	status: string;
	contract: RdvFileFieldValue;
	contractDeclinedReason: string;
	loading: boolean;
}

export const ContractDeclinedView = ({
	status,
	contract,
	contractDeclinedReason,
	loading,
}: ContractDeclinedViewProps) => {
	return (
		<Stack direction="column" spacing={2}>
			<ContractMatchStatus status={status} loading={loading} />
			{loading ? <Skeleton width={120} /> : <Typography variant="body1">{contractDeclinedReason}</Typography>}
			{loading ? (
				<Skeleton width={120} />
			) : (
				<File key={contract?.id_file} url={contract?.url} fileName={contract?.file_name} />
			)}
		</Stack>
	);
};

import { AQUGA_NATURAL_10, AQUGA_NATURAL_60, AQUGA_NATURAL_90 } from '@aquga/styles/theme';
import { Skeleton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { useIntl } from 'react-intl';

interface TabLabelProps {
	loading?: boolean;
	text: string;
	active: boolean;
	matchesCount?: number | undefined;
	showMatches?: boolean;
}
const TabLabel = ({ loading = false, text, active, matchesCount, showMatches = false }: TabLabelProps) => {
	return (
		<Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
			<Typography
				sx={{
					textTransform: 'none',
					color: active ? AQUGA_NATURAL_90 : AQUGA_NATURAL_60,
				}}
				variant="subtitle2"
			>
				{text}
			</Typography>
			{showMatches && matchesCount !== undefined && (
				<>
					{loading && <Skeleton variant="circular" width={24} height={24} />}
					{!loading && (
						<Avatar sx={{ bgcolor: 'primary.main', width: 24, height: 24, fontSize: '0.8rem' }}>
							{matchesCount}
						</Avatar>
					)}
				</>
			)}
		</Stack>
	);
};

interface QuestionTabsProps {
	loading: boolean;
	selected: 'overview' | 'description' | 'matches' | undefined;
	matchesCount: number | undefined;
	isBusiness: boolean;
	onSelect: (params: any) => any;
}
const QuestionTabs = ({ loading, selected = 'overview', matchesCount, isBusiness, onSelect }: QuestionTabsProps) => {
	const intl = useIntl();

	return (
		<Box sx={{ backgroundColor: AQUGA_NATURAL_10, pl: 1.5, pr: 1.5, borderRadius: 3 }}>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				sx={{ pl: 2, pr: 2, pb: 0, width: '100%' }}
			>
				<Tabs value={selected} variant="scrollable" allowScrollButtonsMobile>
					<Tab
						value="overview"
						label={
							<TabLabel
								text={intl.formatMessage({ id: 'questions.details.tabTitleOverview' })}
								active={selected === 'overview'}
							/>
						}
						onClick={() => onSelect('overview')}
					/>
					<Tab
						value="description"
						label={
							<TabLabel
								text={intl.formatMessage({ id: 'questions.details.tabTitleDescription' })}
								active={selected === 'description'}
							/>
						}
						onClick={() => onSelect('description')}
					/>
					{isBusiness && (
						<Tab
							value="matches"
							label={
								<TabLabel
									loading={loading}
									text={intl.formatMessage({ id: 'questions.details.tabTitleMatches' })}
									active={selected === 'matches'}
									matchesCount={matchesCount}
									showMatches
								/>
							}
							onClick={() => onSelect('matches')}
						/>
					)}
				</Tabs>
			</Stack>
		</Box>
	);
};

export default QuestionTabs;

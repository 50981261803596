import randevu from '@aquga/services/randevuApi';
import { isSessionExpired } from '@aquga/services/randevuApi/errors/errorHelper';
import { Field, FieldInput, User } from '@aquga/services/randevuApi/generatedTypes';
import { sleep } from '@aquga/services/sleep';
import { AppDispatch, RootState } from '@aquga/store/configureStore';
import { SHORT_SLEEP_LOADING_SPINNER } from '@aquga/store/lib';
import { requestReauthentication } from '@aquga/store/slices/authSlice';
import THUNKS from '@aquga/store/thunkKeys';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FieldsGroupKind = 'personalInfo' | 'companyInfo' | 'pointOfContact' | 'none';

interface ParticipantSliceState {
	loading: boolean;
	updatingParticipantFieldsGroup: FieldsGroupKind;
	me: any | undefined;
	error: any | undefined;
}

// Initial state for the slice
const initialState: ParticipantSliceState = {
	loading: false,
	me: undefined,
	error: undefined,
	updatingParticipantFieldsGroup: 'none',
};

// Actual Slice
export const participantSlice = createSlice({
	name: 'participant',
	initialState,
	reducers: {
		setCurrentParticipant: (state: ParticipantSliceState, action: PayloadAction<any>) => {
			state.loading = false;
			state.me = { ...state.me, ...action.payload };
		},
		removeCurrentParticipant: (state: ParticipantSliceState) => {
			state.loading = false;
			state.me = undefined;
		},
		loadCurrentParticipantRequested: (state: ParticipantSliceState) => {
			state.loading = false;
		},
		loadCurrentParticipantFailed: (state: ParticipantSliceState, action?: PayloadAction<any>) => {
			state.loading = false;
			state.error = action?.payload;
		},
		currentParticipantLoaded: (state: ParticipantSliceState, action: PayloadAction<any>) => {
			state.loading = false;
			state.me = { ...state.me, ...action.payload };
		},
		updateMyFieldsRequested: (state: ParticipantSliceState, action: PayloadAction<FieldsGroupKind>) => {
			state.loading = true;
			state.updatingParticipantFieldsGroup = action.payload;
		},
		updateMyFieldsFailed: (state: ParticipantSliceState, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload;
			state.updatingParticipantFieldsGroup = 'none';
		},
		myFieldsUpdated: (state: ParticipantSliceState, action: PayloadAction<FieldInput[]>) => {
			state.loading = false;
			state.error = undefined;
			const updatedFields = state.me.fields.map((field: Field) => {
				const foundField = action.payload.find(
					(updatedField: FieldInput) => field.field_type.tech_name === updatedField.tech_name
				);
				if (typeof foundField !== 'undefined') {
					field.value = foundField.value;
				}
				return field;
			});
			state.me.fields = updatedFields;
			state.updatingParticipantFieldsGroup = 'none';
		},
		onboardParticipantFieldsRequested: (state: ParticipantSliceState) => {
			state.loading = true;
		},
		onboardParticipantFieldsFailed: (state: ParticipantSliceState, action?: PayloadAction<any>) => {
			state.loading = false;
			state.error = action?.payload;
		},
		participantFieldsOnboarded: (state: ParticipantSliceState) => {
			state.loading = false;
			state.error = undefined;
		},
	},
});

export const {
	setCurrentParticipant,
	removeCurrentParticipant,
	loadCurrentParticipantRequested,
	loadCurrentParticipantFailed,
	currentParticipantLoaded,
	updateMyFieldsRequested,
	updateMyFieldsFailed,
	myFieldsUpdated,
	onboardParticipantFieldsRequested,
	onboardParticipantFieldsFailed,
	participantFieldsOnboarded,
} = participantSlice.actions;

export const loadCurrentParticipant = () => async (dispatch: AppDispatch, getState: () => RootState) => {
	dispatch(loadCurrentParticipantRequested());

	const randevuService = new randevu({ token: getState().auth.token });

	const { participant, errors } = await randevuService.participants.getCurrentParticipant();

	if (isSessionExpired(errors)) {
		dispatch(
			requestReauthentication({
				callbackThunkKey: THUNKS.LOAD_CURRENT_PARTICIPANT.key,
			})
		);
		return dispatch(loadCurrentParticipantFailed(undefined));
	}

	return dispatch(currentParticipantLoaded(participant));
};

interface UpdateMyFieldsRequest {
	fields: FieldInput[];
	fieldsGroup: FieldsGroupKind;
}

export const updateMyFields =
	({ fields, fieldsGroup }: UpdateMyFieldsRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(updateMyFieldsRequested(fieldsGroup));
			await sleep(SHORT_SLEEP_LOADING_SPINNER);
			const randevuService = new randevu({ token: getState().auth.token });

			const { errors } = await randevuService.participants.updateParticipant({
				id: getState().participant.me.id,
				fields,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.UPDATE_MY_FIELDS.key,
						payload: { fields, fieldsGroup },
					})
				);
				return dispatch(updateMyFieldsFailed(undefined));
			} else if (errors) {
				return dispatch(updateMyFieldsFailed(errors));
			}

			return dispatch(myFieldsUpdated(fields));
		};

interface OnboardParticipantFieldsRequest {
	fields: FieldInput[];
}

export const onboardParticipantFields =
	({ fields }: OnboardParticipantFieldsRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(onboardParticipantFieldsRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { errors } = await randevuService.participants.updateParticipant({
				id: getState().participant.me.id,
				fields,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.ONBOARD_PARTICIPANT_FIELDS.key,
						payload: { fields },
					})
				);
				return dispatch(onboardParticipantFieldsFailed(undefined));
			} else if (errors) {
				return dispatch(onboardParticipantFieldsFailed(errors));
			}

			// Wait before refetching onboarding fields to verify current user status;
			await sleep(2000);

			const { participant } = await randevuService.participants.getCurrentParticipant();

			dispatch(currentParticipantLoaded(participant));

			return dispatch(participantFieldsOnboarded());
		};

export const selectCurrentParticipant = (state: RootState) => state.participant.me;
export const selectLoading = (state: RootState) => state.participant.loading;
export const selectUpdatingParticipantFields = (state: RootState) => state.participant.updatingParticipantFieldsGroup;
export default participantSlice.reducer;

import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface RegisterFormBottomBar {
	label: string;
	href: string;
}

const RegisterFormBottomBar = ({ label, href }: RegisterFormBottomBar) => {
	return (
		<Link to={href} style={{ textDecoration: 'none' }}>
			<Typography
				fontWeight="bold"
				color="secondary"
				variant="body2"
				textAlign="center"
				sx={{
					'&:hover': {
						cursor: 'pointer',
					},
				}}
			>
				{label}
			</Typography>
		</Link>
	);
};

export default RegisterFormBottomBar;

import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import { ROUTES } from '@aquga/config/routes';
import { AQUGA_PURPLE_30 } from '@aquga/styles/theme';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, Grid, IconButton, InputAdornment, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { TextField } from 'mui-rff';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

export interface LoginFormValues {
	email: string;
	password: string;
}

interface LoginFormProps {
	loading: boolean;
	onValidate: (values: LoginFormValues) => LoginFormValues | Record<string, never>;
	onSubmit: (values: LoginFormValues) => any;
}

const LoginForm = ({ loading, onSubmit, onValidate }: LoginFormProps) => {
	const intl = useIntl();
	const [showPassword, setShowPassword] = useState(false);
	const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

	return (
		<Form
			onSubmit={onSubmit}
			validate={onValidate}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Grid
						container
						direction="column"
						alignItems="stretch"
						justifyContent="center"
						paddingRight={largeScreen ? 12 : 5}
						paddingLeft={largeScreen ? 12 : 5}
						spacing={4}
						textAlign="center"
					>
						<Grid item>
							<Typography variant="h4" component="h1">
								<FormattedMessage id="loginPage.form.title.normalTitle" />
								<Typography variant="h4" component="span" color={AQUGA_PURPLE_30}>
									<FormattedMessage id="loginPage.form.title.coloredTitle" />
								</Typography>
							</Typography>
						</Grid>
						<Grid item>
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								label={intl.formatMessage({
									id: 'loginPage.form.email.label',
								})}
								name="email"
								placeholder={intl.formatMessage({
									id: 'loginPage.form.email.placeholder',
								})}
								margin="normal"
								fullWidth
								type="email"
								disabled={loading}
							/>
						</Grid>
						<Grid item>
							<Stack alignItems="flex-end" spacing={1}>
								<TextField
									fullWidth
									disabled={loading}
									name="password"
									InputLabelProps={{ shrink: true }}
									required
									label={intl.formatMessage({
										id: 'loginPage.form.password.label',
									})}
									placeholder={intl.formatMessage({
										id: 'loginPage.form.password.placeholder',
									})}
									autoComplete="current-password"
									type={showPassword ? 'text' : 'password'}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => setShowPassword(!showPassword)}
													onMouseDown={() => setShowPassword(!showPassword)}
												>
													{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<Button component={Link} to={ROUTES.FORGOT_PASSWORD} variant="text" color="secondary">
									<FormattedMessage id="loginPage.form.forgotPassword" />
								</Button>
							</Stack>
						</Grid>
						<Grid item>
							<RdvLoadingButton
								variant="contained"
								color="primary"
								type="submit"
								loading={loading}
								loadingPosition="center"
								fullWidth={largeScreen ? false : true}
							>
								<FormattedMessage id="loginPage.form.buttonText" />
							</RdvLoadingButton>
						</Grid>
					</Grid>
				</form>
			)}
		/>
	);
};

export default LoginForm;

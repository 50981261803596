import { BASE_ONBOARDING_STEP, SECURITY_FORMS_MAX_WIDTH } from '@aquga/app/constants';
import { isBusinessSide, mapOnboardingFormValuesToUpdateMyFieldsRequest } from '@aquga/app/participantHelpers';
import { validator } from '@aquga/app/validators';
import ParticipantOnboardingForm, {
	OnboardingFormValues,
} from '@aquga/components/onboarding/ParticipantOnboardingForm';
import AccountReviewView from '@aquga/components/register/AccountReviewView';
import SecurityPageLayout from '@aquga/components/security/SecurityPageLayout';
import SecurityPageTopBar from '@aquga/components/security/SecurityPageTopBar';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { ROUTES } from '@aquga/config/routes';
import { ParticipantStatusKind } from '@aquga/services/randevuApi/generatedTypes';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import {
	loadCurrentParticipant,
	onboardParticipantFields,
	onboardParticipantFieldsFailed,
	selectCurrentParticipant,
} from '@aquga/store/slices/participantSlice';
import { AQUGA_LOGIN_GRAY } from '@aquga/styles/theme';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

const ParticipantOnboardingPage = () => {
	const intl = useIntl();
	const [currentStep, setCurrentStep] = useState(BASE_ONBOARDING_STEP);
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const onboardingState = currentParticipant?.onboarding_progress?.status_tech_name;
	const userType = isBusinessSide(currentParticipant)
		? RANDEVU_IDS.USER_TYPE_CUSTOMER
		: RANDEVU_IDS.USER_TYPE_CONSULTING_HOUSE;
	const metaTitle = `${intl.formatMessage({ id: 'participantOnboardingPage.metaTitle' })} - AQUGA`;

	useEffect(() => {
		dispatch(loadCurrentParticipant());
	}, [dispatch]);

	const handleSubmitOnboarding = async (values: OnboardingFormValues, form: any) => {
		const fieldValues = mapOnboardingFormValuesToUpdateMyFieldsRequest({ ...values }, userType);

		const resolvedAction = await dispatch(onboardParticipantFields({ fields: fieldValues }));

		if (resolvedAction.type === onboardParticipantFieldsFailed.type) {
			return resolvedAction.payload;
		}

		form.reset();
	};

	const handleValidateOnboardingStepForm = (values: OnboardingFormValues) => {
		const stepFieldValidators = [
			{
				vat_id: [
					(value: any) =>
						validator.required({
							value,
							errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
						}),
				],
				company_name: [
					(value: any) =>
						validator.required({
							value,
							errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
						}),
					(value: string) =>
						validator.minLength({
							value,
							min: 3,
							errorMsg: intl.formatMessage({ id: 'validation.general.minLengthThreeCharacters' }),
						}),
				],
				industries: [
					(value: any) =>
						validator.required({
							value,
							errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
						}),
				],
			},
			{
				city: [
					(value: any) =>
						validator.required({
							value,
							errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
						}),
				],
				zip_code: [
					(value: any) =>
						validator.required({
							value,
							errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
						}),
				],
				country_code: [
					(value: any) =>
						validator.required({
							value,
							errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
						}),
				],
				street_name: [
					(value: any) =>
						validator.required({
							value,
							errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
						}),
				],
				street_number: [
					(value: any) =>
						validator.required({
							value,
							errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
						}),
				],
			},
			{
				contact_email: [
					(value: any) =>
						validator.required({
							value,
							errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
						}),
					(value: any) =>
						validator.isEmail({
							value,
							errorMsg: intl.formatMessage({ id: 'validation.general.invalidEmail' }),
						}),
				],
				contact_person: [
					(value: any) =>
						validator.required({
							value,
							errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
						}),
					(value: string) =>
						validator.minLength({
							value,
							min: 2,
							errorMsg: intl.formatMessage({ id: 'validation.general.minLengthThreeCharacters' }),
						}),
				],
				phone_number: [
					(value: any) =>
						validator.required({
							value,
							errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
						}),
					(value: string) =>
						validator.minLength({
							value,
							min: 5,
							errorMsg: intl.formatMessage({ id: 'validation.general.minLengthFiveCharacters' }),
						}),
				],
			},
		];

		return validator.doValidation(stepFieldValidators[currentStep - BASE_ONBOARDING_STEP], values);
	};

	useEffect(() => {
		if (ParticipantStatusKind.Rejected === currentParticipant?.status) {
			navigate(ROUTES.PARTICIPANT_REJECTED);
			return;
		}

		if (ParticipantStatusKind.Onboarded === currentParticipant?.status) {
			navigate(ROUTES.DASHBOARD);
		}
		setCurrentStep(
			Object.values(userType.ONBOARDING.STATE_TYPES).indexOf(
				currentParticipant?.onboarding_progress?.status_tech_name
			) + BASE_ONBOARDING_STEP
		);
	}, [currentParticipant, navigate, dispatch]);

	return (
		<>
			<Helmet>
				<title>{metaTitle}</title>
			</Helmet>
			<SecurityPageLayout backgroundColor={AQUGA_LOGIN_GRAY}>
				<Grid
					container
					direction="column"
					justifyContent="center"
					maxWidth={SECURITY_FORMS_MAX_WIDTH}
					marginLeft="auto"
					marginRight="auto"
				>
					{onboardingState === userType.ONBOARDING.STATE_TYPES.PENDING_APPROVAL ? (
						<Grid item>
							<AccountReviewView />
						</Grid>
					) : Object.values(userType.ONBOARDING.STATE_TYPES).includes(onboardingState) ? (
						<>
							<Grid item xs={12} paddingLeft={1} marginBottom={5}>
								<SecurityPageTopBar noButton={true} step={currentStep} />
							</Grid>
							<Grid item>
								<ParticipantOnboardingForm
									onValidate={handleValidateOnboardingStepForm}
									onSubmit={handleSubmitOnboarding}
									onboardingState={onboardingState}
								/>
							</Grid>
						</>
					) : (
						<></>
					)}
				</Grid>
			</SecurityPageLayout>
		</>
	);
};
export default ParticipantOnboardingPage;

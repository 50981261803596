import React from 'react';
import { Field } from 'react-final-form';

interface ConditionallyRenderProps {
	when: string;
	is?: any;
	isNot?: string;
	isOneOf?: string[];
	isDefined?: any;
	contains?: string;
	children: React.ReactNode;
}
const ConditionallyRender = ({ when, is, isNot, isOneOf, isDefined, contains, children }: ConditionallyRenderProps) => {
	if (is !== undefined)
		return (
			<Field name={when} subscription={{ value: true }}>
				{({ input: { value } }) => (value === is ? children : null)}
			</Field>
		);

	if (isDefined)
		return (
			<Field name={when} subscription={{ value: true }}>
				{({ input: { value } }) => (value ? children : null)}
			</Field>
		);

	if (isNot !== undefined)
		return (
			<Field name={when} subscription={{ value: true }}>
				{({ input: { value } }) => (value === isNot ? null : children)}
			</Field>
		);

	if (isOneOf !== undefined)
		return (
			<Field name={when} subscription={{ value: true }}>
				{({ input: { value } }) => (isOneOf?.includes(value) ? children : null)}
			</Field>
		);
	if (contains !== undefined)
		return (
			<Field name={when} subscription={{ value: true }}>
				{({ input: { value } }) => (value?.includes(contains) ? children : null)}
			</Field>
		);
	return <></>;
};

export default ConditionallyRender;

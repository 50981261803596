import { ASSETS, EXTERNAL_URLS, MAIN_LAYOUT_TOP_SPACING } from '@aquga/app/constants';
import { isBusinessSide } from '@aquga/app/participantHelpers';
import AnsweringExpertiseLogo from '@aquga/components/common/AnsweringExpertiseLogo';
import AqugaDashboardLogo from '@aquga/components/common/AqugaDashboardLogo';
import { ROUTES } from '@aquga/config/routes';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import { selectCurrentLanguage } from '@aquga/store/slices/uiSlice';
import { AQUGA_BACKGROUND_COLOR } from '@aquga/styles/theme';
import CloseIcon from '@mui/icons-material/Close';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import { IconButton, SvgIcon, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import CurrentParticipantMenu from './CurrentParticipantMenu';

interface MainNavigationProps {
	mobile?: boolean | undefined;
	onOpenLanguageDialog: (params: any) => any;
	onSignOut: (params: any) => any;
	onClose?: (params: any) => any | undefined;
}

const StyledList = styled(List)({
	// selected and (selected + hover) states
	'&& .Mui-selected, && .Mui-selected:hover': {
		backgroundColor: 'white',
		borderRadius: 12,
	},
	// hover states
	'& .MuiListItemButton-root:hover': {
		backgroundColor: 'white',
		borderRadius: 12,
	},
});

const MainNavigation = ({ mobile, onOpenLanguageDialog, onSignOut, onClose }: MainNavigationProps) => {
	const intl = useIntl();
	const theme = useTheme();
	const currentLanguage = useAppSelector(selectCurrentLanguage);
	let currentLanguageLabel = intl.formatMessage({ id: 'langs.en' });

	if (currentLanguage?.includes('DE')) currentLanguageLabel = intl.formatMessage({ id: 'langs.de' });

	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const isBusiness = isBusinessSide(currentParticipant);

	const determineIsActive = (route: string) => {
		return location.pathname?.includes(route?.split('?')[0]);
	};

	return (
		<>
			{mobile && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						pl: 2,
						pr: 2,
						pt: 2,
						backgroundColor: AQUGA_BACKGROUND_COLOR,
					}}
				>
					<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
						<Typography
							color="textSecondary"
							variant="body2"
							fontWeight="bold"
							sx={{ textTransform: 'uppercase' }}
						>
							<FormattedMessage id="navigation.menu" />
						</Typography>

						<IconButton onClick={onClose}>
							<CloseIcon sx={{ color: 'black' }} />
						</IconButton>
					</Stack>
				</Box>
			)}

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					pl: 2,
					pr: 2,
					pt: MAIN_LAYOUT_TOP_SPACING,
					backgroundColor: AQUGA_BACKGROUND_COLOR,
				}}
			>
				<CurrentParticipantMenu />

				<StyledList>
					<ListItem disablePadding>
						<ListItemButton
							component={Link}
							to={ROUTES.DASHBOARD}
							selected={determineIsActive(ROUTES.DASHBOARD)}
						>
							<ListItemIcon>
								<AqugaDashboardLogo
									fill={determineIsActive(ROUTES.DASHBOARD) ? theme.palette.primary.main : 'black'}
								/>
							</ListItemIcon>
							<ListItemText
								primary={intl.formatMessage({ id: 'navigation.dashboard' })}
								primaryTypographyProps={{ variant: 'body2' }}
							/>
						</ListItemButton>
					</ListItem>

					<ListItem disablePadding>
						<ListItemButton
							component={Link}
							to={ROUTES.TICKETS_W_FILTER_ACTIVE}
							selected={determineIsActive(ROUTES.TICKETS_W_FILTER_ACTIVE)}
						>
							<ListItemIcon>
								{
									<ViewAgendaIcon
										sx={{
											color: determineIsActive(ROUTES.TICKETS_W_FILTER_ACTIVE)
												? 'primary'
												: 'black',
										}}
										color="primary"
									/>
								}
							</ListItemIcon>
							<ListItemText
								primary={intl.formatMessage({ id: 'navigation.consumer.tickets' })}
								primaryTypographyProps={{ variant: 'body2' }}
							/>
						</ListItemButton>
					</ListItem>
					{!isBusiness && (
						<ListItem disablePadding>
							<ListItemButton
								component={Link}
								to={ROUTES.LIST_SERVICES}
								selected={determineIsActive(ROUTES.LIST_SERVICES)}
							>
								<ListItemIcon>
									<AnsweringExpertiseLogo
										fill={
											determineIsActive(ROUTES.LIST_SERVICES)
												? theme.palette.primary.main
												: 'black'
										}
									/>
								</ListItemIcon>
								<ListItemText
									primary={intl.formatMessage({ id: 'navigation.answeringExpertise' })}
									primaryTypographyProps={{ variant: 'body2' }}
								/>
							</ListItemButton>
						</ListItem>
					)}

					<ListItem disablePadding>
						<ListItemButton
							component={Link}
							to={ROUTES.CONTRACTS}
							selected={determineIsActive(ROUTES.CONTRACTS)}
						>
							<ListItemIcon>
								{
									<FileCopyIcon
										sx={{
											color: determineIsActive(ROUTES.CONTRACTS) ? 'primary' : 'black',
										}}
										color="primary"
									/>
								}
							</ListItemIcon>
							<ListItemText
								primary={intl.formatMessage({ id: 'navigation.contracts' })}
								primaryTypographyProps={{ variant: 'body2' }}
							/>
						</ListItemButton>
					</ListItem>
				</StyledList>
				<Box sx={{ flexGrow: 1 }} />
				<Divider />
				<StyledList>
					<ListItem disablePadding>
						<ListItemButton
							component={Link}
							to={ROUTES.PARTICIPANT_ACCOUNT}
							selected={determineIsActive(ROUTES.PARTICIPANT_ACCOUNT)}
						>
							<ListItemIcon>
								<PersonIcon
									sx={{
										color: determineIsActive(ROUTES.PARTICIPANT_ACCOUNT) ? 'primary' : 'black',
									}}
									color="primary"
								/>
							</ListItemIcon>
							<ListItemText
								primary={intl.formatMessage({ id: 'common.account' })}
								primaryTypographyProps={{ variant: 'body2' }}
							/>
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton onClick={onOpenLanguageDialog}>
							<ListItemIcon>
								<LanguageIcon sx={{ color: 'black' }} />
							</ListItemIcon>

							<ListItemText
								primary={currentLanguageLabel}
								primaryTypographyProps={{ variant: 'body2', textTransform: 'uppercase' }}
							/>
						</ListItemButton>
					</ListItem>

					<ListItem disablePadding>
						<ListItemButton
							component="a"
							href={EXTERNAL_URLS.AQUGA_PRIVACY_POLICY}
							target="_blank"
							rel="noopener noreferrer"
						>
							<ListItemIcon>
								<TextSnippetOutlinedIcon sx={{ color: 'black' }} />
							</ListItemIcon>
							<ListItemText
								primary={intl.formatMessage({ id: 'common.privacyPolicy' })}
								primaryTypographyProps={{ variant: 'body2' }}
							/>
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton
							component="a"
							href={EXTERNAL_URLS.AQUGA_TERMS_OF_SERVICE}
							target="_blank"
							rel="noopener noreferrer"
						>
							<ListItemIcon>
								<GppMaybeOutlinedIcon sx={{ color: 'black' }} />
							</ListItemIcon>
							<ListItemText
								primary={intl.formatMessage({ id: 'common.termsOfService' })}
								primaryTypographyProps={{ variant: 'body2' }}
							/>
						</ListItemButton>
					</ListItem>
				</StyledList>
				<Divider />
				<StyledList>
					<ListItem disablePadding onClick={onSignOut}>
						<ListItemButton>
							<ListItemIcon>
								<LogoutIcon sx={{ color: 'black' }} />
							</ListItemIcon>
							<ListItemText
								primary={intl.formatMessage({ id: 'common.btnSignOut' })}
								primaryTypographyProps={{ variant: 'body2' }}
							/>
						</ListItemButton>
					</ListItem>
					<ListItem>
						<ListItemText
							primaryTypographyProps={{ variant: 'caption' }}
							primary={intl.formatMessage({ id: 'navigation.productOwner' })}
						/>
					</ListItem>
				</StyledList>
			</Box>
		</>
	);
};

export default MainNavigation;

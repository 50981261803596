import { SECURITY_FORMS_MAX_WIDTH } from '@aquga/app/constants';
import { decodeToJSONObject } from '@aquga/app/helpers';
import { validator } from '@aquga/app/validators';
import ChoosePasswordForm, { ChoosePasswordFormValues } from '@aquga/components/register/ChoosePasswordForm';
import SecurityPageLayout from '@aquga/components/security/SecurityPageLayout';
import SecurityPageTopBar from '@aquga/components/security/SecurityPageTopBar';
import { ROUTES } from '@aquga/config/routes';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import {
	loginParticipant,
	verifyParticipantAccount,
	verifyParticipantAccountFailed,
} from '@aquga/store/slices/authSlice';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import { AQUGA_BLUE_30, themeBusinessSide, themeConsultingSide } from '@aquga/styles/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

const AccountActivationPage = () => {
	const intl = useIntl();
	const dispatch = useAppDispatch();
	const metaTitle = `${intl.formatMessage({ id: 'accountActivationPage.metaTitle' })} - AQUGA`;
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const participantSide = searchParams.get('participantSide');
	const theme = participantSide === 'business' ? themeBusinessSide : themeConsultingSide;
	const data = searchParams?.get('data');
	const { token, email } = decodeToJSONObject(data);

	useEffect(() => {
		if ([data, token, email, participantSide].includes(undefined)) {
			navigate(ROUTES.SIGN_IN_PAGE);
		}
	}, []);

	useEffect(() => {
		if (currentParticipant) {
			navigate(ROUTES.PARTICIPANT_ONBOARDING);
		}
	}, [currentParticipant, navigate]);

	const handleValidateAccountActivationForm = (values: ChoosePasswordFormValues) => {
		const fieldValidators = {
			password: [
				(value: any) =>
					validator.isPasswordCompliant({
						value,
						errorMsg: '',
					}),
			],
		};

		return validator.doValidation(fieldValidators, values);
	};

	const handleSubmitAccountActivationForm = async (values: ChoosePasswordFormValues) => {
		const resolvedAction = await dispatch(
			verifyParticipantAccount({
				password: values.password,
				token: token,
			})
		);

		if (resolvedAction?.type === verifyParticipantAccountFailed.type) {
			const submissionErrors = { ...resolvedAction.payload };
			// TODO: write nice helper function which does this one
			Object.entries(submissionErrors).map((keyValue) => {
				submissionErrors[keyValue[0]] = intl.formatMessage({ id: keyValue[1] as string });
			});
			return submissionErrors;
		}

		await dispatch(loginParticipant({ email: email, password: values.password }));
	};

	return (
		<>
			<Helmet>
				<title>{metaTitle}</title>
			</Helmet>
			<SecurityPageLayout backgroundColor={AQUGA_BLUE_30}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Grid
						container
						justifyContent="center"
						maxWidth={SECURITY_FORMS_MAX_WIDTH}
						marginLeft="auto"
						marginRight="auto"
					>
						<Grid item xs={12} paddingLeft={2} marginBottom={3}>
							<SecurityPageTopBar noButton={true} step={3} />
						</Grid>
						<ChoosePasswordForm
							normalTitle={intl.formatMessage({ id: 'registerPage.activateAccount.title.normal' })}
							coloredTitle={intl.formatMessage({ id: 'registerPage.activateAccount.title.colored' })}
							onSubmit={handleSubmitAccountActivationForm}
							onValidate={handleValidateAccountActivationForm}
						/>
					</Grid>
				</ThemeProvider>
			</SecurityPageLayout>
		</>
	);
};
export default AccountActivationPage;

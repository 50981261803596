import { ASSETS } from '@aquga/app/constants';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const AccountReviewView = () => {
	return (
		<Grid
			container
			direction="column"
			textAlign="center"
			justifyContent="center"
			alignItems="center"
			width="100%"
			paddingTop={3}
			spacing={2}
		>
			<Stack spacing={2}>
				{/* <Grid
					item
					sx={{
						backgroundColor: 'inherit',
						backgroundImage: `url(${ASSETS.ACCOUNT_REVIEW_GAVEL})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'contain',
						backgroundPosition: 'center',
						minHeight: { xs: '50vW', md: '15vW' },
						minWidth: { xs: '30vW', md: '20vW' },
					}}
				/> */}
				<Typography component="h1" variant="h4">
					<FormattedMessage id="registerPage.accountReview.title.normal" />{' '}
					<Typography component="span" color="primary" variant="h4">
						<FormattedMessage id="registerPage.accountReview.title.colored" />
					</Typography>
				</Typography>
				<Typography variant="subtitle2">
					<FormattedMessage id="registerPage.accountReview.subtitle" />
				</Typography>
				<Typography variant="body1" maxWidth="34rem" sx={{ wordWrap: 'break-word' }}>
					<FormattedMessage id="registerPage.accountReview.bulletPoint1" />
				</Typography>
				<Typography variant="body1">
					<FormattedMessage id="registerPage.accountReview.bulletPoint2" />
				</Typography>
			</Stack>
		</Grid>
	);
};

export default AccountReviewView;

import validator from '@aquga/app/validators';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';

interface SendMessageFormValues {
	message: string;
}

interface SendMessageDialogProps {
	open: boolean;
	onClose: any;
}

const SendMessageDialog = ({ open, onClose }: SendMessageDialogProps) => {
	const intl = useIntl();
	const handleSubmit = async (values: SendMessageFormValues) => {
		console.log(values);
	};

	const fieldValidators = {
		message: [
			(value: string) =>
				validator.required({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
				}),
		],
	};

	const handleValidate = async (values: SendMessageFormValues) => {
		return validator.doValidation(fieldValidators, values);
	};

	return (
		<Dialog
			aria-labelledby="send-message-dialog"
			open={open}
			fullWidth
			maxWidth="sm"
			BackdropProps={{
				sx: {
					backdropFilter: 'blur(5px)',
					backgroundColor: 'rgba(0,0,30,0.5)',
				},
			}}
		>
			<DialogTitle id="send-message-dialog-title">
				<Stack direction="column" spacing={2} justifyContent="flex-start">
					<Typography variant="h6" fontWeight={700}>
						Send a message
					</Typography>
					<Typography variant="body1">
						Schedule below a meeting proposal with your matched respondent
					</Typography>
				</Stack>
			</DialogTitle>
			<Form
				onSubmit={handleSubmit}
				validate={handleValidate}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit} noValidate>
						<DialogContent>
							<Stack
								direction="column"
								alignItems="stretch"
								justifyContent="center"
								spacing={2}
								textAlign="left"
							>
								<TextField
									name="message"
									label="Description"
									placeholder="Add a personalized message"
									required
									multiline
									rows={5}
									sx={{ height: 'auto ' }}
									margin="none"
								/>
								<Stack direction="row" justifyContent="flex-end" spacing={1}>
									<Button variant="outlined" onClick={onClose()} size="large">
										Cancel
									</Button>
									<Button type="submit" color="primary" variant="contained">
										Send message
									</Button>
								</Stack>
							</Stack>
						</DialogContent>
					</form>
				)}
			/>
		</Dialog>
	);
};

export default SendMessageDialog;

import { Button, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

export interface ForgetPasswordFormValues {
	email: string;
}

interface ForgotPasswordFormProps {
	onSubmit: (values: ForgetPasswordFormValues) => any;
	onValidate: (values: ForgetPasswordFormValues) => ForgetPasswordFormValues | Record<string, never>;
}

const ForgotPasswordForm = ({ onSubmit, onValidate }: ForgotPasswordFormProps) => {
	const intl = useIntl();

	const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

	return (
		<Form
			onSubmit={onSubmit}
			validate={onValidate}
			render={({ handleSubmit, pristine }) => (
				<form onSubmit={handleSubmit} noValidate={true}>
					<Grid
						container
						direction="column"
						alignItems="center"
						justifyContent="center"
						gap={4}
						paddingRight={7}
						paddingLeft={7}
						textAlign="center"
					>
						<Grid item>
							<Typography variant="h4" component="h1">
								<FormattedMessage id="forgotPasswordPage.title.normalTitle" />
								<Typography variant="h4" component="span" color="primary">
									<FormattedMessage id="forgotPasswordPage.title.coloredTitle" />
								</Typography>
							</Typography>
						</Grid>
						<Grid item textAlign="center" width="inherit">
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								name="email"
								type="email"
								label={intl.formatMessage({
									id: 'forgotPasswordPage.form.email.label',
								})}
								placeholder={intl.formatMessage({
									id: 'forgotPasswordPage.form.email.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item textAlign="center" width="inherit">
							<Button
								variant="contained"
								disabled={pristine}
								type="submit"
								fullWidth={largeScreen ? false : true}
							>
								<FormattedMessage id="forgotPasswordPage.btnRequestPasswordReset" />
							</Button>
						</Grid>
					</Grid>
				</form>
			)}
		/>
	);
};

export default ForgotPasswordForm;

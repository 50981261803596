import { SupportedLocaleKeys } from '@aquga/i18n/messages';
import type { RootState } from '@aquga/store/configureStore';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UiSliceState {
	loading: boolean;
	language: SupportedLocaleKeys;
}

// Initial state for the slice
const initialState: UiSliceState = {
	loading: false,
	language: 'de-DE',
};

// Actual Slice
export const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		setLanguage: (state: UiSliceState, action: PayloadAction<SupportedLocaleKeys>) => {
			state.loading = false;
			state.language = action.payload;
		},
	},
});

export const { setLanguage } = uiSlice.actions;

export const selectCurrentLanguage = (state: RootState): SupportedLocaleKeys => state.ui.language;

export default uiSlice.reducer;

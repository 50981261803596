import NotificationsIcon from '@mui/icons-material/Notifications';
import { Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { Stack } from '@mui/system';

interface PageHeaderProps {
	title: string;
	color?: 'default' | 'primary' | 'secondary' | undefined;
}

const PageHeader = ({ title, color = 'primary' }: PageHeaderProps) => {
	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
				<Typography component="h1" variant="h4">
					{title}
				</Typography>
				{/* <IconButton>
					<Badge badgeContent={4} color={color}>
						<NotificationsIcon fontSize="small" />
					</Badge>
				</IconButton> */}
			</Stack>
		</>
	);
};

export default PageHeader;

import PersonalInfoForm, { PersonalInfoFormValues } from '@aquga/components/participantAccount/PersonalInfoForm';
import { Field } from '@aquga/services/randevuApi/generatedTypes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

interface PersonalInfoSectionProps {
	loading: boolean;
	participantFields: Field[];
}

const PersonalInfoSection = ({ participantFields, loading }: PersonalInfoSectionProps) => {
	const intl = useIntl();
	return (
		<Accordion
			sx={{
				borderRadius: '12px !important',
				boxShadow: 'none',
				'&:before': {
					display: 'none',
				},
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="subtitle2">
					<FormattedMessage id="personalInfo.title" />
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<PersonalInfoForm loading={loading} participantFields={participantFields} />
			</AccordionDetails>
		</Accordion>
	);
};

export default PersonalInfoSection;

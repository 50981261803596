import { ASSETS, LAYOUT_PADDING, MOBILE_APPBAR_HEIGHT } from '@aquga/app/constants';
import LanguagePicker from '@aquga/components/layout/nav/LanguagePicker';
import { ROUTES } from '@aquga/config/routes';
import { useAppDispatch } from '@aquga/store/configureStore';
import { logoutParticipant } from '@aquga/store/slices/authSlice';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { Box, styled } from '@mui/system';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

const StyledToolbar = styled(Toolbar)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: LAYOUT_PADDING,
});

function ProtectedNavbar() {
	const [mobileOpen, setMobileOpen] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const isNotSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const handleSignOut = async () => {
		await dispatch(logoutParticipant());
		navigate(ROUTES.SIGN_IN_PAGE);
	};

	return (
		<>
			<AppBar
				elevation={0}
				component="nav"
				position="sticky"
				sx={{
					background: 'white',
					height: { xs: MOBILE_APPBAR_HEIGHT, sm: MOBILE_APPBAR_HEIGHT, md: 'inherit' },
				}}
			>
				<StyledToolbar disableGutters>
					<Grid
						container
						direction="row"
						spacing={2}
						justifyContent={isNotSmallScreen ? 'space-between' : 'center'}
						width="100%"
					>
						<Grid item>
							<Link to={ROUTES.SIGN_IN_PAGE}>
								<img alt="AQUGA Logo" style={{ width: 200, height: 50 }} src={ASSETS.AQUGA_LOGO_MAIN} />
							</Link>
						</Grid>
						<Grid item sx={{ display: isNotSmallScreen ? 'block' : 'none' }}>
							<LanguagePicker />
							<Button variant="outlined" color="secondary" onClick={handleSignOut}>
								<FormattedMessage id="common.btnSignOut" />
							</Button>
						</Grid>
					</Grid>
				</StyledToolbar>
			</AppBar>
		</>
	);
}

export default ProtectedNavbar;

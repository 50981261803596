export interface UserTypeDefinition {
	TECH_NAME: string;
	FIELDS: object;
	ONBOARDING: object;
}

export const RANDEVU_IDS = {
	USER_TYPE_CUSTOMER: {
		TECH_NAME: 'business',
		ONBOARDING: {
			STATE_TYPES: {
				COMPANY_INFORMATION: 'company_information',
				LOCATION_INFORMATION: 'location_information',
				CONTACT_INFORMATION: 'contact_information',
				PENDING_APPROVAL: 'pending_approval',
				REJECTED: 'rejected_by_bo',
				ONBOARDED: 'onboarded',
			},
		},
		FIELDS: {
			COMPANY_NAME: {
				TECH_NAME: 'company_name',
			},
			INDUSTRIES: {
				TECH_NAME: 'industries',
				VALUES: {
					AUTOMOTIVE: 'automotive',
					MECHANICAL_ELECTRICAL_ENGINEERING: 'mechanical_electrical_engineering',
					CHEMISTRY_PHARMACEUTICALS: 'chemistry_pharmaceuticals',
					CONSUMER: 'consumer',
					TRADE_RETAIL: 'trade_retail',
					LOGISTICS: 'logistics',
					SERVICE: 'service',
					BANKING_INSURANCE: 'banking_insurance',
					ENERGY: 'energy',
					AEROSPACE_AND_DEFENSE: 'aerospace_and_defense',
					OTHER: 'other',
				},
			},
			VAT_ID: {
				TECH_NAME: 'vat_id',
			},
			FIRST_NAME: {
				TECH_NAME: 'first_name',
			},
			LAST_NAME: {
				TECH_NAME: 'last_name',
			},
			PROFILE_IMAGE: {
				TECH_NAME: 'profile_image',
			},
			CONTACT_PERSON: {
				TECH_NAME: 'contact_person',
			},
			CONTACT_EMAIL: {
				TECH_NAME: 'contact_email',
			},
			PHONE_NUMBER: {
				TECH_NAME: 'phone_number',
			},
			CITY: {
				TECH_NAME: 'city',
			},
			COUNTRY: {
				TECH_NAME: 'country_code',
			},
			ZIP_CODE: {
				TECH_NAME: 'zip_code',
			},
			STREET_NAME: {
				TECH_NAME: 'street_name',
			},
			STREET_NUMBER: {
				TECH_NAME: 'street_number',
			},
			ADDRESS_LINE_2: {
				TECH_NAME: 'address_line_2',
			},
		},
	},
	USER_TYPE_CONSULTING_HOUSE: {
		TECH_NAME: 'consultant',
		ONBOARDING: {
			STATE_TYPES: {
				COMPANY_INFORMATION: 'company_information',
				LOCATION_INFORMATION: 'location_information',
				CONTACT_INFORMATION: 'contact_information',
				PENDING_APPROVAL: 'pending_approval',
				REJECTED: 'rejected_by_bo',
				ONBOARDED: 'onboarded',
			},
		},
		FIELDS: {
			COMPANY_NAME: {
				TECH_NAME: 'company_name',
			},
			INDUSTRIES: {
				TECH_NAME: 'industries',
				VALUES: {
					AUTOMOTIVE: 'automotive',
					MECHANICAL_ELECTRICAL_ENGINEERING: 'mechanical_electrical_engineering',
					CHEMISTRY_PHARMACEUTICALS: 'chemistry_pharmaceuticals',
					CONSUMER: 'consumer',
					TRADE_RETAIL: 'trade_retail',
					LOGISTICS: 'logistics',
					SERVICE: 'service',
					BANKING_INSURANCE: 'banking_insurance',
					ENERGY: 'energy',
					AEROSPACE_AND_DEFENSE: 'aerospace_and_defense',
				},
			},
			VAT_ID: {
				TECH_NAME: 'vat_id',
			},
			FIRST_NAME: {
				TECH_NAME: 'first_name',
			},
			LAST_NAME: {
				TECH_NAME: 'last_name',
			},
			PROFILE_IMAGE: {
				TECH_NAME: 'profile_image',
			},
			CONTACT_PERSON: {
				TECH_NAME: 'contact_person',
			},
			CONTACT_EMAIL: {
				TECH_NAME: 'contact_email',
			},
			PHONE_NUMBER: {
				TECH_NAME: 'phone_number',
			},
			CITY: {
				TECH_NAME: 'city',
			},
			COUNTRY: {
				TECH_NAME: 'country_code',
			},
			ZIP_CODE: {
				TECH_NAME: 'zip_code',
			},
			STREET: {
				TECH_NAME: 'street_name',
			},
			STREET_NUMBER: {
				TECH_NAME: 'street_number',
			},
			ADDRESS_LINE_2: {
				TECH_NAME: 'address_line_2',
			},
		},
	},
	SUPPLY_TYPE_CONSULTING_SERVICE: {
		TECH_NAME: 'consulting_expertise',
		FIELDS: {
			BUSINESS_AREA: {
				TECH_NAME: 'business_area',
				VALUES: {
					SALES_CRM: 'sales_crm',
					LOGISTICS: 'logistics',
					FINANCIALS: 'financials',
					PRODUCTION_QM: 'production_qm',
					PURCHASING: 'purchasing',
					WAREHOUSE: 'warehouse',
					PLANNING: 'planning',
					ANALYTICS: 'analytics',
					HCM: 'hcm',
					OTHER: 'other',
				},
			},
			BUSINESS_AREA_OTHER: {
				TECH_NAME: 'business_area_other',
			},
			TECHNOLOGY_KINDS: {
				TECH_NAME: 'technology_kind',
				VALUES: {
					SAP: 'sap',
					MICROSOFT_ERP: 'microsoft_erp',
					OTHER: 'other',
				},
			},
			TECHNOLOGY_KIND_OTHER: {
				TECH_NAME: 'technology_kind_other',
			},
			TITLE: {
				TECH_NAME: 'title',
			},
			DESCRIPTION: {
				TECH_NAME: 'description',
			},
			ADDITIONAL_NOTES: {
				TECH_NAME: 'notes',
			},
			ADDITIONAL_DOCS: {
				TECH_NAME: 'documents',
				MAX_FILES: 6,
				INPUT_OPTIONS: {
					PDF: '.pdf',
					DOC: '.doc',
					DOCX: '.docx',
					XLS: '.xls',
					XLSX: '.xlsx',
					PNG: '.png',
					JPG: '.jpg',
					JPEG: '.jpeg',
				},
			},
		},
	},
	TRANSACTION_TYPES: {
		TECH_NAME_CREATE_TICKET: 'create_ticket',
	},
	TICKET_CONSULTANT_MATCH: {
		FIELDS: {
			GENERATED_CONTRACT: 'generated_contract',
			APPOINTMENT_START_DATETIME: 'appointment_start_time',
			APPOINTMENT_END_DATETIME: 'appointment_end_time',
			REQUESTED_CHANGES: 'reason_for_requesting_change',
			AGREED_DESCRIPTION: 'agreed_description',
			AGREED_PRICE: 'agreed_price',
			CONTRACT: 'contract',
			CONTRACT_SIGNED_BY_CONSULTANT: 'contract_signed_by_consultant',
			CONTRACT_DECLINED_REASON: 'contract_declined_reason',
			SOLUTION_DOCUMENTS: 'solution_documents',
			SOLUTION_CHANGE_REQUEST_REASON: 'solution_change_request_reason',
		},
	},
	TICKET_MATCHING_ORDER_TYPE: {
		FIELDS: {
			INTERNAL_REF_TICKET: {
				TECH_NAME: 'internal_ref_ticket',
			},
			TITLE: {
				TECH_NAME: 'title',
			},
			DESCRIPTION: {
				TECH_NAME: 'description',
			},
			PRIORITY: {
				TECH_NAME: 'priority',
				VALUES: {
					ASAP: 'asap',
					DUE_DATE: 'due_date',
				},
			},
			DUE_DATE: {
				TECH_NAME: 'due_date',
			},
			PRICE: {
				TECH_NAME: 'price',
			},
			ADDITIONAL_NOTES: {
				TECH_NAME: 'notes',
			},
			TICKET_NUMBER: {
				TECH_NAME: 'ticket_number',
			},
			ADDITIONAL_DOCS: {
				TECH_NAME: 'documents',
				MAX_FILES: 6,
				INPUT_OPTIONS: {
					PDF: '.pdf',
					DOC: '.doc',
					DOCX: '.docx',
					XLS: '.xls',
					XLSX: '.xlsx',
					PNG: '.png',
					JPG: '.jpg',
					JPEG: '.jpeg',
				},
			},
		},
	},

	TICKET_TRANSACTION: {
		STATE_TYPES: {
			SCHEDULED: {
				TECH_NAME: 'scheduled',
				TRANSITIONS: {
					DISMISS: 'customer_dismisses_application_from_scheduled_state',
					CONSULTANT_CONFIRMS_REQUIREMENTS: 'consultant_confirms_requirements',
					DEMAND_CHANGE_REQUIREMENTS: 'demand_change_request',
				},
			},
			CONTRACT_DECLINED: {
				TECH_NAME: 'consultant_withdrew',
				TRANSITIONS: {},
			},
			CHANGES_REQUESTED: {
				TECH_NAME: 'changes_requested',
				TRANSITIONS: {
					CUSTOMER_UPDATED_REQUIREMENTS: 'customer_updated_requirements',
				},
			},
			ANSWER_DELIVERED: {
				TECH_NAME: 'answer_delivered',
				TRANSITIONS: {
					CONSUMER_ACCEPTS_SOLUTION: 'customer_accepts_solution',
					CUSTOMER_REQUESTS_CHANGE: 'customer_requests_change',
				},
			},
			CHANGES_RECEIVED: {
				TECH_NAME: 'changes_received',
				TRANSITIONS: {
					CHANGE_REQUEST_REJECTED: 'change_reqeust_rejected',
					CONSULTANT_CONFIRMS_REQUIREMENTS: 'consultant_confirms_requirements_from_changed_received',
				},
			},
			CONTRACT_OFFERING: {
				TECH_NAME: 'contract_offering',
				TRANSITIONS: {
					OFFER_CONTRACT: 'offer_contract',
				},
			},
			CONTRACT_OFFERED: {
				TECH_NAME: 'contract_offered',
				TRANSITIONS: {
					CUSTOMER_WITHDRAWS_CONTRACT: 'customer_withdraws_the_contract',
					CONSULTANT_CANCELS_DEAL: 'consultant_cancels_deal',
					CONSULNTANT_SIGNS_THE_CONTRACT: 'consultant_signs_the_contract',
					DISMISS: 'customer_withdraws_the_contract',
				},
			},
			ANSWER_ACCEPTED: {
				TECH_NAME: 'answer_accepted',
				TRANSITIONS: {},
			},
			CONTRACT_ACCEPTED: {
				TECH_NAME: 'contract_accepted',
				TRANSITIONS: {
					DELIVER_SOLUTION: 'deliver_solution',
				},
			},
			PROPOSED_TIME: {
				TECH_NAME: 'meeting_time_proposed',
				TRANSITIONS: {
					CONSULTANT_CONFIRMS_APPOINTMENT_DATE: 'consultant_confirms_appointment',
					CONSULTANT_PROPOSES_NEW_DATE: 'consultant_proposes_new_date',
				},
			},
			ANSWER_TO_BE_IMPROVED: {
				TECH_NAME: 'improving_answer',
				TRANSITIONS: {
					CONSULTANT_SUBMITS_CHANGES: 'consultant_submits_changes',
				},
			},
			MEETING_INVITE: {
				TECH_NAME: 'meeting_invite',
				TRANSITIONS: {
					CUSTOMER_CONFIRMS_APPOINTMENT_DATE: 'customer_confirms_appointment_date',
					CUSTOMER_PROPOSES_NEW_DATE: 'customer_proposes_new_date',
				},
			},
			MATCH_TIME: {
				TECH_NAME: 'match_time',
				TRANSITIONS: {
					DISMISS: 'match_dismissed_by_customer',
				},
			},
			CONSULTANT_APPLIED: {
				TECH_NAME: 'consultant_applied',
				TRANSITIONS: {
					ACCEPT: 'customer_accepts_application',
					DECLINE: 'customer_dismisses_application',
					PARK: 'customer_parks_application',
					WITHDRAW: 'consultant_withdraws_application',
				},
			},
			PARKED: {
				TECH_NAME: 'application_parked',
				TRANSITIONS: {
					DECLINE: 'customer_declines_application',
					ACCEPT: 'customer_accepts_application_from_parked_state',
				},
			},
			OPEN: {
				TECH_NAME: 'open', // FIXME: @Nebo add state type tech name for all STATE TYPES after randevu API returns the value
				TRANSITIONS: {},
			},
			OPEN_OFFER: {
				TECH_NAME: 'opportunity_for_consultant', // FIXME: @Nebo add state type tech name for all STATE TYPES after randevu API returns the value
				TRANSITIONS: {},
			},
			IN_PROGRESS: {
				TECH_NAME: 'in_progress',
				TRANSITIONS: {},
			},
			DONE: {
				TECH_NAME: 'finished',
				TRANSITIONS: {},
			},
			AUTO_REJECTED: {
				TECH_NAME: 'auto_rejected',
			},
		},
	},
	CONSULTANT_SUB_TRANSACTION: {
		STATE_TYPES: {
			SUGGESTED: {
				TECH_NAME: 'suggested', // FIXME: @Nebo add state type tech name for all STATE TYPES after randevu API returns the value
			},
			REJECTED: {
				TECH_NAME: 'rejected',
			},
			AUTO_REJECTED: {
				TECH_NAME: 'auto_rejected',
			},
			OPEN_OFFER: {
				TECH_NAME: 'opportunity_for_consultant',
			},
			CONSULTANT_APPLIED: {
				TECH_NAME: 'consultant_applied',
			},
			DECLINED: {
				TECH_NAME: 'declined',
			},
			DECIDE_LATER: {
				TECH_NAME: 'application_parked',
			},
			DECLINED_BY_CUSTOMER: {
				TECH_NAME: 'application_dismissed_by_business',
			},
			MATCH_TIME: {
				TECH_NAME: 'match_time',
			},
			DISMISSED_BY_CUSTOMER: {
				TECH_NAME: 'consultant_not_interested',
			},
			PROPOSED_TIME: {
				TECH_NAME: 'meeting_time_proposed',
			},
			MEETING_INVITATION: {
				TECH_NAME: 'meeting_invite',
			},
			SCHEDULED: {
				TECH_NAME: 'scheduled',
			},
			CHANGES_REQUESTED: {
				TECH_NAME: 'changes_requested',
			},
			CHANGES_RECEIVED: {
				TECH_NAME: 'changes_received',
			},
			CONTRACT_OFFERING: {
				TECH_NAME: 'contract_offering',
			},
			CONTRACT_OFFERED: {
				TECH_NAME: 'contract_offered',
			},
			CONTRACT_WITHDRAWN: {
				TECH_NAME: 'business_withdrew_contract',
			},
			AUTO_REJECT: {
				TECH_NAME: 'auto_rejected',
			},
			CANCELLED: {
				TECH_NAME: 'consultant_withdrew',
			},
			CONTRACT_ACCEPTED: {
				TECH_NAME: 'contract_accepted',
			},
			ANSWER_DELIVERED: {
				TECH_NAME: 'answer_delivered',
			},
			IMPROVING_ANSWER: {
				TECH_NAME: 'improving_answer',
			},
			ANSWER_ACCEPTED: {
				TECH_NAME: 'answer_accepted',
			},
		},
	},
};

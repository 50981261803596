import { MutationPrepareFileUploadArgs } from '@aquga/services/randevuApi/generatedTypes';
import { print } from 'graphql';
import gql from 'graphql-tag';

const PREPARE_FILE_UPLOAD = gql`
	mutation PREPARE_FILE_UPLOAD(
		$file_name: String!
		$type: FileKind!
		$extension: String!
		$name: String
		$description: String
	) {
		prepareFileUpload(
			file_name: $file_name
			type: $type
			extension: $extension
			name: $name
			description: $description
		) {
			id
			upload_url
		}
	}
`;

export const prepareFileUpload = ({ file_name, type, extension, name, description }: MutationPrepareFileUploadArgs) => {
	return JSON.stringify({
		query: print(PREPARE_FILE_UPLOAD),
		variables: {
			file_name,
			type,
			extension,
			name,
			description,
		},
	});
};

import { useAppDispatch } from '@aquga/store/configureStore';
import { setLanguage } from '@aquga/store/slices/uiSlice';
import { Menu, MenuItem } from '@mui/material';

interface LanguagePickerProps {
	open: boolean;
	anchorEl: HTMLElement | null;
	onClose: () => any;
}

function LanguageMenu({ open, anchorEl, onClose }: LanguagePickerProps) {
	const dispatch = useAppDispatch();

	return (
		<>
			<Menu open={open} id="language-menu" anchorEl={anchorEl} onClose={onClose}>
				<MenuItem
					onClick={() => {
						dispatch(setLanguage('de-DE'));
						onClose();
					}}
				>
					DE
				</MenuItem>
				<MenuItem
					onClick={() => {
						dispatch(setLanguage('en-US'));
						onClose();
					}}
				>
					EN
				</MenuItem>
			</Menu>
		</>
	);
}

export default LanguageMenu;

import { get, set } from 'lodash';

export const REGEX = {
	PASSWORD: {
		minMaxLength: /^[\s\S]{8,64}$/,
		atLeastOneDigit: /^[0-9]$/g,
		atLastOneLowercase: /(?=.*?[a-z]).*/,
		atLastOneUppercase: /(?=.*?[A-Z]).*/,
		POLICY: /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/,
		// eslint-disable-next-line no-useless-escape
		countSpecialCharacters: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g,
	},
	DIGITS_ONLY: '^[0-9]*$',
	EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const PASSWORD_POLICY = {
	atleastLength: 9,
	maxAllowedLength: 64,
	atleastUppercase: 1,
	atleastLowercase: 1,
	atleastDigits: 1,
	atleastSpecialCharacters: 1,
};

interface ValidationInput {
	value: any;
	errorMsg?: string;
}

interface MinMaxValidationInput extends ValidationInput {
	min?: number;
	max?: number;
}

type ValidationAssessment = string | undefined;

type ValidationResult = any | undefined;

// Given for example { a: 1, b: { c: 2 } }, return [ 'a', 'b.c' ]
const getFlattenedKeys = (obj: any, path = ''): any =>
	Object.keys(obj)?.reduce((res: any, key: any) => {
		const value = obj[key];
		const newPath = `${path}${key}`;
		if (!Array.isArray(value) && typeof value === 'object' && value !== null) {
			return [...res, ...getFlattenedKeys(value, `${newPath}.`)];
		}
		return [...res, newPath];
	}, []);

// Go through all validation functions for the field and return error message of the first one
const validateField = (fieldValidators: any, fieldPath: string, value: any) => {
	return get(fieldValidators, fieldPath)?.reduce((acc: any, validator: any) => {
		return acc ? acc : validator(value);
	}, undefined);
};

export const validator = {
	required: ({ value, errorMsg }: ValidationInput): ValidationAssessment => {
		let violated = false;
		if (value === undefined || value === '' || value === null) {
			violated = true;
		}

		const defaultErrorMessage = 'Mandatory field.';
		const msg = errorMsg ?? defaultErrorMessage;

		return violated ? msg : undefined;
	},
	isTrue: ({ value, errorMsg }: ValidationInput): ValidationAssessment => {
		const violated = !(value === true);

		const defaultErrorMessage = 'Mandatory field.';
		const msg = errorMsg ?? defaultErrorMessage;

		return violated ? msg : undefined;
	},
	minLength: ({ value, min, errorMsg }: MinMaxValidationInput): ValidationAssessment => {
		if (!min) throw new Error('Missing "min" value for minLength validation function');
		const defaultErrorMessage = `Min length is ${min}`;
		const msg = errorMsg ?? defaultErrorMessage;

		return value?.length < min ? msg : undefined;
	},
	maxLength: ({ value, max, errorMsg }: MinMaxValidationInput): ValidationAssessment => {
		if (!max) throw new Error('Missing "max" value for minLength validation function');
		const defaultErrorMessage = `Max length is ${max}`;
		const msg = errorMsg ?? defaultErrorMessage;

		return value?.length > max ? msg : undefined;
	},
	minValue: ({ value, min, errorMsg }: MinMaxValidationInput): ValidationAssessment => {
		if (!min) throw new Error('Missing "min" value for minValue validation function');
		const defaultErrorMessage = `Value has to be equal or great to ${min}`;
		const msg = errorMsg ?? defaultErrorMessage;
		const number = Number(value);

		return number < min ? msg : undefined;
	},
	maxValue: ({ value, max, errorMsg }: MinMaxValidationInput): ValidationAssessment => {
		if (!max) throw new Error('Missing "max" value for maxValue validation function');
		const defaultErrorMessage = `Value has to be less or equal to ${max}`;
		const msg = errorMsg ?? defaultErrorMessage;
		const number = Number(value);

		return number > max ? msg : undefined;
	},
	validDate: ({ value, errorMsg }: ValidationInput): ValidationAssessment => {
		const defaultErrorMessage = 'Value not in hh:mm format';
		const msg = errorMsg ?? defaultErrorMessage;

		return value == 'Invalid Date' ? msg : undefined;
	},
	atLeastUppercase: ({ value, min, errorMsg }: MinMaxValidationInput): ValidationAssessment => {
		if (!min) throw new Error('Missing min value');
		const defaultErrorMessage = `Value must contain atlease ${min} uppercase characters`;
		const msg = errorMsg ?? defaultErrorMessage;

		return value.length - value.replace(REGEX.PASSWORD.atLastOneUppercase, '').length < min ? msg : undefined;
	},
	atLeastLowercase: ({ value, min, errorMsg }: MinMaxValidationInput): ValidationAssessment => {
		if (!min) throw new Error('Missing min value');
		const defaultErrorMessage = `Value must contain atleast ${min} lowercase character`;
		const msg = errorMsg ?? defaultErrorMessage;

		return value.length - value.replace(REGEX.PASSWORD.atLastOneLowercase, '').length < min ? msg : undefined;
	},
	atLeastDigits: ({ value, min, errorMsg }: MinMaxValidationInput): ValidationAssessment => {
		if (!min) throw new Error('Missing min value');
		const defaultErrorMessage = `Value must contain atleast ${min} number/numbers`;
		const msg = errorMsg ?? defaultErrorMessage;

		return value.replace(REGEX.PASSWORD.atLeastOneDigit, '').length < min ? msg : undefined;
	},
	atLeastSpecialCharacters: ({ value, min, errorMsg }: MinMaxValidationInput): ValidationAssessment => {
		if (!min) throw new Error('Missing min value');
		const defaultErrorMessage = `Value must contain atleast ${min} special characters`;
		const msg = errorMsg ?? defaultErrorMessage;

		return (value.match(REGEX.PASSWORD.countSpecialCharacters) || []).length < min ? msg : undefined;
	},
	digitsOnly: ({ value, errorMsg }: ValidationInput): ValidationAssessment => {
		if (!value) return undefined;
		const defaultErrorMessage = 'Only digits are allowed.';
		const msg = errorMsg ?? defaultErrorMessage;
		const violated = value?.match(REGEX.DIGITS_ONLY) === null;

		return violated ? msg : undefined;
	},

	isEmail: ({ value, errorMsg }: ValidationInput): ValidationAssessment => {
		if (!value) return undefined;
		const defaultErrorMessage = 'Not a valid email.';
		const msg = errorMsg ?? defaultErrorMessage;

		const violated = value.toLowerCase().match(REGEX.EMAIL) === null;

		return violated ? msg : undefined;
	},

	isPasswordCompliant({ value, errorMsg }: ValidationInput): ValidationAssessment {
		if (!value) return undefined;
		const defaultErrorMessage = 'Some example default error msg';
		const msg = errorMsg ?? defaultErrorMessage;

		return (value?.match(REGEX.PASSWORD.countSpecialCharacters) || []).length <
			PASSWORD_POLICY.atleastSpecialCharacters
			? msg
			: value?.replace(REGEX.PASSWORD.atLeastOneDigit, '').length < PASSWORD_POLICY.atleastDigits
				? msg
				: !REGEX.PASSWORD.atLastOneUppercase.test(value)
					? msg
					: !REGEX.PASSWORD.atLastOneLowercase.test(value)
						? msg
						: value?.length > PASSWORD_POLICY.maxAllowedLength || value?.length < PASSWORD_POLICY.atleastLength
							? msg
							: undefined;
	},
	exampleValidationFunction({ value, errorMsg }: ValidationInput): ValidationAssessment {
		if (!value) return undefined;
		const defaultErrorMessage = 'Some example default error msg';
		const msg = errorMsg ?? defaultErrorMessage;

		// Perform logic to assess if the rule is violated
		const violated = true;

		return violated ? msg : undefined;
	},

	doValidation: (fieldValidators: any, values: any): ValidationResult => {
		const flatFieldValidatorPaths = getFlattenedKeys(fieldValidators);

		const flatErrors = flatFieldValidatorPaths?.reduce((acc: any, fieldPath: string) => {
			return { ...acc, [fieldPath]: validateField(fieldValidators, fieldPath, get(values, fieldPath)) };
		}, {});

		const nestedErrors = Object.keys(flatErrors)?.reduce((acc, fieldPath) => {
			const newAcc = { ...acc };
			if (flatErrors[fieldPath] !== undefined) set(newAcc, fieldPath, flatErrors[fieldPath]);
			return newAcc;
		}, {});

		return Object.keys(nestedErrors)?.length === 0 ? undefined : nestedErrors;
	},
};

export default validator;

import {
	mapCreateAnsweringExpertiseFormValuesAdditionDocumentsFormFieldRandevuField,
	mapCreateAnsweringExpertiseFormValuesToSupplyFields,
	mapSupplyToAnsweringExpertise,
} from '@aquga/app/answeringExpertiseHelpers';
import PageHeader from '@aquga/components/common/PageHeader';
import WhiteBackground from '@aquga/components/common/WhiteBackground';
import CreateAnsweringExpertiseForm, {
	CreateAnsweringExpertiseFormValues,
} from '@aquga/components/services/create/CreateAnsweringExpertiseForm';
import { ROUTES } from '@aquga/config/routes';
import { Field } from '@aquga/services/randevuApi/generatedTypes';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import {
	loadMySupply,
	selectLoading,
	selectMySupply,
	updateSupply,
	updateSupplyFailed,
} from '@aquga/store/slices/supplySlice';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';

const UpdateAnsweringExpertisePage = () => {
	const intl = useIntl();
	const dispatch = useAppDispatch();
	const mySupply = useAppSelector(selectMySupply);
	const loading = useAppSelector(selectLoading);
	const navigate = useNavigate();
	const { id } = useParams();

	const initialFormValues = mapSupplyToAnsweringExpertise(mySupply?.fields as Field[]);

	useEffect(() => {
		if (id) dispatch(loadMySupply({ id }));
	}, [id]);

	const handleSubmitAnsweringExpertiseForm = async (values: CreateAnsweringExpertiseFormValues) => {
		const supplyFields = mapCreateAnsweringExpertiseFormValuesToSupplyFields({
			...values,
		});

		const supplyFieldsAdditionalDoc = mapCreateAnsweringExpertiseFormValuesAdditionDocumentsFormFieldRandevuField({
			...values,
		});

		const resolvedAction = await dispatch(
			updateSupply({
				id: id ?? '',
				fields: supplyFields,
				field_additional_docs: supplyFieldsAdditionalDoc,
			})
		);

		if (resolvedAction.type === updateSupplyFailed.type) {
			return resolvedAction.payload;
		}
	};

	const handleCancelSubmitAnsweringExpertiseForm = () => {
		navigate(-1);
	};

	return (
		<>
			<Helmet>
				<title>{intl.formatMessage({ id: 'updateAnsweringExpertisePage.metaTitle' })}</title>
			</Helmet>

			<PageHeader title={intl.formatMessage({ id: 'updateAnsweringExpertisePage.metaTitle' })} />
			<br />

			<Breadcrumbs>
				<Button
					component={Link}
					disableRipple
					disableFocusRipple
					disableElevation
					color="secondary"
					to={ROUTES.LIST_SERVICES}
					startIcon={<KeyboardBackspaceIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
				>
					{intl.formatMessage({ id: 'common.back' })}
				</Button>
				<Button
					disableRipple
					disableElevation
					disableFocusRipple
					color="inherit"
					to={ROUTES.LIST_SERVICES}
					component={Link}
				>
					<FormattedMessage id="updateAnsweringExpertisePage.title" />
				</Button>
				<Typography color="text.primary" variant="caption" fontSize="small" fontWeight={600}>
					<FormattedMessage id="updateAnsweringExpertisePage.updateAnsweringExpertise" />
				</Typography>
			</Breadcrumbs>
			<br />
			<WhiteBackground>
				<Typography component="h2" variant="h5" gutterBottom fontWeight="bold">
					<FormattedMessage id="updateAnsweringExpertisePage.updateAnsweringExpertise" />
				</Typography>
				<Typography component="h3">
					<FormattedMessage id="updateAnsweringExpertisePage.moreInfo" />
				</Typography>
				<br />
				<br />
				<CreateAnsweringExpertiseForm
					submitLabel={intl.formatMessage({ id: 'updateAnsweringExpertisePage.btnSubmit' })}
					onSubmit={handleSubmitAnsweringExpertiseForm}
					onCancel={handleCancelSubmitAnsweringExpertiseForm}
					initialValues={initialFormValues}
					maxWidth="sm"
					loading={loading}
				/>
			</WhiteBackground>
		</>
	);
};
export default UpdateAnsweringExpertisePage;

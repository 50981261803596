import { Question, TicketFilterEnum } from '@aquga/components/questions/types';

export const decodeToJSONObject = (encodedValue: any) => {
	try {
		return JSON.parse(atob(encodedValue));
	} catch (ex) {
		console.warn('Failed decoding string into JSON object');
		return {};
	}
};

export const filterQuestions = (questions: Question[] | undefined, filter: string | null) => {
	if (filter === TicketFilterEnum.ACTIVE) return questions?.filter((question) => question?.terminatedAt === null);
	if (filter === TicketFilterEnum.PAST) return questions?.filter((question) => question?.terminatedAt !== null);
	return questions;
};

export const combineDateAndTime = (date: Date, time: Date) => {
	const timeString = ('0' + time.getHours()).slice(-2) + ':' + ('0' + time.getMinutes()).slice(-2) + ':00';

	const year = date.getFullYear();
	const month = ('0' + (date.getMonth() + 1)).slice(-2); // Jan is 0, dec is 11
	const day = ('0' + date.getDate()).slice(-2);
	const dateString = '' + year + '-' + month + '-' + day;
	const combined = new Date(dateString + 'T' + timeString);

	return combined;
};

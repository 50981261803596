import QuestionStatus from '@aquga/components/questions/QuestionStatus';
import { Question } from '@aquga/components/questions/types';
import { ROUTES } from '@aquga/config/routes';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface QuestionsTableProps {
	loading: boolean;
	items: Question[];
}

interface QuestionProps {
	loading: boolean;
	item: Question;
}

const QuestionComponent = ({ loading, item }: QuestionProps) => {
	// Workaround - to be done properly with native css ellipsis
	let truncationTitle = item?.title;

	if (item?.title?.length >= 125) {
		truncationTitle = `${item.title.substring(0, 127)}...`;
	}
	return (
		<>
			<Grid item xs={12}>
				<Typography fontWeight="600" variant="body1" mb={2}>
					<FormattedMessage id="questions.list.table.columnQuestion" />
				</Typography>
				<Typography
					gutterBottom
					mb={1}
					sx={{ wordWrap: 'break-word', width: '100%', fontWeight: 450, lineHeight: 1.5 }}
				>
					{truncationTitle}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography fontWeight="600" variant="body1" mb={2}>
					<FormattedMessage id="questions.list.table.columnToBeAnswered" />
				</Typography>
				{item.toBeAnsweredUntil !== 'ASAP' ? (
					<FormattedDate value={item.toBeAnsweredUntil} day="numeric" year="numeric" month="long" />
				) : (
					'ASAP'
				)}
			</Grid>
			<Grid item xs={12}>
				<Typography fontWeight="600" variant="body1" mb={2}>
					<FormattedMessage id="questions.list.table.columnMatches" />
				</Typography>
				<Typography color="textSecondary" variant="body2">
					{item.matches === 0 ? '-' : item.matches}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography fontWeight="600" variant="body1" mb={2}>
					<FormattedMessage id="questions.list.table.columnStatus" />
				</Typography>
				<QuestionStatus loading={loading} status={item.status} />
			</Grid>
			<Grid item xs={12} justifyContent="flex-end">
				<Box display="flex" justifyContent="flex-end">
					<Button
						component={Link}
						color="secondary"
						to={ROUTES.TICKET_DETAILS.replace(':id', item.id)}
						endIcon={<ArrowRightAltIcon />}
					>
						<FormattedMessage id="common.open" />
					</Button>
				</Box>
			</Grid>
		</>
	);
};

const QuestionsList = ({ loading, items = [] }: QuestionsTableProps) => {
	const omitLastDivider = items?.length - 1;
	return (
		<>
			<Grid container spacing={3}>
				{items?.map((item: Question, index) => (
					<>
						<QuestionComponent loading={loading} key={item.id} item={item} />
						{omitLastDivider !== index && (
							<Grid item xs={12}>
								<Divider />
							</Grid>
						)}
					</>
				))}
			</Grid>
		</>
	);
};

export default QuestionsList;

// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import COUNTRY_CODES from '@aquga/app/countryCodes';
import { Select } from 'mui-rff';
import { useIntl } from 'react-intl';

interface CountryCodeFieldProps {
	name: string;
	label: string;
}
const CountryCodeField = ({ label, name }: CountryCodeFieldProps) => {
	const intl = useIntl();

	const countryOptions = Object.keys(COUNTRY_CODES)
		.filter((key) => !isNaN(Number(COUNTRY_CODES[key])))
		.map((countryCode) => {
			if (isNaN(Number(countryCode))) {
				const i18n = `country.${countryCode.toString().toLowerCase()}`;
				return {
					value: countryCode,
					label: intl.formatMessage({ id: i18n }),
				};
			}
		})
		.sort((a, b) => a.label.localeCompare(b.label)); // sort alphabetically

	return <Select label={label} name={name} required data={countryOptions} />;
};

export default CountryCodeField;

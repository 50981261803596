// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
	AQUGA_NATURAL_90,
	AQUGA_NATURAL_10,
	AQUGA_DELETE_RED,
	AQUGA_PINK_00,
	AQUGA_DANGER_10,
} from '@aquga/styles/theme';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Box, Stack, styled } from '@mui/system';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldArray, useFieldArray } from 'react-final-form-arrays';
import { FormattedMessage, useIntl } from 'react-intl';

const errorCodeToCamelCase = (errorCode: string) => {
	const stepOne = errorCode.split('-');
	const stepTwo = stepOne.map((word) => word[0]?.toUpperCase() + word.slice(1));
	const stepThree = stepTwo?.join('');
	return stepThree[0].toLowerCase() + stepThree.slice(1);
};

interface FilePreviewProps {
	fileName: string;
	onRemove: () => void;
}
const FilePreview = ({ fileName, onRemove }: FilePreviewProps) => {
	return (
		<>
			<Paper
				sx={{
					width: '100%',
					pt: 0.5,
					pb: 0.5,
					pl: 2,
					pr: 2,
					backgroundColor: AQUGA_NATURAL_10,
					borderRadius: 3,
				}}
				elevation={0}
			>
				<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
					<Typography
						variant="body1"
						sx={{
							color: AQUGA_NATURAL_90,
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{fileName?.toLowerCase()}
					</Typography>

					<IconButton aria-label={`Download ${fileName}`} onClick={onRemove} sx={{ color: AQUGA_DELETE_RED }}>
						<DeleteIcon />
					</IconButton>
				</Stack>
			</Paper>
		</>
	);
};
interface RejectedFileProps {
	fileName: string;
	errorCode: string;
	onRemove: () => void;
}
const RejectedFile = ({ fileName, errorCode, onRemove }: RejectedFileProps) => {
	const intl = useIntl();

	const validationError = intl.formatMessage({ id: `validation.general.file.${errorCode}` });

	return (
		<>
			<Paper
				sx={{
					width: '100%',
					pt: 0.5,
					pb: 0.5,
					pl: 2,
					pr: 2,
					backgroundColor: AQUGA_DANGER_10,
					borderRadius: 3,
				}}
				elevation={0}
			>
				<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
					<Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
						<InfoOutlinedIcon sx={{ color: 'white' }} />
						<div>
							<Typography variant="body1" sx={{ color: 'white' }}>
								{`${fileName?.toLowerCase()}`}
							</Typography>
							<Typography variant="body2" sx={{ color: 'white' }}>
								{validationError}
							</Typography>
						</div>
					</Stack>

					<IconButton onClick={onRemove} sx={{ color: 'white' }}>
						<ClearTwoToneIcon />
					</IconButton>
				</Stack>
			</Paper>
		</>
	);
};

const StyledZone = styled(Box)((props: any) => {
	const { theme } = props;

	return {
		borderColor: theme.palette.primary.main,
		...((props?.isDragActive || props?.error) && { backgroundColor: AQUGA_PINK_00 }),
		borderStyle: 'dotted',
		borderWidth: '2px',
		borderRadius: theme.spacing(2),
		padding: theme.spacing(4),
		'&:hover': {
			cursor: !props?.error && 'pointer',
		},
	};
});

interface DropZoneFieldProps {
	name: string;
	disabled: boolean;
	multiple?: boolean;
	maxFiles?: number;
	maxFileSize: number;
	acceptedFileFormats: { [key: string]: string[] };
	onAddFile: (listName: string, file: File) => void;
}

const DropZoneField = ({
	name,
	disabled,
	multiple = true,
	acceptedFileFormats,
	maxFiles,
	maxFileSize,
	onAddFile,
}: DropZoneFieldProps) => {
	const theme = useTheme();
	const formFieldDocuments = useFieldArray(name);

	const [errorFiles, setErrorFiles] = useState<{ id: number; fileName: string; errorCode: string }[]>([]);

	const errorFilesPresent = errorFiles.length >= 1;
	const ONE_MEGABYTE = 1000000;
	const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive } = useDropzone({
		disabled: errorFilesPresent | disabled,
		accept: acceptedFileFormats,
		maxSize: maxFileSize * ONE_MEGABYTE,
		...(maxFiles && { maxFiles: maxFiles }),
		multiple: multiple,
		onDrop: (acceptedFiles, fileRejections) => {
			const errors = fileRejections.map((rejectedFile, index) => ({
				id: index,
				fileName: rejectedFile.file.name,
				errorCode: errorCodeToCamelCase(rejectedFile.errors[0].code),
			}));

			const maxFilesErrors = [];
			acceptedFiles.map((file, index) => {
				if (maxFiles !== undefined && formFieldDocuments?.fields?.length <= maxFiles) {
					if (formFieldDocuments?.fields?.length + (index + 1) <= maxFiles) {
						onAddFile(name, file);
					} else {
						maxFilesErrors.push({
							id: index,
							fileName: file.name,
							errorCode: 'tooManyFiles',
						});
					}
				}

				if (maxFiles === undefined) onAddFile(name, file);
			});

			if (maxFilesErrors.length > 0) {
				maxFilesErrors.map((err) => {
					errors.push(err);
				});
			}

			setErrorFiles(errors);
		},
	});

	const handleRemoveRejectedFile = (id: number) => {
		setErrorFiles(errorFiles.filter((file) => file.id !== id));
	};

	const acceptedFileFormatsLabel = Object.values(acceptedFileFormats).flat().join(', ');

	return (
		<>
			<Stack spacing={4}>
				<StyledZone
					{...getRootProps({ isFocused, isDragAccept, isDragReject, isDragActive })}
					error={errorFilesPresent}
				>
					<input {...getInputProps()} />

					<Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
						<Avatar sx={{ bgcolor: theme.palette.primary.main, mb: 2 }}>
							{(isDragReject || errorFilesPresent) && <ErrorIcon fontSize="large" />}
							{!isDragReject && !errorFilesPresent && <UploadFileIcon fontSize="large" />}
						</Avatar>

						<Typography variant="body2">
							{(isDragReject || errorFilesPresent) && (
								<FormattedMessage id="common.dropZone.generalErrors" />
							)}
							{isDragActive && !isDragReject && <FormattedMessage id="common.dropZone.release" />}
							{!isDragActive && !errorFilesPresent && <FormattedMessage id="common.dropZone.title" />}
						</Typography>
						<Typography variant="caption" sx={isDragActive ? { color: 'transparent' } : {}}>
							<FormattedMessage
								id="common.dropZone.fileFormat"
								values={{ fileFormat: acceptedFileFormatsLabel }}
							/>
						</Typography>
						<Typography variant="caption" sx={isDragActive ? { color: 'transparent' } : {}}>
							<FormattedMessage id="common.dropZone.fileSize" values={{ maxFileSize }} />
						</Typography>
						{maxFiles && (
							<Typography variant="caption" sx={isDragActive ? { color: 'transparent' } : {}}>
								<FormattedMessage id="common.dropZone.maxFiles" values={{ maxFiles }} />
							</Typography>
						)}
					</Stack>
				</StyledZone>

				{errorFiles.map((file) => (
					<RejectedFile
						key={file.id}
						fileName={file.fileName}
						errorCode={file.errorCode}
						onRemove={() => handleRemoveRejectedFile(file.id)}
					/>
				))}

				<FieldArray name={name}>
					{({ fields }) => {
						return (
							<Stack component="aside" spacing={2}>
								{fields.map((name, index) => (
									<FilePreview
										key={index}
										fileName={fields.value[index].name || fields.value[index].file_name}
										onRemove={() => fields.remove(index)}
									/>
								))}
							</Stack>
						);
					}}
				</FieldArray>
			</Stack>
		</>
	);
};

export default DropZoneField;

import { filterQuestions } from '@aquga/app/helpers';
import { mapTransactionToTicketOverview } from '@aquga/app/ticketHelpers';
import PageHeader from '@aquga/components/common/PageHeader';
import ListQuestionsView from '@aquga/components/questions/list/ListQuestionsView';
import NoQuestionsAskedView from '@aquga/components/questions/NoQuestionsAskedView';
import { TicketFilter, TicketFilterEnum } from '@aquga/components/questions/types';
import { ROUTES } from '@aquga/config/routes';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { loadMyTickets, selectMyTickets, selectLoading } from '@aquga/store/slices/transactionSlice';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ListTicketsPage = () => {
	const [searchParams] = useSearchParams();
	const filter = searchParams?.get('filter');

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const tickets = useAppSelector(selectMyTickets);
	const loading = useAppSelector(selectLoading);
	const intl = useIntl();
	const metaTitle = `${intl.formatMessage({ id: 'questions.list.metaTitle' })} - AQUGA`;

	const handleFilterTickets = (value: TicketFilter) => {
		navigate(`${ROUTES.LIST_TICKETS}?filter=${value}`);
	};

	const handleGoToTicketDetails = (id: string) => {
		navigate(`${ROUTES.TICKET_DETAILS.replace(':id', id)}`);
	};

	const questions = tickets?.map((transaction) => mapTransactionToTicketOverview(transaction));

	useEffect(() => {
		dispatch(loadMyTickets());
	}, []);

	return (
		<>
			<Helmet>
				<title>{metaTitle}</title>
			</Helmet>

			<PageHeader title={intl.formatMessage({ id: 'questions.list.title' })} />
			<br></br>
			<Box sx={{ overflowX: 'hidden' }}>
				{questions && questions?.length > 0 ? (
					<ListQuestionsView
						loading={loading}
						items={filterQuestions(questions, filter) ?? []}
						filter={filter as TicketFilter}
						onFilter={handleFilterTickets}
						onSeeDetails={handleGoToTicketDetails}
					/>
				) : (
					<NoQuestionsAskedView />
				)}
			</Box>
		</>
	);
};
export default ListTicketsPage;

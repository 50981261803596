import AuthService from './auth/authService';
import FilesService from './files';
import ParticipantsService from './participants';
import SuppliesService from './supplies';
import TransactionService from './transactions';

export type IAuthService = InstanceType<typeof AuthService>;
export type IFilesService = InstanceType<typeof FilesService>;
export type IParticipantsService = InstanceType<typeof ParticipantsService>;
export type ITransactionService = InstanceType<typeof TransactionService>;
export type ISuppliesService = InstanceType<typeof SuppliesService>;

export default {
	AuthService,
	SuppliesService,
	ParticipantsService,
	TransactionService,
	FilesService,
};

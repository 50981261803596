import {
	initCompanyInfoFormValues,
	initPersonalInfoFormValues,
	initPointOfContactFormValues,
} from '@aquga/app/participantHelpers';
import PageHeader from '@aquga/components/common/PageHeader';
import CompanyInfoSection from '@aquga/components/participantAccount/CompanyInfoSection';
import ParticipantAccountTabs from '@aquga/components/participantAccount/ParticipantAccountTabs';
import PersonalInfoSection from '@aquga/components/participantAccount/PersonalInfoSection';
import PointOfContactSection from '@aquga/components/participantAccount/PointOfContactSection';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import {
	loadCurrentParticipant,
	selectCurrentParticipant,
	selectUpdatingParticipantFields,
} from '@aquga/store/slices/participantSlice';
import { Grid, Stack } from '@mui/material';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const ParticipantAccountPage = () => {
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const dispatch = useAppDispatch();
	const intl = useIntl();
	const updatingParticipantFields = useAppSelector(selectUpdatingParticipantFields);

	useEffect(() => {
		dispatch(loadCurrentParticipant());
	}, [dispatch]);
	//TODO: @Nikola i18n

	return (
		<>
			<Helmet>
				<title>{intl.formatMessage({ id: 'participantAccountPage.metaTitle' })} - AQUGA</title>
			</Helmet>

			<PageHeader title={intl.formatMessage({ id: 'participantAccountPage.title' })} />
			<br />
			<Stack direction={{ xs: 'column' }} gap={2} sx={{ width: '100%' }}>
				<ParticipantAccountTabs selected="general" />
				<Grid container direction="row" spacing={2}>
					<Grid item xs={12} md={6}>
						<Stack spacing={2}>
							<PersonalInfoSection
								participantFields={currentParticipant?.fields ?? []}
								loading={updatingParticipantFields === 'personalInfo'}
							/>
							<PointOfContactSection
								initialFormValues={initPointOfContactFormValues(currentParticipant?.fields)}
								loading={updatingParticipantFields === 'pointOfContact'}
							/>
						</Stack>
					</Grid>
					<Grid item xs={12} md={6}>
						<Stack>
							<CompanyInfoSection
								participantFields={currentParticipant?.fields ?? []}
								loading={updatingParticipantFields === 'companyInfo'}
							/>
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</>
	);
};
export default ParticipantAccountPage;

import {
	mapCreateTicketFormValuesAdditionDocumentsFormFieldRandevuField,
	mapCreateTicketFormValuesToMatchingOrderFields,
	mapCreateTicketFormValuesToSupplyFilters,
} from '@aquga/app/ticketHelpers';
import PageHeader from '@aquga/components/common/PageHeader';
import WhiteBackground from '@aquga/components/common/WhiteBackground';
import CreateQuestionForm, { CreateQuestionFormValues } from '@aquga/components/questions/create/CreateQuestionForm';
import { ROUTES } from '@aquga/config/routes';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { createTicket, createTicketFailed, selectLoading } from '@aquga/store/slices/transactionSlice';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

const CreateTicketPage = () => {
	const intl = useIntl();
	const metaTitle = `${intl.formatMessage({ id: 'questions.create.metaTitle' })} - AQUGA`;
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const loading = useAppSelector(selectLoading);

	const handleSubmitTicketForm = async (
		values: CreateQuestionFormValues
	): Promise<void | CreateQuestionFormValues> => {
		const supplyFilterFields = mapCreateTicketFormValuesToSupplyFilters({
			...values,
		});
		const matchingOrderFieldsWithoutAdditionalDocs = mapCreateTicketFormValuesToMatchingOrderFields({
			...values,
		});

		const matchingOrderFieldAdditionalDoc = mapCreateTicketFormValuesAdditionDocumentsFormFieldRandevuField({
			...values,
		});
		const resolvedAction = await dispatch(
			createTicket({
				supply_filter: supplyFilterFields,
				fields: matchingOrderFieldsWithoutAdditionalDocs,
				field_additional_docs: matchingOrderFieldAdditionalDoc,
			})
		);

		if (resolvedAction.type === createTicketFailed.type) {
			const submissionErrors = { ...resolvedAction.payload };

			// TODO: write nice helper function which does this one
			Object.entries(submissionErrors).map((keyValue) => {
				submissionErrors[keyValue[0]] = intl.formatMessage({ id: keyValue[1] as string });
			});

			return submissionErrors;
		}

		navigate(ROUTES.TICKET_DETAILS.replace(':id', resolvedAction.payload.id));
	};

	const handleCancelSubmitTicketForm = () => {
		navigate(-1);
	};

	return (
		<>
			<Helmet>
				<title>{metaTitle}</title>
			</Helmet>

			<PageHeader title={intl.formatMessage({ id: 'questions.list.title' })} />
			<br />

			<Breadcrumbs>
				<Button
					component={Link}
					disableRipple
					disableFocusRipple
					disableElevation
					color="secondary"
					to={ROUTES.TICKETS_W_FILTER_ACTIVE}
					startIcon={<KeyboardBackspaceIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
				>
					{intl.formatMessage({ id: 'common.back' })}
				</Button>
				<Button
					disableRipple
					disableElevation
					disableFocusRipple
					color="inherit"
					to={ROUTES.TICKETS_W_FILTER_ACTIVE}
					component={Link}
				>
					{intl.formatMessage({ id: 'questions.create.breadcrumbs.questions' })}
				</Button>
				<Typography color="text.primary" variant="body2" fontWeight="500">
					{intl.formatMessage({ id: 'questions.create.breadcrumbs.addQuestion' })}
				</Typography>
			</Breadcrumbs>
			<br />
			<WhiteBackground>
				<Typography component="h2" variant="h5" gutterBottom fontWeight="bold">
					{intl.formatMessage({ id: 'questions.create.title' })}
				</Typography>
				<Typography component="h3">{intl.formatMessage({ id: 'questions.create.subtitle' })}</Typography>
				<br />
				<br />
				<CreateQuestionForm
					loading={loading}
					onSubmit={handleSubmitTicketForm}
					onCancel={handleCancelSubmitTicketForm}
					maxWidth="sm"
				/>
			</WhiteBackground>
		</>
	);
};
export default CreateTicketPage;

import { isBusinessSide } from '@aquga/app/participantHelpers';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import { AQUGA_NEUTRAL_0, AQUGA_SUCCESS_40, AQUGA_NEUTRAL_80 } from '@aquga/styles/theme';
import { Skeleton } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useIntl } from 'react-intl';

interface RespondentMatchStatusProps {
	status: string;
	loading: boolean;
}

export const determineMatchStatusColor = (status: string) => {
	if (
		status?.toUpperCase() ==
		RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONTRACT_ACCEPTED.TECH_NAME.toUpperCase()
	)
		return { backgroundColor: AQUGA_NEUTRAL_80, color: AQUGA_NEUTRAL_0, fontWeight: 600, borderColor: 'black' };
	if (
		status?.toUpperCase() ==
		RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.ANSWER_DELIVERED.TECH_NAME.toUpperCase()
	)
		return { backgroundColor: AQUGA_NEUTRAL_80, color: AQUGA_NEUTRAL_0, fontWeight: 600 };

	if (
		status?.toUpperCase() ==
		RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.IMPROVING_ANSWER.TECH_NAME.toUpperCase()
	)
		return { backgroundColor: AQUGA_NEUTRAL_80, color: AQUGA_NEUTRAL_0, fontWeight: 600 };

	return { backgroundColor: AQUGA_SUCCESS_40, color: AQUGA_NEUTRAL_0, fontWeight: 600 };
};

// FIXME: @Nikola rename prop "status" to stateTypeName
const AnswerMatchStatus = ({ status, loading }: RespondentMatchStatusProps) => {
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const businessSide = isBusinessSide(currentParticipant);
	const intl = useIntl();
	if (loading) return <Skeleton width={60} height={40} />;

	const localizatizedName =
		status.toUpperCase() ===
		RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONTRACT_ACCEPTED.TECH_NAME.toUpperCase()
			? businessSide
				? intl.formatMessage({ id: `resources.subTransaction.answer.stateTypes.asking.${status}` })
				: intl.formatMessage({ id: `resources.subTransaction.answer.stateTypes.answering.${status}` })
			: status.toUpperCase() ===
			  RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.ANSWER_DELIVERED.TECH_NAME.toUpperCase()
				? intl.formatMessage({ id: `resources.subTransaction.stateTypes.${status}` })
				: status.toUpperCase() ===
			  RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.IMPROVING_ANSWER.TECH_NAME.toUpperCase()
					? intl.formatMessage({ id: `resources.subTransaction.stateTypes.${status}` })
					: intl.formatMessage({
						id: `resources.subTransaction.stateTypes.${RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.ANSWER_ACCEPTED.TECH_NAME}`,
			  });

	const color = determineMatchStatusColor(status);

	return <Chip label={localizatizedName} sx={color} />;
};

export default AnswerMatchStatus;

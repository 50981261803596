import PageHeader from '@aquga/components/common/PageHeader';
import WhiteBackground from '@aquga/components/common/WhiteBackground';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';

const ContractsPage = () => {
	return (
		<>
			<Helmet>
				<title>Contracts - AQUGA</title>
			</Helmet>

			<PageHeader title="Contracts" />
			<br></br>
			<WhiteBackground>
				<Typography variant="h6">
					<FormattedMessage id="common.comingSoon" />
				</Typography>
			</WhiteBackground>
		</>
	);
};
export default ContractsPage;

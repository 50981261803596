import File from '@aquga/components/common/File';
import QuestionsList from '@aquga/components/questions/list/QuestionsList';
import QuestionStatus from '@aquga/components/questions/QuestionStatus';
import MatchStatus from '@aquga/components/questions/status/MatchStatus';
import RespondentMatchStatus from '@aquga/components/questions/status/RespondentMatchStatus';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { AQUGA_NEUTRAL_30 } from '@aquga/styles/theme';
import { Divider, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface GenericDetailsViewProps {
	match: any;
	consultingExpertiseTitle: string;
	consultingExpertiseDescription: string;
	loading: boolean;
	supplyTechnology: string[];
	supplyBusinessArea: string[];
	supplyTechnologyOther: string;
	supplyBusinessAreaOther: string;
	supplyAdditionalDocs: any[];
	supplyAdditionalNotes: string;
}

export const GenericMatchDetails = ({
	consultingExpertiseTitle,
	consultingExpertiseDescription,
	match,
	loading,
	supplyTechnology,
	supplyBusinessArea,
	supplyTechnologyOther,
	supplyBusinessAreaOther,
	supplyAdditionalDocs,
	supplyAdditionalNotes,
}: GenericDetailsViewProps) => {
	const intl = useIntl();

	const localizedTopics = supplyBusinessArea
		?.map((topic: string) => {
			if (topic !== RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.OTHER)
				return intl.formatMessage({ id: `resources.question.service.values.${topic}` });
		})
		.filter((value) => value !== undefined);

	let localizedOtherTopic = '';
	if (
		supplyBusinessAreaOther &&
		supplyBusinessArea.includes(RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.OTHER)
	) {
		localizedOtherTopic = `${intl.formatMessage({
			id: 'resources.question.service.values.other',
		})} : ${supplyBusinessAreaOther}`;
	}

	const localizedServices = supplyTechnology
		?.map((service: string) => {
			if (service !== RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KINDS.VALUES.OTHER)
				return intl.formatMessage({ id: `resources.question.topics.values.${service}` });
		})
		.filter((value) => value !== undefined);

	let localizedOtherService = '';
	if (
		supplyTechnologyOther &&
		supplyBusinessArea.includes(RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KINDS.VALUES.OTHER)
	) {
		localizedOtherService = `${intl.formatMessage({
			id: 'resources.question.topics.values.other',
		})} : ${supplyTechnologyOther}`;
	}
	return (
		<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
			<Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
				{consultingExpertiseTitle}
			</Typography>
			{loading ? (
				<Skeleton width={80} />
			) : (
				<Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
					{consultingExpertiseDescription}
				</Typography>
			)}
			<Typography variant="overline">
				{' '}
				<FormattedMessage id="questions.details.generic.title.status" />
			</Typography>

			{match.status ? <RespondentMatchStatus loading={loading} status={match.status} /> : '-'}

			<Typography variant="overline">
				<FormattedMessage id="questions.details.generic.title.matchDate" />
			</Typography>
			{loading ? (
				<Skeleton width={80} />
			) : (
				<Typography variant="body1">
					{intl.formatDate(match.createdAt, {
						day: 'numeric',
						year: 'numeric',
						month: 'long',
					})}
				</Typography>
			)}

			<Typography variant="overline">
				{' '}
				<FormattedMessage id="questions.details.generic.title.technology" />
			</Typography>
			{loading ? (
				<Skeleton width={80} />
			) : (
				<>
					<Typography variant="body1">{localizedTopics?.join(' | ')}</Typography>
					{localizedOtherTopic !== '' && (
						<Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
							{localizedOtherTopic}
						</Typography>
					)}
				</>
			)}

			<Typography variant="overline">
				<FormattedMessage id="questions.details.generic.title.businessFocusArea" />
			</Typography>
			{loading ? (
				<Skeleton width={80} />
			) : (
				<>
					<Typography variant="body1">{localizedServices?.join(' | ')}</Typography>
					{localizedOtherService !== '' && (
						<Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
							{localizedOtherService}
						</Typography>
					)}
				</>
			)}

			{loading ? (
				<Skeleton width={80} />
			) : (
				(supplyAdditionalDocs || supplyAdditionalNotes) && (
					<>
						<Divider variant="middle" sx={{ bgcolor: AQUGA_NEUTRAL_30 }} />
						<Typography variant="overline">
							<FormattedMessage id="questions.details.generic.title.additionalInformation" />
						</Typography>
					</>
				)
			)}
			{loading ? (
				<Skeleton width={80} />
			) : (
				supplyAdditionalDocs &&
				supplyAdditionalDocs.map((doc: any) => (
					<File key={doc?.id_file} url={doc?.url} fileName={doc?.file_name} />
				))
			)}
			{supplyAdditionalNotes && (
				<Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
					{supplyAdditionalNotes}
				</Typography>
			)}
		</Stack>
	);
};

import {
	MutationPrepareAndInitiateReverseAuctionTransactionArgs,
	MutationTriggerManualTransactionTransitionArgs,
	MutationUpdateTransactedMatchArgs,
} from '@aquga/services/randevuApi/generatedTypes';
import { print } from 'graphql';
import gql from 'graphql-tag';

const TRIGGER_MANUAL_TRANSACTION_TRANSITION = gql`
	mutation TRIGGER_MANUAL_TRANSACTION_TRANSITION($id_transaction: ID!, $transition_tech_name: String!) {
		triggerManualTransactionTransition(id_transaction: $id_transaction, transition_tech_name: $transition_tech_name)
	}
`;

const triggerManualTransactionTransition = ({
	id_transaction,
	transition_tech_name,
}: MutationTriggerManualTransactionTransitionArgs) => {
	return JSON.stringify({
		query: print(TRIGGER_MANUAL_TRANSACTION_TRANSITION),
		variables: { id_transaction, transition_tech_name },
	});
};

const ACCEPT_TRANSACTED_MATCH = gql`
	mutation ACCEPT_TRANSACTED_MATCH($id_transaction: ID!) {
		acceptTransactedMatch(id_transaction: $id_transaction)
	}
`;

interface AcceptTransactedMatch {
	id_transaction: string;
}
const acceptTransactedMatch = ({ id_transaction }: AcceptTransactedMatch) => {
	return JSON.stringify({
		query: print(ACCEPT_TRANSACTED_MATCH),
		variables: { id_transaction },
	});
};

const DECLINE_TRANSACTED_MATCH = gql`
	mutation DECLINE_TRANSACTED_MATCH($id_transaction: ID!) {
		declineTransactedMatch(id_transaction: $id_transaction)
	}
`;

interface DeclinetTransactedMatch {
	id_transaction: string;
}
const declineTransactedMatch = ({ id_transaction }: DeclinetTransactedMatch) => {
	return JSON.stringify({
		query: print(DECLINE_TRANSACTED_MATCH),
		variables: { id_transaction },
	});
};

const PREPARE_AND_INITIATE_REVERSE_AUCTION_TRANSACTION = gql`
	mutation PREPARE_AND_INITIATE_REVERSE_AUCTION_TRANSACTION(
		$transaction_tech_name: String
		$supply_filter: [FieldFilterInput!]!
		$provider_filter: [FieldFilterInput!]!
		$consumer_filter: [FieldFilterInput!]!
		$fields: [FieldInput!]
	) {
		prepareAndInitiateReverseAuctionTransaction(
			transaction_tech_name: $transaction_tech_name
			supply_filter: $supply_filter
			consumer_filter: $consumer_filter
			provider_filter: $provider_filter
			fields: $fields
		) {
			id
		}
	}
`;

const prepareAndInitiateReverseAuctionTransaction = ({
	transaction_tech_name,
	supply_filter,
	provider_filter,
	consumer_filter,
	fields,
}: MutationPrepareAndInitiateReverseAuctionTransactionArgs) => {
	return JSON.stringify({
		query: print(PREPARE_AND_INITIATE_REVERSE_AUCTION_TRANSACTION),
		variables: {
			transaction_tech_name,
			supply_filter,
			provider_filter,
			consumer_filter,
			fields,
		},
	});
};

const UPDATE_TRANSACTED_MATCH = gql`
	mutation UPDATE_TRANSACTED_MATCH($id_transaction: ID!, $fields: [FieldInput!]) {
		updateTransactedMatch(id_transaction: $id_transaction, fields: $fields)
	}
`;
const updateTransactedMatch = ({ id_transaction, fields }: MutationUpdateTransactedMatchArgs) => {
	return JSON.stringify({
		query: print(UPDATE_TRANSACTED_MATCH),
		variables: {
			id_transaction,
			fields,
		},
	});
};

export {
	acceptTransactedMatch,
	declineTransactedMatch,
	prepareAndInitiateReverseAuctionTransaction as initiateReverseAuctionTransaction,
	triggerManualTransactionTransition,
	updateTransactedMatch,
};

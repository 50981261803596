import { AQUGA_BLUE_10, AQUGA_BLUE_30 } from '@aquga/styles/theme';
import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

interface ItemProps {
	active: boolean;
}
const Item = ({ active }: ItemProps) => {
	const sidePadding = active ? '1.33rem' : '0.666rem';
	return (
		<>
			<Paper
				component="div"
				elevation={0}
				style={{ paddingLeft: sidePadding, paddingRight: sidePadding, paddingTop: '3px', paddingBottom: '3px' }}
				sx={{
					backgroundColor: active ? AQUGA_BLUE_30 : AQUGA_BLUE_10,
					borderRadius: 0,
				}}
			/>
		</>
	);
};

interface OnboardingStepperProps {
	currentStep: number;
	numberOfSteps: number;
}

const OnboardingStepper = ({ currentStep, numberOfSteps }: OnboardingStepperProps) => {
	const intl = useIntl();
	return (
		<Stack direction="column" spacing={2} sx={{ paddingRight: '1rem' }}>
			<Typography variant="body2" color="secondary" textAlign="center">
				{`${intl.formatMessage({ id: 'common.onboardingStepper' })} ${currentStep}/${numberOfSteps}`}
			</Typography>
			<Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
				{[...Array(numberOfSteps)].map((step, index) => (
					<Item key={index} active={currentStep === index + 1} />
				))}
			</Stack>
		</Stack>
	);
};

export default OnboardingStepper;

import { isBusinessSide } from '@aquga/app/participantHelpers';
import { Question, TicketFilter } from '@aquga/components/questions/types';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import { Theme, useMediaQuery } from '@mui/material';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Box from '@mui/system/Box';

import ListQuestionsFilter from './ListQuestionsFilter';
import ListQuestionsTop from './ListQuestionsTop';
import ProviderQuestionsList from './ProviderQuestionsList';
import ProviderQuestionsTable from './ProviderQuestionsTable';
import QuestionsList from './QuestionsList';
import QuestionsTable from './QuestionsTable';

interface ListQuestionsViewProps {
	loading: boolean;
	items: Question[];
	filter: TicketFilter;
	onFilter: (params: any) => any;
	onSeeDetails: (params: any) => any;
}
const ListQuestionsView = ({ loading, items, filter, onFilter, onSeeDetails }: ListQuestionsViewProps) => {
	const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const isBusiness = isBusinessSide(currentParticipant);

	return (
		<Box component={Paper} sx={{ pl: 4, pr: 4, pt: 4, pb: 10, width: '100%' }}>
			{isBusiness && (
				<>
					<ListQuestionsTop />
					<Divider sx={{ mt: 3, mb: 3 }} />
				</>
			)}
			<ListQuestionsFilter loading={loading} selected={filter} onFilter={onFilter} count={items?.length} />
			<br />
			{largeScreen ? (
				isBusiness ? (
					<QuestionsTable loading={loading} items={items} onSeeDetails={onSeeDetails} />
				) : (
					<ProviderQuestionsTable loading={loading} items={items} onSeeDetails={onSeeDetails} />
				)
			) : isBusiness ? (
				<QuestionsList loading={loading} items={items} />
			) : (
				<ProviderQuestionsList loading={loading} items={items} />
			)}
		</Box>
	);
};

export default ListQuestionsView;

import { AQUGA_NATURAL_60 } from '@aquga/styles/theme';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';

import MatchesTable from './MatchesTable';

interface NoMatchesProps {
	text: string;
}
const NoMatches = ({ text }: NoMatchesProps) => (
	<Typography color="textSecondary" variant="body1">
		{text}
	</Typography>
);

interface QuestionMatchesProps {
	loading: boolean;
	subTransactions: any[] | undefined; // TODO ADD definition of the match
	questionStatus: string; // TODO ADD definition of the match
	pt?: number;
	pb?: number;
	onSeeDetails: (params: any) => any;
}

const QuestionMatches = ({ loading, subTransactions, pt = 4.5, pb = 4.5, onSeeDetails }: QuestionMatchesProps) => {
	const intl = useIntl();

	return (
		<Box sx={{ pl: 1.5, pr: 1.5, pt, pb, width: '100%' }}>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={2}
				sx={{ pl: 2, pr: 2, pb: 0, width: '100%' }}
			>
				<Typography sx={{ color: AQUGA_NATURAL_60 }} variant="h6">
					<FormattedMessage id="questions.details.sectionTitleMatches" />{' '}
				</Typography>

				{loading && <Skeleton width={250} height={80} />}

				{!loading && subTransactions != undefined && subTransactions?.length >= 1 && (
					<MatchesTable loading={loading} items={subTransactions} onSeeDetails={onSeeDetails} />
				)}
				{!loading && (subTransactions === undefined || subTransactions?.length === 0) && (
					<NoMatches text={intl.formatMessage({ id: 'questions.details.explainerPoint1' })} />
				)}
			</Stack>
		</Box>
	);
};

export default QuestionMatches;

import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectLoading } from '@aquga/store/slices/authSlice';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, Grid, IconButton, InputAdornment, List, Theme, Typography, useMediaQuery } from '@mui/material';
import { TextField } from 'mui-rff';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useIntl, FormattedMessage } from 'react-intl';

import PasswordFeedback from './PasswordFeedback';

interface ChoosePasswordFormProps {
	onSubmit: (values: ChoosePasswordFormValues) => any;
	onValidate: (values: ChoosePasswordFormValues) => ChoosePasswordFormValues | Record<string, never>;
	normalTitle: string;
	coloredTitle: string;
}

export interface ChoosePasswordFormValues {
	password: string;
}

const ChoosePasswordForm = ({ onSubmit, onValidate, normalTitle, coloredTitle }: ChoosePasswordFormProps) => {
	const intl = useIntl();
	const loading = useAppSelector(selectLoading);

	const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
	const [showPassword, setShowPassword] = useState(false);
	return (
		<Form
			onSubmit={onSubmit}
			validate={onValidate}
			render={({ handleSubmit, hasValidationErrors, values, pristine }) => {
				const currentPassword = values?.password;
				return (
					<form onSubmit={handleSubmit} noValidate={true}>
						<Grid
							container
							direction="column"
							alignItems="center"
							justifyContent="center"
							gap={4}
							textAlign="center"
						>
							<Grid item>
								<Typography variant="h4" component="h1">
									{normalTitle}
									<Typography variant="h4" component="span" color="primary">
										{' '}
										{coloredTitle}
									</Typography>
								</Typography>
							</Grid>
							<Grid item textAlign="center" width="inherit">
								<TextField
									fullWidth
									name="password"
									InputLabelProps={{ shrink: true }}
									required
									label={intl.formatMessage({ id: 'registerPage.activateAccount.passwordLabel' })}
									placeholder={intl.formatMessage({
										id: 'registerPage.activateAccount.passwordPlaceholder',
									})}
									type={showPassword ? 'text' : 'password'}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => setShowPassword(!showPassword)}
													onMouseDown={() => setShowPassword(!showPassword)}
												>
													{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item textAlign="center" width="inherit">
								<Typography textAlign="left" variant="subtitle1">
									{' '}
									<FormattedMessage id="registerPage.activateAccount.passwordExplainer" />
								</Typography>
								<List dense={true}>
									<PasswordFeedback password={currentPassword} />
								</List>
							</Grid>
							<Grid item textAlign="center" width="inherit">
								<RdvLoadingButton
									variant="contained"
									color="primary"
									type="submit"
									loading={loading}
									disabled={hasValidationErrors && pristine}
									loadingPosition="center"
									fullWidth={largeScreen ? false : true}
								>
									<FormattedMessage id="registerPage.activateAccount.buttonText" />
								</RdvLoadingButton>
							</Grid>
						</Grid>
					</form>
				);
			}}
		/>
	);
};

export default ChoosePasswordForm;

import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';

interface WhiteBackgroundProps {
	pl?: number;
	pr?: number;
	pt?: number;
	pb?: number;
	children: React.ReactNode;
}
const WhiteBackground = ({ pl = 3, pr = 3, pt = 5, pb = 5, children }: WhiteBackgroundProps) => {
	return (
		<Box component={Paper} sx={{ pl: pl, pr: pr, pt: pt, pb: pb, width: '100%' }}>
			{children}
		</Box>
	);
};

export default WhiteBackground;

import { TicketFilter } from '@aquga/components/questions/types';
import { AQUGA_NATURAL_20 } from '@aquga/styles/theme';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { useIntl } from 'react-intl';

interface ListQuestionsFilterProps {
	loading: boolean;
	selected: TicketFilter;
	count: number;
	onFilter: (params: any) => any;
}
const ListQuestionsFilter = ({
	loading = false,
	selected = 'active',
	count = 0,
	onFilter,
}: ListQuestionsFilterProps) => {
	const intl = useIntl();
	const theme = useTheme();
	const isNotSmallScreen = useMediaQuery(theme.breakpoints.up('md'));

	const counterLabel =
		count >= 2
			? intl.formatMessage({ id: 'questions.list.questionCounter.plural' })
			: intl.formatMessage({ id: 'questions.list.questionCounter.singular' });

	return (
		<Box sx={{ backgroundColor: AQUGA_NATURAL_20, pl: 1.5, pr: 1.5, borderRadius: 3 }}>
			<Stack
				direction="row"
				justifyContent={isNotSmallScreen ? 'space-between' : 'center'}
				alignItems="center"
				spacing={2}
			>
				<Tabs value={selected} variant="scrollable" allowScrollButtonsMobile>
					<Tab
						sx={{ color: 'gray', textTransform: 'none' }}
						value="all"
						label={
							<Typography variant="subtitle2">
								{intl.formatMessage({ id: 'questions.list.filter.all' })}
							</Typography>
						}
						onClick={() => onFilter('all')}
					/>
					<Tab
						sx={{ color: 'gray', textTransform: 'none' }}
						value="active"
						label={
							<Typography variant="subtitle2">
								{intl.formatMessage({ id: 'questions.list.filter.active' })}
							</Typography>
						}
						onClick={() => onFilter('active')}
					/>
					<Tab
						sx={{ color: 'gray', textTransform: 'none' }}
						value="past"
						label={
							<Typography variant="subtitle2">
								{intl.formatMessage({ id: 'questions.list.filter.past' })}
							</Typography>
						}
						onClick={() => onFilter('past')}
					/>
				</Tabs>

				{isNotSmallScreen && (
					<Typography variant="caption" color="textSecondary">
						{loading ? <Skeleton width="50px" /> : `${count} ${counterLabel}`}
					</Typography>
				)}
			</Stack>
		</Box>
	);
};

export default ListQuestionsFilter;

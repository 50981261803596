import { ASSETS } from '@aquga/app/constants';
import { isBusinessSide } from '@aquga/app/participantHelpers';
import { ROUTES } from '@aquga/config/routes';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Box, Stack } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const NoQuestionsAskedView = () => {
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const isBusiness = isBusinessSide(currentParticipant);
	return (
		<Box component={Paper} sx={{ pl: 2, pr: 2, pt: 10, pb: 10, width: '100%' }}>
			<Stack justifyContent="center" alignItems="center" spacing={3}>
				<Stack justifyContent="center" alignItems="center" spacing={0}>
					<img
						alt="Ask your first question"
						style={{ width: 200, height: 200 }}
						src={
							isBusiness ? ASSETS.NO_QUESTIONS_ASKED_YET_CONSUMER : ASSETS.NO_QUESTIONS_ASKED_YET_PROVIDER
						}
					/>

					<Typography
						color="textSecondary"
						fontWeight="bold"
						sx={{
							marginTop: '-30px',
							zIndex: 100,
						}}
					>
						{isBusiness ? (
							<FormattedMessage id="questions.list.noQuestionsAskedYet" />
						) : (
							<FormattedMessage id="questions.list.noQuestionsYet" />
						)}
					</Typography>
				</Stack>
				{isBusiness && (
					<Button component={Link} to={ROUTES.CONSUMER_CREATE_TICKET} color="primary" variant="contained">
						<FormattedMessage id="questions.create.breadcrumbs.addQuestion" />
					</Button>
				)}
			</Stack>
		</Box>
	);
};

export default NoQuestionsAskedView;

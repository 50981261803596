import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentLanguage, setLanguage } from '@aquga/store/slices/uiSlice';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Stack } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';

interface LanguageDialogProps {
	open: boolean;

	onClose: () => any;
}

const LanguageDialog = ({ open, onClose }: LanguageDialogProps) => {
	const intl = useIntl();
	const dispatch = useAppDispatch();

	const currentLanguage = useAppSelector(selectCurrentLanguage);
	const isGermanSelected = currentLanguage?.includes('DE');

	return (
		<>
			<Dialog maxWidth="sm" open={open} onClose={onClose}>
				<DialogTitle>
					<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
						<FormattedMessage id="languageDialog.title" />
						<IconButton color="inherit" onClick={onClose}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<List aria-label="supported languages">
						<ListItem>
							<ListItemButton
								dense
								selected={isGermanSelected}
								disabled={isGermanSelected}
								onClick={() => dispatch(setLanguage('de-DE'))}
							>
								<ListItemIcon sx={{ minWidth: '40px' }}>{'🇩🇪'}</ListItemIcon>
								<ListItemText
									primary={intl.formatMessage({ id: 'langs.german' })}
									primaryTypographyProps={{ fontWeight: 'bold' }}
								/>
							</ListItemButton>
						</ListItem>
						<ListItem>
							<ListItemButton
								dense
								selected={!isGermanSelected}
								disabled={!isGermanSelected}
								onClick={() => dispatch(setLanguage('en-US'))}
							>
								<ListItemIcon sx={{ minWidth: '40px' }}>{'🇺🇸'}</ListItemIcon>
								<ListItemText
									primary={intl.formatMessage({ id: 'langs.english_us' })}
									primaryTypographyProps={{ fontWeight: 'bold' }}
								/>
							</ListItemButton>
						</ListItem>
					</List>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default LanguageDialog;

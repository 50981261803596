import { isBusinessSide } from '@aquga/app/participantHelpers';
import QuestionStatus from '@aquga/components/questions/QuestionStatus';
import { Question } from '@aquga/components/questions/types';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import { AQUGA_NATURAL_10, AQUGA_NATURAL_20 } from '@aquga/styles/theme';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormattedDate, FormattedMessage } from 'react-intl';

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: AQUGA_NATURAL_20,
	},
}));

const StyledTableRow = styled(TableRow)(() => ({
	border: 0,
	'&:hover': {
		backgroundColor: AQUGA_NATURAL_20,
		cursor: 'pointer',
	},
}));

interface QuestionsTableProps {
	loading: boolean;
	items: Question[];
	onSeeDetails: (params: any) => any;
}

const QuestionsTable = ({ loading, items = [], onSeeDetails }: QuestionsTableProps) => {
	return (
		<Box sx={{ backgroundColor: AQUGA_NATURAL_10, borderRadius: 3 }}>
			<TableContainer sx={{ backgroundColor: AQUGA_NATURAL_10 }}>
				<Table
					sx={{
						minWidth: 700,
						[`& .${tableCellClasses.root}`]: {
							borderBottom: 'none',
						},
					}}
				>
					<TableHead>
						<TableRow>
							<StyledTableCell>
								<Typography variant="subtitle2">
									<FormattedMessage id="questions.list.table.columnQuestion" />
								</Typography>
							</StyledTableCell>
							<StyledTableCell>
								<Typography variant="subtitle2">
									<FormattedMessage id="questions.list.table.columnToBeAnswered" />
								</Typography>
							</StyledTableCell>
							<StyledTableCell>
								<Typography variant="subtitle2">
									<FormattedMessage id="questions.list.table.columnMatches" />
								</Typography>
							</StyledTableCell>
							<StyledTableCell>
								<Typography variant="subtitle2">
									<FormattedMessage id="questions.list.table.columnStatus" />
								</Typography>
							</StyledTableCell>
							<StyledTableCell></StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{items?.map((item: Question) => {
							// Workaround - to be done properly with native css ellipsis
							let truncationTitle = item.title;
							if (item?.title?.length >= 125) {
								truncationTitle = `${item.title.substring(0, 127)}...`;
							}

							return (
								<StyledTableRow key={item.id} onClick={() => onSeeDetails(item.id)}>
									<StyledTableCell component="th" scope="row">
										<Typography
											//loading={loading}
											variant="body1"
											gutterBottom
											sx={{ wordWrap: 'break-word', width: '30rem', lineHeight: 1.5 }}
										>
											{loading ? <Skeleton /> : truncationTitle}
										</Typography>
									</StyledTableCell>
									<StyledTableCell>
										<Typography variant="body1">
											{loading ? (
												<Skeleton />
											) : item.toBeAnsweredUntil !== 'ASAP' ? (
												<FormattedDate
													value={item.toBeAnsweredUntil}
													day="numeric"
													year="numeric"
													month="long"
												/>
											) : (
												'ASAP'
											)}
										</Typography>
									</StyledTableCell>
									<StyledTableCell>
										<Typography variant="body1">
											{loading ? <Skeleton /> : item.matches === 0 ? '-' : item.matches}
										</Typography>
									</StyledTableCell>
									<StyledTableCell>
										<Typography variant="body1" component="div">
											{loading ? (
												<Skeleton />
											) : (
												<QuestionStatus loading={loading} status={item.status} />
											)}
										</Typography>
									</StyledTableCell>
									<StyledTableCell>
										{!loading && <ArrowForwardIosIcon fontSize="small" />}
									</StyledTableCell>
								</StyledTableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default QuestionsTable;

import { ROUTES } from '@aquga/config/routes';
import { Theme, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/system/Stack';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const ListQuestionsTop = () => {
	const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

	return (
		<Stack direction="row" alignItems="center" justifyContent={largeScreen ? 'flex-end' : 'center'}>
			<Button
				component={Link}
				to={ROUTES.CONSUMER_CREATE_TICKET}
				color="primary"
				variant="contained"
				fullWidth={!largeScreen}
			>
				<FormattedMessage id="common.btnAskQuestions" />
			</Button>
		</Stack>
	);
};

export default ListQuestionsTop;

import File from '@aquga/components/common/File';
import DeclineContractDialog from '@aquga/components/matchDialogs/DeclineContractDialog';
import ContractMatchStatus from '@aquga/components/questions/status/ContractMatchStatus';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppDispatch } from '@aquga/store/configureStore';
import { acceptContract, updateTicketFailed } from '@aquga/store/slices/transactionSlice';
import { AQUGA_NATURAL_30 } from '@aquga/styles/theme';
import { Button, CircularProgress, Container, Paper, Skeleton, Stack, Typography } from '@mui/material';
import arrayMutators from 'final-form-arrays';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import DropZoneField from './DropZoneField';
import RdvLoadingButton from './RdvLoadingButton';

interface UploadContractFormValues {
	contract: File[];
}

interface ConsultantContractOfferedViewProps {
	status: string;
	doc: any;
	transactionId: string;
	loading: boolean;
}

export const ConsultantContractOfferedView = ({
	status,
	doc,
	transactionId,
	loading,
}: ConsultantContractOfferedViewProps) => {
	const [isDeclineDialogOpen, setDeclineDialogOpen] = useState(false);
	const intl = useIntl();
	const dispatch = useAppDispatch();

	const onSubmit = async (values: UploadContractFormValues) => {
		const contract = {
			tech_name: RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.CONTRACT_SIGNED_BY_CONSULTANT,
			value: values.contract,
		};
		const resolvedAction = await dispatch(
			acceptContract({ transactionId: transactionId, contract_field: contract })
		);

		if (resolvedAction.type === updateTicketFailed.type) {
			const submissionErrors = { ...resolvedAction.payload };

			// TODO: write nice helper function which does this one
			Object.entries(submissionErrors).map((keyValue) => {
				submissionErrors[keyValue[0]] = intl.formatMessage({ id: keyValue[1] as string });
			});

			return submissionErrors;
		}
	};

	return (
		<Form
			onSubmit={onSubmit}
			mutators={{
				...arrayMutators,
			}}
		>
			{({
				handleSubmit,
				form: {
					mutators: { push },
				},
				values,
				submitting,
			}) => (
				<form onSubmit={handleSubmit} noValidate>
					<DeclineContractDialog
						transactionId={transactionId}
						open={isDeclineDialogOpen}
						onClose={() => setDeclineDialogOpen(false)}
					/>
					<Stack direction="column" spacing={2} justifyContent="flex-start" alignItems="flex-start">
						<ContractMatchStatus status={status} loading={loading} />
						{loading ? (
							<Skeleton width={120} />
						) : !doc ? (
							<Stack
								direction="row"
								spacing={2}
								justifyContent="flex-start"
								alignItems="center"
								component={Paper}
								p="10px 5px 10px "
								width="100%"
								sx={{
									backgroundColor: AQUGA_NATURAL_30,
									borderRadius: 3,
								}}
							>
								<CircularProgress sx={{ color: 'primary', ml: 2 }} size={20} />
								<Typography component="span">
									<FormattedMessage id="questions.details.contract.generating" />
								</Typography>
							</Stack>
						) : (
							<File key={doc?.id_file} url={doc?.url} fileName={doc?.file_name} />
						)}
						<Stack direction="row" justifyContent="space-between" width="100%">
							<Button
								variant="outlined"
								color="primary"
								onClick={() => setDeclineDialogOpen(true)}
								disabled={!doc || submitting || loading}
							>
								<FormattedMessage id="questions.details.contractOfferedView.btnDecline" />
							</Button>

							<RdvLoadingButton
								variant="contained"
								color="primary"
								type="submit"
								disabled={!doc || submitting || loading}
								loading={loading}
								loadingPosition="center"
							>
								<FormattedMessage id="questions.details.contractOfferedView.btnAccept" />
							</RdvLoadingButton>
						</Stack>
					</Stack>
				</form>
			)}
		</Form>
	);
};

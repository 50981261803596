import { SECURITY_FORMS_MAX_WIDTH } from '@aquga/app/constants';
import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { Button, Grid, MenuItem, Theme, Typography, useMediaQuery } from '@mui/material';
import { Select, TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

export interface WhatIsYourCompanyViewValues {
	company_name: string;
	industries: string;
	vat_id: string;
}

interface WhatIsYourCompanyViewProps {
	userType: any;
	loading: boolean;
}

const WhatIsYourCompanyView = ({ userType, loading }: WhatIsYourCompanyViewProps) => {
	const intl = useIntl();
	const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

	const industryOptions = [
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.automotive',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.AUTOMOTIVE,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.mechanical_electrical_engineering',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.MECHANICAL_ELECTRICAL_ENGINEERING,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.chemistry_pharmaceuticals',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.CHEMISTRY_PHARMACEUTICALS,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.consumer',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.CONSUMER,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.trade_retail',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.TRADE_RETAIL,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.logistics',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.LOGISTICS,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.service',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.SERVICE,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.banking_insurance',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.BANKING_INSURANCE,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.energy',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.ENERGY,
		},
		{
			label: intl.formatMessage({
				id: 'participantOnboardingPage.forms.onboarding.industries.aerospace_and_defense',
			}),
			value: userType.FIELDS.INDUSTRIES.VALUES.AEROSPACE_AND_DEFENSE,
		},
	];

	return (
		<Grid
			maxWidth={SECURITY_FORMS_MAX_WIDTH}
			container
			direction="column"
			width="100%"
			alignItems="center"
			justifyContent="center"
			spacing={3}
			paddingRight={largeScreen ? 14 : 2}
			paddingLeft={largeScreen ? 14 : 2}
		>
			<Grid item xs={12} width="inherit">
				<Typography variant="h4" component="h1" textAlign="center">
					<FormattedMessage id="participantOnboardingPage.forms.onboarding.title.normalTitle" />
					<Typography variant="h4" component="span" color="primary">
						<FormattedMessage id="participantOnboardingPage.forms.onboarding.title.coloredTitle" />
					</Typography>
				</Typography>
			</Grid>
			<Grid item textAlign="center" width="inherit">
				<TextField
					InputLabelProps={{ shrink: true }}
					required
					name="company_name"
					label={intl.formatMessage({ id: 'participantOnboardingPage.forms.onboarding.companyName.label' })}
					placeholder={intl.formatMessage({
						id: 'participantOnboardingPage.forms.onboarding.companyName.placeholder',
					})}
					margin="normal"
				/>
			</Grid>
			<Grid item textAlign="left" width="inherit">
				<Select
					label={intl.formatMessage({
						id: 'participantOnboardingPage.forms.onboarding.industryField',
					})}
					name="industries"
					required
					data={industryOptions}
				/>
			</Grid>
			<Grid item textAlign="center" width="inherit">
				<TextField
					InputLabelProps={{ shrink: true }}
					required
					name="vat_id"
					label={intl.formatMessage({ id: 'participantOnboardingPage.forms.onboarding.vatId.label' })}
					placeholder={intl.formatMessage({
						id: 'participantOnboardingPage.forms.onboarding.vatId.placeholder',
					})}
					margin="normal"
					fullWidth
				/>
			</Grid>

			<Grid item textAlign="center" width="inherit">
				<RdvLoadingButton
					variant="contained"
					color="primary"
					type="submit"
					loading={loading}
					loadingPosition="center"
					fullWidth={largeScreen ? false : true}
				>
					<FormattedMessage id="participantOnboardingPage.forms.onboarding.buttonText" />
				</RdvLoadingButton>
			</Grid>
		</Grid>
	);
};

export default WhatIsYourCompanyView;

import { isBusinessSide } from '@aquga/app/participantHelpers';
import ReauthenticateDialog from '@aquga/components/common/ReauthenticationDialog';
import MainLayout from '@aquga/components/layout/MainLayout';
import PublicLayout from '@aquga/components/layout/PublicLayout';
import { ROUTES } from '@aquga/config/routes';
import ContractsPage from '@aquga/pages/ContractsPage';
import DashboardPage from '@aquga/pages/DashboardPage';
import ParticipantOnboardingPage from '@aquga/pages/onboarding/ParticipantOnboardingPage';
import ParticipantRejectedPage from '@aquga/pages/onboarding/ParticipantRejectedPage';
import ParticipantAccountPage from '@aquga/pages/ParticipantAccountPage';
import AccountActivationPage from '@aquga/pages/security/AccountActivationPage';
import ChooseNewPasswordPage from '@aquga/pages/security/ChooseNewPasswordPage';
import ForgotPasswordPage from '@aquga/pages/security/ForgotPasswordPage';
import LoginPage from '@aquga/pages/security/LoginPage';
import RegistrationBusinessPage from '@aquga/pages/security/RegistrationBusinessPage';
import RegistrationConsultingHousePage from '@aquga/pages/security/RegistrationConsultingHousePage';
import CreateAnsweringExpertisePage from '@aquga/pages/services/CreateAnsweringExpertisePage';
import MyAnsweringExpertise from '@aquga/pages/services/MyServices';
import UpdateAnsweringExpertisePage from '@aquga/pages/services/UpdateAnsweringExpertisePage';
import CreateTicketPage from '@aquga/pages/tickets/CreateTicketPage';
import ListTicketsPage from '@aquga/pages/tickets/ListTicketsPage';
import TicketDetailsPage from '@aquga/pages/tickets/TicketDetailsPage';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import { theme, themeBusinessSide, themeConsultingSide } from '@aquga/styles/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Route, Routes } from 'react-router-dom';

import PrivateLayout from './PrivateLayout';
import ProtectedRoutes from './ProtectedRoutes';

const GlobalLayout = () => {
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const participantSideTheme = isBusinessSide(currentParticipant) ? themeBusinessSide : themeConsultingSide;

	return (
		<>
			<ThemeProvider theme={theme}>
				<Routes>
					<Route element={<PublicLayout />}>
						<Route index element={<LoginPage />} />
						<Route path={ROUTES.SIGN_IN_PAGE} element={<LoginPage />} />
						<Route path={ROUTES.SIGN_UP_BUSINESS_PAGE} element={<RegistrationBusinessPage />} />
						<Route
							path={ROUTES.SIGN_UP_CONSULTING_HOUSE_PAGE}
							element={<RegistrationConsultingHousePage />}
						/>
						<Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
						<Route path={ROUTES.ACTIVATE_ACCOUNT} element={<AccountActivationPage />} />
						<Route path={ROUTES.CHOOSE_PASSWORD} element={<ChooseNewPasswordPage />} />
						{/* 	<Route path={ROUTES.PARTICIPANT_ONBOARDING} element={<PlasticMoulderOnboardingPage />} />
			<Route path={ROUTES.PARTICIPANT_REJECTED} element={<ParticipantRejectedPage />} />
			{/* 	<Route path="*" element={<NotFound404Page />} /> */}
					</Route>
				</Routes>
			</ThemeProvider>

			<ThemeProvider theme={participantSideTheme}>
				<ReauthenticateDialog />
				<CssBaseline />
				<Routes>
					<Route element={<ProtectedRoutes />}>
						<Route element={<PrivateLayout />}>
							<Route path={ROUTES.PARTICIPANT_ONBOARDING} element={<ParticipantOnboardingPage />} />
							<Route path={ROUTES.PARTICIPANT_REJECTED} element={<ParticipantRejectedPage />} />
						</Route>
						<Route element={<MainLayout />}>
							<Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
							<Route path={ROUTES.LIST_SERVICES} element={<MyAnsweringExpertise />} />
							<Route path={ROUTES.CREATE_SERVICE} element={<CreateAnsweringExpertisePage />} />
							<Route path={ROUTES.UPDATE_SERVICE} element={<UpdateAnsweringExpertisePage />} />
							<Route path={ROUTES.LIST_TICKETS} element={<ListTicketsPage />} />
							<Route path={ROUTES.CONSUMER_CREATE_TICKET} element={<CreateTicketPage />} />
							<Route path={ROUTES.TICKET_DETAILS} element={<TicketDetailsPage />} />
							<Route path={ROUTES.CONTRACTS} element={<ContractsPage />} />
							<Route path={ROUTES.PARTICIPANT_ACCOUNT} element={<ParticipantAccountPage />} />
						</Route>
					</Route>
				</Routes>
			</ThemeProvider>
		</>
	);
};

export default GlobalLayout;

import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentLanguage } from '@aquga/store/slices/uiSlice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LanguageIcon from '@mui/icons-material/Language';
import { IconButton, Typography } from '@mui/material';
import React from 'react';

import LanguageMenu from './LanguageMenu';

function LanguagePicker() {
	const currentLanguage = useAppSelector(selectCurrentLanguage);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton sx={{ backgroundColor: 'transparent' }} disableRipple onClick={handleClick}>
				<LanguageIcon />
				<Typography variant="caption">{currentLanguage?.toUpperCase().split('-')[0]}</Typography>
				<KeyboardArrowDownIcon />
			</IconButton>
			<LanguageMenu anchorEl={anchorEl} open={open} onClose={handleClose} />
		</>
	);
}

export default LanguagePicker;

import { EXTERNAL_URLS } from '@aquga/app/constants';
import { AQUGA_YELLOW_30 } from '@aquga/styles/theme';
import { Grid, Link, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const MobileView = () => {
	return (
		<Grid container spacing={1} textAlign="center">
			<Grid item xs={12} sm={12}>
				<Typography>
					<FormattedMessage id="footer.copyRight" />
				</Typography>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Typography
					marginRight={1}
					component={Link}
					sx={{ color: 'white' }}
					href={EXTERNAL_URLS.AQUGA_TERMS_OF_SERVICE}
					target="_blank"
				>
					Terms of Service
				</Typography>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Typography
					marginLeft={1}
					component={Link}
					href={EXTERNAL_URLS.AQUGA_PRIVACY_POLICY}
					sx={{ color: 'white' }}
					target="_blank"
				>
					Privacy Policy
				</Typography>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Typography component="span">Developed by </Typography>
				<Typography
					component={Link}
					href={EXTERNAL_URLS.RANDEVU_TECH}
					target="_blank"
					variant="caption"
					color={AQUGA_YELLOW_30}
				>
					randevu.tech
				</Typography>
			</Grid>
		</Grid>
	);
};

const DesktopView = () => {
	return (
		<Grid container direction="row" justifyContent="space-around" alignItems="flex-end" width="100%">
			<Grid item xs={12} sm={4}>
				<Typography variant="caption">
					<FormattedMessage id="footer.copyRight" />
				</Typography>
			</Grid>
			<Grid item xs={12} sm={4} textAlign="center" justifyContent="flex-end">
				<Grid container justifyContent="center">
					<Grid item>
						<Typography
							variant="caption"
							marginRight={1}
							component={Link}
							sx={{ color: 'white' }}
							href={EXTERNAL_URLS.AQUGA_TERMS_OF_SERVICE}
							target="_blank"
						>
							<FormattedMessage id="common.termsOfService" />
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant="caption"
							marginLeft={1}
							component={Link}
							sx={{ color: 'white' }}
							href={EXTERNAL_URLS.AQUGA_PRIVACY_POLICY}
							target="_blank"
						>
							<FormattedMessage id="common.privacyPolicy" />
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={4} textAlign="right">
				<Typography component="span" variant="caption">
					Powered by{' '}
				</Typography>
				<Typography
					component={Link}
					href={EXTERNAL_URLS.RANDEVU_TECH}
					target="_blank"
					variant="caption"
					color={AQUGA_YELLOW_30}
				>
					randevu.tech
				</Typography>
			</Grid>
		</Grid>
	);
};

function LowerFooterSegment() {
	const theme: Theme = useTheme();
	const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
	if (isLargeScreen) return <DesktopView />;

	return <MobileView />;
}

export default LowerFooterSegment;

import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import RdvLoadingTextButton from '@aquga/components/common/RdvLoadingTextButton';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import {
	applyForTicketProvider,
	declineTransactedTicket,
	selectProviderApplyForTicketLoading,
	selectProviderDeclineTicketLoading,
	triggerManualTransactionTransition,
} from '@aquga/store/slices/transactionSlice';
import { Button, Skeleton } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface ProviderOpenOfferOptionsViewProps {
	transactionId: string | undefined;
	loading: boolean;
}

export const ProviderOpenOfferOptionsView = ({ transactionId, loading }: ProviderOpenOfferOptionsViewProps) => {
	const dispatch = useAppDispatch();
	const applyForTicketLoading = useAppSelector(selectProviderApplyForTicketLoading);
	const declineTicketLoading = useAppSelector(selectProviderDeclineTicketLoading);

	return (
		<>
			{loading ? (
				<Skeleton width={100} />
			) : (
				<RdvLoadingTextButton
					variant="text"
					color="secondary"
					loading={declineTicketLoading}
					disabled={declineTicketLoading || applyForTicketLoading}
					onClick={() =>
						transactionId && dispatch(declineTransactedTicket({ id_transaction: transactionId }))
					}
				>
					<FormattedMessage id="questions.details.openOfferMatchOptions.btnNotInterested" />
				</RdvLoadingTextButton>
			)}
			{loading ? (
				<Skeleton width={100} />
			) : (
				<RdvLoadingButton
					variant="contained"
					loading={applyForTicketLoading}
					disabled={declineTicketLoading || applyForTicketLoading}
					onClick={() =>
						transactionId &&
						dispatch(
							applyForTicketProvider({
								id_transaction: transactionId,
							})
						)
					}
				>
					<FormattedMessage id="questions.details.openOfferMatchOptions.btnRequestToJoin" />
				</RdvLoadingButton>
			)}
		</>
	);
};

import { AQUGA_NATURAL_10, AQUGA_NATURAL_90 } from '@aquga/styles/theme';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface FileProps {
	fileName: string;
	url: string;
}

const File = ({ fileName, url }: FileProps) => {
	return (
		<>
			<Paper
				sx={{
					width: '100%',
					pt: 0.5,
					pb: 0.5,
					pl: 2,
					pr: 2,
					backgroundColor: AQUGA_NATURAL_10,
					borderRadius: 2,
				}}
				elevation={0}
			>
				<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
					<Typography variant="body1" sx={{ color: AQUGA_NATURAL_90, wordBreak: 'break-all' }}>
						{fileName?.toLowerCase()}
					</Typography>

					<IconButton
						aria-label={`Download ${fileName}`}
						component={Link}
						href={url}
						download={fileName}
						target="_blank"
						sx={{ color: AQUGA_NATURAL_90 }}
					>
						<DownloadIcon fontSize="large" />
					</IconButton>
				</Stack>
			</Paper>
		</>
	);
};

export default File;

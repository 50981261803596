// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useTheme } from '@emotion/react';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';

const RdvLoadingButton = (props: LoadingButtonProps) => {
	const theme = useTheme();

	let color = theme.palette.primary.main;
	if (props?.color) {
		color = theme.palette[props.color].main;
	}

	let indicatorColor = theme.palette.action.disabled;
	if (props?.color === 'primary') indicatorColor = 'white';
	const isLoadingStyle = props.disabled === true && props.loading === true;

	const customLoadingStyle = {
		'&:disabled': {
			backgroundColor: color,
		},
		color: 'white',
	};
	return (
		<LoadingButton
			sx={isLoadingStyle ? { ...customLoadingStyle } : {}}
			loadingIndicator={<CircularProgress sx={{ color: indicatorColor }} size={16} />}
			{...props}
		>
			{props?.children}
		</LoadingButton>
	);
};

export default RdvLoadingButton;

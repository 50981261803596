import File from '@aquga/components/common/File';
import ContractMatchStatus from '@aquga/components/questions/status/ContractMatchStatus';
import { AQUGA_NATURAL_30 } from '@aquga/styles/theme';
import { CircularProgress, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface ContractAcceptedViewProps {
	status: string;
	loading: boolean;
	contract: any;
}

export const ContractAcceptedView = ({ status, contract, loading }: ContractAcceptedViewProps) => {
	return (
		<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
			<ContractMatchStatus status={status} loading={loading} />
			{loading ? (
				<Skeleton width={120} />
			) : !contract ? (
				<Stack
					direction="row"
					spacing={2}
					justifyContent="flex-start"
					alignItems="center"
					component={Paper}
					p="10px 5px 10px "
					width="100%"
					sx={{
						backgroundColor: AQUGA_NATURAL_30,
						borderRadius: 3,
					}}
				>
					<CircularProgress sx={{ color: 'primary', ml: 2 }} size={20} />
					<Typography component="span">
						<FormattedMessage id="questions.details.contract.generating" />
					</Typography>
				</Stack>
			) : (
				<File key={contract?.id_file} url={contract?.url} fileName={contract?.file_name} />
			)}
		</Stack>
	);
};

import { findFieldByTechName, findFieldPurpose } from '@aquga/app/fieldHelpers';
import { isBusinessSide } from '@aquga/app/participantHelpers';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { ROUTES } from '@aquga/config/routes';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import { AQUGA_NATURAL_40 } from '@aquga/styles/theme';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const CurrentParticipantMenu = () => {
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const isBusiness = isBusinessSide(currentParticipant);

	const firstNameTechName = isBusiness
		? RANDEVU_IDS.USER_TYPE_CUSTOMER.FIELDS.FIRST_NAME.TECH_NAME
		: RANDEVU_IDS.USER_TYPE_CONSULTING_HOUSE.FIELDS.FIRST_NAME.TECH_NAME;

	const lastNameTechName = isBusiness
		? RANDEVU_IDS.USER_TYPE_CUSTOMER.FIELDS.LAST_NAME.TECH_NAME
		: RANDEVU_IDS.USER_TYPE_CONSULTING_HOUSE.FIELDS.LAST_NAME.TECH_NAME;

	const firstName = findFieldByTechName(currentParticipant?.fields, firstNameTechName)?.value;

	const lastName = findFieldByTechName(currentParticipant?.fields, lastNameTechName)?.value;

	return (
		<div>
			<Link to={ROUTES.PARTICIPANT_ACCOUNT} style={{ textDecoration: 'none' }}>
				<Box
					sx={{
						borderRadius: '8px',
						borderStyle: 'solid',
						padding: '16px',
						borderColor: AQUGA_NATURAL_40,
						borderWidth: 'thin',
						'&:hover': {
							cursor: 'pointer',
						},
					}}
				>
					<Stack direction="row" spacing={2} alignItems="center" justifyItems="space-between">
						<AccountCircleOutlinedIcon fontSize="large" sx={{ color: 'black' }} />

						<Box>
							<Typography fontWeight="bold" variant="subtitle2" color="black">
								{`${firstName} ${lastName}`}
							</Typography>
							{isBusiness ? (
								<Typography
									color="textSecondary"
									variant="overline"
									sx={{ textTransform: 'uppercase' }}
								>
									<FormattedMessage id="navigation.consumer.titleAsker" />
								</Typography>
							) : (
								<Typography
									color="textSecondary"
									variant="overline"
									sx={{ textTransform: 'uppercase' }}
								>
									<FormattedMessage id="navigation.consumer.titleConsultant" />
								</Typography>
							)}
						</Box>
					</Stack>
				</Box>
			</Link>
		</div>
	);
};

export default CurrentParticipantMenu;

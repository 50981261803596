import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface ForgotPasswordRequestedViewProps {
	email: string;
}

const ForgotPasswordRequestedView = ({ email }: ForgotPasswordRequestedViewProps) => {
	return (
		<Stack alignItems="center" gap={3}>
			<Typography variant="h4" component="h1" fontWeight="bold" textAlign="center">
				<FormattedMessage id="forgotPasswordPage.forgotPasswordRequestedView.title.normalTitle" />
				<Typography variant="h4" component="span" color="primary" fontWeight="bold">
					<FormattedMessage id="forgotPasswordPage.forgotPasswordRequestedView.title.coloredTitle" />
				</Typography>
			</Typography>

			<Typography variant="subtitle2">
				<FormattedMessage id="forgotPasswordPage.forgotPasswordRequestedView.body.title" />
			</Typography>
			<Stack alignItems="center">
				<Typography variant="body1">
					<FormattedMessage id="forgotPasswordPage.forgotPasswordRequestedView.body.text" />
				</Typography>
				<Typography variant="body1" fontWeight="bold">
					{email}
				</Typography>
			</Stack>

			{/* <Typography variant="body1">
				<FormattedMessage id="forgotPasswordPage.forgotPasswordRequestedView.body.linkExpTime" />
			</Typography> */}
		</Stack>
	);
};

export default ForgotPasswordRequestedView;

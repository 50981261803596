import { isBusinessSide } from '@aquga/app/participantHelpers';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import { AQUGA_NEUTRAL_0, AQUGA_PURPLE_20, AQUGA_DANGER_30, AQUGA_SUCCESS_40 } from '@aquga/styles/theme';
import { Skeleton } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useIntl } from 'react-intl';

interface RespondentMatchStatusProps {
	status: string;
	loading: boolean;
}

export const determineMatchStatusColor = (status: string) => {
	if (
		status?.toUpperCase() ==
		RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONTRACT_OFFERING.TECH_NAME.toUpperCase()
	)
		return { backgroundColor: AQUGA_PURPLE_20, color: AQUGA_NEUTRAL_0, fontWeight: 600 };
	if (
		status?.toUpperCase() ==
		RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONTRACT_OFFERED.TECH_NAME.toUpperCase()
	)
		return { backgroundColor: AQUGA_PURPLE_20, color: AQUGA_NEUTRAL_0, fontWeight: 600 };

	if (status?.toUpperCase() == RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CANCELLED.TECH_NAME.toUpperCase())
		return { backgroundColor: AQUGA_DANGER_30, color: AQUGA_NEUTRAL_0, fontWeight: 600 };

	if (
		status?.toUpperCase() ==
		RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONTRACT_WITHDRAWN.TECH_NAME.toUpperCase()
	)
		return { backgroundColor: AQUGA_DANGER_30, color: AQUGA_NEUTRAL_0, fontWeight: 600 };

	return { backgroundColor: AQUGA_SUCCESS_40, color: AQUGA_NEUTRAL_0, fontWeight: 600 };
};

// FIXME: @Nikola rename prop "status" to stateTypeName
const ContractMatchStatus = ({ status, loading }: RespondentMatchStatusProps) => {
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const businessSide = isBusinessSide(currentParticipant);
	const intl = useIntl();
	if (loading) return <Skeleton width={60} height={40} />;
	if (
		status?.toUpperCase() ==
			RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONSULTANT_APPLIED.TECH_NAME.toUpperCase() &&
		businessSide
	)
		return <p>-</p>;

	const checkedName =
		status?.toUpperCase() ===
		RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONTRACT_OFFERING.TECH_NAME.toUpperCase()
			? status
			: status?.toUpperCase() ===
			  RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONTRACT_OFFERED.TECH_NAME.toUpperCase()
				? status
				: status?.toUpperCase() ===
			  RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CANCELLED.TECH_NAME.toUpperCase()
					? status
					: status?.toUpperCase() ===
			  RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONTRACT_WITHDRAWN.TECH_NAME.toUpperCase()
						? status
						: RANDEVU_IDS.CONSULTANT_SUB_TRANSACTION.STATE_TYPES.CONTRACT_ACCEPTED.TECH_NAME;

	const label = intl.formatMessage({ id: `resources.subTransaction.stateTypes.${checkedName}` });

	const color = determineMatchStatusColor(status);

	return <Chip label={label} sx={color} />;
};

export default ContractMatchStatus;

import { Service } from '@aquga/components/services/types';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { ROUTES } from '@aquga/config/routes';
import { AQUGA_BLUE_30 } from '@aquga/styles/theme';
import { Button, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

interface ServicesTableProps {
	items: Service[];
}

interface ServiceProps {
	item: Service;
}

const ServiceComponent = ({ item }: ServiceProps) => {
	const navigate = useNavigate();
	// Workaround - to be done properly with native css ellipsis
	const intl = useIntl();
	let truncationTitle = item.name;

	if (item?.name?.length >= 125) {
		truncationTitle = `${item.name.substring(0, 127)}...`;
	}

	let localizedBusinessAreaOther = '';
	const localizedBusinessArea = item?.businessArea?.map((businessArea) =>
		intl.formatMessage({ id: `resources.service.service.values.${businessArea}` })
	);
	if (
		item?.businessAreaOther &&
		item?.businessArea?.includes(RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.OTHER)
	) {
		localizedBusinessAreaOther = `${intl.formatMessage({
			id: 'resources.service.service.values.other',
		})} : ${item?.businessAreaOther}`;
	}

	let localizedExpertiseOther = '';
	const localizedExpertise = item?.technology?.map((technology) =>
		intl.formatMessage({ id: `resources.service.topics.values.${technology}` })
	);
	if (
		item?.technologyOther &&
		item?.technology?.includes(RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KINDS.VALUES.OTHER)
	) {
		localizedExpertiseOther = `${intl.formatMessage({ id: 'resources.service.topics.values.other' })} : ${
			item?.technologyOther
		}`;
	}

	return (
		<>
			<Grid item xs={12}>
				<Typography fontWeight="600" variant="body1" mb={2}>
					<FormattedMessage id="questions.list.services.expertise" />
				</Typography>
				<Typography
					gutterBottom
					mb={1}
					sx={{ wordWrap: 'break-word', width: '100%', fontWeight: 450, lineHeight: 1.5 }}
				>
					{truncationTitle}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography fontWeight="600" variant="body1" mb={2}>
					<FormattedMessage id="questions.list.services.serviceProvided" />
				</Typography>
				<Typography color="textSecondary" variant="body2">
					{localizedBusinessArea?.join(' | ')}
				</Typography>
				{localizedBusinessAreaOther !== '' && (
					<Typography color="textSecondary" variant="body2">
						{localizedBusinessAreaOther}
					</Typography>
				)}
			</Grid>
			<Grid item xs={12}>
				<Typography fontWeight="600" variant="body1" mb={2}>
					<FormattedMessage id="questions.list.services.answeringExpertise" />
				</Typography>
				<Typography color="textSecondary" variant="body2">
					{localizedExpertise?.join(' | ')}
				</Typography>
				{localizedExpertiseOther !== '' && (
					<Typography color="textSecondary" variant="body2">
						{localizedExpertiseOther}
					</Typography>
				)}
			</Grid>
			<Grid item xs={12} textAlign="right">
				<Button
					variant="text"
					color="secondary"
					onClick={() => navigate(ROUTES.UPDATE_SERVICE.replace(':id', item?.id))}
				>
					<FormattedMessage id="questions.list.update" />
				</Button>
			</Grid>
		</>
	);
};

const ServicesList = ({ items = [] }: ServicesTableProps) => {
	const omitLastDivider = items?.length - 1;
	return (
		<>
			<Grid container spacing={3}>
				{items.map((item: Service, index) => (
					<>
						<ServiceComponent key={item.name} item={item} />
						{omitLastDivider !== index && (
							<Grid item xs={12}>
								<Divider />
							</Grid>
						)}
					</>
				))}
			</Grid>
		</>
	);
};

export default ServicesList;

import { Service } from '@aquga/components/services/types';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { Field, Supply } from '@aquga/services/randevuApi/generatedTypes';

import { findFieldByTechName } from './fieldHelpers';

export const mapSupplyFieldsToServiceFields = (supplies: Supply[]): Service[] => {
	return supplies.map((supply: Supply) => {
		return {
			id: supply.id,
			name: findFieldByTechName(
				supply?.fields as Field[],
				RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TITLE.TECH_NAME
			)?.value,
			technology: findFieldByTechName(
				supply?.fields as Field[],
				RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KINDS.TECH_NAME
			)?.value,
			businessArea: findFieldByTechName(
				supply?.fields as Field[],
				RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.TECH_NAME
			)?.value,
			technologyOther: findFieldByTechName(
				supply?.fields as Field[],
				RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KIND_OTHER.TECH_NAME
			)?.value,
			businessAreaOther: findFieldByTechName(
				supply?.fields as Field[],
				RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA_OTHER.TECH_NAME
			)?.value,
		};
	});
};

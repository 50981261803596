import { Field } from '@aquga/services/randevuApi/generatedTypes';
import { FormApi } from 'final-form';
import { pick } from 'lodash';

export const getDirtyFields = (values: any, form: FormApi) => {
	const dirtyFieldIds = Object.keys(form.getState().dirtyFields).map((id) => id.split('.')[0]);
	return pick(values, dirtyFieldIds);
};

export const mapFieldsToFormValues = (fields: Field[]) => {
	if (fields.length < 1) return {};

	return fields?.reduce((acc, currentField) => {
		return {
			...acc,
			[currentField.field_type.tech_name]: currentField.value,
		};
	}, {});
};

import ProviderUpdateRequirementsDialog from '@aquga/components/common/ProviderRequestChangesDialog';
import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppDispatch } from '@aquga/store/configureStore';
import {
	confirmRequirementsAndSetAgreedPrice,
	triggerManualTransactionTransition,
} from '@aquga/store/slices/transactionSlice';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface ProviderMeetingScheduledViewProps {
	messages: string[];
	transactionId: string;
	update_transition_tech_name: string;
	confirm_transition_tech_name: string;
	oppositeSideParticipantName: string;
	price?: number;
	loading: boolean;
}

export const ProviderMeetingScheduledView = ({
	messages,
	transactionId,
	oppositeSideParticipantName,
	update_transition_tech_name,
	confirm_transition_tech_name,
	price,
	loading,
}: ProviderMeetingScheduledViewProps) => {
	const dispatch = useAppDispatch();
	const [isUpdateRequirementsDialogOpen, setIsUpdateRequirementsDialogOpen] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const dispatchableAction = price
		? () =>
			confirmRequirementsAndSetAgreedPrice({
				id_transaction: transactionId,
				transition_tech_name: confirm_transition_tech_name,
				fields: [
					{
						tech_name: RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.AGREED_PRICE,
						value: {
							amount: Number(price * 100),
							unit: 'CENT',
							currency: 'EUR',
						},
					},
				],
			})
		: () =>
			triggerManualTransactionTransition({
				id_transaction: transactionId,
				transition_tech_name: confirm_transition_tech_name,
			});
	return (
		<>
			<ProviderUpdateRequirementsDialog
				messages={messages}
				transactionId={transactionId}
				oppositeSideParticipantName={oppositeSideParticipantName}
				open={isUpdateRequirementsDialogOpen}
				transition_tech_name={update_transition_tech_name}
				onClose={() => setIsUpdateRequirementsDialogOpen(false)}
			/>
			<Stack direction="column" width="100%" spacing={1} justifyContent="flex-end">
				<Stack direction="row" spacing={1} justifyContent="space-between">
					<Button
						variant="text"
						color="secondary"
						onClick={() => setIsUpdateRequirementsDialogOpen(true)}
						disabled={isSubmitting}
					>
						<FormattedMessage id="questions.details.meeting_scheduled_view.btnRequestChanges" />
					</Button>
					<RdvLoadingButton
						variant="contained"
						color="primary"
						type="submit"
						loading={loading}
						loadingPosition="center"
						onClick={() => {
							setIsSubmitting(true);
							dispatch(dispatchableAction());
						}}
					>
						<FormattedMessage id="questions.details.meeting_scheduled_view.btnSubmit" />
					</RdvLoadingButton>
				</Stack>
			</Stack>
		</>
	);
};

import { getDirtyFields } from '@aquga/app/formHelpers';
import {
	initPersonalInfoFormValues,
	mapParticipantAccountFormValuesToFieldInputs,
} from '@aquga/app/participantHelpers';
import validator from '@aquga/app/validators';
import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import { Field } from '@aquga/services/randevuApi/generatedTypes';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant, updateMyFields, updateMyFieldsFailed } from '@aquga/store/slices/participantSlice';
import { Button, Grid, Stack } from '@mui/material';
import { FormApi } from 'final-form';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { useIntl, FormattedMessage } from 'react-intl';

interface PersonalInfoFormProps {
	participantFields: Field[];
	loading: boolean;
}

export interface PersonalInfoFormValues {
	email: string;
	first_name: string;
	last_name: string;
}

const PersonalInfoForm = ({ loading }: PersonalInfoFormProps) => {
	const intl = useIntl();
	const dispatch = useAppDispatch();
	const currentParticipant = useAppSelector(selectCurrentParticipant);

	const handleSubmit = async (values: any, form: FormApi) => {
		const dirtyValues = getDirtyFields({ ...values }, form);
		const fieldValues = mapParticipantAccountFormValuesToFieldInputs(dirtyValues);

		const resolvedAction = await dispatch(updateMyFields({ fields: fieldValues, fieldsGroup: 'personalInfo' }));

		if (resolvedAction.type === updateMyFieldsFailed.type) {
			return resolvedAction.payload;
		}
	};

	const handleValidate = (values: any) => {
		const fieldValidators = {
			first_name: [
				(value: string) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
			],
			last_name: [
				(value: string) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
			],
		};

		return validator.doValidation(fieldValidators, values);
	};

	return (
		<Form
			onSubmit={handleSubmit}
			validate={handleValidate}
			initialValues={initPersonalInfoFormValues(currentParticipant?.fields, currentParticipant?.email)}
			render={({ handleSubmit, pristine, submitting }) => (
				<form onSubmit={handleSubmit} noValidate={true}>
					<Grid
						container
						direction="row"
						alignItems="center"
						justifyContent="center"
						spacing={3}
						textAlign="center"
					>
						<Grid item textAlign="center" xs={12} sm={6}>
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								disabled
								name="first_name"
								label={intl.formatMessage({ id: 'registerPage.form.fields.firstName.label' })}
								placeholder={intl.formatMessage({
									id: 'registerPage.form.fields.firstName.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item textAlign="center" xs={12} sm={6}>
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								disabled
								name="last_name"
								label={intl.formatMessage({ id: 'registerPage.form.fields.lastName.label' })}
								placeholder={intl.formatMessage({
									id: 'registerPage.form.fields.lastName.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item textAlign="center" xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								disabled
								name="email"
								type="email"
								label={intl.formatMessage({ id: 'registerPage.form.fields.email.label' })}
								placeholder={intl.formatMessage({
									id: 'registerPage.form.fields.email.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Stack direction="row" justifyContent="space-between">
								<Button variant="outlined" color="primary">
									<FormattedMessage id="common.cancel" />
								</Button>
								<RdvLoadingButton
									disabled={pristine}
									variant="contained"
									color="primary"
									type="submit"
									loading={loading}
									loadingPosition="center"
								>
									<FormattedMessage id="common.save" />
								</RdvLoadingButton>
							</Stack>
						</Grid>
					</Grid>
				</form>
			)}
		/>
	);
};

export default PersonalInfoForm;

// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import GlobalLayout from '@aquga/components/layout/GlobalLayout';
import { getLocalizedMessages, SupportedLocaleKeys } from '@aquga/i18n/index';
import { persistor, store, useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentLanguage } from '@aquga/store/slices/uiSlice';
import { theme } from '@aquga/styles/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

const AqugaApp = () => {
	const locale = useAppSelector(selectCurrentLanguage) as SupportedLocaleKeys;
	const messages = getLocalizedMessages(locale);
	return (
		<>
			<ThemeProvider theme={theme}>
				<IntlProvider locale={locale} messages={messages}>
					<CssBaseline />
					<GlobalLayout />
				</IntlProvider>
			</ThemeProvider>
		</>
	);
};

function App() {
	return (
		<>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<AqugaApp />
				</PersistGate>
			</Provider>
		</>
	);
}

export default App;

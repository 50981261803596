import validator from '@aquga/app/validators';
import ConditionallyRender from '@aquga/components/common/ConditionallyRender';
import DropZoneField from '@aquga/components/common/DropZoneField';
import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectLoading } from '@aquga/store/slices/supplySlice';
import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Breakpoint, Container, Stack } from '@mui/system';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-focus';
import { Checkboxes, TextField, Debug } from 'mui-rff';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

export interface CreateAnsweringExpertiseFormValues {
	technology_kind?: Array<keyof typeof RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KINDS.VALUES>;
	technology_kind_other?: string;
	business_area?: Array<keyof typeof RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES>;
	business_area_other?: string;
	title?: string;
	description?: string;
	notes?: string;
	documents?: File[];
}
interface CreateAnsweringExpertiseFormProps {
	submitLabel?: string;
	maxWidth?: Breakpoint | false;
	initialValues?: CreateAnsweringExpertiseFormValues;
	loading: boolean;
	onSubmit: (params: CreateAnsweringExpertiseFormValues) => any;
	onCancel: (params: any) => any;
}

const CreateAnsweringExpertiseForm = ({
	submitLabel,
	initialValues = {},
	maxWidth = false,
	loading,
	onSubmit,
	onCancel,
}: CreateAnsweringExpertiseFormProps) => {
	const theme = useTheme();
	const intl = useIntl();
	const [formValues, setFormValues] = useState({} as CreateAnsweringExpertiseFormValues);
	const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

	const handleValidateCreateAnsweringExpertiseForm = (values: any) => {
		return validator.doValidation(fieldValidators, values);
	};
	const fieldValidators = {
		technology_kind: [
			(value: string[]) =>
				validator.required({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
				}),
		],
		technology_kind_other: formValues?.technology_kind?.includes(
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KINDS.VALUES.OTHER as any
		)
			? [
				(value: string) =>
					validator.required({
						value,
						errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
					}),
			  ]
			: [],
		business_area: [
			(value: string[]) =>
				validator.required({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
				}),
		],
		business_area_other: formValues?.business_area?.includes(
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.OTHER as any
		)
			? [
				(value: string) =>
					validator.required({
						value,
						errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
					}),
			  ]
			: [],
		title: [
			(value: string) =>
				validator.required({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
				}),
		],
		description: [
			(value: string) =>
				validator.required({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
				}),
		],
	};

	const technologyTypeOptions = [
		{
			label: intl.formatMessage({ id: 'resources.service.topics.values.sap' }),
			value: RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KINDS.VALUES.SAP,
		},
		{
			label: intl.formatMessage({ id: 'resources.service.topics.values.microsoft_erp' }),
			value: RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KINDS.VALUES.MICROSOFT_ERP,
		},
		{
			label: intl.formatMessage({ id: 'resources.service.topics.values.other' }),
			value: RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KINDS.VALUES.OTHER,
		},
	];

	const expertiseOptions = [
		{
			label: intl.formatMessage({ id: 'resources.service.service.values.sales_crm' }),
			value: RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.SALES_CRM,
		},
		{
			label: intl.formatMessage({ id: 'resources.service.service.values.logistics' }),
			value: RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.LOGISTICS,
		},
		{
			label: intl.formatMessage({ id: 'resources.service.service.values.financials' }),
			value: RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.FINANCIALS,
		},
		{
			label: intl.formatMessage({ id: 'resources.service.service.values.production_qm' }),
			value: RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.PRODUCTION_QM,
		},
		{
			label: intl.formatMessage({ id: 'resources.service.service.values.purchasing' }),
			value: RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.PURCHASING,
		},
		{
			label: intl.formatMessage({ id: 'resources.service.service.values.warehouse' }),

			value: RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.WAREHOUSE,
		},
		{
			label: intl.formatMessage({ id: 'resources.service.service.values.planning' }),
			value: RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.PLANNING,
		},
		{
			label: intl.formatMessage({ id: 'resources.service.service.values.analytics' }),
			value: RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.ANALYTICS,
		},
		{
			label: intl.formatMessage({ id: 'resources.service.service.values.hcm' }),
			value: RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.HCM,
		},
		{
			label: intl.formatMessage({ id: 'resources.service.service.values.other' }),
			value: RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.OTHER,
		},
	];
	const focusOnError = createDecorator();
	return (
		<Form
			onSubmit={onSubmit}
			decorators={[focusOnError]}
			initialValues={initialValues}
			mutators={{
				...arrayMutators,
			}}
			validate={handleValidateCreateAnsweringExpertiseForm}
		>
			{({
				handleSubmit,
				submitting,
				form: {
					mutators: { push },
				},
				values,
				pristine,
			}) => {
				setFormValues(values);
				return (
					<form onSubmit={handleSubmit} noValidate>
						<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={4}>
							<Typography variant="subtitle2" sx={{ mb: -1 }}>
								{intl.formatMessage({ id: 'createAnsweringExpertisePage.form.type.label' })}
							</Typography>
							<Checkboxes
								name="technology_kind"
								required
								size="small"
								formLabelProps={{
									sx: {
										fontWeight: 'bold',
										color: 'black',
										'&.Mui-focused': {
											color: 'black',
										},
									},
								}}
								data={technologyTypeOptions}
							/>
							<ConditionallyRender when="technology_kind" contains="other">
								<TextField
									name="technology_kind_other"
									required
									fullWidth={false}
									placeholder="Specify what other technology" //FIXME: Text too large
									InputLabelProps={{ shrink: true }}
									label="Technology"
								/>
							</ConditionallyRender>

							<Typography variant="subtitle2">
								{intl.formatMessage({ id: 'createAnsweringExpertisePage.form.expertise.label' })}
							</Typography>

							<Checkboxes
								name="business_area"
								required
								size="small"
								data={expertiseOptions}
								formLabelProps={{
									sx: {
										fontWeight: 'bold',
										color: 'black',
										'&.Mui-focused': {
											color: 'black',
										},
									},
								}}
							/>
							<ConditionallyRender when="business_area" contains="other">
								<TextField
									name="business_area_other"
									required
									fullWidth={false}
									placeholder="Specify what other business area" //FIXME: Text too large
									InputLabelProps={{ shrink: true }}
									label="Business area"
								/>
							</ConditionallyRender>
							<Container maxWidth={maxWidth} disableGutters>
								<Typography fontWeight="bold">
									<FormattedMessage id="createAnsweringExpertisePage.form.understandBackground.title" />
								</Typography>
								<br />
								<Stack
									direction="column"
									justifyContent="flex-start"
									alignItems="flex-start"
									spacing={4}
								>
									<TextField
										InputLabelProps={{ shrink: true }}
										name="title"
										label={intl.formatMessage({
											id: 'createAnsweringExpertisePage.form.name.label',
										})}
										placeholder={intl.formatMessage({
											id: 'createAnsweringExpertisePage.form.name.placeholder',
										})}
										required
									/>

									<TextField
										InputLabelProps={{ shrink: true }}
										name="description"
										label={intl.formatMessage({
											id: 'createAnsweringExpertisePage.form.description.label',
										})}
										placeholder={intl.formatMessage({
											id: 'createAnsweringExpertisePage.form.description.placeholder',
										})}
										required
										multiline
										sx={{ height: 'auto ' }}
										rows={8}
										margin="none"
									/>
								</Stack>
							</Container>
							<Container maxWidth={maxWidth} disableGutters>
								<FormattedMessage id="createAnsweringExpertisePage.form.additionalNote.title" />
								<br />
								<br />

								<TextField
									InputLabelProps={{ shrink: true }}
									name="notes"
									label={intl.formatMessage({
										id: 'createAnsweringExpertisePage.form.additionalNote.label',
									})}
									placeholder={intl.formatMessage({
										id: 'createAnsweringExpertisePage.form.additionalNote.placeholder',
									})}
									multiline
									sx={{ height: 'auto ' }}
									minRows={6}
								/>
							</Container>
							<Container maxWidth={maxWidth} disableGutters>
								<Typography fontWeight="body1" color="textSecondary">
									<FormattedMessage id="questions.create.consultantSectionFiles" />
								</Typography>
								<br />

								<DropZoneField
									name="documents"
									maxFileSize={4}
									maxFiles={RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.MAX_FILES}
									acceptedFileFormats={{
										'image/*': [
											RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS
												.JPG,
											RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS
												.JPEG,
											RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS
												.PNG,
										],
										'application/pdf': [
											RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS
												.PDF,
										],
										'application/msword': [
											RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS
												.DOC,
											RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS
												.DOCX,
										],
										'application/vnd.ms-excel': [
											RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS
												.XLS,
											RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS
												.XLSX,
										],
									}}
									onAddFile={push}
									disabled={submitting}
								/>
							</Container>
						</Stack>

						<br />
						<br />
						<Divider />

						<br />
						<br />
						<Stack
							direction="column"
							width="100%"
							justifyContent="space-between"
							alignItems="flex-start"
							spacing={1}
						>
							<Box width={isLargeScreen ? '50%' : 'inherit'}>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="flex-start"
									spacing={3}
								>
									<Button
										color="primary"
										variant="outlined"
										onClick={onCancel}
										disabled={loading || submitting}
									>
										<FormattedMessage id="common.cancel" />
									</Button>
									<RdvLoadingButton
										variant="contained"
										color="primary"
										type="submit"
										loading={loading}
										disabled={pristine || loading || submitting}
										loadingPosition="center"
										fullWidth={isLargeScreen ? false : true}
									>
										{submitLabel || (
											<FormattedMessage id="questions.create.btnSubmitAnsweringExpertise" />
										)}
									</RdvLoadingButton>
								</Stack>
							</Box>
						</Stack>
					</form>
				);
			}}
		</Form>
	);
};

export default CreateAnsweringExpertiseForm;

import ProtectedNavbar from '@aquga/components/layout/nav/ProtectedNavbar';

const ProtectedHeader = () => {
	return (
		<header>
			<ProtectedNavbar />
		</header>
	);
};

export default ProtectedHeader;

import { isBusinessSide } from '@aquga/app/participantHelpers';
import validator from '@aquga/app/validators';
import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import { requestChanges, updateMatchFailed } from '@aquga/store/slices/transactionSlice';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

interface RequestChangeFormValues {
	improvementNotes: string;
}

interface RequestChangeDialogProps {
	open: boolean;
	transactionId: string;
	onClose: any;
}

const RequestChangeDialog = ({ open, transactionId, onClose }: RequestChangeDialogProps) => {
	const intl = useIntl();
	const dispatch = useAppDispatch();
	const isBusiness = isBusinessSide(useAppSelector(selectCurrentParticipant));

	const handleSubmit = async (values: RequestChangeFormValues) => {
		const fields = [
			{
				tech_name: RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.SOLUTION_CHANGE_REQUEST_REASON,
				value: values.improvementNotes,
			},
		];
		const resolvedAction = await dispatch(requestChanges({ transactionId, fields, isBusiness }));

		if (resolvedAction.type === updateMatchFailed.type) {
			const submissionErrors = { ...resolvedAction.payload };

			// TODO: write nice helper function which does this one
			Object.entries(submissionErrors).map((keyValue) => {
				submissionErrors[keyValue[0]] = intl.formatMessage({ id: keyValue[1] as string });
			});

			return submissionErrors;
		}
	};

	const fieldValidators = {
		improvementNotes: [
			(value: string) =>
				validator.required({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
				}),
		],
	};

	const handleValidate = async (values: RequestChangeFormValues) => {
		return validator.doValidation(fieldValidators, values);
	};

	return (
		<Dialog
			aria-labelledby="request-change-dialog"
			open={open}
			fullWidth
			maxWidth="sm"
			BackdropProps={{
				sx: {
					backdropFilter: 'blur(5px)',
					backgroundColor: 'rgba(0,0,30,0.5)',
				},
			}}
		>
			<DialogTitle id="request-change-dialog-title">
				<Stack direction="column" spacing={2} justifyContent="flex-start">
					<Typography variant="h6" fontWeight={700}>
						<FormattedMessage id="questions.dialogs.requestChangeDialog.title" />
					</Typography>
					<Typography variant="body2">
						<FormattedMessage id="questions.dialogs.requestChangeDialog.body" />
					</Typography>
					<Typography variant="body1">
						<FormattedMessage id="questions.dialogs.requestChangeDialog.explainer" />
					</Typography>
				</Stack>
			</DialogTitle>
			<Form
				onSubmit={handleSubmit}
				validate={handleValidate}
				render={({ handleSubmit, submitting, pristine }) => (
					<form onSubmit={handleSubmit} noValidate>
						<DialogContent>
							<Stack
								direction="column"
								alignItems="stretch"
								justifyContent="center"
								spacing={2}
								textAlign="left"
							>
								<TextField
									name="improvementNotes"
									label={intl.formatMessage({
										id: 'questions.dialogs.requestChangeDialog.fields.improvementNotes.label',
									})}
									placeholder={intl.formatMessage({
										id: 'questions.dialogs.requestChangeDialog.fields.improvementNotes.placeholder',
									})}
									required
									multiline
									rows={5}
									sx={{ height: 'auto ' }}
									margin="none"
								/>
								<Stack direction="row" justifyContent="flex-end" spacing={1}>
									<Button
										variant="outlined"
										onClick={() => onClose()}
										size="large"
										disabled={submitting}
									>
										<FormattedMessage id="questions.dialogs.requestChangeDialog.btnCancel" />
									</Button>
									<RdvLoadingButton
										variant="contained"
										color="primary"
										disabled={pristine}
										type="submit"
										loading={submitting}
										loadingPosition="center"
									>
										<FormattedMessage id="questions.dialogs.requestChangeDialog.btnSubmit" />
									</RdvLoadingButton>
								</Stack>
							</Stack>
						</DialogContent>
					</form>
				)}
			/>
		</Dialog>
	);
};

export default RequestChangeDialog;

import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import unifyFieldValue from '@aquga/services/randevuApi/fieldHelper';
import { MutationUpdateSupplyArgs, QueryMySupplyArgs, Supply } from '@aquga/services/randevuApi/generatedTypes';

import * as supplyMutations from './supplyMutations';
import * as supplyQueries from './supplyQueries';

class SuppliesService {
	private randevu: any;
	/**
	 * @param {randevu} randevu
	 */
	constructor(randevu: any) {
		this.randevu = randevu;
	}

	async updateSupply({ id, fields }: MutationUpdateSupplyArgs): Promise<{ supply_updated: boolean; errors: any }> {
		const payload = supplyMutations.updateSupply({ id, fields });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { supply_updated: data?.updateSupply as boolean, errors };
	}

	async createSupply(): Promise<{ created_supply: string; errors: any }> {
		const payload = supplyMutations.createSupply({
			tech_name: RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.TECH_NAME,
		});
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { created_supply: data?.createSupply.id as string, errors };
	}

	async mySupply({ id }: QueryMySupplyArgs): Promise<{ my_supply: Supply; errors: any }> {
		const payload = supplyQueries.mySupply({ id });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		const supply = data?.mySupply;

		const fields = unifyFieldValue(supply?.fields);
		if (supply) {
			supply.fields = fields;
		}

		return { my_supply: supply, errors };
	}

	async mySupplies(): Promise<{ my_supplies: Supply[]; errors: any }> {
		const payload = supplyQueries.mySupplies();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		let supplies = data?.mySupplies;

		supplies = supplies?.map((supply: Supply) => {
			const fields = unifyFieldValue(supply?.fields);

			supply.fields = fields;

			return supply as Supply;
		});
		return { my_supplies: supplies, errors };
	}
}

export default SuppliesService;

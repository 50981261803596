import File from '@aquga/components/common/File';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import Button from '@mui/material/Button';
import { Stack } from '@mui/system';
import { FieldArray } from 'react-final-form-arrays';

interface FilesFieldProps {
	loading: boolean;
	name: string;
	multiple?: boolean;
	accept?: string;
	addFilesLabel: string;
}
const FilesField = ({ name }: FilesFieldProps) => {
	return (
		<FieldArray name={name}>
			{({ fields }) => {
				return (
					<>
						<>
							<Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
								{fields.map((name, index) => (
									<>
										<File
											key={index}
											url={fields.value[index].url}
											fileName={fields.value[index].name || fields.value[index].file_name}
										/>
									</>
								))}
							</Stack>
						</>
					</>
				);
			}}
		</FieldArray>
	);
};

export default FilesField;

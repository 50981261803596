export const ROUTES = {
	SIGN_IN_PAGE: '/login',

	SIGN_UP_BUSINESS_PAGE: '/register-business',
	SIGN_UP_CONSULTING_HOUSE_PAGE: '/register-consulting-house',

	FORGOT_PASSWORD: '/forgot-password',
	CHOOSE_PASSWORD: '/choose-new-password',
	ACTIVATE_ACCOUNT: '/account-activation',
	FAQ: '/faq',
	CONTACT_US: '/contact-us',

	// Private
	PARTICIPANT_ONBOARDING: '/private/onboarding',
	PARTICIPANT_REJECTED: '/private/rejected',

	// Protected Routes
	DASHBOARD: '/protected/dashboard',
	LIST_SERVICES: '/protected/services',
	CREATE_SERVICE: '/protected/services/create',
	UPDATE_SERVICE: '/protected/services/update/:id',
	SERVICE_DETAILS: '/protected/services/:id',
	LIST_TICKETS: '/protected/tickets',
	TICKETS_W_FILTER_ACTIVE: '/protected/tickets?filter=active',
	TICKET_DETAILS: '/protected/tickets/:id',
	CONSUMER_CREATE_TICKET: '/protected/tickets/create',
	CONTRACTS: '/protected/contracts',
	PARTICIPANT_ACCOUNT: '/protected/account',
};

export const PUBLIC_ROUTES = [
	ROUTES.SIGN_IN_PAGE,
	ROUTES.FORGOT_PASSWORD,
	ROUTES.SIGN_UP_BUSINESS_PAGE,
	ROUTES.SIGN_UP_CONSULTING_HOUSE_PAGE,
	ROUTES.ACTIVATE_ACCOUNT,
];

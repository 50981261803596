import { AgreedDataView } from '@aquga/components/common/AgreedDataView';
import { AnswerAcceptedView } from '@aquga/components/common/AnswerAcceptedView';
import { AnswerDeliveredView } from '@aquga/components/common/AnswerDeliveredView';
import { ContractAcceptedView } from '@aquga/components/common/ConsultantContractAcceptedView';
import { ContractDeclinedView } from '@aquga/components/common/ContractDeclinedView';
import { ContractOfferingView } from '@aquga/components/common/ContractOfferingView';
import { RequestedChangesView } from '@aquga/components/common/RequestedChangesView';
import { GenericMatchDetails } from '@aquga/components/matches/details/GenericMatchDetails';
import { MatchTimeView } from '@aquga/components/matches/details/MatchTimeView';
import { ProposedTimeView } from '@aquga/components/matches/details/ProposedTimeView';
import { ScheduledView } from '@aquga/components/matches/details/ScheduledView';
import ContactDetailsView from '@aquga/components/matches/provider/details/ContactDetailsView';
import { MeetingProposedView } from '@aquga/components/matches/provider/details/MeetingProposedView';
import AnswerMatchStatus from '@aquga/components/questions/status/AnswerMatchStatus';
import ContractMatchStatus from '@aquga/components/questions/status/ContractMatchStatus';
import { RdvFileFieldValue } from '@aquga/components/services/types';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Skeleton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface MatchDetailsProps {
	match: any; //TODO: Declare match type
	consultingExpertiseTitle: string;
	consultingExpertiseDescription: string;
	contract: RdvFileFieldValue;
	rawMatchData: any;
	solutionDocuments: any;
	loading: boolean;
	requestedChangesFromMatch: string;
	contractDeclinedReason: string;
	supplyTechnology: string[];
	supplyBusinessArea: string[];
	supplyTechnologyOther: string;
	supplyBusinessAreaOther: string;
	generatedContract: RdvFileFieldValue;
	supplyAdditionalDocs: any[];
	supplyAdditionalNotes: string;
	contactPerson: string;
	contactNumber: string;
	contactEmail: string;
}

export const MatchDetails = ({
	consultingExpertiseTitle,
	consultingExpertiseDescription,
	contract,
	solutionDocuments,
	generatedContract,
	contractDeclinedReason,
	rawMatchData,
	match,

	loading,
	requestedChangesFromMatch,
	supplyTechnology,
	supplyBusinessArea,
	supplyTechnologyOther,
	supplyBusinessAreaOther,
	supplyAdditionalDocs,
	supplyAdditionalNotes,
	contactPerson,
	contactNumber,
	contactEmail,
}: MatchDetailsProps) => {
	const [isDetailsExpanded, setDetailsExpanded] = useState(true);
	const [isContractDetailsExpanded, setContractDetailsExpanded] = useState(false);
	const [isAnswerDetailsExpanded, setAnswerDetailsExpanded] = useState(false);
	const contractStates = [
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_OFFERING.TECH_NAME.toUpperCase(),
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_OFFERED.TECH_NAME.toUpperCase(),
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_ACCEPTED.TECH_NAME.toUpperCase(),
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_DECLINED.TECH_NAME.toUpperCase(),
	];
	const answerStates = [
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_ACCEPTED.TECH_NAME.toUpperCase(),
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_DELIVERED.TECH_NAME.toUpperCase(),
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_ACCEPTED.TECH_NAME.toUpperCase(),
	];

	useEffect(() => {
		if (contractStates?.includes(match?.status?.toUpperCase())) setContractDetailsExpanded(true);

		if (answerStates?.includes(match?.status?.toUpperCase())) setAnswerDetailsExpanded(true);
	}, [match?.status]);
	return (
		<>
			{contractStates.includes(match?.status?.toUpperCase()) && (
				<Accordion
					expanded={isContractDetailsExpanded}
					onChange={() => setContractDetailsExpanded((expanded) => !expanded)}
					sx={{
						borderRadius: '12px',
						p: 2,
						'&:before': {
							display: 'none',
						},
					}}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						{loading ? (
							<Skeleton width={80} />
						) : (
							<Typography variant="subtitle2">
								<FormattedMessage id="questions.match.contract.title" />
							</Typography>
						)}
					</AccordionSummary>
					<AccordionDetails>
						{match?.status?.toUpperCase() ===
							RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_OFFERING.TECH_NAME.toUpperCase() && (
							<ContractOfferingView
								loading={loading}
								status={match?.status}
								transactionId={match.id}
								generatedContract={generatedContract}
							/>
						)}
						{match?.status?.toUpperCase() ===
							RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_OFFERED.TECH_NAME.toUpperCase() && (
							<ContractMatchStatus status={match?.status} loading={loading} />
						)}
						{match?.status?.toUpperCase() ===
							RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_ACCEPTED.TECH_NAME.toUpperCase() && (
							<ContractAcceptedView loading={loading} status={match.status} contract={contract} />
						)}
						{match?.status?.toUpperCase() ===
							RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_DECLINED.TECH_NAME.toUpperCase() && (
							<ContractDeclinedView
								loading={loading}
								status={match?.status}
								contract={contract}
								contractDeclinedReason={contractDeclinedReason}
							/>
						)}
					</AccordionDetails>
				</Accordion>
			)}
			{answerStates.includes(match?.status?.toUpperCase()) && (
				<Accordion
					expanded={isAnswerDetailsExpanded}
					onChange={() => setAnswerDetailsExpanded((expanded) => !expanded)}
					sx={{
						borderRadius: '12px',
						p: 2,
						'&:before': {
							display: 'none',
						},
					}}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						{loading ? (
							<Skeleton width={80} />
						) : (
							<Typography variant="subtitle2">
								<FormattedMessage id="questions.match.answer.title" />
							</Typography>
						)}
					</AccordionSummary>
					<AccordionDetails>
						{match?.status?.toUpperCase() ===
							RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_ACCEPTED.TECH_NAME.toUpperCase() && (
							<AnswerMatchStatus status={match.status} loading={loading} />
						)}
						{match?.status?.toUpperCase() ===
							RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_DELIVERED.TECH_NAME.toUpperCase() && (
							<AnswerDeliveredView
								loading={loading}
								requestedChangesFromMatch={requestedChangesFromMatch}
								transactionId={match.id}
								status={match.status}
								buttons={true}
								solutionDocuments={solutionDocuments}
							/>
						)}
						{match?.status?.toUpperCase() ===
							RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_ACCEPTED.TECH_NAME.toUpperCase() && (
							<AnswerAcceptedView
								loading={loading}
								status={match.status}
								solutionDocuments={solutionDocuments}
							/>
						)}
					</AccordionDetails>
				</Accordion>
			)}
			<Accordion
				expanded={isDetailsExpanded}
				onChange={() => setDetailsExpanded((expanded) => !expanded)}
				sx={{
					borderRadius: '12px',
					p: 2,
					'&:before': {
						display: 'none',
					},
				}}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					{loading ? (
						<Skeleton width={80} />
					) : (
						<Typography variant="subtitle2">
							<FormattedMessage id="questions.match.respondent.title" />
						</Typography>
					)}
				</AccordionSummary>
				<AccordionDetails>
					<GenericMatchDetails
						consultingExpertiseTitle={consultingExpertiseTitle}
						consultingExpertiseDescription={consultingExpertiseDescription}
						match={match}
						loading={loading}
						supplyTechnology={supplyTechnology}
						supplyBusinessArea={supplyBusinessArea}
						supplyTechnologyOther={supplyTechnologyOther}
						supplyBusinessAreaOther={supplyBusinessAreaOther}
						supplyAdditionalDocs={supplyAdditionalDocs}
						supplyAdditionalNotes={supplyAdditionalNotes}
					/>
					<br />
					{match?.status?.toUpperCase() ===
						RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.MATCH_TIME.TECH_NAME.toUpperCase() && (
						<MatchTimeView transactionId={match.id} />
					)}
					{match?.status?.toUpperCase() ===
						RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.PROPOSED_TIME.TECH_NAME.toUpperCase() && (
						<ProposedTimeView
							loading={loading}
							button={true}
							match_fields={rawMatchData.matches[0].fields}
							transactionId={match.id}
						/>
					)}
					{match?.status?.toUpperCase() ===
						RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.SCHEDULED.TECH_NAME.toUpperCase() && (
						<>
							<ProposedTimeView
								loading={loading}
								button={false}
								match_fields={rawMatchData.matches[0].fields}
								transactionId={match.id}
							/>
						</>
					)}
					{match?.status?.toUpperCase() ===
						RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CHANGES_REQUESTED.TECH_NAME.toUpperCase() && (
						<Stack direction="column" spacing={1}>
							<ProposedTimeView
								loading={loading}
								button={false}
								match_fields={rawMatchData.matches[0].fields}
								transactionId={match.id}
							/>
							<AgreedDataView
								loading={loading}
								price={match?.price}
								matchFields={rawMatchData?.matches?.[0].fields}
							/>
							<RequestedChangesView loading={loading} matchFields={rawMatchData.matches[0].fields} />
							<ScheduledView
								oppositeSideParticipantName={match.providerName}
								messages={match.messages}
								loading={loading}
								price={match.agreedPrice ? match.agreedPrice : match.price}
								transactionId={match.id}
							/>
						</Stack>
					)}
					{match?.status?.toUpperCase() ===
						RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CHANGES_RECEIVED.TECH_NAME.toUpperCase() && (
						<>
							<ProposedTimeView
								button={false}
								loading={loading}
								match_fields={rawMatchData.matches[0].fields}
								transactionId={match.id}
							/>
							<br />
							<AgreedDataView
								loading={loading}
								price={match.price}
								matchFields={rawMatchData?.matches?.[0].fields}
							/>
							<br />
							<RequestedChangesView loading={loading} matchFields={rawMatchData.matches[0].fields} />
						</>
					)}
					{match?.status?.toUpperCase() ===
						RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.MEETING_INVITE.TECH_NAME.toUpperCase() && (
						<>
							<MeetingProposedView
								loading={loading}
								matchFields={rawMatchData.matches[0].fields}
								transactionId={match.id}
							/>
						</>
					)}
					{contactNumber && contactPerson && (
						<ContactDetailsView
							contactPerson={contactPerson}
							contactNumber={contactNumber}
							contactEmail={contactEmail}
							loading={loading}
						/>
					)}
					{match?.status?.toUpperCase() ===
						RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_OFFERING.TECH_NAME.toUpperCase() && (
						<>
							<ProposedTimeView
								loading={loading}
								button={false}
								match_fields={rawMatchData.matches[0].fields}
								transactionId={match.id}
							/>
							<br />
							<AgreedDataView
								loading={loading}
								price={match.price}
								matchFields={rawMatchData?.matches?.[0].fields}
							/>
							<br />
							<RequestedChangesView loading={loading} matchFields={rawMatchData.matches[0].fields} />
						</>
					)}
				</AccordionDetails>
			</Accordion>
		</>
	);
};

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface MessageHistoryAccordionProps {
	isHistoryAccordionExpanded: boolean;
	setHistoryAccordionExpanded: any;
	oppositeSideParticipanName: string;
	messages: string[];
	isBussinesSide: boolean;
}

const MessageHistoryAccordion = ({
	isHistoryAccordionExpanded,
	setHistoryAccordionExpanded,
	oppositeSideParticipanName,
	messages,
	isBussinesSide,
}: MessageHistoryAccordionProps) => {
	return (
		<Accordion
			expanded={isHistoryAccordionExpanded}
			onChange={() => setHistoryAccordionExpanded((expanded: boolean) => !expanded)}
			sx={{
				borderRadius: '12px',
				p: 2,
				'&:before': {
					display: 'none',
				},
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="body1">
					<FormattedMessage id="questions.details.message_history_accordion.title" />
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{messages?.map((message, i) => (
					<Stack
						key={message + '_key'}
						alignItems="flex-start"
						direction="row"
						sx={{ p: 1, borderRadius: 5, pl: i % 2 === 0 ? 0 : 2 }}
						spacing={2}
					>
						<Typography variant="body2" sx={{ pr: 1 }} fontWeight={600} minWidth="fit-content">
							{i % 2 === 0 ? (
								!isBussinesSide ? (
									<FormattedMessage id="questions.details.message_history_accordion.roles.you" />
								) : (
									<Typography variant="body2">{oppositeSideParticipanName}:</Typography>
								)
							) : isBussinesSide ? (
								<FormattedMessage id="questions.details.message_history_accordion.roles.you" />
							) : (
								<Typography variant="body2">{oppositeSideParticipanName}:</Typography>
							)}
						</Typography>
						<Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
							{message}
						</Typography>
					</Stack>
				))}
			</AccordionDetails>
		</Accordion>
	);
};

export default MessageHistoryAccordion;

import { DRAWER_WIDTH, MAIN_LAYOUT_TOP_SPACING, MOBILE_HEADER_HEIGHT } from '@aquga/app/constants';
import LanguageDialog from '@aquga/components/common/LanguageDialog';
import MainNavigation from '@aquga/components/layout//nav/MainNavigationDrawer';
import MobileHeader from '@aquga/components/layout/header/MobileHeader';
import { ROUTES } from '@aquga/config/routes';
import { useAppDispatch } from '@aquga/store/configureStore';
import { logoutParticipant } from '@aquga/store/slices/authSlice';
import { AQUGA_BACKGROUND_COLOR } from '@aquga/styles/theme';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

const MainLayout = () => {
	const navigate = useNavigate();
	const [mobileOpen, setMobileOpen] = useState(false);
	const [languageDialogOpen, setOpenLanguageDialog] = useState(false);
	const dispatch = useAppDispatch();

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleSignOut = async () => {
		await dispatch(logoutParticipant());
		navigate(ROUTES.SIGN_IN_PAGE);
	};

	return (
		<>
			<LanguageDialog open={languageDialogOpen} onClose={() => setOpenLanguageDialog(false)} />
			<MobileHeader onMenuClick={handleDrawerToggle} />
			<Box sx={{ display: 'flex', height: '100vH', backgroundColor: AQUGA_BACKGROUND_COLOR }}>
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', sm: 'none' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
					}}
				>
					<MainNavigation
						mobile
						onClose={() => setMobileOpen(false)}
						onOpenLanguageDialog={() => setOpenLanguageDialog(true)}
						onSignOut={() => handleSignOut()}
					/>
				</Drawer>
				<Drawer
					PaperProps={{
						elevation: 0,
						sx: { border: 0 },
					}}
					sx={{
						width: DRAWER_WIDTH,

						'& .MuiDrawer-paper': {
							width: DRAWER_WIDTH,
							boxSizing: 'border-box',
						},

						display: { xs: 'none', sm: 'block' },
					}}
					variant="permanent"
					anchor="left"
				>
					<MainNavigation
						onOpenLanguageDialog={() => setOpenLanguageDialog(true)}
						onSignOut={() => handleSignOut()}
					/>
				</Drawer>
				<Box
					id="page"
					component="main"
					sx={{
						p: 3,
						height: '100%',
						width: '100%',
						overflow: 'auto',
						pt: { sm: MAIN_LAYOUT_TOP_SPACING, xs: MAIN_LAYOUT_TOP_SPACING + MOBILE_HEADER_HEIGHT },
					}}
				>
					<Outlet />
				</Box>
			</Box>
		</>
	);
};

export default MainLayout;

import AnswerMatchStatus from '@aquga/components/questions/status/AnswerMatchStatus';
import { RdvFileFieldValue } from '@aquga/components/services/types';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { File } from '@aquga/services/randevuApi/generatedTypes';
import { useAppDispatch } from '@aquga/store/configureStore';
import { presentSolution, updateTicketFailed } from '@aquga/store/slices/transactionSlice';
import { Box, Container, Skeleton, Stack, Typography } from '@mui/material';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import DropZoneField from './DropZoneField';
import FilesField from './FilesField';
import RdvLoadingButton from './RdvLoadingButton';

interface UploadSolutionFormValues {
	solution: File[];
	additionalDocs: RdvFileFieldValue[];
}

interface UploadSolutionViewProps {
	status: string;
	transactionId: string;
	requestedChanges?: string;
	loading: boolean;
	previousSolutionDocuments?: RdvFileFieldValue[];
}

export const UploadSolutionView = ({
	status,
	transactionId,
	requestedChanges,
	loading,
	previousSolutionDocuments,
}: UploadSolutionViewProps) => {
	const dispatch = useAppDispatch();
	const intl = useIntl();

	const appropriateTransitionTechName = requestedChanges
		? RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_TO_BE_IMPROVED.TRANSITIONS.CONSULTANT_SUBMITS_CHANGES
		: RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_ACCEPTED.TRANSITIONS.DELIVER_SOLUTION;
	const onSubmit = async (values: UploadSolutionFormValues) => {
		const solution = {
			tech_name: RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.SOLUTION_DOCUMENTS,
			value: values.solution,
		};
		const resolvedAction = await dispatch(
			presentSolution({
				additionalDocs: values.additionalDocs ?? [],
				transactionId: transactionId,
				solution_field: solution,
				transitionTechName: appropriateTransitionTechName,
			})
		);

		if (resolvedAction.type === updateTicketFailed.type) {
			const submissionErrors = { ...resolvedAction.payload };

			// TODO: write nice helper function which does this one
			Object.entries(submissionErrors).map((keyValue) => {
				submissionErrors[keyValue[0]] = intl.formatMessage({ id: keyValue[1] as string });
			});

			return submissionErrors;
		}
	};

	return (
		<Form
			onSubmit={onSubmit}
			mutators={{
				...arrayMutators,
			}}
			initialValues={{ additionalDocs: previousSolutionDocuments }}
		>
			{({
				handleSubmit,
				form: {
					mutators: { push },
				},
				pristine,
				submitting,
			}) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
						<AnswerMatchStatus status={status} loading={loading} />
						<Container disableGutters>
							<Typography fontWeight="body1" color="textSecondary">
								<FormattedMessage id="questions.details.uploadSolutionView.title" />
							</Typography>
							<br />

							<DropZoneField
								name="solution"
								maxFileSize={4}
								acceptedFileFormats={{
									'image/*': [
										RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS.JPG,
										RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS
											.JPEG,
										RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS.PNG,
									],
									'application/pdf': [
										RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS.PDF,
									],
									'application/msword': [
										RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS.DOC,
										RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS
											.DOCX,
									],
									'application/vnd.ms-excel': [
										RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS.XLS,
										RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.INPUT_OPTIONS
											.XLSX,
									],
								}}
								onAddFile={push}
								disabled={submitting || loading}
							/>
						</Container>
					</Stack>
					<br />
					<Box width="100%" justifyContent="flex-end" display="flex" pb={1}>
						<RdvLoadingButton
							variant="contained"
							color="primary"
							type="submit"
							loading={loading}
							loadingPosition="center"
							disabled={pristine}
						>
							<FormattedMessage id="questions.details.uploadSolutionView.btnSubmit" />
						</RdvLoadingButton>
					</Box>
					{requestedChanges && (
						<Stack>
							<Typography variant="body2">
								<FormattedMessage id="questions.details.uploadSolutionView.previousAnswers.title" />
							</Typography>
							{loading ? (
								<Skeleton width={120} />
							) : (
								<Stack direction="column" spacing={2} mt={2} mb={2}>
									<FilesField
										loading={loading}
										accept=".pdf, .doc, .docx, application/vnd.ms-excel ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
										name="additionalDocs"
										addFilesLabel={intl.formatMessage({
											id: 'resources.files.additionalDocs.label',
										})}
									/>
								</Stack>
							)}
							{requestedChanges && (
								<>
									<Typography variant="body2">
										<FormattedMessage id="questions.details.uploadSolutionView.previousAnswers.requestedChanges" />
									</Typography>
									{loading ? (
										<Skeleton width={120} />
									) : (
										<Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
											{requestedChanges}
										</Typography>
									)}
								</>
							)}
						</Stack>
					)}
				</form>
			)}
		</Form>
	);
};

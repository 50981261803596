import randevu, {
	hasSubmissionErrors,
	isDuplicate,
	isFetchError,
	isInvalidCredentials,
} from '@aquga/services/randevuApi';
import {
	MutationLoginParticipantArgs,
	MutationRegisterParticipantArgs,
	MutationResetParticipantPasswordArgs,
	MutationVerifyParticipantArgs,
} from '@aquga/services/randevuApi/generatedTypes';
import { sleep } from '@aquga/services/sleep';
import { AppDispatch, RootState } from '@aquga/store/configureStore';
import { SHORT_SLEEP_LOADING_SPINNER } from '@aquga/store/lib';
import THUNKS from '@aquga/store/thunkKeys';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';

import { removeCurrentParticipant, setCurrentParticipant } from './participantSlice';

interface AuthSliceState {
	loading: boolean;
	token: string | undefined;
	error: any | undefined;
	reauthentication: 'required' | 'idle';
	reauthenticationCallback: undefined | ReauthenticationRequest;
}

// Initial state for the slice
const initialState: AuthSliceState = {
	loading: false,
	token: undefined,
	error: undefined,
	reauthentication: 'idle',
	reauthenticationCallback: undefined,
};

// Actual Slice
export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginParticipantRequested: (state) => {
			state.loading = true;
		},
		loginParticipantFailed: (state: AuthSliceState, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload;
		},
		participantLoggedIn: (state: AuthSliceState, action: PayloadAction<string>) => {
			state.loading = false;
			state.token = action.payload;
		},
		signUpParticipantRequested: (state: AuthSliceState) => {
			state.loading = true;
		},
		signUpParticipantFailed: (state: AuthSliceState, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload;
		},
		participantSignedUp: (state: AuthSliceState) => {
			state.loading = false;
			state.error = null;
		},
		participantLogoutRequested: (state: AuthSliceState) => {
			state.loading = true;
		},
		participantLoggedOut: (state: AuthSliceState) => {
			state.loading = false;
			state.token = undefined;
		},
		requestPasswordResetRequested: (state: AuthSliceState) => {
			state.loading = true;
			state.error = null;
		},
		requestPasswordResetFailed: (state: AuthSliceState, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload;
		},
		resetPasswordRequested: (state: AuthSliceState) => {
			state.loading = false;
			state.error = null;
		},
		reauthenticatedStatusChanged: (state: AuthSliceState, action: PayloadAction<'required' | 'idle'>) => {
			state.loading = true;
			state.error = null;
			state.reauthentication = action.payload;
		},
		setReauthenticationCallback: (
			state: AuthSliceState,
			action: PayloadAction<undefined | ReauthenticationRequest>
		) => {
			state.reauthenticationCallback = action.payload;
		},
		abortReauthentication: (state: AuthSliceState) => {
			state.reauthentication = 'idle';
			state.reauthenticationCallback = undefined;
		},
		reauthenticateParticipantRequested: (state: AuthSliceState) => {
			state.loading = true;
			state.error = null;
		},
		reauthenticateParticipantFailed: (state: AuthSliceState, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload;
		},
		participantReauthenticated: (state: AuthSliceState, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = null;
			state.reauthentication = 'idle';
			state.token = action.payload;
		},
		verifyParticipantAccountRequested: (state: AuthSliceState) => {
			state.loading = true;
			state.error = undefined;
		},
		verifyParticipantAccountFailed: (state: AuthSliceState, action: PayloadAction<any>) => {
			state.error = action.payload;
			state.loading = false;
		},
		participantAccountVerified: (state: AuthSliceState) => {
			state.error = undefined;
			state.loading = false;
		},
		passwordResetRequested: (state: AuthSliceState) => {
			state.loading = true;
			state.error = null;
		},
		passwordResetFailed: (state: AuthSliceState, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload;
		},
		passwordReset: (state: AuthSliceState) => {
			state.loading = false;
			state.error = null;
		},
	},
});

export const {
	loginParticipantRequested,
	loginParticipantFailed,
	participantLoggedIn,
	participantLogoutRequested,
	participantLoggedOut,
	requestPasswordResetRequested,
	requestPasswordResetFailed,
	resetPasswordRequested,
	signUpParticipantRequested,
	participantSignedUp,
	signUpParticipantFailed,
	reauthenticatedStatusChanged,
	setReauthenticationCallback,
	abortReauthentication,
	reauthenticateParticipantRequested,
	reauthenticateParticipantFailed,
	participantReauthenticated,
	verifyParticipantAccountRequested,
	verifyParticipantAccountFailed,
	participantAccountVerified,
	passwordResetRequested,
	passwordResetFailed,
	passwordReset,
} = authSlice.actions;

export default authSlice.reducer;

export const loginParticipant =
	({ email, password }: MutationLoginParticipantArgs) =>
		async (dispatch: AppDispatch) => {
			dispatch(loginParticipantRequested());

			await sleep(SHORT_SLEEP_LOADING_SPINNER);

			const randevuService = new randevu();
			const { token, errors: loginErrors } = await randevuService.auth.signInParticipant({
				email,
				password,
			});

			//TODO: Localize content
			if (hasSubmissionErrors(loginErrors)) {
				const submissionErrors = {
					...(isInvalidCredentials(loginErrors) && {
						email: 'Incorrect email or password',
					}),
					...(isFetchError(loginErrors) && {
						[FORM_ERROR]: 'An unexpected error ocurred, please try again or contact us via email',
					}),
				};

				return dispatch(loginParticipantFailed(submissionErrors));
			}

			randevuService.withAuth(token);

			const { user } = await randevuService.participants.getCurrentUser();
			const { participant } = await randevuService.participants.getCurrentParticipant();

			await dispatch(setCurrentParticipant({ ...user, ...participant }));

			return await dispatch(participantLoggedIn(token));
		};

export const logoutParticipant = () => async (dispatch: AppDispatch, getState: () => RootState) => {
	dispatch(participantLogoutRequested());

	const randevuService = new randevu({ token: getState().auth.token });

	// we ignore any errors from the API
	// since the only error can be that the token does not exist
	// ie user session already expired
	await randevuService.auth.signOut();

	dispatch(removeCurrentParticipant());

	return dispatch(participantLoggedOut());
};

export const requestPasswordReset = (email: string) => async (dispatch: AppDispatch) => {
	dispatch(requestPasswordResetRequested());

	const randevuService = new randevu();
	const { errors } = await randevuService.auth.requestParticipantPasswordReset({ email });

	if (hasSubmissionErrors(errors)) {
		//TODO: localize error message
		const submissionErrors = {
			email: 'forgotPasswordPage.fields.email.validation.generalError',
		};
		return dispatch(requestPasswordResetFailed(submissionErrors));
	}

	return dispatch(resetPasswordRequested());
};

export const signupParticipant =
	({ email, first_name, last_name, participant_tech_name }: MutationRegisterParticipantArgs) =>
		async (dispatch: AppDispatch) => {
			dispatch(signUpParticipantRequested());

			const randevuService = new randevu();
			const { signed_up, errors } = await randevuService.auth.signUp({
				first_name,
				last_name,
				email,
				participant_tech_name,
			});

			if (hasSubmissionErrors(errors)) {
				const submissionErrors = {
					...(isDuplicate(errors) && { email: 'Account with this email already exists.' }),
					...(isFetchError(errors) && { [FORM_ERROR]: errors[0].message }),
				};
				if (Object.keys(submissionErrors).length) return dispatch(signUpParticipantFailed(submissionErrors));
			}

			return dispatch(participantSignedUp());
		};

export interface ReauthenticationRequest {
	callbackThunkKey: string;
	payload?: any;
}
export const requestReauthentication = (args: ReauthenticationRequest) => async (dispatch: AppDispatch) => {
	if (!args) return dispatch(reauthenticatedStatusChanged('required'));
	const { callbackThunkKey, payload } = args;
	if (callbackThunkKey) dispatch(setReauthenticationCallback({ callbackThunkKey, payload }));
	return dispatch(reauthenticatedStatusChanged('required'));
};

export const reauthenticate = (password: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
	dispatch(reauthenticateParticipantRequested());

	const randevuService = new randevu();
	const { token, errors } = await randevuService.auth.signInParticipant({
		email: getState().participant.me.email,
		password,
	});

	if (errors) {
		if (hasSubmissionErrors(errors)) {
			//TODO: localize error message
			const submissionErrors = {
				password: 'reauthenticationDialog.password.validation.incorrectPassword',
			};
			return dispatch(reauthenticateParticipantFailed(submissionErrors));
		}
	}

	dispatch(participantReauthenticated(token));

	const reauthenticationCallback = getState().auth.reauthenticationCallback;
	if (!reauthenticationCallback?.callbackThunkKey) return;

	const thunkCallback = THUNKS[reauthenticationCallback.callbackThunkKey]?.getThunk();
	return dispatch(thunkCallback(reauthenticationCallback?.payload));
};

export const verifyParticipantAccount =
	({ password, token }: MutationVerifyParticipantArgs) =>
		async (dispatch: AppDispatch) => {
			dispatch(verifyParticipantAccountRequested());

			const randevuService = new randevu();

			const { verified, errors } = await randevuService.auth.verifyParticipantAccount({
				token,
				password,
			});

			if (hasSubmissionErrors(errors)) {
				const submissionErrors = {
					password: 'accountActivationPage.fields.password.validation.generalError',
				};
				return dispatch(verifyParticipantAccountFailed(submissionErrors));
			}

			return dispatch(participantAccountVerified());
		};

export const resetPassword =
	({ token, password }: MutationResetParticipantPasswordArgs) =>
		async (dispatch: AppDispatch) => {
			dispatch(passwordResetRequested());
			const randevuService = new randevu();
			const { errors } = await randevuService.auth.resetParticipantPassword({
				token,
				password,
			});

			if (hasSubmissionErrors(errors)) {
				const submissionErrors = {
					password: 'chooseNewPasswordPage.fields.password.validation.generalError',
				};
				return dispatch(passwordResetFailed(submissionErrors));
			}

			return dispatch(passwordReset());
		};

// Selectors
export const selectLoading = (state: RootState) => state.auth.loading;
export const selectIsLoggedIn = (state: RootState) => state.auth.token !== undefined;
export const selectReauthenticationStatus = (state: RootState) => state.auth.reauthentication;

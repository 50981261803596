import { isBusinessSide } from '@aquga/app/participantHelpers';
import validator from '@aquga/app/validators';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import {
	updateMatchFailed,
	updateRequirementsAndTransitionToChangesReceived,
} from '@aquga/store/slices/transactionSlice';
import { InputAdornment, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { TextField } from 'mui-rff';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import MessageHistoryAccordion from './MessageHistoryAccordion';
import RdvLoadingButton from './RdvLoadingButton';

interface UpdateRequirementsFormValues {
	message: string;
	price: string;
}

interface ConsumerUpdateRequirementsDialogProps {
	open: boolean;
	transactionId: string;
	oppositeSideParticipantName: string;
	messages: string[];
	price?: number;
	loading: boolean;
	onClose: any;
}

const ConsumerUpdateRequirementsDialog = ({
	price = undefined,
	messages,
	transactionId,
	oppositeSideParticipantName,
	open,
	onClose,
	loading,
}: ConsumerUpdateRequirementsDialogProps) => {
	const dispatch = useAppDispatch();
	const intl = useIntl();
	const participant = useAppSelector(selectCurrentParticipant);
	const isParticipantBussinesSide = isBusinessSide(participant);
	const [isHistoryAccordionExpanded, setHistoryAccordionExpanded] = useState<boolean>(false);

	const handleSubmit = async (values: UpdateRequirementsFormValues) => {
		const fields = [];
		values.price &&
			fields.push({
				tech_name: RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.AGREED_PRICE,
				value: {
					amount: Number(Number(values.price) * 100),
					unit: 'CENT',
					currency: 'EUR',
				},
			}); //FIXME: turn price into monetary value representation
		fields.push({
			tech_name: RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.AGREED_DESCRIPTION,
			value: [...(messages ? messages : []), values.message],
		}); //FIXME: turn price into monetary value representation

		const resolvedAction = await dispatch(
			updateRequirementsAndTransitionToChangesReceived({ fields, transactionId })
		);

		if (resolvedAction.type === updateMatchFailed.type) {
			const submissionErrors = { ...resolvedAction.payload };

			// TODO: write nice helper function which does this one
			Object.entries(submissionErrors).map((keyValue) => {
				submissionErrors[keyValue[0]] = intl.formatMessage({ id: keyValue[1] as string });
			});

			return submissionErrors;
		}
	};

	const fieldValidators = {
		message: [
			(value: string) =>
				validator.required({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
				}),
		],
		price: [
			(value: string) =>
				validator.required({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
				}),
			(value: string) =>
				validator.digitsOnly({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.digitsOnly' })}`,
				}),
		],
	};

	const handleValidate = async (values: UpdateRequirementsFormValues) => {
		return validator.doValidation(fieldValidators, values);
	};

	return (
		<Dialog
			aria-labelledby="update-requirements-dialog"
			open={open}
			fullWidth
			maxWidth="sm"
			BackdropProps={{
				sx: {
					backdropFilter: 'blur(5px)',
					backgroundColor: 'rgba(0,0,30,0.5)',
				},
			}}
		>
			<DialogTitle id="update-requirements-dialog-title">
				<Stack direction="column" spacing={2} justifyContent="flex-start">
					<Typography variant="h6" fontWeight={700}>
						<FormattedMessage id="questions.dialogs.updateRequirementsDialog.common.title" />
					</Typography>
					<Typography variant="body2">
						<FormattedMessage id="questions.dialogs.updateRequirementsDialog.common.body" />
					</Typography>
					<Typography variant="body1">
						<FormattedMessage id="questions.dialogs.updateRequirementsDialog.common.explainer" />
					</Typography>
				</Stack>
			</DialogTitle>
			<Form
				onSubmit={handleSubmit}
				validate={handleValidate}
				initialValues={{ price: price?.toString() }}
				render={({ handleSubmit, submitting }) => (
					<form onSubmit={handleSubmit} noValidate>
						<DialogContent>
							<Stack
								direction="column"
								alignItems="stretch"
								justifyContent="center"
								spacing={2}
								textAlign="left"
							>
								<TextField
									name="message"
									label={intl.formatMessage({
										id: 'questions.dialogs.updateRequirementsDialog.common.fields.message.label',
									})}
									placeholder={intl.formatMessage({
										id: 'questions.dialogs.updateRequirementsDialog.common.fields.message.placeholder',
									})}
									required
									multiline
									disabled={loading || submitting}
									sx={{ height: 'auto ' }}
									rows={5}
									margin="none"
								/>
								<TextField
									name="price"
									label={intl.formatMessage({ id: 'resources.question.price.label' })}
									placeholder={intl.formatMessage({
										id: 'resources.question.price.placeholder',
									})}
									disabled={loading || submitting}
									required
									InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }}
								/>
								{messages && (
									<MessageHistoryAccordion
										oppositeSideParticipanName={oppositeSideParticipantName}
										isHistoryAccordionExpanded={isHistoryAccordionExpanded}
										setHistoryAccordionExpanded={setHistoryAccordionExpanded}
										messages={messages}
										isBussinesSide={isParticipantBussinesSide}
									/>
								)}
								<Stack direction="row" justifyContent="flex-end" spacing={1} width="100%">
									<Button
										variant="outlined"
										onClick={onClose}
										size="large"
										disabled={loading || submitting}
									>
										<FormattedMessage id="questions.dialogs.updateRequirementsDialog.common.btnCancel" />
									</Button>
									<RdvLoadingButton
										variant="contained"
										color="primary"
										type="submit"
										disabled={loading || submitting}
										loading={loading}
										loadingPosition="center"
									>
										<FormattedMessage id="questions.dialogs.updateRequirementsDialog.consumer.btnSubmit" />
									</RdvLoadingButton>
								</Stack>
							</Stack>
						</DialogContent>
					</form>
				)}
			/>
		</Dialog>
	);
};

export default ConsumerUpdateRequirementsDialog;

import { MutationPrepareFileUploadArgs, FileKind } from '@aquga/services/randevuApi/generatedTypes';

import * as fileMutations from './filesMutations';
interface UploadToFileStorageArgs {
	destination_url: string;
	file: File;
}

interface UploadFileArgs {
	provided_name?: string;
	description?: string;
	file: File;
}

interface NamedFile extends File {
	provided_name?: string;
	description?: string;
}

interface UploadFilesArgs {
	files: NamedFile[] | undefined;
}

interface UploadedFile {
	id_file: string;
}

class FilesService {
	private randevu: any;
	/**
	 * @param {randevu} randevu
	 */
	constructor(randevu: any) {
		this.randevu = randevu;
	}

	getFileType(file: File) {
		const result = {
			type: FileKind.Image,
			extension: '',
		};

		if (file.type.startsWith('image/')) {
			result.type = FileKind.Image;
			result.extension = file.type.split('/')[1];

			return result;
		}

		if (file.type.startsWith('application/')) {
			result.type = FileKind.Document;
			result.extension = file.type.split('/')[1];

			return result;
		}

		throw new Error('Unknown file type and extension!');
	}

	async prepareFileUpload({ file_name, type, extension, name, description }: MutationPrepareFileUploadArgs) {
		const payload = fileMutations.prepareFileUpload({ file_name, type, extension, name, description });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { upload_config: data?.prepareFileUpload, errors };
	}

	async uploadToFileStorage({ destination_url, file }: UploadToFileStorageArgs) {
		const headers = {
			'Content-Type': file.type,
		};

		const response = await fetch(destination_url, {
			method: 'PUT',
			headers,
			body: file,
		});

		return response.ok;
	}

	async uploadFile({ provided_name, description, file }: UploadFileArgs): Promise<UploadedFile> {
		const typeDetails = this.getFileType(file);

		const { upload_config, errors } = await this.prepareFileUpload({
			name: provided_name,
			description: description,
			file_name: file.name,
			extension: typeDetails.extension,
			type: typeDetails.type,
		});

		// TODO: handle session expired error;
		// TODO: handle error when uploading to a file storage
		const uploaded = await this.uploadToFileStorage({ destination_url: upload_config.upload_url, file });

		return {
			id_file: upload_config.id,
		};
	}
	async uploadFiles({ files }: UploadFilesArgs): Promise<UploadedFile[]> {
		if (files == undefined) return Promise.resolve([]);
		// upload files in parallel
		const uploaded_files = await Promise.all(
			files.map(async (named_file: NamedFile) => {
				return await this.uploadFile({
					provided_name: named_file.provided_name,
					description: named_file.description,
					file: named_file,
				});
			})
		);

		return uploaded_files.flat();
	}
}

export default FilesService;

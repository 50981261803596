import { EXTERNAL_URLS, SECURITY_FORMS_MAX_WIDTH } from '@aquga/app/constants';
import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import { Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Checkboxes, TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

export interface RegisterFormValues {
	firstName: string;
	lastName: string;
	email: string;
	isCustomer?: boolean;
	acceptedTerms?: boolean;
}

interface RegisterFormProps {
	loading: boolean;
	participantRole: 'business' | 'consulting';
	onSubmit: (value: RegisterFormValues) => any;
	onValidate: (value: RegisterFormValues) => RegisterFormValues | Record<string, never>;
}

const RegisterForm = ({ loading, participantRole, onSubmit, onValidate }: RegisterFormProps) => {
	const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
	const intl = useIntl();

	return (
		<Form
			onSubmit={onSubmit}
			validate={onValidate}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate={true}>
					<Grid
						maxWidth={SECURITY_FORMS_MAX_WIDTH}
						container
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						width="100%"
						spacing={0}
						marginTop={2}
						paddingRight={largeScreen ? 12 : 5}
						paddingLeft={largeScreen ? 12 : 5}
					>
						<Grid item xs={12} marginBottom={4}>
							<Stack direction="column" textAlign="center">
								<Typography variant="h4" component="h1">
									<FormattedMessage id={`registerPage.${participantRole}Side.title.normal`} />
								</Typography>
								<Typography variant="h4" component="h1" color="primary">
									<FormattedMessage id={`registerPage.${participantRole}Side.title.colored`} />
								</Typography>
							</Stack>
						</Grid>
						<Grid item textAlign="center" width="inherit" xs={12} sm={5.8}>
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								name="firstName"
								label={intl.formatMessage({ id: 'registerPage.form.fields.firstName.label' })}
								placeholder={intl.formatMessage({
									id: 'registerPage.form.fields.firstName.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item textAlign="center" width="inherit" xs={12} sm={5.8}>
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								name="lastName"
								label={intl.formatMessage({ id: 'registerPage.form.fields.lastName.label' })}
								placeholder={intl.formatMessage({
									id: 'registerPage.form.fields.lastName.placeholder',
								})}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item textAlign="center" width="inherit" xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								required
								name="email"
								type="email"
								label={intl.formatMessage({ id: 'registerPage.form.fields.email.label' })}
								placeholder={intl.formatMessage({ id: 'registerPage.form.fields.email.placeholder' })}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} textAlign="left" alignItems="flex-start" mt={2}>
							<Checkboxes
								name="acceptedTerms"
								required
								size="small"
								disabled={loading}
								formLabelProps={{
									sx: {
										fontWeight: 'bold',
										color: 'black',
										'&.Mui-focused': {
											color: 'black',
										},
									},
								}}
								data={{
									value: false,
									label: (
										<Typography variant="body1">
											<FormattedMessage id="registerPage.form.agreement" />
											<Typography
												component="a"
												href={EXTERNAL_URLS.AQUGA_TERMS_OF_SERVICE}
												target="_blank"
												rel="noopener noreferrer"
												variant="caption"
												color="secondary"
												sx={{
													'&:hover': {
														cursor: 'pointer',
														textDecoration: 'none',
													},
													textDecoration: 'none',
												}}
											>
												<FormattedMessage id="common.termsOfService" />
												{', '}
											</Typography>
											<Typography
												component="a"
												href={EXTERNAL_URLS.AQUGA_PRIVACY_POLICY}
												target="_blank"
												rel="noopener noreferrer"
												variant="caption"
												color="secondary"
												sx={{
													'&:hover': {
														cursor: 'pointer',
													},
													textDecoration: 'none',
												}}
											>
												<FormattedMessage id="common.privacyPolicy" />
												{', '}
											</Typography>

											<Typography variant="caption">
												{' '}
												<FormattedMessage id="common.and" />{' '}
											</Typography>
											<Typography
												component="a"
												href={EXTERNAL_URLS.AQUGA_COOKIE_POLICY}
												target="_blank"
												rel="noopener noreferrer"
												variant="caption"
												color="secondary"
												sx={{
													'&:hover': {
														cursor: 'pointer',
													},
													textDecoration: 'none',
												}}
											>
												<FormattedMessage id="common.cookiePolicy" />
												{'.'}
											</Typography>
										</Typography>
									),
								}}
							/>
						</Grid>
						<Grid item textAlign="center" width="inherit" paddingTop={3}>
							<RdvLoadingButton
								variant="contained"
								color="primary"
								type="submit"
								loading={loading}
								loadingPosition="center"
								fullWidth={largeScreen ? false : true}
							>
								<Typography variant="body2">
									<FormattedMessage id="registerPage.form.btnSignUp" />
								</Typography>
							</RdvLoadingButton>
						</Grid>
					</Grid>
				</form>
			)}
		/>
	);
};

export default RegisterForm;

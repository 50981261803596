import { PASSWORD_POLICY, REGEX } from '@aquga/app/validators';
import { AQUGA_PINK_10, AQUGA_PINK_30, AQUGA_YELLOW_10 } from '@aquga/styles/theme';
import CircleIcon from '@mui/icons-material/Circle';
import { ListItemText, Stack, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';

interface PasswordFeedbackBulletpointProps {
	isCompliant: boolean;
	label: string;
}

const PasswordFeedbackBulletpoint = ({ isCompliant, label }: PasswordFeedbackBulletpointProps) => {
	const theme = useTheme();
	const bulletpointBorderColor = theme.palette.primary.main === AQUGA_PINK_30 ? AQUGA_PINK_10 : AQUGA_YELLOW_10;
	return (
		<Stack direction="row" alignItems="center" gap={1}>
			<CircleIcon
				color={isCompliant ? 'disabled' : 'primary'}
				sx={{
					padding: '3px',
					backgroundColor: `${isCompliant ? 'inherit' : bulletpointBorderColor}`,
					borderRadius: '20px',
				}}
			/>
			<ListItemText primaryTypographyProps={{ variant: 'subtitle1' }} primary={label} />
		</Stack>
	);
};

interface PasswordFeedbackProps {
	password: string | undefined;
}

const PasswordFeedback = ({ password }: PasswordFeedbackProps) => {
	const intl = useIntl();

	const isUppercase = password ? !REGEX.PASSWORD.atLastOneUppercase.test(password as string) : true;
	const isLowercase = password ? !REGEX.PASSWORD.atLastOneLowercase.test(password as string) : true;
	const isMinMaxLength = password
		? password?.length > PASSWORD_POLICY.maxAllowedLength || password?.length < PASSWORD_POLICY.atleastLength
		: true;
	const isAtLeastSpecialCharacters = password
		? (password?.match(REGEX.PASSWORD.countSpecialCharacters) || []).length <
		  PASSWORD_POLICY.atleastSpecialCharacters
		: true;
	const isMinDigits = password ? password?.replace(/[^0-9]/g, '').length < PASSWORD_POLICY.atleastDigits : true;

	return (
		<Stack direction="column" textAlign="left">
			<PasswordFeedbackBulletpoint
				label={intl.formatMessage({ id: 'registerPage.activateAccount.bulletPoint1' })}
				isCompliant={isMinMaxLength}
			/>
			<PasswordFeedbackBulletpoint
				label={intl.formatMessage({ id: 'registerPage.activateAccount.bulletPoint2' })}
				isCompliant={isUppercase}
			/>
			<PasswordFeedbackBulletpoint
				label={intl.formatMessage({ id: 'registerPage.activateAccount.bulletPoint3' })}
				isCompliant={isLowercase}
			/>
			<PasswordFeedbackBulletpoint
				label={intl.formatMessage({ id: 'registerPage.activateAccount.bulletPoint4' })}
				isCompliant={isMinDigits}
			/>
			<PasswordFeedbackBulletpoint
				label={intl.formatMessage({ id: 'registerPage.activateAccount.bulletPoint5' })}
				isCompliant={isAtLeastSpecialCharacters}
			/>
		</Stack>
	);
};

export default PasswordFeedback;

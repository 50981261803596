import { SupportedLocaleKeys } from '@aquga/i18n/messages';

import { DEFAULT_LOCALE, locale } from './locale';
import { messages } from './messages';
export * from './messages';
export { locale, DEFAULT_LOCALE };

export const getLocalizedMessages = (locale: SupportedLocaleKeys) => {
	return messages[locale];
};

import { QueryMySupplyArgs } from '@aquga/services/randevuApi/generatedTypes';
import { print } from 'graphql';
import gql from 'graphql-tag';

const GET_MY_SUPPLY = gql`
	query GET_MY_SUPPLY($id: ID!) {
		mySupply(id: $id) {
			id
			status
			type {
				name
			}
			onboarding_progress {
				next_steps {
					type
					action_message
					config
				}
				status
				message
			}
			fields {
				... on SimpleField {
					simpleValue: value
					field_type {
						tech_name
					}
				}
				... on ObjectField {
					objectValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									tech_name
								}
							}
						}
					}
					field_type {
						tech_name
					}
				}

				... on ObjectSetField {
					id
					objectSetValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									tech_name
								}
							}
						}
					}
					field_type {
						tech_name
					}
				}
			}
		}
	}
`;

const mySupply = ({ id }: QueryMySupplyArgs) => {
	return JSON.stringify({
		query: print(GET_MY_SUPPLY),
		variables: {
			id: id,
		},
	});
};

const MY_SUPPLIES = gql`
	query {
		mySupplies {
			id
			status
			type {
				name
				id
			}
			fields {
				... on SimpleField {
					simpleValue: value
					field_type {
						tech_name
					}
				}
				... on ObjectField {
					objectValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									tech_name
								}
							}
						}
					}
					field_type {
						tech_name
					}
				}

				... on ObjectSetField {
					id
					objectSetValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									tech_name
								}
							}
						}
					}
					field_type {
						tech_name
					}
				}
			}
		}
	}
`;

const mySupplies = () => {
	return JSON.stringify({
		query: print(MY_SUPPLIES),
	});
};

export { mySupplies, mySupply };

import { findFieldByTechName } from '@aquga/app/fieldHelpers';
import MatchStatus from '@aquga/components/questions/status/MatchStatus';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { AQUGA_NATURAL_00, AQUGA_NATURAL_10, AQUGA_NATURAL_20 } from '@aquga/styles/theme';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormattedDate, FormattedMessage } from 'react-intl';

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: AQUGA_NATURAL_10,
		fontWeight: 600,
	},
}));

const StyledTableRow = styled(TableRow)(() => ({
	border: 0,
	backgroundColor: AQUGA_NATURAL_00,

	'&:hover': {
		backgroundColor: AQUGA_NATURAL_20,
		cursor: 'pointer',
	},
}));

interface MatchesTableProps {
	items: any[];
	loading: boolean;
	onSeeDetails: (params: any) => any;
}

const MatchesTable = ({ loading, items = [], onSeeDetails }: MatchesTableProps) => {
	return (
		<Box sx={{ backgroundColor: AQUGA_NATURAL_10, borderRadius: 3, width: '100%' }}>
			<TableContainer sx={{ backgroundColor: AQUGA_NATURAL_10 }}>
				<Table
					sx={{
						[`& .${tableCellClasses.root}`]: {
							borderBottom: 'none',
						},
					}}
				>
					<TableHead>
						<TableRow>
							<StyledTableCell>
								<Typography variant="subtitle2">
									<FormattedMessage id="questions.details.matches.table.columnProvider" />
								</Typography>
							</StyledTableCell>
							<StyledTableCell>
								<Typography variant="subtitle2">
									<FormattedMessage id="questions.details.matches.table.columnCreatedOn" />
								</Typography>
							</StyledTableCell>
							<StyledTableCell>
								<Typography variant="subtitle2">
									<FormattedMessage id="questions.details.matches.table.columnStatus" />
								</Typography>
							</StyledTableCell>
							<StyledTableCell></StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{items.map((item: any) => {
							const providerFirstName = findFieldByTechName(
								item?.providerFields,
								RANDEVU_IDS.USER_TYPE_CONSULTING_HOUSE.FIELDS.FIRST_NAME.TECH_NAME
							)?.value;
							const providerLastName = findFieldByTechName(
								item?.providerFields,
								RANDEVU_IDS.USER_TYPE_CONSULTING_HOUSE.FIELDS.LAST_NAME.TECH_NAME
							)?.value;

							return (
								<StyledTableRow key={item.id} onClick={() => onSeeDetails(item.id)}>
									<StyledTableCell component="th" scope="row">
										<Typography color="textSecondary" variant="body1">
											{/* {item.providerName} */}
											{`${providerFirstName} ${providerLastName}`}
										</Typography>
									</StyledTableCell>
									<StyledTableCell>
										<Typography variant="body1">
											<FormattedDate
												value={item.createdAt}
												day="numeric"
												year="numeric"
												month="long"
											></FormattedDate>
										</Typography>
									</StyledTableCell>

									<StyledTableCell>
										<MatchStatus loading={loading} status={item.status} />
									</StyledTableCell>
									<StyledTableCell>
										<ArrowForwardIosIcon fontSize="small" />
									</StyledTableCell>
								</StyledTableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default MatchesTable;

import { createTheme, PaletteColorOptions, responsiveFontSizes, Theme, ThemeOptions } from '@mui/material/styles';

export const FONT_WEIGHT_LIGHT = 300;
export const FONT_WEIGHT_SEMIBOLD = 600;
export const FONT_WEIGHT_BOLD = 700;
export const FONT_WEIGHT_EXTRABOLD = 800;

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		tertiary: true;
		quaternary: true;
		quinary: true;
	}
}

declare module '@mui/material/styles' {
	interface CustomTheme extends Theme {
		[key: string]: any; //
	}
	// allow configuration using `createTheme`
	interface CustomThemeOptions extends ThemeOptions {
		[key: string]: any; //
	}
	export function createTheme(options?: CustomThemeOptions): CustomTheme;
}

export const AQUGA_YELLOW_00 = '#FDF3DA';
export const AQUGA_YELLOW_10 = '#FBE7B5';
export const AQUGA_YELLOW_20 = '#FFDC85';
export const AQUGA_YELLOW_30 = '#FFC20D'; //
export const AQUGA_YELLOW_40 = '#CC9B0A'; //
export const AQUGA_YELLOW_50 = '#96731B';

export const AQUGA_PINK_00 = '#FBEBF1';
export const AQUGA_PINK_10 = '#F4C4D5';
export const AQUGA_PINK_20 = '#EC719D';
export const AQUGA_PINK_30 = '#FF1273'; //
export const AQUGA_PINK_40 = '#CC0E5C'; //
export const AQUGA_PINK_50 = '#7D1F41';

export const AQUGA_PURPLE_00 = '#FBCBFF';
export const AQUGA_PURPLE_10 = '#D1B6D9';
export const AQUGA_PURPLE_20 = '#9F74A9';
export const AQUGA_PURPLE_30 = '#750080'; //
export const AQUGA_PURPLE_40 = '#5E0066'; //
export const AQUGA_PURPLE_50 = '#230026';

export const AQUGA_BLUE_00 = '#EFF8FC';
export const AQUGA_BLUE_10 = '#9DE3FD';
export const AQUGA_BLUE_20 = '#8DD0F7';
export const AQUGA_BLUE_30 = '#0ABAFA'; //
export const AQUGA_BLUE_40 = '#0895C8'; //
export const AQUGA_BLUE_50 = '#2A526A';

export const AQUGA_NATURAL_00 = '#FCFCFD';
export const AQUGA_NATURAL_10 = '#F8F8FA';
export const AQUGA_NATURAL_20 = '#E4E6ED';
export const AQUGA_NATURAL_30 = '#C2C5D1';
export const AQUGA_NATURAL_40 = '#ABAEBD';
export const AQUGA_NATURAL_50 = '#8B8F9E';
export const AQUGA_NATURAL_60 = '#737787';
export const AQUGA_NATURAL_70 = '#5a5d6c';
export const AQUGA_NATURAL_80 = '#434651';
export const AQUGA_NATURAL_90 = '#31333C';
export const AQUGA_NATURAL_100 = '#131315';

export const AQUGA_WARNING_10 = '#FFB84D';
export const AQUGA_WARNING_30 = '#D98608';

export const AQUGA_SUCCESS_20 = '#3DCC87';
export const AQUGA_SUCCESS_40 = '#069952';
export const AQUGA_SUCCESS_50 = '#008042';

export const AQUGA_DANGER_10 = '#F94B4B';
export const AQUGA_DANGER_30 = '#B30000';
export const AQUGA_DELETE_RED = '#D90611';

export const AQUGA_NEUTRAL_0 = '#FCFCFD';
export const AQUGA_NEUTRAL_10 = '#F8F8FA';
export const AQUGA_NEUTRAL_30 = '#C2C5D1';
export const AQUGA_NEUTRAL_80 = '#434651';

export const AQUGA_BACKGROUND_COLOR = AQUGA_NATURAL_20;

export const AQUGA_LOGIN_GRAY = '#434651';

export const RANDEVU_PURPLE = '#484177';

declare module '@mui/material/styles' {
	interface Palette {
		tertiary: PaletteColorOptions;
		quaternary: PaletteColorOptions;
		quinary: PaletteColorOptions;
	}
	interface PaletteOptions {
		tertiary?: PaletteColorOptions;
		quaternary?: PaletteColorOptions;
		quinary?: PaletteColorOptions;
	}
}

/////////////
// COMMON //
////////////

const typography = {
	fontFamily: ['Montserrat', 'Open Sans'].join(','),
	h1: {
		fontSize: '5rem',
		fontWeight: FONT_WEIGHT_EXTRABOLD,
		letterSpacing: '−0.09375em',
		paragraphSpacing: '1.875rem',
	},
	h2: {
		fontSize: '3.75rem',
		fontWeight: FONT_WEIGHT_LIGHT,
		letterSpacing: '−0.03125rem',
		paragraphSpacing: '1.875rem',
	},
	h3: {
		fontSize: '3rem',
		fontWeight: FONT_WEIGHT_SEMIBOLD,
		letterSpacing: '0rem',
		paragraphSpacing: '1.5rem',
	},
	h4: {
		fontSize: '2rem',
		fontWeight: FONT_WEIGHT_SEMIBOLD,
		letterSpacing: '0.015625rem',
		paragraphSpacing: '1.125rem',
	},
	h5: {
		fontSize: '1.5rem',
		fontWeight: FONT_WEIGHT_SEMIBOLD,
		letterSpacing: '0rem',
		paragraphSpacing: '0.5rem',
	},
	h6: {
		fontSize: '1.25rem',
		fontWeight: FONT_WEIGHT_SEMIBOLD,
		letterSpacing: '−0.009375rem',
		paragraphSpacing: '0.5',
	},
	subtitle1: {
		fontSize: '1rem',
		letterSpacing: '0.009375rem',
		paragraphSpacing: '0.375rem',
	},
	subtitle2: {
		fontSize: '1rem',
		fontWeight: FONT_WEIGHT_BOLD,
		letterSpacing: '0.009375rem',
		paragraphSpacing: '0.375rem',
	},
	body1: {
		fontSize: '1rem',
		letterSpacing: '0rem',
		paragraphSpacing: '0.375rem',
	},
	body2: {
		fontSize: '0.875rem',
		fontWeight: FONT_WEIGHT_BOLD,
		letterSpacing: '0rem',
		paragraphSpacing: '0.375rem',
	},
	button: {
		fontSize: '0.875rem',
		fontWeight: FONT_WEIGHT_SEMIBOLD,
		letterSpacing: '0.03125rem',
		paragraphSpacing: '0rem',
	},
	caption: {
		fontSize: '0.75rem',
		fontWeight: FONT_WEIGHT_SEMIBOLD,
		letterSpacing: '0.025rem',
		paragraphSpacing: '0.25rem',
	},
	overline: {
		fontSize: '0.625rem',
		letterSpacing: '0.015625rem',
		paragraphSpacing: '0.25rem',
	},
};

const defaultTheme = createTheme();

///////////////////
// GENERAL THEME //
///////////////////

const themeNameGeneral = 'AQUGA Theme';

const palette = {
	primary: {
		main: AQUGA_PURPLE_30,
		dark: AQUGA_PURPLE_40,
	},
	secondary: {
		main: AQUGA_BLUE_30,
		dark: AQUGA_BLUE_40,
	},
	tertiary: defaultTheme.palette.augmentColor({
		name: 'tertiary',
		color: { main: AQUGA_YELLOW_30, dark: AQUGA_YELLOW_40 },
	}),
	quaternary: defaultTheme.palette.augmentColor({
		name: 'quaternary',
		color: { main: AQUGA_PINK_30, dark: AQUGA_PINK_40 },
	}),
	quinary: defaultTheme.palette.augmentColor({
		name: 'quinary',
		color: { main: AQUGA_NATURAL_10, dark: AQUGA_NATURAL_30 },
	}),
};

export const theme = responsiveFontSizes(
	createTheme({
		palette,
		themeName: themeNameGeneral,
		typography: typography,
		components: {
			MuiButton: {
				styleOverrides: {
					root: ({ theme }) => ({
						borderRadius: 28,
						textTransform: 'none',
						'&.MuiButton-containedPrimary:hover': {
							backgroundColor: theme.palette.primary.dark,
						},
						'&.MuiButton-containedSecondary:hover': {
							backgroundColor: theme.palette.secondary.dark,
						},
						'&.MuiButton-containedTertiary:hover': {
							backgroundColor: 'tertiary-dark',
						},
						'&.MuiButton-containedQuaternary:hover': {
							backgroundColor: 'quaternary-dark',
						},
						'&.MuiButton-containedQuinary:hover': {
							backgroundColor: 'quinary-dark',
						},
					}),
				},
			},
			MuiTextField: {
				defaultProps: {
					helperText: ' ',
					sx: { height: 57 },
					InputProps: {
						style: { ...typography.subtitle1 },
					},
					InputLabelProps: {
						style: { ...typography.caption },
					},
				},
			},
		},
	})
);

const muiTextFieldDefaultProps = {
	defaultProps: {
		helperText: ' ',
		sx: { height: 57 },
	},
};

////////////////////
// BUSINESS SIDE //
//////////////////

const themeNameBusinessSide = 'AQUGA Theme - Business Side';

const paletteBusinessSide = {
	primary: {
		main: AQUGA_PINK_30,
		dark: AQUGA_PINK_40,
	},
	secondary: {
		main: AQUGA_BLUE_30,
		dark: AQUGA_BLUE_40,
	},
};

export const themeBusinessSide = responsiveFontSizes(
	createTheme({
		themeName: themeNameBusinessSide,
		palette: paletteBusinessSide,
		typography: typography,
		components: {
			MuiButton: {
				styleOverrides: {
					root: () => ({
						borderRadius: 28,
						textTransform: 'none',
					}),
				},
			},
			MuiTextField: {
				...muiTextFieldDefaultProps,
			},
		},
	})
);

//////////////////////
// CONSULTING SIDE //
/////////////////////

const themeNameConsultingSide = 'AQUGA Theme - Consulting Side';

const paletteConsultingSide = {
	primary: {
		main: AQUGA_YELLOW_30,
		dark: AQUGA_YELLOW_40,
	},
	secondary: {
		main: AQUGA_BLUE_30,
		dark: AQUGA_BLUE_40,
	},
};

export const themeConsultingSide = responsiveFontSizes(
	createTheme({
		themeName: themeNameConsultingSide,
		palette: paletteConsultingSide,
		typography: typography,
		components: {
			MuiButton: {
				styleOverrides: {
					root: () => ({
						borderRadius: 28,
						textTransform: 'none',
					}),
				},
			},
			MuiTextField: {
				...muiTextFieldDefaultProps,
			},
		},
	})
);

import { mapSupplyFieldsToServiceFields } from '@aquga/app/mappers';
import PageHeader from '@aquga/components/common/PageHeader';
import ListServicesView from '@aquga/components/services/list/ListServicesView';
import NoServicesYetView from '@aquga/components/services/NoServicesYetView';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { loadMySupplies, selectMySupplies } from '@aquga/store/slices/supplySlice';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

const MyAnsweringExpertise = () => {
	const intl = useIntl();
	const dispatch = useAppDispatch();
	const mySupply = useAppSelector(selectMySupplies);

	const service = mapSupplyFieldsToServiceFields(mySupply);

	useEffect(() => {
		dispatch(loadMySupplies());
	}, [dispatch]);

	return (
		<>
			<PageHeader title={intl.formatMessage({ id: 'myAnsweringExpertise.title' })} />
			<br />
			{service.length > 0 ? <ListServicesView items={service} /> : <NoServicesYetView />}
		</>
	);
};

export default MyAnsweringExpertise;

interface EnvVar {
	envName: string;
	value: any;
}
const assertEnvVar = ({ envName, value }: EnvVar): void => {
	if (['', null, undefined].includes(value)) {
		const errorMsg = `Environment variable ${envName} is missing!.`;
		throw new Error(errorMsg);
	}
};

const getEnvironmentVariable = (environmentVariableName: string) => {
	const envVar = process.env[environmentVariableName];
	assertEnvVar({ envName: environmentVariableName, value: envVar });
	if (!envVar) throw new Error('Error with environment variables');

	return envVar;
};

interface EnvVars {
	RANDEVU_API: string;
	RANDEVU_PUBLIC_API_KEY: string;
}
export const ENV_VARS: EnvVars = {
	RANDEVU_API: getEnvironmentVariable('REACT_APP_RANDEVU_API_ENDPOINT'),
	RANDEVU_PUBLIC_API_KEY: getEnvironmentVariable('REACT_APP_RANDEVU_PK_API_KEY'),
};

import validator from '@aquga/app/validators';
import { ROUTES } from '@aquga/config/routes';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import {
	abortReauthentication,
	logoutParticipant,
	reauthenticate,
	reauthenticateParticipantFailed,
	selectReauthenticationStatus,
} from '@aquga/store/slices/authSlice';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { TextField } from 'mui-rff';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

interface ReauthenticationFormValues {
	password: string;
}

const ReauthenticateDialog = () => {
	const intl = useIntl();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const reauthenticationStatus = useAppSelector(selectReauthenticationStatus);
	const open = reauthenticationStatus === 'required';

	const [showPassword, setShowPassword] = useState(false);

	const handleCancel = () => {
		dispatch(logoutParticipant());
		dispatch(abortReauthentication());
		navigate(ROUTES.SIGN_IN_PAGE);
	};

	const handleSubmit = async (values: ReauthenticationFormValues) => {
		const resolvedAction = await dispatch(reauthenticate(values.password));

		if (resolvedAction.type === reauthenticateParticipantFailed.type) {
			const submissionErrors = { ...resolvedAction.payload };

			// TODO: write nice helper function which does this one
			Object.entries(submissionErrors).map((keyValue) => {
				submissionErrors[keyValue[0]] = intl.formatMessage({ id: keyValue[1] as string });
			});

			return submissionErrors;
		}
	};

	const fieldValidators = {
		password: [
			(value: string) =>
				validator.required({
					value,
					errorMsg: `${intl.formatMessage({ id: 'validation.general.mandatoryField' })}`,
				}),
		],
	};

	const handleValidate = async (values: ReauthenticationFormValues) => {
		return validator.doValidation(fieldValidators, values);
	};

	return (
		<Dialog
			aria-labelledby="reauthenticate-dialog"
			open={open}
			fullWidth
			maxWidth="xs"
			BackdropProps={{
				sx: {
					backdropFilter: 'blur(5px)',
					backgroundColor: 'rgba(0,0,30,0.5)',
				},
			}}
		>
			<DialogTitle id="reauthenticate-dialog-title">
				<Stack direction="column" spacing={2} justifyContent="flex-start">
					<Typography variant="h6" fontWeight={700}>
						<FormattedMessage id="reauthenticationDialog.title" />
					</Typography>
					<Typography variant="body1">
						<FormattedMessage id="reauthenticationDialog.body" />
					</Typography>
				</Stack>
			</DialogTitle>
			<Form
				onSubmit={handleSubmit}
				validate={handleValidate}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit} noValidate>
						<DialogContent>
							<Stack
								direction="column"
								alignItems="stretch"
								justifyContent="center"
								spacing={2}
								textAlign="left"
							>
								<TextField
									fullWidth
									name="password"
									InputLabelProps={{ shrink: true }}
									required
									label={intl.formatMessage({
										id: 'reauthenticationDialog.password.label',
									})}
									placeholder={intl.formatMessage({
										id: 'reauthenticationDialog.password.placeholder',
									})}
									sx={{ pb: 1 }}
									type={showPassword ? 'text' : 'password'}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => setShowPassword(!showPassword)}
													onMouseDown={() => setShowPassword(!showPassword)}
												>
													{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<Button type="submit" color="primary" variant="contained" fullWidth>
									<FormattedMessage id="reauthenticationDialog.continueButton" />
								</Button>
								<Button variant="outlined" onClick={handleCancel}>
									<FormattedMessage id="reauthenticationDialog.cancelButton" />
								</Button>
							</Stack>
						</DialogContent>
					</form>
				)}
			/>
		</Dialog>
	);
};

export default ReauthenticateDialog;

import File from '@aquga/components/common/File';
import { AQUGA_NATURAL_60, AQUGA_NATURAL_80, AQUGA_NATURAL_90 } from '@aquga/styles/theme';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { FormattedMessage } from 'react-intl';

interface QuestionTitleDescriptionAdditionalNotesAndDocsProps {
	loading: boolean;
	title: string;
	description: string;
	additionalNote?: string;
	ticketNumber: number;
	additionalDocs?: { id_file: string; description?: string; file_name: string; name?: string; url: string }[];
	pt?: number;
	pb?: number;
}
const QuestionTitleDescriptionAdditionalNotesAndDocs = ({
	loading,
	title,
	description,
	additionalNote,
	ticketNumber,
	additionalDocs,
	pt = 4.5,
	pb = 4.5,
}: QuestionTitleDescriptionAdditionalNotesAndDocsProps) => {
	return (
		<Box sx={{ pl: 1.5, pr: 1.5, pt, pb, width: '100%' }}>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={2}
				sx={{ pl: 2, pr: 2, pb: 0, width: '100%' }}
			>
				<Box>
					{loading ? (
						<Skeleton width={100} height={20} />
					) : (
						<Typography sx={{ color: AQUGA_NATURAL_60 }} variant="overline">
							{ticketNumber}
						</Typography>
					)}
					<Typography sx={{ color: AQUGA_NATURAL_60 }} variant="h6">
						<FormattedMessage id="questions.details.sectionTitleDescription" />
					</Typography>
				</Box>
				{loading ? (
					<Skeleton width={210} height={60} />
				) : (
					<>
						<Typography sx={{ color: AQUGA_NATURAL_90, wordBreak: 'break-all' }} variant="subtitle2">
							{title}
						</Typography>
						<Typography sx={{ color: AQUGA_NATURAL_80, wordBreak: 'break-all' }} variant="body1">
							{description}
						</Typography>
					</>
				)}

				{loading ? (
					<Skeleton width={210} height={60} />
				) : (
					(additionalNote || additionalDocs) && (
						<>
							<br />
							<Typography sx={{ color: AQUGA_NATURAL_60 }} variant="overline">
								<FormattedMessage id="questions.details.sectionTitleAdditionalNotes" />
							</Typography>
							<Typography sx={{ color: AQUGA_NATURAL_80, wordBreak: 'break-all' }} variant="body1">
								{additionalNote}
							</Typography>
							{additionalDocs?.map((doc) => (
								<File key={doc.id_file} url={doc.url} fileName={doc.file_name} />
							))}
						</>
					)
				)}
			</Stack>
		</Box>
	);
};

export default QuestionTitleDescriptionAdditionalNotesAndDocs;

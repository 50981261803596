import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppDispatch } from '@aquga/store/configureStore';
import {
	acceptTransactedMatch,
	declineTransactedMatch,
	triggerManualMatchTransition,
} from '@aquga/store/slices/transactionSlice';
import { Button, Paper, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface MatchOptionsProps {
	status: string;
	transactionId: string;
	loading: boolean;
}

export const MatchOptions = ({ transactionId, status, loading }: MatchOptionsProps) => {
	const theme = useTheme();
	const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
	const dispatch = useAppDispatch();

	const stateTypesWhenComponentIsRendered = [
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONSULTANT_APPLIED,
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.MATCH_TIME,
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.SCHEDULED,
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_OFFERED,
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.PARKED,
	];

	const currentState = stateTypesWhenComponentIsRendered.find(
		(stateType) => stateType.TECH_NAME.toUpperCase() === status?.toUpperCase()
	);
	const currentTransitions = currentState?.TRANSITIONS as any;
	return currentState ? (
		<Stack
			direction={isLargeScreen ? 'row' : 'column'}
			component={Paper}
			justifyContent="space-around"
			padding={2}
			elevation={0}
			spacing={1}
			borderRadius="12px"
		>
			{loading ? (
				<>
					<Skeleton width={120} /> <Skeleton width={120} /> <Skeleton width={120} />
				</>
			) : (
				currentTransitions?.DISMISS && (
					<Button
						variant="text"
						color="secondary"
						size="large"
						disableRipple
						onClick={() =>
							dispatch(
								triggerManualMatchTransition({
									id_transaction: transactionId,
									transition_tech_name: currentTransitions?.DISMISS,
								})
							)
						}
					>
						<FormattedMessage id="questions.match.options.btnDismiss" />
					</Button>
				)
			)}
			{currentTransitions?.PARK && !loading && (
				<Button
					variant="text"
					color="secondary"
					size="large"
					disableRipple
					onClick={() =>
						dispatch(
							triggerManualMatchTransition({
								id_transaction: transactionId,
								transition_tech_name: currentTransitions?.PARK,
							})
						)
					}
				>
					<FormattedMessage id="questions.match.options.btnDecideLater" />
				</Button>
			)}
			{currentTransitions?.DECLINE && !loading && (
				<Button
					variant="outlined"
					color="primary"
					size="large"
					onClick={() =>
						dispatch(
							triggerManualMatchTransition({
								transition_tech_name: currentTransitions.DECLINE,
								id_transaction: transactionId,
							})
						)
					}
				>
					<FormattedMessage id="questions.match.options.btnDecline" />
				</Button>
			)}
			{currentTransitions?.ACCEPT && !loading && (
				<Button
					variant="contained"
					color="primary"
					size="large"
					disableElevation
					onClick={() =>
						dispatch(
							acceptTransactedMatch({
								id_transaction: transactionId,
							})
						)
					}
				>
					<FormattedMessage id="questions.match.options.btnApprove" />
				</Button>
			)}
		</Stack>
	) : (
		<></>
	);
};

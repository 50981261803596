import { SECURITY_FORMS_MAX_WIDTH } from '@aquga/app/constants';
import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import { Button, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { TextField } from 'mui-rff';
import { useIntl, FormattedMessage } from 'react-intl';

export interface AddContactDetailsFormValues {
	contact_person: string;
	contact_email: string;
	phoneNumber: string;
}

interface AddContactDetailsViewProps {
	loading: boolean;
}

const AddContactDetailsView = ({ loading }: AddContactDetailsViewProps) => {
	const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
	const intl = useIntl();

	return (
		<Grid
			maxWidth={SECURITY_FORMS_MAX_WIDTH}
			container
			direction="row"
			alignItems="center"
			justifyContent="center"
			spacing={3}
			paddingRight={largeScreen ? 12 : 10}
			paddingLeft={largeScreen ? 12 : 5}
		>
			<Grid item xs={12} width="inherit">
				<Typography
					variant="h4"
					component="span"
					marginLeft="auto"
					marginRight="auto"
					maxWidth="21rem"
					sx={{ wordBreak: 'break-word' }}
				>
					<FormattedMessage id="registerPage.addContactDetails.title.normal" />{' '}
					<Typography variant="h4" component="span" color="primary">
						<FormattedMessage id="registerPage.addContactDetails.title.colored" />
					</Typography>
				</Typography>
			</Grid>
			<Grid item textAlign="center" width="inherit" xs={12}>
				<TextField
					InputLabelProps={{ shrink: true }}
					required
					name="contact_person"
					label={intl.formatMessage({ id: 'registerPage.addContactDetails.contactPerson.label' })}
					placeholder={intl.formatMessage({
						id: 'registerPage.addContactDetails.contactPerson.placeholder',
					})}
					margin="normal"
					fullWidth
				/>
			</Grid>
			<Grid item textAlign="center" width="inherit" xs={12}>
				<TextField
					InputLabelProps={{ shrink: true }}
					required
					name="contact_email"
					type="email"
					label={intl.formatMessage({ id: 'registerPage.addContactDetails.email.label' })}
					placeholder={intl.formatMessage({
						id: 'registerPage.addContactDetails.email.placeholder',
					})}
					margin="normal"
					fullWidth
				/>
			</Grid>
			<Grid item textAlign="center" width="inherit" xs={12}>
				<TextField
					InputLabelProps={{ shrink: true }}
					required
					name="phone_number"
					label={intl.formatMessage({ id: 'registerPage.addContactDetails.phoneNumber.label' })}
					placeholder={intl.formatMessage({
						id: 'registerPage.addContactDetails.phoneNumber.placeholder',
					})}
					margin="normal"
					fullWidth
				/>
			</Grid>
			<Grid item textAlign="center" width="inherit" mt={2}>
				<RdvLoadingButton
					variant="contained"
					color="primary"
					type="submit"
					loading={loading}
					loadingPosition="center"
					fullWidth={largeScreen ? false : true}
				>
					<FormattedMessage id="registerPage.addContactDetails.buttonText" />
				</RdvLoadingButton>
			</Grid>
		</Grid>
	);
};

export default AddContactDetailsView;

import { ROUTES } from '@aquga/config/routes';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectIsLoggedIn } from '@aquga/store/slices/authSlice';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoutes = () => {
	const loggedIn = useAppSelector(selectIsLoggedIn);

	return loggedIn ? <Outlet /> : <Navigate to={ROUTES.SIGN_IN_PAGE} />;
};

export default ProtectedRoutes;

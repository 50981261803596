import { Drawer } from '@mui/material';
import { Stack } from '@mui/system';

import LanguagePickerDrawer from './LanguagePickerDrawer';
import NavigationItems from './NavigationItems';
interface NavigationDrawerProps {
	open: boolean;
	drawerWidth: number;
	onDrawerToggle: (params: any) => any;
}
const NavigationDrawer = ({ open, drawerWidth, onDrawerToggle }: NavigationDrawerProps) => {
	return (
		<Drawer
			variant="persistent"
			open={open}
			anchor="left"
			onClose={onDrawerToggle}
			ModalProps={{
				keepMounted: true, // Better open performance on mobile.
			}}
			sx={{
				display: { sm: 'block', md: 'none' },
				'& .MuiDrawer-paper': {
					boxSizing: 'border-box',
					width: drawerWidth,
				},
			}}
		>
			<Stack sx={{ mb: 'auto' }}>
				<NavigationItems onDrawerToggle={onDrawerToggle} />
			</Stack>
			<LanguagePickerDrawer />
		</Drawer>
	);
};

export default NavigationDrawer;

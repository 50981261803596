import { Service } from '@aquga/components/services/types';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { ROUTES } from '@aquga/config/routes';
import { AQUGA_NATURAL_10, AQUGA_NATURAL_20 } from '@aquga/styles/theme';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: AQUGA_NATURAL_20,
	},
}));

const StyledTableRow = styled(TableRow)(() => ({
	border: 0,
	'&:hover': {
		backgroundColor: AQUGA_NATURAL_20,
		cursor: 'pointer',
	},
}));

interface AnsweringExpertiseTableProps {
	items: Service[];
}

const AnsweringExpertiseTable = ({ items = [] }: AnsweringExpertiseTableProps) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const seeDetails = (answeringExpertiseId: string) => {
		navigate(ROUTES.UPDATE_SERVICE.replace(':id', answeringExpertiseId));
	};

	return (
		<Box sx={{ backgroundColor: AQUGA_NATURAL_10, borderRadius: 3 }}>
			<TableContainer sx={{ backgroundColor: AQUGA_NATURAL_10 }}>
				<Table
					sx={{
						minWidth: 700,
						[`& .${tableCellClasses.root}`]: {
							borderBottom: 'none',
						},
					}}
				>
					<TableHead>
						<TableRow>
							<StyledTableCell>
								<Typography variant="subtitle2">
									<FormattedMessage id="questions.list.services.expertise" />
								</Typography>
							</StyledTableCell>
							<StyledTableCell>
								<FormattedMessage id="questions.list.services.serviceProvided" />
								<Typography variant="subtitle2"></Typography>
							</StyledTableCell>
							<StyledTableCell>
								<FormattedMessage id="questions.list.services.answeringExpertise" />
								<Typography variant="subtitle2"></Typography>
							</StyledTableCell>
							<StyledTableCell></StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{items.map((item: Service) => {
							// Workaround - to be done properly with native css ellipsis
							let truncationTitle = item?.name;
							if (item?.name?.length >= 125) {
								truncationTitle = `${item.name.substring(0, 127)}...`;
							}

							let localizedTechnologyOther = '';
							const localizedTechnology = item?.technology?.map((technology) =>
								intl.formatMessage({
									id: `resources.service.topics.values.${technology}`,
								})
							);
							if (
								item?.technologyOther &&
								item?.technology?.includes(
									RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KINDS.VALUES.OTHER
								)
							) {
								localizedTechnologyOther = `${intl.formatMessage({
									id: 'resources.service.topics.values.other',
								})} : ${item?.technologyOther}`;
							}

							let localizedBusinessAreaOther;
							const localizedBusinessArea = item?.businessArea?.map((businessArea) =>
								intl.formatMessage({
									id: `resources.service.service.values.${businessArea}`,
								})
							);
							if (
								item?.businessAreaOther &&
								item?.businessArea?.includes(
									RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.VALUES.OTHER
								)
							) {
								localizedBusinessAreaOther = `${intl.formatMessage({
									id: 'resources.service.service.values.other',
								})} : ${item?.businessAreaOther}`;
							}
							if (item?.businessArea && item?.technology && truncationTitle)
								return (
									<StyledTableRow key={item.id}>
										<StyledTableCell component="th" scope="row">
											<Typography
												variant="body1"
												gutterBottom
												sx={{ wordWrap: 'break-word', width: '30rem', lineHeight: 1.5 }}
											>
												{truncationTitle}
											</Typography>
										</StyledTableCell>
										<StyledTableCell>
											<Typography variant="body1">{localizedTechnology?.join(' | ')}</Typography>
											{localizedTechnologyOther !== '' && (
												<Typography variant="body1">{localizedTechnologyOther}</Typography>
											)}
										</StyledTableCell>
										<StyledTableCell>
											<Typography variant="body1">
												{localizedBusinessArea?.join(' | ')}
											</Typography>
											{localizedBusinessAreaOther !== '' && (
												<Typography variant="body1">{localizedBusinessAreaOther}</Typography>
											)}
										</StyledTableCell>
										<StyledTableCell onClick={() => seeDetails(item?.id)}>
											<ArrowForwardIosIcon />
										</StyledTableCell>
									</StyledTableRow>
								);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default AnsweringExpertiseTable;

import {
	MutationRequestParticipantPasswordResetArgs,
	MutationResetParticipantPasswordArgs,
	MutationRegisterParticipantArgs,
	MutationVerifyParticipantArgs,
} from '@aquga/services/randevuApi/generatedTypes';
import { print } from 'graphql';
import gql from 'graphql-tag';

const LOGIN_PARTICIPANT = gql`
	mutation LOGIN_PARTICIPANT($email: String!, $password: String!) {
		loginParticipant(email: $email, password: $password) {
			token
		}
	}
`;

interface SignInPayload {
	email: string;
	password: string;
}
const signInParticipant = ({ email, password }: SignInPayload) => {
	return JSON.stringify({
		query: print(LOGIN_PARTICIPANT),
		variables: {
			email: String(email),
			password: String(password),
		},
	});
};

const LOGOUT_ME = gql`
	mutation LOGOUT_ME {
		logoutMe
	}
`;

const signOut = () => JSON.stringify({ query: print(LOGOUT_ME) });

const REQUEST_PARTICIPANT_PASSWORD_RESET = gql`
	mutation REQUEST_PARTICIPANT_PASSWORD_RESET($email: String!) {
		requestParticipantPasswordReset(email: $email)
	}
`;

const requestParticipantPasswordReset = ({ email }: MutationRequestParticipantPasswordResetArgs) => {
	return JSON.stringify({
		query: print(REQUEST_PARTICIPANT_PASSWORD_RESET),
		variables: {
			email: String(email),
		},
	});
};

const REGISTER_PARTICIPANT = gql`
	mutation REGISTER_PARTICIPANT(
		$email: String!
		$first_name: String!
		$last_name: String!
		$participant_tech_name: String!
	) {
		registerParticipant(
			email: $email
			first_name: $first_name
			last_name: $last_name
			participant_tech_name: $participant_tech_name
		)
	}
`;

const registerParticipant = ({
	first_name,
	last_name,
	email,
	participant_tech_name,
}: MutationRegisterParticipantArgs) => {
	return JSON.stringify({
		query: print(REGISTER_PARTICIPANT),
		variables: {
			email: String(email),
			first_name: String(first_name),
			last_name: String(last_name),
			participant_tech_name: String(participant_tech_name),
		},
	});
};

const RESET_PARTICIPANT_PASSWORD = gql`
	mutation RESET_PARTICIPANT_PASSWORD($token: String!, $password: String!) {
		resetParticipantPassword(token: $token, password: $password)
	}
`;

export const resetParticipantPassword = ({ password, token }: MutationResetParticipantPasswordArgs) => {
	return JSON.stringify({
		query: print(RESET_PARTICIPANT_PASSWORD),
		variables: {
			password: String(password),
			token: String(token),
		},
	});
};

const VERIFY_PARTICIPANT_ACCOUNT = gql`
	mutation VERIFY_PARTICIPANT_ACCOUNT($token: String!, $password: String!) {
		verifyParticipant(token: $token, password: $password)
	}
`;

const verifyParticipantAccount = ({ password, token }: MutationVerifyParticipantArgs) => {
	return JSON.stringify({
		query: print(VERIFY_PARTICIPANT_ACCOUNT),
		variables: {
			password: String(password),
			token: String(token),
		},
	});
};

export { signInParticipant, signOut, verifyParticipantAccount, requestParticipantPasswordReset, registerParticipant };

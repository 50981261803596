import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const RegistrationSubmittedView = () => {
	return (
		<Stack direction="column" gap={3} textAlign="center" sx={{ marginLeft: 2, marginRight: 2 }}>
			<Typography component="h1" variant="h4" marginBottom={4}>
				<FormattedMessage id="registerPage.emailSentView.title.normal" />{' '}
				<Typography component="span" color="primary" variant="h4">
					<FormattedMessage id="registerPage.emailSentView.title.colored" />
				</Typography>
			</Typography>
			<Typography variant="subtitle2">
				<FormattedMessage id="registerPage.emailSentView.subtitle" />
			</Typography>
			<Typography variant="body1">
				<FormattedMessage id="registerPage.emailSentView.explainer" />
			</Typography>
		</Stack>
	);
};

export default RegistrationSubmittedView;
